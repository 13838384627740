export interface DeviceTypes {
  id: number;
  name: string;
  allowAttribute?: boolean;
  children?: Array<DeviceTypes>;
  deviceTypes?: Array<DeviceTypes>;
  parentId?: number;
  parentName?: string;
  rootId?: number;
}

export interface CascadingMultipleData {
  label: string;
  value: number;
  child: Array<CascadingMultipleData> | undefined;
}

export interface DeviceTypeTree {
  aliasName: Array<string>;
  tree: Array<DeviceTypes>;
}

export function formatOptionData(response: DeviceTypeTree | undefined): Array<CascadingMultipleData> | undefined {
  const loop = (data: Array<DeviceTypes>): Array<CascadingMultipleData> => {
    return data.map(item => ({
      label: item.name,
      value: item.id,
      child: item.deviceTypes || item.children ? loop(item.deviceTypes || item.children!) : undefined,
    }));
  };
  if (!response) return undefined;
  return loop(response.tree);
}

export const getLastChildrenIds = (sourceData: DeviceTypes[]) => {
  let ids: number[] = [];
  const loop = (data: DeviceTypes[]) =>
    data.forEach(item => {
      if (!item.children) {
        ids.push(item.id);
        return;
      }
      loop(item.children);
    });

  loop(sourceData);
  return ids;
};

export const getChildNodesByParentIds = (treeData?: number[][], sourceData?: DeviceTypes[]) => {
  let ids: number[] = [];
  if (!treeData || !sourceData || sourceData.length === 0) return;
  treeData.forEach(nodes => {
    let tempData = sourceData.find(item => nodes.includes(item.id))?.deviceTypes;
    if (!tempData) return;

    const findData = (data: DeviceTypes[]) => {
      return data.some(item => {
        if (nodes.includes(item.id)) {
          if (item.children) {
            let flag = findData(item.children);
            if (!flag) {
              let temp = getLastChildrenIds(item.children);
              ids.push(...temp);
            }
          } else {
            ids.push(item.id);
          }
          return true;
        }
        return false;
      });
    };

    let flag = findData(tempData);
    if (!flag) {
      let temp = getLastChildrenIds(tempData);
      ids.push(...temp);
    }
  });
  return ids;
};
