import { Spin, Timeline, Tag } from 'antd';
import React, { FC, useEffect, useRef } from 'react';
import { useRequest } from 'ahooks';
import { isNil } from 'lodash-es';
import { apiV2DispatchUetStructPublishListPost } from '@maxtropy/intelligent-dispath-apis-v2';
// import { Tag } from '@maxtropy/components';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { Empty } from '@maxtropy/components';

export interface IVersionRecords {
  topologyId?: string;
  getCurrentTag: (tag?: number) => void;
}

const VersionRecords: FC<IVersionRecords> = props => {
  const { topologyId, getCurrentTag } = props;

  const { data, loading } = useRequest(
    async () => {
      if (isNil(topologyId)) return;
      const versionList = await apiV2DispatchUetStructPublishListPost({ id: +topologyId });
      if (versionList.list && versionList.list?.length > 0) {
        getCurrentTag(versionList.list[0].version);
        const versionRenderList = versionList?.list?.map((item, index) => {
          return {
            children: (
              <>
                <div>
                  <span className={styles.titleSty}>{item.version ?? '--'}</span>
                  {index === 0 && <Tag color="green">最新</Tag>}
                </div>
                <div className={styles.timeSty}>
                  {item.publishTime ? dayjs(item.publishTime).format('YYYY-MM-DD HH:mm:ss') : '--'}
                </div>
                <div className={styles.custNameSty}>操作人：{item.customerName}</div>
              </>
            ),
          };
        });
        return versionRenderList ?? [];
      }
    },
    { refreshDeps: [topologyId] }
  );

  return (
    <div style={{ width: 260, padding: 16, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div className={styles.publishTitle}>发布记录</div>
      <div className={styles.timeLineSty} style={{ flex: 1, overflowY: 'auto' }}>
        <Spin spinning={loading}>{data ? <Timeline items={data} /> : <Empty description={'暂无数据'} />}</Spin>
      </div>
    </div>
  );
};

export default VersionRecords;
