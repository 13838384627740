import { FC, useContext } from 'react';
import { ActionTypeEnum, BsaStrategyCalendarContext } from '../const';
import styles from './index.module.scss';
import StrategyDetail from './StrategyDetail';
import ActionView from './ActionView';

const RightComponent: FC = () => {
  const context = useContext(BsaStrategyCalendarContext);
  const { actionType } = context;

  return (
    <div className={styles.contentWrap}>{actionType === ActionTypeEnum.VIEW ? <StrategyDetail /> : <ActionView />}</div>
  );
};

export default RightComponent;
