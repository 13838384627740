import styles from './index.module.scss';
import antiBackflowDevice from '../../imgs/icon_antiBackflowDevice.gif';

const AntiBackflowDevice = () => {
  return (
    <div className={styles.customAntiBackFlowReactNode}>
      <div className={styles.vline}></div>
      <div className={styles.hline}></div>
      <div className={styles.cardMeterSty}>
        <img style={{ width: 24, height: 24, marginLeft: 12 }} src={antiBackflowDevice} alt="" />
        <div className={styles.itemControlobjContentSty}>防逆流点</div>
      </div>
    </div>
  );
};

export default AntiBackflowDevice;
