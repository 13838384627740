import { apiV2DispatchUetStructPublishBackupPowerDetailPost } from '@maxtropy/intelligent-dispath-apis-v2';
import { useRequest } from 'ahooks';
import { Descriptions, Drawer, Form, Spin } from 'antd';
import { isNil } from 'lodash-es';
import React, { FC, useState } from 'react';
import styles from './index.module.scss';
import ShowInput from '@/components/ShowInput';
import {
  BackUpPowerType,
  BackUpPowerTypeLabel,
} from '@/pages/EnergyDispatchManagement/ElementCreationModal/BackupPower/types';
import TitleTag from '../TitleTag';
import { NodeType } from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';

export interface BackupPowerDrawerProps {
  isShowModal: (open: boolean) => void;
  open: boolean;
  id?: number;
  version?: number;
}

const { Item } = Form;

const BackupPowerDrawer: FC<BackupPowerDrawerProps> = props => {
  const { isShowModal, open, id, version } = props;

  const [form] = Form.useForm();
  const [title, setTitle] = useState<string>();
  const [controlLabel, setControlLabel] = useState<String>();
  // const hasControlItem = Form.useWatch('hasControlItem', form);

  const { loading } = useRequest(
    async () => {
      const detail = await apiV2DispatchUetStructPublishBackupPowerDetailPost({ id: id!, version });
      setTitle(detail.backupPower?.name);
      setControlLabel(BackUpPowerTypeLabel[detail.backupPower?.type as BackUpPowerType]);
      form.setFieldsValue({
        ...detail,
        backupPowerId: detail.backupPower?.id,
        deviceId: detail.backupPower?.relatedDeviceId,
        NodeTypeName: '备用电源',
        name: detail.backupPower?.name,
        circuitName: detail.backupPower?.circuitName,

        deviceName: detail.backupPower?.relatedDeviceName,
      });
    },
    {
      ready: !isNil(id) && !isNil(version) && open,
      refreshDeps: [id, open, version],
    }
  );

  return (
    <div className={styles.drawerStyle}>
      <Drawer
        title={<TitleTag title={title ?? '备用电源'} nodeType={NodeType.SOURCE} />}
        placement="right"
        width={400}
        mask={false}
        onClose={() => {
          isShowModal?.(false);
        }}
        open={open}
        getContainer={false}
      >
        <Spin spinning={loading}>
          <Form form={form}>
            <Descriptions labelStyle={{ width: '40%' }} title={null} bordered column={1} size="small">
              <Descriptions.Item label="元素类型">
                <Item name="NodeTypeName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="备用电源名称">
                <Item name="name" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="关联回路">
                <Item name="circuitName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
            </Descriptions>

            <div style={{ marginTop: 16, marginBottom: 8 }}>控制对象信息</div>
            <Descriptions labelStyle={{ width: '40%' }} title={''} bordered column={1} size="small">
              <Descriptions.Item label={controlLabel}>
                <Item name="deviceName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
            </Descriptions>

            <Item name="hasControlItem" hidden />

            <div className={styles.preview}>
              <div className={styles.previewBtn} style={{ marginTop: 16, marginBottom: 8 }}>
                查看图标
              </div>

              <div className={styles.iconWrapper}>
                <>
                  <div className={styles.iconBox}>
                    <div className={styles.icon} />

                    <Item noStyle dependencies={['name']}>
                      {({ getFieldValue }) => {
                        const name = getFieldValue('name');
                        return <div className={styles.iconText}>{name}</div>;
                      }}
                    </Item>

                    {/* {hasControlItem && (
                      <>
                        <div className={styles.line} />
                        <div className={styles.controlArea}>
                          <Item noStyle dependencies={['deviceName']}>
                            {({ getFieldValue }) => {
                              const deviceName = getFieldValue('deviceName');
                              if (deviceName) {
                                return <div className={styles.controlItem}>{deviceName}</div>;
                              }
                            }}
                          </Item>
                        </div>
                      </>
                    )} */}
                  </div>
                </>
              </div>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </div>
  );
};

export default BackupPowerDrawer;
