import { createContext } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { V2BsaStrategyCalendarInfoPostResponse } from '@maxtropy/intelligent-dispath-apis-v2';

/**
 * 提交到后端的状态
 */
export enum OpChangeStatus {
  PUT = 1,
  STOP,
  START,
  DELETE,
  EDIT,
}

/**
 * 日历的操作状态, 不与后端交互
 */
export enum ActionTypeEnum {
  VIEW,
  PUT,
  STOP,
  START,
  DELETE,
  EDIT,
}

export const ActionTypeLabel = {
  [ActionTypeEnum.VIEW]: '查看',
  [ActionTypeEnum.PUT]: '投放',
  [ActionTypeEnum.START]: '启动',
  [ActionTypeEnum.STOP]: '停止',
  [ActionTypeEnum.DELETE]: '删除',
  [ActionTypeEnum.EDIT]: '编辑',
};

export enum StrategyStatusEnum {
  HAVE_RUN = 1,
  RUNNING = 2,
  TO_RUN = 3,
  HAVE_STOP = 4,
}

export enum ConnectStatusEnum {
  OFFLINE = 0,
  ONLINE = 1,
}

export const StrategyStatusLabel = {
  [StrategyStatusEnum.HAVE_RUN]: '已运行',
  [StrategyStatusEnum.RUNNING]: '运行中',
  [StrategyStatusEnum.TO_RUN]: '待运行',
  [StrategyStatusEnum.HAVE_STOP]: '已停止',
};

export type StrategyCalendarData = Exclude<V2BsaStrategyCalendarInfoPostResponse['list'], undefined>[number];

interface BsaStrategyCalendarProps {
  actionType?: ActionTypeEnum;
  setActionType?: (actionType: ActionTypeEnum) => void;
  viewDate?: Dayjs;
  setViewDate?: (date: Dayjs) => void;
  calendarShowDate?: string;
  setCalendarShowDate?: (date: string) => void;
  selectedDateList?: Dayjs[];
  setSelectedDateList?: (dateList: Dayjs[]) => void;
  strategyCalendarData?: StrategyCalendarData[];
  setStrategyCalendarData?: (data: StrategyCalendarData[]) => void;
  cellHeight?: number;
  getActionFormData?: any;
  setGetActionFormData?: any;
  connectStatus?: ConnectStatusEnum;
  update?: any;
  setUpdate?: () => void;
}

export const BsaStrategyCalendarContext = createContext<BsaStrategyCalendarProps>({});

export const MONTH_FORMAT = 'YYYY-MM';

export function checkCanSelect(date: Dayjs, strategyCalendarData: StrategyCalendarData[], actionType: ActionTypeEnum) {
  const today = dayjs();

  // 投放，只选择无策略的当日和未来日期
  if (
    actionType === ActionTypeEnum.PUT &&
    date.isSameOrAfter(today, 'd') &&
    !strategyCalendarData.some(item => dayjs(item.time).isSame(date, 'd'))
  )
    return true;
  // 启动，只选择已经停止且在当日和未来日期的策略
  else if (
    actionType === ActionTypeEnum.START &&
    date.isSameOrAfter(today, 'd') &&
    strategyCalendarData
      .filter(item => item.status === StrategyStatusEnum.HAVE_STOP)
      .some(item => dayjs(item.time).isSame(date, 'd'))
  )
    return true;
  // 停止，只选择已经启动且在当日和未来日期的策略
  else if (
    actionType === ActionTypeEnum.STOP &&
    date.isSameOrAfter(today, 'd') &&
    strategyCalendarData
      .filter(item => item.status === StrategyStatusEnum.RUNNING || item.status === StrategyStatusEnum.TO_RUN)
      .some(item => dayjs(item.time).isSame(date, 'd'))
  )
    return true;
  // 删除，只选择当日和未来日期已经停止的策略
  else if (
    actionType === ActionTypeEnum.DELETE &&
    date.isSameOrAfter(today, 'd') &&
    strategyCalendarData
      .filter(item => item.status === StrategyStatusEnum.HAVE_STOP)
      .some(item => dayjs(item.time).isSame(date, 'd'))
  )
    return true;

  return false;
}
