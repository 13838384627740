import { EllipsisSpan, Table } from '@maxtropy/components';
import { Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useRequest } from 'ahooks';
import { Link, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import { apiV2DispatchUetFcsDispatchStructFcsListPost } from '@maxtropy/intelligent-dispath-apis-v2';

const ControllerMange = () => {
  const { id } = useParams<{ id: string }>();
  // 列表
  const { data: list, loading } = useRequest(
    () => {
      return apiV2DispatchUetFcsDispatchStructFcsListPost({
        dispatchGroupId: Number(id), // 调度组id
      }).then(res => {
        return res?.list?.map(item => ({ ...item, ...item.fcsInfo, listId: item.id }));
      });
    },
    {
      ready: !!id,
      refreshDeps: [id],
    }
  );

  const columns: ColumnsType<any> = [
    {
      title: '控制器编号',
      dataIndex: 'serialNumber',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '控制器名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '控制器类型',
      dataIndex: 'type',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={'BESS'} />,
    },
    {
      title: '关联拓扑',
      dataIndex: 'dispatchStructName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '关联网关',
      dataIndex: 'edgeGatewayName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所在组网',
      dataIndex: 'edgeNetworkName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '控制软件版本',
      dataIndex: 'softwareVersion',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '操作',
      width: 200,
      render: (v: any, item: any) => {
        return (
          <Space size={16}>
            <Link
              to={`/energyAssetConstruction/energyTopologyManagement/energyDispatchManagement/basicInfo/${item.listId}`}
            >
              <span className={styles.drop_btn}>查看基础信息</span>
            </Link>
            <Link
              to={`/energyAssetConstruction/energyTopologyManagement/energyDispatchManagement/nodeSet/${item.listId}/${item.dispatchGroupId}/${item.dispatchUetStructId}`}
            >
              <span className={styles.drop_btn}>查看监测节点</span>
            </Link>
          </Space>
        );
      },
    },
  ];
  return <Table loading={loading} rowKey="id" columns={columns} dataSource={list} />;
};

export default ControllerMange;
