// @ts-ignore
import { microAppRewrite } from '@maxtropy/components';

if (window.__MICRO_APP_ENVIRONMENT__) {
  // @ts-ignore
  __webpack_public_path__ = '/' + window.__MICRO_APP_NAME__ + '/';
  microAppRewrite();
} else {
  // @ts-ignore
  __webpack_public_path__ = '/';
}
