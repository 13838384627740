import Page404 from './Page404';

const routes = [
  {
    path: '*',
    element: <Page404 />,
  },
];

export default routes;
