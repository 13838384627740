import bsaIcon from '@/assets/images/bsaIcon.png';
import styles from './index.module.scss';
import { FileSearchOutlined, InfoCircleOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { apiV2BsaDetailBsaV2Post } from '@maxtropy/intelligent-dispath-apis-v2';
import { Space } from 'antd';
import classname from 'classnames/bind';
import { Key, useMemo } from 'react';
const cx = classname.bind(styles);
interface Iprops {
  hasBg?: boolean;
  bsaId?: Key;
}
const BaseInfo = ({ hasBg, bsaId }: Iprops) => {
  const { id } = useParams();
  const _bsaId = useMemo(() => {
    return id || bsaId;
  }, [id, bsaId]);
  const { data: bsaData } = useRequest(
    () =>
      apiV2BsaDetailBsaV2Post({
        id: Number(_bsaId),
      }),
    {
      refreshDeps: [_bsaId],
      ready: !!_bsaId,
    }
  );

  return (
    <div className={cx(styles.topCard, hasBg && styles.card_bg)}>
      <Space split={<div className={styles.divider} />}>
        <div className={styles.itemWrap}>
          <img src={bsaIcon} alt="" className={styles.basIcon} />
          <div>{bsaData?.name || '--'}</div>
        </div>
        <div>
          <span className={styles.label}>软件版本：</span>
          <span className={styles.value}> {bsaData?.softwareVersion ?? '--'} </span>
        </div>
        <div>
          <span className={styles.label}>策略版本：</span>
          <span className={styles.value}> {bsaData?.strategyVersion ?? '--'} </span>
        </div>
      </Space>
    </div>
  );
};

export default BaseInfo;
