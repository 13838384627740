import { NodeType, TreeNode } from '@/pages/SchedulingTopology/type';
import { loopTreeTofindParent } from '@/pages/SchedulingTopology/utils/graph';
import { loopTreeTofindId } from '@/pages/SchedulingTopology/utils/utils';
import { Key } from 'react';

// 当前节点向上遍历，找到电网接入点截取
export function findTopGridAccessPointData(allNodeDataRef?: TreeNode[], id?: Key, element?: NodeType) {
  const loopNode = (
    allNodeDataRef?: TreeNode[],
    id?: number | string,
    element?: NodeType,
    node?: TreeNode
  ): TreeNode | null => {
    if (element === NodeType.GRID_ACCESS_POINT) {
      return loopTreeTofindId(allNodeDataRef ?? [], id!, element);
    }
    const findParentItem = loopTreeTofindParent(allNodeDataRef ?? [], id!, element!);
    if (findParentItem) {
      if (findParentItem.element !== NodeType.GRID_ACCESS_POINT) {
        return loopNode(allNodeDataRef, findParentItem.id, findParentItem.element, node);
      } else {
        return findParentItem;
      }
    } else {
      return null;
    }
  };
  const node = loopNode(allNodeDataRef ?? [], id!, element!);
  return node;
}
