import React, { useContext, useMemo } from 'react';
import styles from './index.module.scss';
import { AlarmStatus, AlarmStatusColorDisplay, AlarmStatusDisplay } from './type';
import { Spin } from 'antd';
import { Button, Empty, Tag, Tooltip } from '@maxtropy/components';
import dayjs from 'dayjs';
import { ShareDataContext } from '../..';
import { useRequest } from 'ahooks';
import {
  apiV2GreenMicrowebShowcaseAlarmCountPost,
  apiV2GreenMicrowebShowcaseAlarmPagePost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { isNil } from 'lodash-es';
import CustomTooltip from '../TopoBox/components/CustomTooltip';
interface Iprops {
  isShow?: boolean;
  initialH?: string;
}
const AlarmPageList = (props: Iprops) => {
  const { configId } = useContext(ShareDataContext);

  const { data: alarmList, loading } = useRequest(
    () =>
      apiV2GreenMicrowebShowcaseAlarmPagePost({
        id: configId,
        page: 1,
        size: 500,
      }).then(res => {
        return res.list;
      }),
    {
      ready: !!configId,
      refreshDeps: [configId],
    }
  );

  const { data: notRecoveredCount } = useRequest(
    async () => {
      const res = await apiV2GreenMicrowebShowcaseAlarmCountPost({
        id: configId,
      });
      return res.notRecoveredCount;
    },
    {
      ready: !!configId,
      refreshDeps: [configId],
    }
  );

  return (
    <div
      className={styles.alarm_wrapper_outer}
      style={{ maxHeight: props.initialH, height: alarmList?.length === 0 ? props.initialH : undefined }}
    >
      <div className={styles.alarm_wrapper_box}>
        <div className={styles.alarm_wrapper_header}>
          <div className={styles.alarm_wrapper_header_title}>报警信息</div>
          <div className={styles.alarm_wrapper_header_count}>
            未恢复报警数：<span className={styles.number}>{!isNil(notRecoveredCount) ? notRecoveredCount : '--'}</span>
          </div>
        </div>
        <div className={styles.alarm_wrapper_content}>
          {alarmList && alarmList.length > 0 ? (
            <Spin spinning={loading}>
              {alarmList.map(item => (
                <div className={styles.item_sty} key={item.id}>
                  <div className={styles.item_sty_top}>
                    <div className={styles.alarmName}>{item.alarmName}</div>

                    <Tag
                      border="solid"
                      color={AlarmStatusColorDisplay[item.alarmLevel as AlarmStatus]}
                      style={{ marginInlineEnd: 0 }}
                    >
                      {AlarmStatusDisplay[item.alarmLevel as AlarmStatus]}
                    </Tag>
                  </div>
                  <div className={styles.item_sty_bottom}>
                    <div className={styles.alarmTime}>
                      {item.alarmTime ? dayjs(item.alarmTime).format('YYYY-MM-DD HH:mm:ss') : '--'}
                    </div>
                    <div className={styles.divide_line}></div>
                    <CustomTooltip title={`${item.deviceName}-${item.deviceCode}`}>
                      <div className={styles.device_box}>
                        {item.deviceName}-{item.deviceCode}
                      </div>
                    </CustomTooltip>
                    <div className={styles.alarmDetail}>
                      <Button
                        type="link"
                        style={{ padding: 0, fontSize: 12 }}
                        onClick={() => {
                          window.open(`${window.IOTPLATFORMORIGIN}/device/alarm/record/detail/${item.id}`, '_blank');
                        }}
                      >
                        查看详情
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </Spin>
          ) : (
            <Empty style={{ margin: 'auto' }} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AlarmPageList;
