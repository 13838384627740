import {
  Button,
  EllipsisSpan,
  FormTitle,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  Wrapper,
} from '@maxtropy/components';
import styles from './index.module.scss';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { apiV2BsaStrategyLogPagePost, V2BsaStrategyLogPagePostResponse } from '@maxtropy/intelligent-dispath-apis-v2';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import RelateDateModal from './RelateDateModal';
import DetailModal from './DetailModal';

type DataItem = Exclude<V2BsaStrategyLogPagePostResponse['list'], undefined>[number];

export enum ActionTypeEnum {
  PUT = 1,
  STOP = 2,
  START = 3,
  DELETE = 4,
  EDIT = 5,
}

export const ActionTypeLabel = {
  [ActionTypeEnum.PUT]: '投放策略',
  [ActionTypeEnum.STOP]: '停止策略',
  [ActionTypeEnum.START]: '启动策略',
  [ActionTypeEnum.DELETE]: '删除策略',
  [ActionTypeEnum.EDIT]: '编辑策略',
};

const StrategyHistory = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const pageName = '投放记录';
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const pagingInfo = usePaging(50);
  const { pageSize, pageOffset, setTotalCount } = pagingInfo;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DataItem[]>([]);

  const [relateDateModalOpen, setRelateDateModalOpen] = useState(false);
  const [relateDateList, setRelateDateList] = useState<string[]>([]);

  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [detailId, setDetailId] = useState<number>();

  useEffect(() => {
    setLoading(true);
    apiV2BsaStrategyLogPagePost({
      page: pageOffset,
      size: pageSize,
      bsaId: +id!,
    })
      .then(res => {
        setTotalCount(res.total ?? 0);
        setData(res.list ?? []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageSize, pageOffset]);

  const columns: ColumnsType<DataItem> = [
    {
      title: '操作人',
      dataIndex: 'username',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v} />,
    },
    {
      title: '操作时间',
      dataIndex: 'updateTime',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
    },
    {
      title: '操作动作',
      dataIndex: 'actionType',
      ellipsis: { showTitle: true },
      render: (v: ActionTypeEnum) => <EllipsisSpan value={ActionTypeLabel[v]} />,
    },
    {
      title: '涉及日期',
      fixed: 'right',
      key: 'relateDate',
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => {
            setRelateDateList(record.dateList ?? []);
            setRelateDateModalOpen(true);
          }}
        >
          查看
        </Button>
      ),
    },
    {
      title: '策略变化',
      fixed: 'right',
      key: 'strategyChange',
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => {
            setDetailId(record.id);
            setDetailModalOpen(true);
          }}
        >
          查看
        </Button>
      ),
    },
  ];

  return (
    <Wrapper className={styles.wrap} routes={[...(breadcrumbRoutes?.routes ?? []), { name: pageName }]}>
      <FormTitle title={pageName} />

      <div className={styles.mainContent}>
        <Table scroll={{ x: 'max-content' }} pagination={false} loading={loading} columns={columns} dataSource={data} />
        <Paging pagingInfo={pagingInfo} />

        <Button
          onClick={() => {
            navigate('/energy-storage/basic/bsa/strategy/' + id);
          }}
        >
          返回
        </Button>
      </div>

      <RelateDateModal
        dateList={relateDateList}
        open={relateDateModalOpen}
        onClose={() => setRelateDateModalOpen(false)}
      />

      <DetailModal open={detailModalOpen} onClose={() => setDetailModalOpen(false)} id={detailId} />
    </Wrapper>
  );
};

export default StrategyHistory;
