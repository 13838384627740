import {
  Button,
  CustomFilter,
  EllipsisSpan,
  Form,
  Input,
  Modal,
  Paging,
  Select,
  Table,
  Tag,
  usePaging,
} from '@maxtropy/components';
import { Cascader, Space } from 'antd';
import { DefaultOptionType } from 'antd/es/cascader';
import React, { useEffect, useMemo, useState } from 'react';
import { OperationType, formateOperationTypeColor, formateOperationTypeDisplay, isJSON } from '../../type';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import { DeviceTypeTree, DeviceTypes, formatOptionData, getChildNodesByParentIds } from '../../utils';
import {
  V2InstructionConfigLogPagePostResponse,
  apiV2DeviceDeviceTypeTreePost,
  apiV2DeviceListPost,
  apiV2InstructionConfigLogPagePost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { isNil } from 'lodash-es';

interface SearchParams {
  deviceType?: number[];
  deviceId?: number;
}

const viewTableTitle = [
  {
    title: '字段',
    dataIndex: 'op',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '原始数据',
    dataIndex: 'before',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '编辑后数据',
    dataIndex: 'after',
    render: (v: string, record: any) => (
      <EllipsisSpan
        value={<span className={v === record.before ? styles.normal : styles.highlight}>{v ?? '--'}</span>}
      />
    ),
  },
];

const columsTemplate = [
  {
    title: '指令名称',
    dataIndex: 'instructionName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '按钮名称',
    dataIndex: 'buttonName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '应用设备',
    dataIndex: 'deviceName',
    render: (v: number) => <EllipsisSpan value={v} />,
  },

  {
    title: '目标数据属性',
    dataIndex: 'dataPropertyName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '目标值',
    dataIndex: 'targetValue',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const tableColumns = [
  {
    title: '编辑时间',
    dataIndex: 'updateTime',
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
  },
  {
    title: '编辑人',
    dataIndex: 'staffUsername',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '操作类型',
    dataIndex: 'operationType',
    ellipsis: { showTitle: true },
    render: (v: OperationType) => (
      <EllipsisSpan
        value={
          <Tag border="solid" color={formateOperationTypeColor[v]}>
            {formateOperationTypeDisplay[v]}
          </Tag>
        }
      />
    ),
  },
  {
    title: '应用设备',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'deviceTypeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '具体操作',
    dataIndex: 'description',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

type RowInfo = Exclude<V2InstructionConfigLogPagePostResponse['list'], undefined>[number];

const OperationRecordsTable = () => {
  const [form] = Form.useForm();
  const pagingInfo = usePaging(20);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [visible, setVisible] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<RowInfo>();
  const deviceType: number[][] | undefined = Form.useWatch('deviceType', form);

  // 表格列表
  const { data: list, loading: tableLoading } = useRequest(
    () =>
      apiV2InstructionConfigLogPagePost({
        ...searchParams,
        page: pageOffset,
        pageSize: pageSize,
      }).then(res => {
        setTotalCount(res.total!);
        return res.list;
      }),

    {
      refreshDeps: [pageOffset, pageSize, searchParams],
    }
  );

  // 所属类目
  const { data: deviceTypeData } = useRequest(() => {
    return apiV2DeviceDeviceTypeTreePost();
  });

  // 目标设备下拉
  const { data: targetDeviceOptions } = useRequest(
    async () => {
      if (deviceType?.length === 0 || isNil(deviceType)) {
        return [];
      } else {
        const deviceTypeIds = getChildNodesByParentIds(deviceType, deviceTypeData?.tree as DeviceTypes[]);
        const res = await apiV2DeviceListPost({ deviceType: deviceTypeIds });
        if (res?.list) {
          return res.list;
        }
      }
    },
    {
      refreshDeps: [deviceType],
    }
  );

  const viewDataSource = useMemo(() => {
    if (!currentRow) return [];

    let { beforeRecord, afterRecord } = currentRow;
    let beforeDataOld = isJSON(beforeRecord) ? JSON.parse(beforeRecord!) : {};
    let afterDataOld = isJSON(afterRecord) ? JSON.parse(afterRecord!) : {};
    let beforeData = {
      ...beforeDataOld,
      ...(isJSON(beforeDataOld.parameter) ? JSON.parse(beforeDataOld.parameter) : {}),
    };
    let afterData = { ...afterDataOld, ...(isJSON(afterDataOld.parameter) ? JSON.parse(afterDataOld.parameter) : {}) };

    return columsTemplate.map(item => ({
      op: item.title,
      before: beforeData[item.dataIndex],
      after: afterData[item.dataIndex],
    }));
  }, [currentRow]);

  const mergeColunms = [
    ...tableColumns,
    {
      title: '操作内容',
      width: 110,
      fixed: 'right' as const,
      render: (v: string, record: RowInfo) => (
        <Space size={16}>
          <Button
            type="link"
            onClick={() => {
              setCurrentRow(record);
              setVisible(true);
            }}
          >
            查看
          </Button>
        </Space>
      ),
    },
  ];

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const filters = (
    <CustomFilter
      form={form}
      onFinish={(values: any) => {
        const deviceTypeIds = getChildNodesByParentIds(values.deviceType, deviceTypeData?.tree as DeviceTypes[]);
        setPageOffset(1);
        setSearchParams({ ...values, deviceType: deviceTypeIds });
      }}
      onReset={() => {
        setPageOffset(1);
        form.resetFields();
        setSearchParams({});
      }}
    >
      <Form.Item name="deviceType" label="所属类目">
        <Cascader
          options={formatOptionData(deviceTypeData as DeviceTypeTree)}
          allowClear={false}
          fieldNames={{ children: 'child' }}
          multiple
          maxTagCount="responsive"
          showSearch={{ filter }}
          placeholder={'请选择所属类目'}
          onChange={() => form.setFieldValue('deviceId', undefined)}
        />
      </Form.Item>

      <Form.Item label="目标设备" name="deviceId">
        <Select
          options={targetDeviceOptions?.map(item => ({
            value: item.id,
            label: item.name,
          }))}
          placeholder="请选择"
          allowClear
          showSearch
          optionFilterProp="label"
        />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <div className={styles.listCon}>
      {filters}
      <div className={styles.table_box}>
      <Table rowKey={() => Math.random()} columns={[...mergeColunms]} loading={tableLoading} dataSource={list} />
      <Paging pagingInfo={pagingInfo} />
      </div>
      {visible && (
        <Modal maskClosable={false} open title="查看" onCancel={() => setVisible(false)} onOk={() => setVisible(false)}>
          <Table rowKey="op" columns={viewTableTitle} dataSource={viewDataSource} />
        </Modal>
      )}
    </div>
  );
};

export default OperationRecordsTable;
