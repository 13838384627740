import styles from './index.module.scss';
import PV from '../../imgs/icon_PV.gif';

const PVa = () => {
  return (
    <div className={styles.customPVaReactNode}>
      <div className={styles.vline}></div>
      <div className={styles.cardSty}>
        <div className={styles.topLine}></div>
        <img style={{ width: 40, height: 40, marginTop: 6 }} src={PV} alt="" />
        <div className={styles.itemControlobjContentSty}>1MW分布式屋顶光伏站</div>
        <div className={styles.pvaStitleSty}>并网点2/2</div>
      </div>
    </div>
  );
};

export default PVa;
