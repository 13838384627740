import React, { FC, useEffect, useMemo, useRef } from 'react';
import styles from './index.module.scss';
import { Graph, Node } from '@antv/x6';
import { TimeDimensionEnm, TimeDimensionEnmDisplay, TimeDimensionEnmDisplayParams, TreeNode } from '../../type';
import { V2GreenMicrowebShowcaseDataPropertyDataPostResponse } from '@maxtropy/intelligent-dispath-apis-v2';
import { ITEM_HEIGHT, treeItemProps } from '../..';
import { isNil } from 'lodash-es';
import { calcMaxLength, handleCommonExpand, judgeDrageOrClick } from '../../utils';
import { Tooltip } from 'antd';
import { DownOutlined, InfoCircleOutlined, UpOutlined } from '@ant-design/icons';
import { keepTwoNull } from '@/pages/MicrogridConfOverview/utils';
import classNames from 'classnames';
import { iconArr } from '@/pages/SchedulingTopology/utils/utils';
import { getRealUrl } from '@maxtropy/components';

export interface OtherVerticalProps {
  node: Node;
  graphRef?: Graph;
  allNodeDataRef?: TreeNode[];
  configId?: number;
}

const OtherVertical: FC<OtherVerticalProps> = props => {
  const { node, graphRef, allNodeDataRef, configId } = props;
  const refBox = useRef<HTMLDivElement>();

  const data = useMemo(() => {
    return node.getData();
  }, [node.getData()]);

  // 获取数据点数据信息
  const dataPropertyDataList: V2GreenMicrowebShowcaseDataPropertyDataPostResponse['list'] = useMemo(() => {
    return data?.value?.deviceInfos;
  }, [data]);

  // 获取数据点信息
  const dataPropertyConfigsList: treeItemProps['dataPropertyConfigs'] = useMemo(() => {
    return data?.value?.dataPropertyConfigs ?? [];
  }, [data]);

  // 平铺当前元素的数据点(如果没有数据点数据返回，则用树形结构中的树)
  const allDataPropertyConfigsList = useMemo(() => {
    if (!isNil(dataPropertyDataList)) {
      const dataConfigsList = (dataPropertyConfigsList ?? [])
        .map(item => {
          return item.list?.map(c => ({
            ...c,
            deviceId: item.deviceId,
          }));
        })
        .flat();
      const dataPropertysList = (dataPropertyDataList ?? [])
        .map(item => {
          return item.list;
        })
        .flat();

      return (dataConfigsList ?? []).map((el: any) => ({
        ...el,
        value: dataPropertysList?.find((item: any) => item.dataPropertyId == el.dataPropertyId)?.value,
        old: dataPropertysList?.find((item: any) => item.dataPropertyId == el.dataPropertyId)?.old,
      }));
    } else {
      return (dataPropertyConfigsList ?? [])
        .map(item => {
          return item.list?.map(c => ({
            ...c,
            deviceId: item.deviceId,
          }));
        })
        .flat();
    }
  }, [dataPropertyConfigsList, dataPropertyDataList]);

  // 数据过于陈旧展示
  const oldDataList = useMemo(() => {
    return allDataPropertyConfigsList
      .filter(item => item.old)
      .map(i => i.dataPropertyName)
      .join();
  }, [allDataPropertyConfigsList]);

  // 区分拖动与点击事件
  useEffect(() => {
    if (refBox.current) {
      judgeDrageOrClick(refBox.current, configId!, data?.id, data?.element);
    }
  }, []);

  const onExpand = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    node.updateData({
      isExpand: !data.isExpand,
    });
    // const height = !data.isExpand ? 174 + 32 * (allDataPropertyConfigsList ?? []).length : 174;
    const height = !data.isExpand ? 174 + ITEM_HEIGHT * calcMaxLength((allDataPropertyConfigsList ?? []).length) : 174;
    handleCommonExpand(
      data?.value?.id,
      data?.element,
      node,
      height,
      !data.isExpand,
      allDataPropertyConfigsList.length,
      graphRef,
      allNodeDataRef
    );
  };

  return (
    <div
      className={styles.otherVerticalNode}
      // paadingmargin+原本尺寸+收起高度
      style={{ height: data.isExpand ? 174 + ITEM_HEIGHT * calcMaxLength(allDataPropertyConfigsList.length) : 174 }}
    >
      <div className={styles.vlineTop}></div>
      <div className={styles.content}>
        <div className={styles.cardSty} style={{ marginTop: allDataPropertyConfigsList.length > 0 ? 'unset' : 8 }}>
          <div
            className={styles.top_card}
            ref={d => {
              if (d) {
                refBox.current = d;
              }
            }}
          >
            <img
              style={{ width: 40, height: 40, marginTop: 6 }}
              src={
                data?.value?.file
                  ? getRealUrl(data?.value?.file)
                  : iconArr.find(i => i.value === data?.value?.icon)?.img
              }
              alt=""
            />
            <Tooltip title={data.value.name}>
              <div className={styles.itemControlobjContentSty}>{data.value.name}</div>
            </Tooltip>

            {oldDataList && oldDataList.length > 0 && (
              <Tooltip title={`数据过于陈旧：${oldDataList}`}>
                <InfoCircleOutlined className={styles.infoIcon} />
              </Tooltip>
            )}
          </div>
          <div className={styles.expand_content}>
            {data.isExpand && allDataPropertyConfigsList.length > 0 ? (
              <>
                <div className={styles.dividerSty} />
                <div className={styles.device_prop_wrapper}>
                  {(allDataPropertyConfigsList ?? []).map(item => (
                    <div
                      key={item?.dataPropertyId}
                      className={styles.item_sty}
                      onClick={() => {
                        window.open(
                          `${window.IOTPLATFORMORIGIN}/data/history/device?deviceId=${item?.deviceId}&dataPropertyId=${
                            item?.dataPropertyId
                          }&dateMode=${
                            !isNil(item?.timeDimensionEnum) &&
                            TimeDimensionEnmDisplayParams[item.timeDimensionEnum as TimeDimensionEnm]
                          }`,
                          '_blank'
                        );
                      }}
                    >
                      {(item?.timeDimensionEnum as TimeDimensionEnm) !== TimeDimensionEnm.REAL_TIME_VALUE && (
                        <Tooltip
                          title={
                            !isNil(item.timeDimensionEnum)
                              ? TimeDimensionEnmDisplay[item.timeDimensionEnum as TimeDimensionEnm] + '数据'
                              : '--'
                          }
                        >
                          <div className={styles.corner}></div>
                        </Tooltip>
                      )}
                      <div className={styles.label_sty}>
                        <Tooltip title={item?.dataPropertyName ?? '--'}>{item?.dataPropertyName ?? '--'}</Tooltip>
                      </div>
                      <div
                        className={
                          item?.timeDimensionEnum !== TimeDimensionEnm.REAL_TIME_VALUE
                            ? styles.value_sty
                            : styles.real_value_sty
                        }
                      >
                        <Tooltip title={keepTwoNull(item?.value) + (item?.generalName ?? '')}>
                          {keepTwoNull(item?.value) + (item?.generalName ?? '')}
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {allDataPropertyConfigsList.length > 0 && (
          <div className={styles.expand_box} onClick={e => onExpand(e)}>
            {data.isExpand ? (
              <UpOutlined className={classNames(styles.nodeExpandIcon)} />
            ) : (
              <DownOutlined className={styles.nodeExpandIcon} />
            )}
          </div>
        )}
      </div>
      {data.value.hasChildren && <div className={styles.vlineBottom} />}
    </div>
  );
};

export default OtherVertical;
