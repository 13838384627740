import React, { FC, useCallback, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { Descriptions, Drawer, Form, Spin } from 'antd';
import { useRequest } from 'ahooks';
import { isNil } from 'lodash-es';
import { apiV2DispatchUetStructPublishMeterDetailPost } from '@maxtropy/intelligent-dispath-apis-v2';
import ShowInput from '@/components/ShowInput';
import { NodeType, NodeTypeDisplay } from '@/pages/SchedulingTopology/type';
import {
  ElectricityType,
  ElectricityTypeLabel,
  NodeType as NodeTypeClass,
} from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import bsaMeter from './imgs/bsaMeter.png';
import bsaMeterDash from './imgs/bsaMeterDash.png';
import loadMeter from './imgs/loadMeter.png';
import loadMeterDash from './imgs/loadMeterDash.png';
import soureMeter from './imgs/soureMeter.png';
import soureMeterDash from './imgs/soureMeterDash.png';
import transformerMeter from './imgs/transformerMeter.png';
import transformerMeterDash from './imgs/transformerMeterDash.png';
import TitleTag from '../TitleTag';

export interface MeteringMeterDrawerProps {
  isShowModal: (open: boolean) => void;
  open: boolean;
  id?: number;
  version?: number;
  type?: NodeType;
}

const { Item } = Form;

const MeteringMeterDrawer: FC<MeteringMeterDrawerProps> = props => {
  const { isShowModal, open, id, version, type } = props;
  const [form] = Form.useForm();
  const [title, setTitle] = useState<string>();

  const { data, loading } = useRequest(
    async () => {
      if (isNil(id) || !open) return Promise.resolve(undefined);
      if (!isNil(version) && !isNil(type)) {
        const detail = await apiV2DispatchUetStructPublishMeterDetailPost({ id, version });
        setTitle(detail?.deviceName);
        form.setFieldsValue({
          ...detail,
          NodeTypeName: !isNil(type) ? NodeTypeDisplay[type as NodeType] : '--',
          monitorObjName: detail?.monitorObject?.name,
          ammeterTypeName: !isNil(detail?.ammeterType)
            ? ElectricityTypeLabel[detail?.ammeterType as ElectricityType]
            : '--',
        });
        return detail;
      }
    },
    {
      refreshDeps: [id, open, version, type],
    }
  );

  const meterType = useMemo(() => {
    if (type === NodeType.GRID_ACCESS_BILLING_POINT || type === NodeType.PVA_BILLING_POINT) {
      return NodeTypeClass.SOURCE;
    } else if (type === NodeType.ANTI_OVERCAPACITY_POINT) {
      return NodeTypeClass.TRANSFORMER;
    } else if (type === NodeType.BSA_BILLING_POINT || type === NodeType.BSA_GRID_CONNECTION_BILLING_POINT) {
      return NodeTypeClass.STORAGE;
    } else if (type === NodeType.CHARGING_STATION_BILLING_POINT || type === NodeType.LOAD_BILLING_POINT) {
      return NodeTypeClass.LOAD;
    }
  }, [type]);

  const renderImg = useCallback(
    (ammeterType: ElectricityType) => {
      if (meterType === NodeTypeClass.SOURCE) {
        if (ammeterType === ElectricityType.REAL) {
          return soureMeter;
        } else {
          return soureMeterDash;
        }
      } else if (meterType === NodeTypeClass.TRANSFORMER) {
        if (ammeterType === ElectricityType.REAL) {
          return transformerMeter;
        } else {
          return transformerMeterDash;
        }
      } else if (meterType === NodeTypeClass.STORAGE) {
        if (ammeterType === ElectricityType.REAL) {
          return bsaMeter;
        } else {
          return bsaMeterDash;
        }
      } else if (meterType === NodeTypeClass.LOAD) {
        if (ammeterType === ElectricityType.REAL) {
          return loadMeter;
        } else {
          return loadMeterDash;
        }
      }
    },
    [meterType]
  );

  const borderLeftColor = useMemo(() => {
    if (meterType === NodeTypeClass.TRANSFORMER) {
      return '#00ADFF';
    } else if (meterType === NodeTypeClass.SOURCE) {
      return '#16DD8E';
    } else if (meterType === NodeTypeClass.LOAD) {
      return '#ABD335';
    } else if (meterType === NodeTypeClass.STORAGE) {
      return '#CE90D1';
    }
  }, [meterType]);

  const belongNodeTypeClasss = useMemo(() => {
    if (type === NodeType.ANTI_OVERCAPACITY_POINT) {
      return '防超容点';
    } else if (type === NodeType.BSA_GRID_CONNECTION_BILLING_POINT) {
      return '储能并网点';
    } else {
      return '计费点';
    }
  }, [type]);

  return (
    <div className={styles.drawerStyle}>
      <Drawer
        title={<TitleTag title={title ?? belongNodeTypeClasss} nodeType={meterType} />}
        placement="right"
        width={400}
        mask={false}
        onClose={() => {
          isShowModal?.(false);
        }}
        open={open}
        getContainer={false}
      >
        <Spin spinning={loading}>
          <Form form={form}>
            <Descriptions labelStyle={{ width: '40%' }} title={null} bordered column={1} size="small">
              <Descriptions.Item label="元素类型">
                <Item name="NodeTypeName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="电表编号">
                <Item name="code" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="电表名称">
                <Item name="deviceName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="监测对象">
                <Item name="monitorObjName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="电表类型">
                <Item name="ammeterTypeName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
            </Descriptions>

            <Item name="ammeterType" hidden />

            <div className={styles.preview}>
              <div className={styles.previewBtn} style={{ marginTop: 16, marginBottom: 8 }}>
                查看图标
              </div>

              <div className={styles.iconWrapper}>
                <div className={styles.iconBoxMeters} style={{ borderLeft: `3px solid ${borderLeftColor}` }}>
                  <Item noStyle dependencies={['ammeterType']}>
                    {({ getFieldValue }) => {
                      const ammeterType = getFieldValue('ammeterType');

                      return (
                        <div
                          className={styles.icon}
                          style={{
                            backgroundImage: `url(${renderImg(ammeterType)})`,
                          }}
                        />
                      );
                    }}
                  </Item>

                  <Item noStyle dependencies={['deviceName']}>
                    {({ getFieldValue }) => (
                      <div className={styles.iconText}>
                        {/* {NodeTypeDisplay[type as NodeType]}--{getFieldValue('deviceName')} */}
                        {/* {belongNodeTypeClasss}-{getFieldValue('deviceName')} */}
                        {getFieldValue('deviceName')}({NodeTypeDisplay[type as NodeType]})
                      </div>
                    )}
                  </Item>
                </div>
              </div>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </div>
  );
};

export default MeteringMeterDrawer;
