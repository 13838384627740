import { Descriptions, Drawer, Form, Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import styles from './index.module.scss';
import ShowInput from '@/components/ShowInput';
import { useRequest } from 'ahooks';
import { apiV2DispatchUetStructPublishFcsDetailPost } from '@maxtropy/intelligent-dispath-apis-v2';
import TitleTag from '../TitleTag';
import { NodeType } from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';

export interface FcsControllerDrawerProps {
  isShowModal: (open: boolean) => void;
  open: boolean;
  id?: number;
  version?: number;
}

const { Item } = Form;

const FcsControllerDrawer: FC<FcsControllerDrawerProps> = props => {
  const { isShowModal, open, id, version } = props;

  const [form] = Form.useForm();
  const [title, setTitle] = useState<string>();

  const { data: detail, loading } = useRequest(
    () => {
      return apiV2DispatchUetStructPublishFcsDetailPost({ id: id!, version });
    },
    {
      ready: !!id && !!open && !!version,
      refreshDeps: [id, open, version],
    }
  );
  useEffect(() => {
    if (detail) {
      form.setFieldsValue({
        ...detail.fcsInfo,
        id: detail.id,
        fcsId: detail?.fcsInfo?.id,
        NodeTypeName: '就地控制系统',
        fcsName: detail.fcsInfo?.name,
        fcsCode: detail.fcsInfo?.serialNumber,
        fcsType: 'BESS',
        fcsSoftwareVersion: detail.fcsInfo?.softwareVersion,
        bindGateway: detail.fcsInfo?.edgeGatewayName,
        belongNetWork: detail.fcsInfo?.edgeNetworkName,

        accessPointItem: detail.accessPointItem?.name,
        bsaItemList: detail.bsaItemList?.map(i => i.name).join(),
        pvItemList: detail.pvItemList?.map(i => i.name).join(),
        // loadItemList: detail.loadItemList?.map(i => i.name).join(),
        // transformerItemList: detail.transformerItemList?.map(i => i.name).join(),
      });
      setTitle(detail.fcsInfo?.name);
    }
  }, [detail, form]);

  return (
    <div className={styles.drawerStyle}>
      <Drawer
        title={<TitleTag title={title ?? '就地控制系统'} nodeType={NodeType.NETWORK} />}
        placement="right"
        width={400}
        mask={false}
        onClose={() => {
          isShowModal?.(false);
        }}
        open={open}
        getContainer={false}
      >
        <Spin spinning={loading}>
          <Form form={form}>
            <Descriptions labelStyle={{ width: '40%' }} title={'FCS信息'} bordered column={1} size="small">
              <Descriptions.Item label="元素类型">
                <Item name="NodeTypeName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="FCS名称">
                <Item name="fcsName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="FCS编号">
                <Item name="fcsCode" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="FCS类型">
                <Item name="fcsType" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="FCS软件版本">
                <Item name="fcsSoftwareVersion" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="绑定网关">
                <Item name="bindGateway" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="所在组网">
                <Item name="belongNetWork" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
            </Descriptions>

            <Descriptions
              style={{ marginTop: 10 }}
              labelStyle={{ width: '40%' }}
              title={'监控节点'}
              bordered
              column={1}
              size="small"
            >
              <Descriptions.Item label="电网接入点">
                <Item name="accessPointItem" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="储能站">
                <Item name="bsaItemList" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="光伏站">
                <Item name="pvItemList" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              {/* <Descriptions.Item label="负载">
                <Item name="loadItemList" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="变压器">
                <Item name="transformerItemList" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item> */}
            </Descriptions>

            <div className={styles.preview}>
              <div className={styles.previewBtn} style={{ marginTop: 16, marginBottom: 8 }}>
                查看图标
              </div>

              <div className={styles.iconWrapper}>
                <div className={styles.iconBox}>
                  <div className={styles.icon} />

                  <Item noStyle dependencies={['fcsName']}>
                    {({ getFieldValue }) => <div className={styles.iconText}>{getFieldValue('fcsName')}</div>}
                  </Item>
                </div>
              </div>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </div>
  );
};

export default FcsControllerDrawer;
