import { Wrapper, useBreadcrumbRoutes, useTenantPermissions, Empty, Tabs } from '@maxtropy/components';
import React, { useEffect, useMemo, useState } from 'react';

import styles from './index.module.scss';
import Top from '@/pages/EnergyDispatchManagement/Edit/components/Top';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TabPane from 'antd/es/tabs/TabPane';
import Topology from '@/pages/EnergyDispatchManagement/Edit/Topology';
import ControllerMange from './ControllerMange';
import { PermissionsType } from '@/common/permissionsConst';

enum TabKeys {
  // 调度拓扑结构
  SCHEDULING_TOPOLOGY = 'schedulingTopology',
  CONTROLLER_MANAGE = 'controllerManage',
}
const tabList = [
  {
    title: '调度拓扑结构',
    key: TabKeys.SCHEDULING_TOPOLOGY,
    permission: PermissionsType.P_EDT,
    children: (detail: boolean, customerMcids: string[]) => <Topology detail={detail} customerMcids={customerMcids} />,
  },
  {
    title: '控制器管理',
    key: TabKeys.CONTROLLER_MANAGE,
    permission: PermissionsType.P_CONTROLLER,
    children: () => <ControllerMange />,
  },
];
interface Props {
  detail?: boolean;
}
const EnergyDispatchManagementEdit: React.FC<Props> = ({ detail }) => {
  const { id, key: routerKey } = useParams<{ id: string; key: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const permission = useTenantPermissions();

  // 组织id
  const [customerMcids, setCustomerMcids] = useState<string[]>([]);

  const onTabChange = (key: string) => {
    const publicPath = location.pathname.split(id!)[0].slice(0, -1);
    navigate(`${publicPath}/${id}/${key}`);
  };

  useEffect(() => {
    if (!routerKey) {
      navigate(`${TabKeys.SCHEDULING_TOPOLOGY}`, { replace: true });
    }
  }, [id, routerKey, navigate]);

  // 拓扑结构tab

  const topologyTab = (
    <>
      {tabList.map(item => {
        return (
          <TabPane tab={item.title} key={item.key}>
            {permission?.includes(item.permission) || !item.permission ? (
              item.children.call(this, !!detail, customerMcids)
            ) : (
              <Empty style={{ margin: 0, padding: '60px 0' }} description={'暂无功能权限，请联系系统管理员'} />
            )}
          </TabPane>
        );
      })}
    </>
  );

  const routes = useMemo(() => {
    return [{ name: `${detail ? '查看' : '编辑'}调度组基础信息` }];
  }, [detail]);

  // ChargingStation

  return (
    <>
      <Wrapper
        routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}
        filters={<Top detail={detail} customerMcids={customerMcids} setCustomerMcids={val => setCustomerMcids(val)} />}
        filtersStyle={{
          position: 'sticky',
          top: 0,
          padding: 0,
          zIndex: 1000,
          boxShadow: '2px 2px 5px 0px rgba(0, 0, 0, 0.35)',
        }}
      >
        <Tabs className={styles.tabs} activeKey={routerKey} onChange={onTabChange}>
          {topologyTab}
        </Tabs>
      </Wrapper>
    </>
  );
};

export default EnergyDispatchManagementEdit;
