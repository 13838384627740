import { PermissionsType } from '@/common/permissionsConst';
import InstructionConfig from '.';
import ControlInstructionEdit from './components/ControlInstructionEdit';

const routes = [
  {
    path: '/microgrid/scheduling/instructionConfig',
    permission: PermissionsType.P_INSTRUCTIONCONFIGURATION,
    element: <InstructionConfig />,
  },
  {
    path: '/microgrid/scheduling/instructionConfig/edit',
    permission: PermissionsType.P_INSTRUCTIONCONFIGURATION,
    element: <ControlInstructionEdit />,
  },
  {
    path: '/microgrid/scheduling/instructionConfig/edit/:id',
    permission: PermissionsType.P_INSTRUCTIONCONFIGURATION,
    element: <ControlInstructionEdit />,
  },
];

export default routes;
