import React, { FC, Key, useEffect, useState } from 'react';
import {
  Form,
  useBreadcrumbRoutes,
  Wrapper,
  usePaging,
  Table,
  Paging,
  Button,
  Select,
  EllipsisSpan,
  Tag,
  Modal,
  CustomFilter,
  Checkbox,
  DatePicker,
} from '@maxtropy/components';
import { Col, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useRequest } from 'ahooks';
import {
  apiV2RemoteControlPagePost,
  apiV2RemoteControlResetAllPost,
  apiV2RemoteControlResetPost,
  V2RemoteControlPagePostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import dayjs, { Dayjs } from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './index.module.scss';

import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';

import { AlarmLevel, AlarmLevelColorDisplay, AlarmLevelDisplay } from '../types';
import BaseInfo from '../RulesConfig/components/BaseInfo';
import { ButtonType, buttonTypeDisplay } from '../RulesConfig/components/TableList';
import { BasicDataNode } from 'antd/es/tree';

const { RangePicker } = DatePicker;

interface SearchParams {
  notResume?: boolean;
  date?: (Dayjs | null)[];
  from?: number;
  to?: number;
  level?: number[];
}

type DataListItem = Exclude<V2RemoteControlPagePostResponse['list'], undefined>[number];

// 默认一周
const defaultDate = [dayjs().subtract(6, 'day'), dayjs()];

const formateUnitForCapacity = (num?: number) => {
  if (!num) return '--';
  return num < 1 ? `${1000 * num}kWh` : `${num}MWh`;
};

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const routes = [{ name: `远控复位` }];
const RemoteReset: FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const { id: bsaId } = useParams();
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    date: defaultDate,
    notResume: true,
  });
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  useEffect(() => {
    form.setFieldsValue({
      date: defaultDate,
    });
  }, []);
  const hasControlConfigEdit = useHasPermission(PermissionsType.B_CONTROL_CONFIGURATION_EDIT);
  const hasStrategy = useHasPermission(PermissionsType.B_CHARGING_AND_DISCHARGING_STRATEGY);

  const {
    data,
    loading: listLoading,
    refresh: refreshList,
  } = useRequest(
    () => {
      let date = searchParams?.date;
      return apiV2RemoteControlPagePost({
        page: pageOffset,
        size: pageSize,
        notResume: searchParams?.notResume,
        neoBsaId: bsaId,
        level: searchParams?.level ?? [],
        from: date?.[0] ? dayjs(date[0]).startOf('day').valueOf() : undefined,
        to: date?.[1] ? dayjs(date[1]).endOf('day').valueOf() : undefined,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list;
      });
    },
    {
      ready: !!bsaId,
      refreshDeps: [searchParams, pageOffset, pageSize, bsaId],
    }
  );
  const [resetLoading, setResetLoading] = useState(false);
  // 复位
  const resetBtn = ({ alarmId, type }: { alarmId?: Key; type?: 'single' | 'all' }) => {
    if (!bsaId) return;
    if (resetLoading) return;
    const modal = Modal.info({
      title: '正在复位...',
      footer: null,
      keyboard: false,
    });
    setResetLoading(true);
    const api =
      type === 'single'
        ? apiV2RemoteControlResetPost({
            id: alarmId,
            neoBsaId: bsaId,
          })
        : apiV2RemoteControlResetAllPost({ id: bsaId });
    api
      .then(() => {
        setPageOffset(1);
        refreshList();
        Modal.success({
          title: '复位成功！',
        });
      })

      .finally(() => {
        setResetLoading(false);
        modal.destroy();
      });
  };
  const filters = (
    <>
      <div>
        <BaseInfo />
      </div>
      <CustomFilter
        form={form}
        initialValues={{
          date: defaultDate,
          notResume: true,
        }}
        onFinish={(v: SearchParams) => {
          setSearchParams(v);
          setPageOffset(1);
        }}
        onReset={() => {
          setSearchParams({
            date: defaultDate,
            notResume: true,
          });
          setPageOffset(1);
        }}
      >
        <Form.Item name="level" label="等级">
          <Select
            placeholder="请选择"
            mode="multiple"
            maxTagCount={3}
            options={Object.entries(AlarmLevelDisplay).map(([key, value]) => ({ label: value, value: key }))}
          />
        </Form.Item>

        <Form.Item name="date" label="报警日期">
          <RangePicker
            style={{ width: '100%' }}
            disabledDate={(current, { from }) => {
              if (current) {
                if (from) {
                  return current >= dayjs().endOf('day') || Math.abs(current.diff(from, 'days')) >= 100;
                }
                return current >= dayjs().endOf('day');
              }

              return false;
            }}
          />
        </Form.Item>
        <Form.Item name="notResume" valuePropName="checked">
          <Checkbox>只查看未恢复报警记录</Checkbox>
        </Form.Item>
      </CustomFilter>
    </>
  );

  const dataColumns: ColumnsType<DataListItem> = [
    {
      title: '报警等级',
      dataIndex: 'level',
      ellipsis: { showTitle: true },
      render: (v: AlarmLevel) => {
        return (
          <EllipsisSpan
            value={
              <Tag border="solid" color={AlarmLevelColorDisplay[v]}>
                {AlarmLevelDisplay[v]}
              </Tag>
            }
          />
        );
      },
    },
    {
      title: '报警信息',
      dataIndex: 'message',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备名称',
      dataIndex: 'deviceName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '报警标签',
      dataIndex: 'alarmLabel',
      ellipsis: { showTitle: true },
      render: (v: ButtonType) => <EllipsisSpan value={buttonTypeDisplay[v]} />,
    },
    {
      title: '报警时间',
      dataIndex: 'alarmTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format(DATE_FORMAT) : '--'} />,
    },

    {
      title: '恢复时间',
      dataIndex: 'resumeTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format(DATE_FORMAT) : '--'} />,
    },
    {
      title: '最新复位操作时间',
      dataIndex: 'recentRestTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format(DATE_FORMAT) : '--'} />,
    },
  ];

  const actionColumns: ColumnsType<DataListItem> = [
    {
      title: '复位操作',
      key: 'action',
      fixed: 'right',
      render: (_, record) => (
        <Space size={16}>
          {
            // 提醒状态复位安妮不显示
            (record.alarmLabel as unknown as ButtonType) !== ButtonType.REMIND && (
              <Button type="link" onClick={() => resetBtn({ alarmId: record.id, type: 'single' })}>
                复位
              </Button>
            )
          }
        </Space>
      ),
    },
  ];

  const columns = [...dataColumns, ...actionColumns];

  return (
    <Wrapper className={styles.wrap} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} filters={filters}>
      <Space size={8} style={{ marginBottom: 10 }}>
        <Button type="primary" onClick={() => resetBtn({ type: 'all' })}>
          一键复位
        </Button>
      </Space>
      <Table
        scroll={{ x: 'max-content' }}
        pagination={false}
        columns={columns}
        dataSource={data}
        loading={listLoading}
      ></Table>
      <Paging pagingInfo={pagingInfo}></Paging>
    </Wrapper>
  );
};

export default RemoteReset;
