import styles from './index.module.scss';

const GridSide = () => {
  return (
    <div className={styles.customReactNode}>
      <div>
        <span className={styles.labelSty}>{'电网侧'}</span>
        <span className={styles.valueSty}>{'35kV'}</span>
      </div>
      <div className={styles.hline}></div>
      <div className={styles.vline}></div>
    </div>
  );
};

export default GridSide;
