import { PermissionsType } from '@/common/permissionsConst';
import BsaList from './List/index';
import ControlConfig from './ControlConfig/index';
import ControlConfigDiff from './ControlConfigDiff/index';
import BsaStrategy from './Strategy/index';
import BsaStrategyHistory from './StrategyHistory/index';
import RulesConfig from './RulesConfig';
import RemoteReset from './RemoteReset';

const routes = [
  {
    path: '/energy-storage/basic/bsa',
    permission: PermissionsType.P_NEW_BESS_STRATEGY_MANAGE,
    element: <BsaList />,
  },
  {
    path: '/energy-storage/basic/bsa/editControlConfig/:id',
    permission: PermissionsType.P_CONTROL_CONFIGURATION_EDIT,
    element: <ControlConfig disabled={false} />,
  },
  {
    path: '/energy-storage/basic/bsa/viewControlConfig/:id',
    permission: PermissionsType.P_CONTROL_CONFIGURATION_VIEW,
    element: <ControlConfig disabled={true} />,
  },
  {
    path: '/energy-storage/basic/bsa/ControlConfigDiff/:id',
    permission: PermissionsType.P_CONTROL_CONFIGURATION_EDIT_RECORD,
    element: <ControlConfigDiff />,
  },
  {
    path: '/energy-storage/basic/bsa/strategy/:id',
    permission: PermissionsType.P_CHARGING_AND_DISCHARGING_STRATEGY,
    element: <BsaStrategy />,
  },
  {
    path: '/energy-storage/basic/bsa/strategyHistory/:id',
    permission: PermissionsType.P_STRATEGY_DEPLOYMENT_RECORD,
    element: <BsaStrategyHistory />,
  },
  {
    path: '/energy-storage/basic/bsa/strategyHistory/:id',
    permission: PermissionsType.P_STRATEGY_DEPLOYMENT_RECORD,
    element: <BsaStrategyHistory />,
  },
  {
    path: '/energy-storage/basic/bsa/rulesConfig/:id',
    permission: PermissionsType.P_RULE_CONFIG,
    element: <RulesConfig />,
  },
  {
    path: '/energy-storage/basic/bsa/remoteReset/:id',
    permission: PermissionsType.P_BSA_REMOTE_CONTROL_SET,
    element: <RemoteReset />,
  },
];

export default routes;
