import { FC, useEffect, useState } from 'react';
import { Modal, SubContent } from '@maxtropy/components';
import {
  apiV2BsaStrategyLogDetailPost,
  V2BsaStrategyLogDetailPostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { Spin } from 'antd';
import StrategyTemplateDetail from '../components/StrategyTemplateDetail';
import { ActionTypeEnum, ActionTypeLabel } from './index';

interface Props {
  open: boolean;
  onClose: () => void;
  id: number | undefined;
}

const DetailModal: FC<Props> = props => {
  const { id, open, onClose } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<V2BsaStrategyLogDetailPostResponse>();

  useEffect(() => {
    if (!id) return;
    if (!open) {
      setData(undefined);
      return;
    }
    setLoading(true);
    apiV2BsaStrategyLogDetailPost({
      id,
    })
      .then(res => {
        setData(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, open]);

  return (
    <Modal title="策略变化详情" bodyScroll={true} size="big" open={open} onOk={onClose} onCancel={onClose}>
      <Spin spinning={loading}>
        {data?.other ? (
          <SubContent
            style={{ padding: 0, backgroundColor: 'unset' }}
            title={`${ActionTypeLabel[data.actionType as ActionTypeEnum]}的策略`}
          >
            <StrategyTemplateDetail
              showTemplateName={false}
              bsaId={id}
              id={data.other?.id}
              data={data?.other as any}
            ></StrategyTemplateDetail>
          </SubContent>
        ) : (
          <>
            <SubContent style={{ padding: 0, backgroundColor: 'unset' }} title="编辑前策略">
              <StrategyTemplateDetail
                showTemplateName={false}
                bsaId={id}
                id={data?.before?.id}
                data={data?.before as any}
                tableCanFold={true}
              ></StrategyTemplateDetail>
            </SubContent>
            <SubContent style={{ padding: 0, backgroundColor: 'unset' }} title="编辑后策略">
              <StrategyTemplateDetail
                showTemplateName={false}
                bsaId={id}
                id={data?.after?.id}
                data={data?.after as any}
                tableCanFold={true}
              ></StrategyTemplateDetail>
            </SubContent>
          </>
        )}
      </Spin>
    </Modal>
  );
};

export default DetailModal;
