import { register } from '@antv/x6-react-shape';
import { NodeMockType } from './type';
import AntiBackflowDevice from './components/AntiBackflowDevice';
import Bsa from './components/Bsa';
import GridAccessPoint from './components/GridAccessPoint';
import GridSide from './components/GridSide';
import Load from './components/Load';
import PVa from './components/PVa';
import Transformer from './components/Transformer';
import MainMeter from './components/MainMeter';
import AcBusBar from './components/AcBusBar';
import AcDcConverter from './components/AcDcConverter';
import DcBusBar from './components/DcBusBar';
import DcConverter from './components/DcConverter';
import BillingPoint from './components/BillingPoint';
import NoDataBillingPoint from './components/NoDataBillingPoint';

export default function () {
  // 电网侧
  registerGridSideNode();
  // 电网接入点
  registerGridAccessPointNode();
  // 进线总电表
  registerMainMeterNode();
  // 变压器
  registerTransformerNode();
  // 直流母线
  registerDcBusBarNode();
  // 交流母线
  registerAcBusBarNode();
  // 交直流变换器
  registerAcDcConverterNode();
  // 直流装换器
  registerDcConverterNode();
  // 光伏站
  registerPVaNode();
  // 储能柜
  registerBsaNode();
  // 负载
  registerLoadNode();
  // 防逆流
  registerAntiBackflowDeviceNode();
  // 计费点
  registerGridAccessBillingPointNode();
  // 无数据计费点
  registerNoDataBillingPointNode();
}

// 电网侧
function registerGridSideNode() {
  register({
    shape: NodeMockType.GRID_SIDE,
    width: 160,
    height: 40,
    effect: ['data'],
    component: () => <GridSide />,
  });
}

// 电网接入点
function registerGridAccessPointNode() {
  register({
    shape: NodeMockType.GRID_ACCESS_POINT,
    width: 160,
    height: 100,
    effect: ['data'],
    component: () => <GridAccessPoint />,
  });
}

// 进线总电表
function registerMainMeterNode() {
  register({
    shape: NodeMockType.MAIN_METER,
    width: 240,
    height: 48,
    effect: ['data'],
    component: () => <MainMeter />,
  });
}

// 防逆流装置
function registerAntiBackflowDeviceNode() {
  register({
    shape: NodeMockType.ANTI_BACKFLOW_DEVICE,
    width: 240,
    height: 40,
    effect: ['data'],
    component: () => <AntiBackflowDevice />,
  });
}

// 变压器
function registerTransformerNode() {
  register({
    shape: NodeMockType.TRANSFORMER,
    width: 160,
    height: 114,
    effect: ['data'],
    component: props => <Transformer {...props} />,
  });
}

// 交流母线
function registerAcBusBarNode() {
  register({
    shape: NodeMockType.AC_BUSBAR,
    width: 955,
    height: 22,
    effect: ['data'],
    component: props => <AcBusBar {...props} />,
  });
}

// 交直流变换器
function registerAcDcConverterNode() {
  register({
    shape: NodeMockType.ACDC_CONVERTER,
    width: 336,
    height: 106,
    effect: ['data'],
    component: props => <AcDcConverter {...props} />,
  });
}

// 直流母线
function registerDcBusBarNode() {
  register({
    shape: NodeMockType.DC_BUSBAR,
    width: 900,
    height: 30,
    effect: ['data'],
    component: () => <DcBusBar />,
  });
}

// 直流转换器
function registerDcConverterNode() {
  register({
    shape: NodeMockType.DC_CONVERTER,
    width: 160,
    height: 94,
    effect: ['data'],
    component: props => <DcConverter {...props} />,
  });
}

// 计费点
function registerGridAccessBillingPointNode() {
  register({
    shape: NodeMockType.BILLING_POINT,
    width: 248,
    height: 160,
    effect: ['data'],
    component: props => <BillingPoint {...props} />,
  });
}

// 无数据计费点
function registerNoDataBillingPointNode() {
  register({
    shape: NodeMockType.NODATA_BILLING_POINT,
    width: 240,
    height: 40,
    effect: ['data'],
    component: () => <NoDataBillingPoint />,
  });
}

// 负载
function registerLoadNode() {
  register({
    shape: NodeMockType.LOAD,
    width: 160,
    height: 94,
    effect: ['data'],
    component: props => <Load {...props} />,
  });
}

// 储能柜
function registerBsaNode() {
  register({
    shape: NodeMockType.BSA,
    width: 160,
    height: 242,
    effect: ['data'],
    component: props => <Bsa {...props} />,
  });
}
// 光伏站
function registerPVaNode() {
  register({
    shape: NodeMockType.PVA,
    width: 160,
    height: 230,
    effect: ['data'],
    component: () => <PVa />,
  });
}
