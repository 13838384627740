import { apiV2EnergyDispatchCreatePost, apiV2UetListAllStatusPost } from '@maxtropy/intelligent-dispath-apis-v2';
import { Form, Input, Select } from 'antd';
import React from 'react';
import { useRequest } from 'ahooks';
import styles from './index.module.scss';
import { getOrganization } from '@/api/customer-user';
import { tiledArray } from '@/pages/EnergyDispatchManagement/List/utils';
import { isEmpty } from 'lodash-es';
import { Modal } from '@maxtropy/components';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Item } = Form;

interface Props {
  visible: boolean;
  onCancel: () => void;
  forceUpdate: () => void;
}
const CreateDispatchModal: React.FC<Props> = ({ visible, onCancel, forceUpdate }) => {
  const [form] = Form.useForm();

  const customerMcids = Form.useWatch('customerMcidList', form);

  // 组织
  const { data: organization } = useRequest(async () => {
    const res = await getOrganization();
    return res ? tiledArray([res]) : [];
  });

  // uet
  const { data: UETList } = useRequest(
    async () => {
      if (isEmpty(customerMcids)) return [];
      const res = await apiV2UetListAllStatusPost({ customerMcids });
      if (res) {
        return res.list?.map(item => ({
          value: item.id,
          label: item.name,
        }));
      }
    },
    {
      refreshDeps: [customerMcids],
    }
  );

  const onOk = async () => {
    form
      .validateFields()
      .then(values => {
        apiV2EnergyDispatchCreatePost(values).then(() => {
          form.resetFields();
          forceUpdate();
          onCancel();
        });
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <>
      <Modal title="新建调度组" open={visible} width={640} onCancel={onCancel} onOk={onOk} destroyOnClose>
        <Form
          form={form}
          layout="horizontal"
          labelCol={{
            span: 6,
          }}
          className={styles.modalWrapper}
          labelAlign="left"
        >
          <Item name="name" label="调度组名称" rules={[{ required: true, min: 2, max: 30 }]}>
            <Input placeholder="请输入" allowClear />
          </Item>

          <Item name="customerMcidList" label="关联组织" rules={[{ required: true, message: '请选择关联组织' }]}>
            <Select placeholder="请选择" options={organization} mode="multiple" allowClear optionFilterProp="label" />
          </Item>

          <Item name="uetId" label="关联UET" rules={[{ required: true, message: '请选择关联UET' }]}>
            <Select placeholder="请选择" options={UETList} mode="multiple" allowClear optionFilterProp="label" />
          </Item>
        </Form>
        <div className={styles.tips}>
          <InfoCircleOutlined style={{ color: '#faad14', paddingRight: '2px' }} />
          调度组需关联已存在的UET，如无所需UET，请先前往
          <span
            className={styles.btn}
            onClick={() => window.open(`${window.IOTPLATFORMORIGIN}/energy/basic/uet`, '_blank')}
          >
            {' '}
            能源拓扑建模配置（UET）
          </span>
          创建所需的基础能源结构
        </div>
      </Modal>
    </>
  );
};

export default CreateDispatchModal;
