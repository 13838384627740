import styles from './index.module.scss';
import poweGrid from '../../imgs/icon_poweGrid.gif';

const GridAccessPoint = () => {
  return (
    <div className={styles.customAccesspointReactNode} style={{ opacity: 1 }}>
      <div className={styles.vlineTop}></div>
      <div className={styles.cardSty}>
        <img style={{ width: 40, height: 40 }} src={poweGrid} alt="" />

        <div className={styles.itemControlobjContentSty}>{'厂区接入点'}</div>
      </div>
      <div className={styles.vlineBottom}></div>
    </div>
  );
};

export default GridAccessPoint;
