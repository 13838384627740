import {
  BaseInfoContent,
  Button,
  Form,
  FormTitle,
  Select,
  SubContent,
  Table,
  Tooltip,
  Wrapper,
  useBreadcrumbRoutes,
} from '@maxtropy/components';
import styles from './index.module.scss';
import React, { useEffect, useMemo, useState } from 'react';
import ShowInput from '@/components/ShowInput';
import { Col, Row, Space, Spin } from 'antd';
import { FileSearchOutlined, InfoCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import {
  apiV2BsaStrategyTemplateEpDetailPost,
  apiV2BsaStrategyTemplateGetPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import {
  ActionType,
  ActionTypeTypeDisplay,
  ChargingAndDischargingPower,
  ChargingAndDischargingPowerplay,
  PVPeriods,
  PVPeriodsDisplay,
  PeaksAndValleysType,
  PeaksAndValleysTypePowerplay,
  StrategyTemplateType,
  StrategyTemplateTypeDisplay,
  PricedisplayType,
  PricedisplayTypeDisplay,
} from '../type';
import { CreatePriceFormProps, getPriceTimeIndex } from '../utils';
import ChargeDisChangeTime from '../StrategyTemplateEdit/components/ChargeDisChangeTime';
import { isNil } from 'lodash-es';
import { columnsWithCustomCustom, columnsWithCustomFollowMax, columnsWithFollowPrice } from '../common';
import PeakItem from '../StrategyTemplateEdit/components/PeakItem';

const routes = [{ name: `查看策略模板` }];

const StrategyTemplateDetail = () => {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const bsaId = urlSearchParams.get('bsaId') || undefined;
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  // const { data: elecTempList } = useRequest(
  //   async () => {
  //     const res = await apiV2BsaStrategyTemplateEpDetailPost({ id: Number(bsaId) });
  //     return res.list;
  //   },
  //   {
  //     ready: !!bsaId,
  //     refreshDeps: [bsaId],
  //   }
  // );

  const { data, loading: tableLoading } = useRequest(
    () =>
      apiV2BsaStrategyTemplateGetPost({ id: Number(id) }).then(res => {
        return res;
      }),
    {
      ready: !!id,
      refreshDeps: [id],
    }
  );

  useEffect(() => {
    if (data && form) {
      const priceData = getPriceTimeIndex(data);
      let chargingAndDischargingPeriodDetailTemp;
      if (data.additionalFields?.periodTypes === PVPeriods.FollowEpriceTemplate) {
        const allList = [
          data.additionalFields?.plainAndValleyTypeList,
          data.additionalFields?.peakAndSharpTypeList,
        ].flat();
        chargingAndDischargingPeriodDetailTemp = allList.map(item => {
          return data.additionalFields?.chargingAndDischargingPeriodDetail?.find(c => c.peakValleyType === item);
        });
      }
      form.setFieldsValue({
        templateName: data.templateName,
        templateType: StrategyTemplateTypeDisplay[data.templateType as StrategyTemplateType],
        periodTypes: PVPeriodsDisplay[data.additionalFields?.periodTypes as PVPeriods],
        epTemplateId: data.additionalFields?.epTemplateId,
        epTemplateName: data.additionalFields?.epTemplateName,
        timePeriodGranularity:
          PricedisplayTypeDisplay[data.additionalFields?.timePeriodGranularity as PricedisplayType],
        chargingAndDischargingPower:
          ChargingAndDischargingPowerplay[
            data.additionalFields?.chargingAndDischargingPower as ChargingAndDischargingPower
          ],
        chargingAndDischargingPeriodDetail:
          data.additionalFields?.periodTypes === PVPeriods.FollowEpriceTemplate
            ? chargingAndDischargingPeriodDetailTemp?.map(item => {
                return {
                  ...item,
                  key: Math.random(),
                  startTime: item?.startTime?.slice(0, 5),
                  endTime: item?.endTime?.slice(0, 5),
                  time: PeaksAndValleysTypePowerplay[item?.peakValleyType as PeaksAndValleysType],
                };
              })
            : (data.additionalFields?.chargingAndDischargingPeriodDetail ?? []).map(item => {
                return {
                  ...item,
                  key: Math.random(),
                  startTime: item?.startTime?.slice(0, 5),
                  endTime: item?.endTime?.slice(0, 5),
                };
              }),
        plainAndValleyTypeList: data.additionalFields?.plainAndValleyTypeList
          ?.map(i => PeaksAndValleysTypePowerplay[i as PeaksAndValleysType])
          .join(),
        peakAndSharpTypeList: data.additionalFields?.peakAndSharpTypeList
          ?.map(i => PeaksAndValleysTypePowerplay[i as PeaksAndValleysType])
          .join(),
        // peakItem
        price: priceData,
      });
    }
  }, [data, form]);

  const columns = useMemo(() => {
    if (!isNil(data?.additionalFields?.periodTypes) && !isNil(data?.additionalFields?.chargingAndDischargingPower)) {
      if (data?.additionalFields?.periodTypes === PVPeriods.FollowEpriceTemplate) {
        if (data?.additionalFields?.chargingAndDischargingPower === ChargingAndDischargingPower.Custom) {
          return columnsWithFollowPrice(undefined, true);
        }
      } else {
        if (data?.additionalFields?.chargingAndDischargingPower === ChargingAndDischargingPower.FollowMaxPower) {
          return columnsWithCustomFollowMax;
        } else {
          return columnsWithCustomCustom(undefined, true);
        }
      }
    }
    return [];
  }, [data]);

  const priceData = useMemo(() => {
    if (data) {
      const price = new Map<
        Exclude<keyof CreatePriceFormProps, 'timePeriodGranularity' | 'price'>,
        number | undefined
      >();
      data.additionalFields?.chargingAndDischargingPeriodDetail?.forEach(i => {
        price.set(
          ActionTypeTypeDisplay[i.type as ActionType] as Exclude<
            keyof CreatePriceFormProps,
            'timePeriodGranularity' | 'price'
          >,
          i.power
        );
      });
      return getPriceTimeIndex(data);
    }
  }, [data]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={'查看策略模板'}></FormTitle>
      <Spin spinning={tableLoading}>
        <SubContent>
          <Form form={form}>
            <BaseInfoContent colNum={3} layout="horizontal">
              <Form.Item name="templateName" label="策略模板名称">
                <ShowInput />
              </Form.Item>

              <Form.Item name="templateType" label="策略模板类型">
                <ShowInput />
              </Form.Item>

              <Form.Item name="periodTypes" label="峰谷时段">
                <ShowInput />
              </Form.Item>

              {data?.additionalFields?.periodTypes === PVPeriods.CustomSettings ? (
                <Form.Item name="timePeriodGranularity" label="时段颗粒度">
                  <ShowInput />
                </Form.Item>
              ) : (
                <Form.Item dependencies={['epTemplateName']} label="电价模板">
                  {({ getFieldValue }) => {
                    const epTemplateName = getFieldValue('epTemplateName');
                    return (
                      <>
                        <ShowInput value={epTemplateName} style={{ display: 'inline-block', marginRight: 10 }} />
                        <Button
                          icon={<FileSearchOutlined />}
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                          type="link"
                          onClick={() =>
                            window.open(
                              `${window.IOTPLATFORMORIGIN}/operation/energy/contract/electric-rate/detail/${data?.additionalFields?.epTemplateId}`,
                              '_blank'
                            )
                          }
                        >
                          查看
                        </Button>
                      </>
                    );
                  }}
                </Form.Item>
              )}

              <Form.Item
                name="chargingAndDischargingPower"
                label={
                  <>
                    充放电功率
                    <Tooltip title="跟随电价模板进行充放电，取控制配置中的最大充电功率和最大放电功率。">
                      <InfoCircleOutlined style={{ marginLeft: 4, marginRight: 4, color: '#FAAD14' }} />
                    </Tooltip>
                  </>
                }
              >
                <ShowInput />
              </Form.Item>

              {data?.additionalFields?.periodTypes === PVPeriods.CustomSettings ? (
                data.additionalFields?.timePeriodGranularity === PricedisplayType.CUSTOM ? (
                  <></>
                ) : (
                  <Col span={16}>
                    <PeakItem displayType={data.additionalFields.timePeriodGranularity} disabled value={priceData} />
                  </Col>
                )
              ) : (
                <>
                  <Form.Item name="plainAndValleyTypeList" label="充电时段">
                    <ShowInput />
                  </Form.Item>

                  <Form.Item name="peakAndSharpTypeList" label="放电时段">
                    <ShowInput />
                  </Form.Item>
                </>
              )}

              {data?.additionalFields?.chargingAndDischargingPower === ChargingAndDischargingPower.FollowMaxPower &&
              data?.additionalFields?.periodTypes === PVPeriods.FollowEpriceTemplate ? (
                <></>
              ) : (
                <Col span={24} style={{ marginTop: 8 }}>
                  <Form.Item
                    name="chargingAndDischargingPeriodDetail"
                    style={{ width: '100%' }}
                    valuePropName="dataSource"
                  >
                    <Table rowKey={_ => Math.random()} columns={columns} pagination={false} />
                  </Form.Item>
                </Col>
              )}
            </BaseInfoContent>
          </Form>
        </SubContent>
        <Space size={8} className="sticky-footer" style={{ zIndex: 99 }}>
          <Button
            className={styles.button}
            onClick={() => navigate(`/energy-storage/basic/bsa/templateList?bsaId=${bsaId}`)}
          >
            返回
          </Button>
        </Space>
      </Spin>
    </Wrapper>
  );
};

export default StrategyTemplateDetail;
