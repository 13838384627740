import { PermissionsType } from '@/common/permissionsConst';
import MicrogridConfigure from '.';
import ConfigTabs from './ConfigTabs';

const routes = [
  {
    path: '/microgrid/conf/page',
    permission: PermissionsType.P_MICRO_CONFIG,
    element: <MicrogridConfigure />,
  },
  {
    path: '/microgrid/conf/page/config/:id/:uetStructId',
    permission: '',
    element: <ConfigTabs />,
  },
];

export default routes;
