import Title from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Title';

import React, { Key, useEffect, useState } from 'react';
import styles from './index.module.scss';
import {
  apiV2DispatchUetStructDevicePagePost,
  apiV2DispatchUetStructLoadCreatePost,
  apiV2DispatchUetStructLoadDeletePost,
  apiV2DispatchUetStructLoadGetPost,
  apiV2DispatchUetStructLoadUpdatePost,
  apiV2DispatchUetStructPublishLoadGetPost,
  apiV2UetListLoadPost,
  apiV2UetListUetTopologyStructPost,
  V2DispatchUetStructPublishLoadGetPostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import Footer from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Footer';
// import ShowInput from '@/shared/components/ShowInput';
import { useRequest } from 'ahooks';
import { isEmpty, isNil } from 'lodash-es';
import { NodeType as NodeTypeClass } from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import ShowInput from '@/components/ShowInput';
import { NodeType, TreeNode, allInfoProps } from '@/pages/SchedulingTopology/type';
import { findTopGridAccessPointData } from '../EnergyStorageStation/utils';
import { Button, Checkbox, Form, Modal, Select } from '@maxtropy/components';

//1生产负载 2柔性负载
export enum LoadType {
  productionLoad = 1,
  flexibleLoad = 2,
}
export const LoadTypeLabel = {
  [LoadType.productionLoad]: '生产负载',
  [LoadType.flexibleLoad]: '柔性负载',
};

const { Item } = Form;

interface Props {
  id?: number;
  open?: boolean;
  dispatchGroupId?: number;
  isPreview?: boolean;
  forceUpdate?: () => void;
  isShowModal?: (open: boolean) => void;
  getFinishedValues?: (values: any) => void;
  shouldDeleteElement?: (state: boolean) => void;
  version?: number;
  allInfo?: allInfoProps;
  allNodeData?: TreeNode[];
}
const LoadModal: React.FC<Props> = ({
  id,
  dispatchGroupId,
  open,
  isPreview,
  isShowModal,
  getFinishedValues,
  shouldDeleteElement,
  version,
  allInfo,
  allNodeData,
}) => {
  const [form] = Form.useForm();

  const [iconVisible, setIconVisible] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();
  const [controlObjectVisible, setControlObjectVisible] = useState<boolean>(false);
  const loadId = Form.useWatch('loadId', form);
  const [gridAccessPointId, setGridAccessPointId] = useState<Key>(); // 电网接入点id
  const urlParams = new URLSearchParams(window.location.search);
  const topologyId = urlParams.get('topologyId');

  const onShowIcon = () => {
    form.validateFields().then(() => {
      setIconVisible(true);
    });
  };

  const onCancel = () => {
    form.resetFields();
    setTitle(undefined);
    isShowModal?.(false);
  };

  // 负载list
  const { data: loadOptions } = useRequest(
    async () => {
      if (isNil(topologyId)) return;

      const loadList = await apiV2UetListLoadPost({ uetStructId: topologyId, gridAccessPointId: gridAccessPointId });
      if (loadList) {
        return loadList?.list;
      }
    },
    {
      ready: !!gridAccessPointId && !!topologyId,
      refreshDeps: [gridAccessPointId, topologyId],
    }
  );

  // 控制对象 list
  const { data: circuitBreakerOptions } = useRequest(
    async () => {
      if (isNil(topologyId)) return;
      const uetList = await apiV2UetListUetTopologyStructPost({ id: +topologyId });
      const uetIds = uetList?.list?.map(item => item.id) ?? [];
      const res = await apiV2DispatchUetStructDevicePagePost({
        page: 1,
        size: 500,
        deviceTypeIdList: [5028, 5029],
        uetIds: uetIds as number[],
        uetStructId: +topologyId!,
        gridAccessPointId,
      });

      if (res) {
        return res.list?.map(item => ({
          label: item.name,
          value: item.id,
        }));
      }
    },
    { ready: !!gridAccessPointId && !!topologyId, refreshDeps: [gridAccessPointId, topologyId] }
  );

  const { data: detail } = useRequest(
    async () => {
      if (isNil(id) || !open) return Promise.resolve(void 0);
      setIsDetail(true);

      let detail: V2DispatchUetStructPublishLoadGetPostResponse;
      if (!isNil(version)) {
        detail = await apiV2DispatchUetStructPublishLoadGetPost({ id, version });
      } else {
        detail = await apiV2DispatchUetStructLoadGetPost({ id });
      }

      if (detail) {
        const title = loadOptions?.find(item => item.id === detail.loadId)?.name;
        setTitle(title);
        setIconVisible(true);
        if (!isEmpty(detail?.circuitBreakerDeviceIdList)) {
          setControlObjectVisible(true);
        }
        form.setFieldsValue(detail);
      }
      return detail;
    },
    {
      refreshDeps: [id, loadOptions, open, version],
    }
  );

  // 通过当前id与type查找电网接入点id
  useEffect(() => {
    if (detail) {
      if (allNodeData) {
        const gridAccessPointData = findTopGridAccessPointData(allNodeData, detail.id, NodeType.LOAD);
        setGridAccessPointId(gridAccessPointData?.id);
      }
    } else {
      if (allInfo) {
        const gridAccessPointData = findTopGridAccessPointData(
          allInfo.allNodeData,
          allInfo.node.getData().id,
          allInfo.node.getData().element
        );
        setGridAccessPointId(gridAccessPointData?.id);
      }
    }
  }, [allInfo, detail, allNodeData]);

  useEffect(() => {
    if (isNil(loadId)) return;
    const load = loadOptions?.find(item => item.id === loadId);
    form.setFieldsValue({
      loadType: LoadTypeLabel[load?.type as LoadType],
      loop: load?.circuitName,
    });
  }, [form, loadId, loadOptions]);

  return (
    <>
      <Modal
        open={open}
        contentClassName="modal-form-content"
        title={
          <Title
            id={id}
            styles={styles}
            isDetail={isDetail}
            isPreview={isPreview}
            title={title ?? '负载'}
            delMethod={apiV2DispatchUetStructLoadDeletePost}
            onEdit={() => setIsDetail(false)}
            shouldDeleteElement={state => shouldDeleteElement?.(state)}
            onCancel={onCancel}
            nodeType={NodeTypeClass.LOAD}
          />
        }
        footer={
          <Footer
            isDetail={isDetail}
            styles={styles}
            form={form}
            dispatchGroupId={dispatchGroupId}
            createMethod={apiV2DispatchUetStructLoadCreatePost}
            updateMethod={apiV2DispatchUetStructLoadUpdatePost}
            getFinishedValues={val => getFinishedValues?.(val)}
            onCancel={onCancel}
          />
        }
        onCancel={onCancel}
        destroyOnClose
      >
        <div className={styles.modalWrapper}>
          <Form form={form} labelCol={{ span: 6 }} labelAlign="left">
            <Item label="负载" name="loadId" rules={[{ required: true, message: '请选择负载' }]}>
              <Select
                placeholder="请选择"
                fieldNames={{
                  label: 'name',
                  value: 'id',
                }}
                options={loadOptions}
                disabled={isDetail}
                allowClear
              />
            </Item>

            <Item label="负载类型" name="loadType">
              <ShowInput />
            </Item>

            <Item label="关联回路" name="loop">
              <ShowInput />
            </Item>

            <Item noStyle name="id" />

            <Checkbox
              style={{
                marginBottom: 10,
              }}
              checked={controlObjectVisible}
              onClick={() => {
                setControlObjectVisible(!controlObjectVisible);
                form.resetFields(['circuitBreakerDeviceIdList']);
              }}
              disabled={isDetail}
            >
              添加控制对象
            </Checkbox>

            {controlObjectVisible && (
              <Item label="断路器" name="circuitBreakerDeviceIdList">
                <Select
                  placeholder="请选择"
                  options={circuitBreakerOptions}
                  mode="multiple"
                  disabled={isDetail}
                  allowClear
                />
              </Item>
            )}

            <div className={styles.preview}>
              <Button type="link" className={styles.previewBtn} onClick={onShowIcon}>
                查看图标
              </Button>

              <div className={styles.iconWrapper}>
                {iconVisible && (
                  <div className={styles.iconBox}>
                    <div className={styles.icon} />

                    <Item noStyle dependencies={['loadId']}>
                      {({ getFieldValue }) => {
                        const loadId = getFieldValue('loadId');
                        const loadName = loadOptions?.find(item => item.id === loadId)?.name;
                        return (
                          <>
                            <div className={styles.iconText}>{loadName}</div>
                          </>
                        );
                      }}
                    </Item>
                    {/* 控制对象隐藏 */}
                    {/* <Item noStyle dependencies={['circuitBreakerDeviceIdList']}>
                      {({ getFieldValue }) => {
                        const circuitBreakerDeviceIdList = getFieldValue('circuitBreakerDeviceIdList');
                        const filteredCircuitBreakerOptions = circuitBreakerOptions?.filter(option =>
                          circuitBreakerDeviceIdList?.includes(option.value)
                        );

                        return (
                          <>
                            {isEmpty(filteredCircuitBreakerOptions) ? null : (
                              <>
                                <div className={styles.dashed} />
                                <div className={styles.controlObject}>
                                  {filteredCircuitBreakerOptions?.map(item => (
                                    <div className={styles.controlObjectIcon} key={item.value}>
                                      {item.label}
                                    </div>
                                  ))}
                                </div>
                              </>
                            )}
                          </>
                        );
                      }}
                    </Item> */}
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default LoadModal;
