import { Button, EllipsisSpan, Form, Input, message, PopConfirm, Table } from '@maxtropy/components';
import { Space } from 'antd';
import { useParams } from 'react-router-dom';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import {
  apiV2MicrogridConfigurationHotspotElementConfPost,
  apiV2MicrogridConfigurationHotspotElementListPost,
  V2MicrogridConfigurationHotspotElementListPostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useState } from 'react';
import { RefProps } from '../const';
import FooterCancelBtn from './FooterCancelBtn';
import { NodeType, NodeTypeDisplay } from '@/pages/SchedulingTopology/type';
type ListItem = Exclude<V2MicrogridConfigurationHotspotElementListPostResponse['list'], undefined>[number];
const columns = [
  {
    title: '元素名称',
    ellipsis: true,
    dataIndex: 'elementName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '类',
    ellipsis: true,
    dataIndex: 'element',
    render: (v: NodeType) => <EllipsisSpan value={NodeTypeDisplay[v]} />,
  },
];

const HotSpot: ForwardRefRenderFunction<RefProps> = (props, ref) => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [saveLoading, setSaveLoading] = useState(false);
  const [hasSave, setHasSave] = useState(true);
  useImperativeHandle(ref, () => ({
    saveBtn: saveBtn,
    isEditStatus: !hasSave,
  }));
  const { data, loading, refresh } = useRequest(
    () => {
      return apiV2MicrogridConfigurationHotspotElementListPost({
        id,
      }).then(res => res.list);
    },
    {
      ready: !!id,
      refreshDeps: [id],
    }
  );
  useEffect(() => {
    form.setFieldsValue({
      list: data,
    });
  }, [data]);
  const saveBtn = (success?: () => void) => {
    let res = form.getFieldsValue();
    setSaveLoading(true);
    apiV2MicrogridConfigurationHotspotElementConfPost({
      microgridConfId: id,
      configurationList: data?.map((item, index) => {
        return {
          ...item,
          url: res?.list?.[index]?.url,
        };
      }),
    })
      .then(() => {
        success?.();
        message.success('保存成功');
      })
      .finally(() => {
        setSaveLoading(false);
        setHasSave(true);
      });
  };
  const buildColumns = [
    ...columns,
    {
      title: 'url',
      render: (v: any, record: ListItem, index: number) => {
        return (
          <Form.Item className={styles.table_inner_form_item} name={['list', index, 'url']}>
            <Input
              onChange={() => {
                hasSave && setHasSave(false);
              }}
            />
          </Form.Item>
        );
      },
    },
  ];

  return (
    <div>
      <Form
        preserve
        form={form}
        initialValues={{
          list: [],
        }}
      >
        <Form.Item noStyle shouldUpdate>
          {() => {
            const dataSource = form.getFieldValue('list');
            return (
              <Table
                rowKey={'id'}
                sticky
                scroll={{ x: 1300, y: 'calc(100vh - 400px)' }}
                loading={loading}
                dataSource={dataSource}
                columns={buildColumns}
                pagination={false}
              />
            );
          }}
        </Form.Item>
      </Form>

      <Space size={8} className={styles.tabs_content_sticky_footer}>
        <Button type="primary" loading={saveLoading} onClick={() => saveBtn()}>
          保存
        </Button>

        <FooterCancelBtn />
      </Space>
    </div>
  );
};

export default forwardRef(HotSpot);
