import { useBreadcrumbRoutes, Wrapper, Button, FormTitle, SubContent, Form } from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { FC, useEffect, useMemo } from 'react';

import styles from './index.module.scss';

import { useNavigate, useParams } from 'react-router-dom';
import ShowInput from '@/components/ShowInput';
import { useRequest } from 'ahooks';
import { apiV2DispatchUetStructFcsDetailPost } from '@maxtropy/intelligent-dispath-apis-v2';

// fcs类型
export enum FcsType {
  BESS1POINT0 = 1,
  BESS2POINT0 = 2,
}

export const FcsTypeLabel = {
  [FcsType.BESS1POINT0]: 'BESS-1.0',
  [FcsType.BESS2POINT0]: 'BESS-2.0',
};

const ControllerBasicInfo: FC = () => {
  const routesContext = useBreadcrumbRoutes();

  const routes = [{ name: '基础信息配置' }];

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const { id } = useParams<{ id?: string }>();

  const { data: fcsInfo } = useRequest(
    () => {
      return apiV2DispatchUetStructFcsDetailPost({ id: Number(id) }).then(res => res.fcsInfo);
    },
    {
      ready: !!id,
      refreshDeps: [id],
    }
  );

  const fcsName = useMemo(() => {
    return fcsInfo?.name ?? '';
  }, [fcsInfo]);

  useEffect(() => {
    if (fcsInfo) {
      form.setFieldsValue({
        ...fcsInfo,
        type: FcsTypeLabel[fcsInfo.type as FcsType],
      });
    }
  }, [fcsInfo, form]);

  return (
    <Wrapper routes={[...(routesContext?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle
        title={`${fcsName}基础信息配置`}
        extraContent={
          <Space size={8}>
            <Button
              type="primary"
              onClick={() => window.open(`${window.IOTPLATFORMORIGIN}/device/fcs/list/edit/${fcsInfo?.id}`, '_blank')}
            >
              前往编辑
            </Button>
            <Button onClick={() => navigate(-1)}>返回</Button>
          </Space>
        }
      />
      <SubContent>
        <Form layout="vertical" className={styles.formWrapper} form={form}>
          <Row>
            <Col span={8}>
              <Form.Item name="name" label="FCS名称">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="serialNumber" label="FCS编号">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="type" label="FCS类型">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="softwareVersion" label="FCS软件版本">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="edgeGatewayName" label="绑定网关">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="edgeNetworkName" label="所在组网">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="dispatchStructName" label="关联拓扑">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </SubContent>
    </Wrapper>
  );
};

export default ControllerBasicInfo;
