import { apiV2DispatchUetStructPublishChargingStationDetailPost } from '@maxtropy/intelligent-dispath-apis-v2';
import { useRequest } from 'ahooks';
import { Descriptions, Drawer, Form, Spin } from 'antd';
import { isNil } from 'lodash-es';
import React, { FC, useState } from 'react';
import styles from './index.module.scss';
import ShowInput from '@/components/ShowInput';
import { NodeType } from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import TitleTag from '../TitleTag';

export interface ChargingStationDrawerProps {
  isShowModal: (open: boolean) => void;
  open: boolean;
  id?: number;
  version?: number;
}

const { Item } = Form;

const ChargingStationDrawer: FC<ChargingStationDrawerProps> = props => {
  const { isShowModal, open, id, version } = props;

  const [form] = Form.useForm();
  const [title, setTitle] = useState<string>();

  const { loading } = useRequest(
    async () => {
      const detail = await apiV2DispatchUetStructPublishChargingStationDetailPost({ id: id!, version });
      setTitle(detail.chargingStation?.name);
      form.setFieldsValue({
        ...detail,
        NodeTypeName: '充电站',
        name: detail.chargingStation?.name,
        circuitName: detail.circuitSimpleDto?.name,
      });
    },
    { ready: !isNil(id) && !isNil(version) && open, refreshDeps: [id, open, version] }
  );

  return (
    <div className={styles.drawerStyle}>
      <Drawer
        title={<TitleTag title={title ?? '充电站'} nodeType={NodeType.LOAD} />}
        placement="right"
        width={400}
        mask={false}
        onClose={() => {
          isShowModal?.(false);
        }}
        open={open}
        getContainer={false}
      >
        <Spin spinning={loading}>
          <Form form={form}>
            <Descriptions labelStyle={{ width: '40%' }} title={null} bordered column={1} size="small">
              <Descriptions.Item label="元素类型">
                <Item name="NodeTypeName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="充电站名称">
                <Item name="name" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="回路">
                <Item name="circuitName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
            </Descriptions>

            <div className={styles.preview}>
              <div className={styles.previewBtn} style={{ marginTop: 16, marginBottom: 8 }}>
                查看图标
              </div>

              <div className={styles.iconWrapper}>
                <div className={styles.iconBox}>
                  <div className={styles.icon} />

                  <Item noStyle dependencies={['name']}>
                    {({ getFieldValue }) => {
                      const name = getFieldValue('name');
                      return <div className={styles.iconText}>{name}</div>;
                    }}
                  </Item>
                </div>
              </div>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </div>
  );
};

export default ChargingStationDrawer;
