import { Node } from '@antv/x6';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { BusBarCircleNumber } from '@/pages/SchedulingTopology/type';
import { EACH_BUSBAR_LENGTH } from '@/pages/SchedulingTopology/utils/graph';
import styles from './index.module.scss';
import {
  BusBarVoltageLevel,
  BusBarVoltageLevelLabel,
} from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import { judgeDrageOrClick } from '../../utils';
import { isNil } from 'lodash-es';

export interface BusBarProps {
  node: Node;
  configId?: number;
}

const BusBar: FC<BusBarProps> = props => {
  const { node, configId } = props;
  const refBox = useRef<HTMLDivElement>();

  const data = useMemo(() => {
    return node.getData();
  }, [node.getData()]);

  // 区分拖动与点击事件
  useEffect(() => {
    if (refBox.current) {
      judgeDrageOrClick(refBox.current, configId!, data?.value?.id, data?.element);
    }
  }, []);

  const circleArr: BusBarCircleNumber[] = useMemo(() => {
    return data?.busbarNeedExtendsMap?.circleArr;
  }, [data]);

  const busbarLength = useMemo(() => {
    return data?.busbarNeedExtendsMap?.busbarLength;
  }, [data]);

  const busbarTlineHeight = useMemo(() => {
    return data?.busbarNeedExtendsMap?.height ?? 0;
  }, [data]);

  // 母线上面那条线
  const tlinePosition = useMemo(() => {
    const findIndex = (circleArr ?? []).findIndex(i => i.circleNumber === 0);
    const left = circleArr[findIndex].left;
    return left + EACH_BUSBAR_LENGTH / 2 - 1;
  }, [circleArr]);

  // 母线下面的线
  const blinePosition = useCallback(
    (circleNumber: number, bLinePosition: number) => {
      const findCircle = (circleArr ?? []).find(i => i.circleNumber === circleNumber);
      return (findCircle?.left ?? 0) + EACH_BUSBAR_LENGTH / 2 + (bLinePosition ?? 0) - 1;
    },
    [circleArr]
  );

  return (
    <div
      className={styles.generatrixWrapper}
      ref={d => {
        if (d) {
          refBox.current = d;
        }
      }}
      // onClick={() => navigateNodeHotArea(configId!, data?.value?.id, data?.element)}
      style={{
        width: busbarLength,
      }}
    >
      <div className={styles.generatrixNode} style={{ width: busbarLength }}>
        {circleArr.map(item => (
          <div key={item.circleNumber}>
            {item.circleNumber === 0 && (
              <div
                className={styles.tline}
                style={{ left: tlinePosition, height: 15 + busbarTlineHeight, top: -14 - busbarTlineHeight }}
              ></div>
            )}
            {item.circleNumber !== 0 && item.circleNumber !== Math.max(...circleArr.map(i => i.circleNumber)) && (
              <div
                className={styles.bline}
                style={{
                  left: blinePosition(item.circleNumber, item.bLinePosition),
                }}
              ></div>
            )}
          </div>
        ))}
      </div>

      <div
        className={styles.generatrixValueSty}
        style={{
          left: busbarLength + 20,
        }}
      >
        <div>{data.value.name}</div>
        {isNil(data?.value?.level) || data?.value?.level === BusBarVoltageLevel.NULL ? (
          <></>
        ) : (
          <div style={{ color: '#faad14' }}>{BusBarVoltageLevelLabel[data.value.level as BusBarVoltageLevel]}</div>
        )}
      </div>
    </div>
  );
};

export default BusBar;
