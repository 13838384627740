import { CustomFilter, EllipsisSpan, Form, Input, Modal, Paging, Select, Table, usePaging } from '@maxtropy/components';
import { Col, Row } from 'antd';
import { ColumnType } from 'antd/es/table';
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useState } from 'react';
import { ElementType, elementTypeDisplay } from '../const';
import { useRequest } from 'ahooks';
import {
  apiV2DevicePhysicalModelListPost,
  apiV2MicrogridConfigurationCustomisationDeviceSavePost,
  apiV2MicrogridConfigurationCustomisationPointDeviceElementListPost,
  apiV2MicrogridConfigurationDevicePagePost,
  apiV2MicrogridConfigurationDeviceTypeListPost,
  V2MicrogridConfigurationDevicePagePostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';

interface SearchParams {
  deviceTypeIdList?: number;
  codeOrName?: string;
  physicalModelId?: string | number;
}
type DeviceItem = Exclude<V2MicrogridConfigurationDevicePagePostResponse['list'], undefined>[number];
const columns = [
  {
    title: '设备编号',
    dataIndex: 'code',
    width: 180,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    width: 180,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v?: string[]) => <EllipsisSpan value={v} />,
  },
  {
    title: '物模型',
    dataIndex: 'physicalModelName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export interface DeviceModalProps {
  uetStructId?: string;
  microgridConfId: string | undefined;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export interface DeviceModalRefProps {
  getDataPropertyId: () => number | undefined;
}

const DeviceSelectModal = ({ onCancel, onConfirm, uetStructId, microgridConfId }: DeviceModalProps) => {
  const [searchform] = Form.useForm();
  const [confirmform] = Form.useForm();
  const [devices, setDevices] = useState<DeviceItem[]>([]);

  const [searchParams, setSearchParams] = useState<SearchParams>();

  const [confirmLoading, setConfirmLoading] = useState(false);

  const [selectedRows, setSelectedRows] = useState<DeviceItem[]>([]);

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const customisationPoint = Form.useWatch('customisationPoint', confirmform);
  const elementId = Form.useWatch('elementId', confirmform);
  const deviceTypeIdList = Form.useWatch('deviceTypeIdList', searchform);

  const { data, loading, refresh } = useRequest(
    () => {
      return apiV2MicrogridConfigurationDevicePagePost({
        ...searchParams,
        uetStructId,
        element: customisationPoint,
        deviceTypeIdList: searchParams?.deviceTypeIdList ? [searchParams?.deviceTypeIdList] : undefined,
        page: pageOffset,
        size: pageSize,
      } as any).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list;
      });
    },
    {
      ready: !!customisationPoint,
      refreshDeps: [pageOffset, pageSize, searchParams, uetStructId, customisationPoint],
    }
  );

  const { data: customisationPointList } = useRequest(
    () =>
      apiV2MicrogridConfigurationCustomisationPointDeviceElementListPost({
        microgridConfId: microgridConfId,
        customisationPoint,
      }),
    {
      ready: !!microgridConfId && !!customisationPoint,
      refreshDeps: [microgridConfId, customisationPoint],
    }
  );
  useEffect(() => {
    confirmform.setFieldsValue({
      elementId: customisationPointList?.list?.[0]?.elementId,
    });
  }, [customisationPointList]);
  // 类目
  const { data: deviceTypeList, mutate: mutateDeviceType } = useRequest(
    () =>
      apiV2MicrogridConfigurationDeviceTypeListPost({
        element: customisationPoint,
      }),
    {
      ready: !!customisationPoint,
      refreshDeps: [customisationPoint],
    }
  );

  // 物模型
  const { data: physicalModelList, mutate: mutatePhysicalModel } = useRequest(
    () =>
      apiV2DevicePhysicalModelListPost({
        deviceTypeIds: [deviceTypeIdList],
      }).then(res => res.list),
    {
      ready: !!deviceTypeIdList,
      refreshDeps: [deviceTypeIdList],
    }
  );

  const onFinish = (val: SearchParams) => {
    searchform.validateFields().then(_ => {
      setPageOffset(1);
      setSearchParams(val);
    });
  };

  const onReset = () => {
    setPageOffset(1);
    setSearchParams(undefined);
  };
  const onOk = async () => {
    let deviceIds = selectedRows?.map(item => item.id);
    if (!deviceIds || deviceIds.length === 0) {
      Modal.warning({ title: '请选择设备' });
      return;
    }
    let valid = await confirmform
      .validateFields()
      .then(res => true)
      .catch(_ => false);
    if (!valid) return;
    setConfirmLoading(true);

    apiV2MicrogridConfigurationCustomisationDeviceSavePost({
      list: deviceIds.map(item => ({
        deviceId: item,
        elementId,
        element: customisationPoint,
        uetStructId,
        microgridConfId,
      })) as any,
    })
      .then(_ => {
        onConfirm?.();
      })
      .finally(() => {
        setConfirmLoading(false);
      });
  };
  const rowSelection = {
    selectedRowKeys: selectedRows?.filter(item => item.id)?.map(item => item.id!),
    onChange: (selectedRowKeys: React.Key[], selectedRows: DeviceItem[]) => {
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record: DeviceItem) => ({
      disabled: record.binding,
    }),
  };

  const filters = (
    <CustomFilter colSpan={8} form={searchform} onFinish={val => onFinish(val as SearchParams)} onReset={onReset}>
      <Form.Item label="名称/编号" name="codeOrName">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item label="所属类目" name="deviceTypeIdList">
        <Select
          placeholder="请选择"
          allowClear
          onChange={() => {
            searchform.setFieldsValue({
              physicalModelId: undefined,
            });
            mutatePhysicalModel([]);
          }}
          options={deviceTypeList?.list?.map(item => ({ label: item.name, value: item.id }))}
        />
      </Form.Item>

      <Form.Item label="物模型" name="physicalModelId">
        <Select
          placeholder="请选择"
          allowClear
          options={physicalModelList?.map(item => ({ label: item.modelNo, value: item.id }))}
        />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Modal open title={'新增'} size="big" onOk={onOk} onCancel={() => onCancel?.()}>
      <Form
        form={confirmform}
        initialValues={{
          customisationPoint: ElementType.BSA,
        }}
      >
        <Row>
          <Col span={24}>
            <Form.Item label="类" name="customisationPoint" rules={[{ required: true, message: '请选择' }]}>
              <Select
                onChange={() => {
                  searchform.setFieldsValue({
                    deviceTypeIdList: undefined,
                    physicalModelId: undefined,
                  });
                  confirmform.setFieldsValue({
                    elementId: undefined,
                  });
                  setSelectedRows([]);
                  mutateDeviceType({ list: [] });
                  mutatePhysicalModel([]);
                }}
                options={Object.entries(elementTypeDisplay).map(([value, label]) => ({ label, value }))}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="元素名称" name="elementId" rules={[{ required: true, message: '请选择' }]}>
              <Select
                placeholder="请选择"
                allowClear
                options={customisationPointList?.list?.map(item => ({
                  label: item.elementName,
                  value: item.elementId,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{ height: 1, backgroundColor: '#3B3B3B', marginBottom: 15 }}></div>
      {filters}
      <Table
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        selectedCount={(selectedRows ?? []).length}
        showSelectedCount
        scroll={{ y: 250 }}
        rowKey="id"
        columns={columns}
        dataSource={data}
      >
        <Paging pagingInfo={pagingInfo} />
      </Table>
    </Modal>
  );
};

export default DeviceSelectModal;
