import { FC, useContext, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Empty, Modal, PopConfirm, SubContent } from '@maxtropy/components';
import {
  apiV2BsaStrategyBsaStrategyDetailPost,
  apiV2BsaStrategyDeliverPost,
  apiV2BsaStrategyEditPost,
  V2BsaStrategyBsaStrategyDetailPostResponse,
  V2BsaStrategyTemplateGetPostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { useParams } from 'react-router-dom';
import { ActionTypeEnum, BsaStrategyCalendarContext, OpChangeStatus } from '../const';
import styles from './index.module.scss';
import StrategyTemplateEdit, { StrategyTemplateEditRefProps } from '../../components/StrategyTemplateEdit';
import StrategyTemplateDetail from '../../components/StrategyTemplateDetail';
import { Space } from 'antd';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';

const StrategyDetail: FC = () => {
  const { id } = useParams();
  const context = useContext(BsaStrategyCalendarContext);
  const { strategyCalendarData, viewDate } = context;
  const [currentData, setCurrentData] = useState<V2BsaStrategyBsaStrategyDetailPostResponse>();
  const [isEdit, setIsEdit] = useState(false);
  const strategyTemplateEditRef = useRef<StrategyTemplateEditRefProps>(null);

  const hasEdit = useHasPermission(PermissionsType.B_EDIT_CHARGING_AND_DISCHARGING_STRATEGY);

  const today = dayjs();

  const currentDateItem = strategyCalendarData?.find(item => dayjs(item.time).isSame(viewDate, 'day'));

  const getData = () => {
    if (!currentDateItem) return;
    setCurrentData({});
    apiV2BsaStrategyBsaStrategyDetailPost({
      neoBsaId: id,
      strategyId: currentDateItem.id,
    }).then(res => {
      setCurrentData(res);
    });
  };

  useEffect(() => {
    getData();
  }, [currentDateItem]);

  const onConfirm = () => {
    strategyTemplateEditRef.current?.getFormData().then((values: any) => {
      const modal = Modal.info({
        title: '正在修改策略...',
        footer: null,
        keyboard: false,
      });
      apiV2BsaStrategyEditPost({
        neoBsaId: id,
        id: currentDateItem?.id,
        day: currentData?.day,
        templateName: currentData?.templateName,
        templateType: currentData?.templateType,
        status: currentData?.status,
        templateId: currentData?.templateId,
        actionType: OpChangeStatus.EDIT,
        ...values,
      })
        .then(() => {
          Modal.success({
            title: '修改策略成功！',
          });
          setIsEdit(false);
          getData();
        })
        .finally(() => {
          modal.destroy();
        });
    });
  };

  return (
    <div className={styles.strategyDetailWrap}>
      {currentDateItem ? (
        <SubContent
          className={styles.flexContent}
          title={`查看策略 ${dayjs(viewDate).format('YYYY-MM-DD')}`}
          extraContent={
            dayjs(viewDate).isSameOrAfter(today, 'day') ? (
              !isEdit ? (
                hasEdit && (
                  <Button type="primary" onClick={() => setIsEdit(true)}>
                    编辑
                  </Button>
                )
              ) : (
                <Space size={8}>
                  <PopConfirm title="确定修改策略？" onConfirm={onConfirm}>
                    <Button type="primary">确认修改</Button>
                  </PopConfirm>
                  <Button onClick={() => setIsEdit(false)}>取消</Button>
                </Space>
              )
            ) : null
          }
        >
          <div className={styles.overflowContent}>
            {isEdit ? (
              <StrategyTemplateEdit
                bsaId={id}
                id={currentData?.id}
                data={currentData as V2BsaStrategyTemplateGetPostResponse}
                showTemplateName={false}
                size="small"
                ref={strategyTemplateEditRef}
              />
            ) : (
              <StrategyTemplateDetail
                bsaId={id}
                id={currentData?.id}
                data={currentData as V2BsaStrategyTemplateGetPostResponse}
                showTemplateName={false}
                size="small"
              />
            )}
          </div>
        </SubContent>
      ) : (
        <Empty description="该日期暂无策略！" className={styles.empty}></Empty>
      )}
    </div>
  );
};

export default StrategyDetail;
