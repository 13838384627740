import React, { FC } from 'react';
import '../../common.module.scss';
import { Carousel } from 'antd';

export interface IRenderCarousel {
  propertyLength: number;
  children?: React.ReactNode;
}

const RenderCarousel: FC<IRenderCarousel> = props => {
  const { children, propertyLength } = props;
  return (
    <>
      {propertyLength > 5 ? (
        <>
          <Carousel
            autoplay={true}
            vertical={true}
            dots={false}
            cssEase="linear"
            autoplaySpeed={4000}
            speed={1500}
            slidesToShow={5}
          >
            {children}
          </Carousel>
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default RenderCarousel;
