import ShowInput from '@/components/ShowInput';
import { Descriptions, Drawer, Form, Spin } from 'antd';
import React, { FC, useState } from 'react';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import { isNil } from 'lodash-es';
import {
  apiV2DispatchUetStructPublishPvDetailPost,
  apiV2DispatchUetStructPvGridConnectionPointPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import {
  GridConnectionLevel,
  GridConnectionLevelLabel,
  NodeType,
} from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import TitleTag from '../TitleTag';

export interface PVaDrawerProps {
  isShowModal: (open: boolean) => void;
  open: boolean;
  id?: number;
  version?: number;
}

const { Item } = Form;

const PVaDrawer: FC<PVaDrawerProps> = props => {
  const { isShowModal, open, id, version } = props;
  const urlSearch = new URLSearchParams(window.location.search);
  const dispatchingGroup = urlSearch.get('dispatchingGroup'); // 调度组id
  const topologyId = urlSearch.get('topologyId'); // 结构id
  const [form] = Form.useForm();
  const pvStationId = Form.useWatch('pvStationId', form);
  // const hasControlItem = Form.useWatch('hasControlItem', form);
  const [title, setTitle] = useState<string>();

  const { data: detail, loading } = useRequest(
    async () => {
      if (isNil(id) || !open) return;
      if (!isNil(version)) {
        const res = await apiV2DispatchUetStructPublishPvDetailPost({ id, version });
        setTitle(res.pvStationDto?.name);
        const controlObjList = [
          ...(res.pvInverterDtos ?? []).map(item => item.deviceName),
          ...(res.agcDeviceVo ?? []).map(item => item.name),
        ];
        const gridConnectionPointList = res.gridConnectionPointSimpleVoList
          ?.filter(item => item.hasChoose)
          ?.map(item => item.name);
        form.setFieldsValue({
          ...res,
          // 判断图标的部分
          pvStationId: res.pvStationDto?.id,
          hasControlItem: res.hasControlItem ?? false,
          gridConnectionPointIdList:
            res.gridConnectionPointSimpleVoList?.filter(item => item.hasChoose)?.map(item => item.id) ?? [],
          pvInverterIds: res.pvInverterDtos?.map(item => item.id),
          agcDeviceIds: res.agcDeviceVo?.map(item => item.id),

          NodeTypeName: '光伏站',
          pvStationName: res.pvStationDto?.name,
          gridConnectionPointList:
            (gridConnectionPointList ?? []).length !== 0 ? gridConnectionPointList?.join() : '--',
          controlObjList: controlObjList.length !== 0 ? controlObjList.join() : '--',
        });
        return res;
      }
    },
    { refreshDeps: [id, open, version] }
  );

  // 并网点list
  const { data: gridConnectionPointOptions } = useRequest(
    async () => {
      if (isNil(pvStationId) || !open) return Promise.resolve([]);

      const res = await apiV2DispatchUetStructPvGridConnectionPointPost({
        id: +pvStationId,
        dispatchGroupId: +dispatchingGroup!,
        dispatchUetStructId: +topologyId!,
      });
      if (res && res.list) {
        return res.list.map(item => {
          // 如果详情里面返回里面有hasChoose,则表示已经选择 不做禁用。
          // 如果详情里面返回里面没有hasChoose,则表示未选择 根据list里面的状态去判断禁用
          const hasChoose = detail?.gridConnectionPointSimpleVoList?.find(ele => ele.id === item.id)?.hasChoose;
          const isDisabled = hasChoose ? false : item.disable;

          return {
            label: item.name
              ? item.name + ' ' + '电压等级' + GridConnectionLevelLabel[item.level as GridConnectionLevel] ?? '--'
              : '',
            value: item.id ?? '',
            disabled: isDisabled,
          };
        });
      }
    },
    {
      refreshDeps: [pvStationId, open],
    }
  );

  return (
    <div className={styles.drawerStyle}>
      <Drawer
        title={<TitleTag title={title ?? '光伏站'} nodeType={NodeType.SOURCE} />}
        placement="right"
        width={400}
        mask={false}
        onClose={() => {
          isShowModal?.(false);
        }}
        open={open}
        getContainer={false}
      >
        <Spin spinning={loading}>
          <Form form={form} labelCol={{ span: 8 }} labelAlign="left">
            <Descriptions labelStyle={{ width: '40%' }} title={null} bordered column={1} size="small">
              <Descriptions.Item label="元素类型">
                <Item name="NodeTypeName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="光伏站名称">
                <Item name="pvStationName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="关联并网点信息">
                <Item name="gridConnectionPointList" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              {/* <Descriptions.Item label="控制对象信息">
                <Item name="controlObjList" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item> */}
            </Descriptions>

            <Item name="pvStationId" hidden />
            <Item name="pvInverterIds" hidden />
            <Item name="agcDeviceIds" hidden />
            <Item name="hasControlItem" hidden />
            <Item name="gridConnectionPointIdList" hidden />

            <div className={styles.preview}>
              <div className={styles.previewBtn} style={{ marginTop: 16, marginBottom: 8 }}>
                查看图标
              </div>

              <div className={styles.iconWrapper}>
                <div className={styles.iconBox}>
                  <div className={styles.icon} />

                  <Item noStyle dependencies={['gridConnectionPointIdList']}>
                    {({ getFieldValue }) => {
                      const gridConnectionPointIdList = getFieldValue('gridConnectionPointIdList');

                      if (gridConnectionPointIdList?.length < gridConnectionPointOptions?.length!) {
                        return <div className={styles.part}>部分</div>;
                      }
                    }}
                  </Item>

                  <Item noStyle dependencies={['pvStationName']}>
                    {({ getFieldValue }) => {
                      const pvStationName = getFieldValue('pvStationName');
                      return <div>{pvStationName}</div>;
                    }}
                  </Item>

                  <Item noStyle dependencies={['gridConnectionPointIdList']}>
                    {({ getFieldValue }) => {
                      const gridConnectionPointIdList = getFieldValue('gridConnectionPointIdList');

                      return (
                        <div className={styles.parallelBranch}>{`并网点${gridConnectionPointIdList?.length ?? 0}/${
                          gridConnectionPointOptions?.length ?? 0
                        }`}</div>
                      );
                    }}
                  </Item>

                  {/* {hasControlItem && (
                    <>
                      <div className={styles.dashed} />

                      <div className={styles.controlObject}>
                        {[
                          ...(detail?.pvInverterDtos ?? []).map(i => i.deviceName),
                          ...(detail?.agcDeviceVo ?? []).map(i => i.name),
                        ].map((item, index) => (
                          <div key={index} className={styles.controlObjectIcon}>
                            {item}
                          </div>
                        ))}
                      </div>
                    </>
                  )} */}
                </div>
              </div>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </div>
  );
};

export default PVaDrawer;
