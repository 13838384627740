import { Graph, Node } from '@antv/x6';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { TimeDimensionEnm, TimeDimensionEnmDisplay, TimeDimensionEnmDisplayParams, TreeNode } from '../../type';
import styles from './index.module.scss';
import antiBackflowDevice from '../../imgs/antiBackflowDevice.gif';
import classNames from 'classnames';
import { DownOutlined, InfoCircleOutlined, UpOutlined } from '@ant-design/icons';
import { calcMaxLength, handleCommonExpand, judgeDrageOrClick } from '../../utils';
import { isNil } from 'lodash-es';
import { keepTwoNull } from '@/pages/MicrogridConfOverview/utils';
import CustomTooltip from '../CustomTooltip';
import { V2GreenMicrowebShowcaseDataPropertyDataPostResponse } from '@maxtropy/intelligent-dispath-apis-v2';
import { ITEM_HEIGHT, treeItemProps } from '../..';
import RenderCarousel from '../RenderCarousel';

export interface AntiBackflowDeviceProps {
  node: Node;
  graphRef?: Graph;
  allNodeDataRef?: TreeNode[];
  configId?: number;
}

const AntiBackflowDevice: FC<AntiBackflowDeviceProps> = props => {
  const { node, graphRef, allNodeDataRef, configId } = props;
  const refBox = useRef<HTMLDivElement>();

  const data = useMemo(() => {
    return node.getData();
  }, [node.getData()]);

  // 获取数据点数据信息
  const dataPropertyDataList: V2GreenMicrowebShowcaseDataPropertyDataPostResponse['list'] = useMemo(() => {
    return data?.value?.deviceInfos;
  }, [data]);

  // 获取数据点信息
  const dataPropertyConfigsList: treeItemProps['dataPropertyConfigs'] = useMemo(() => {
    return data?.value?.dataPropertyConfigs ?? [];
  }, [data]);

  // 平铺当前元素的数据点(如果没有数据点数据返回，则用树形结构中的树)
  const allDataPropertyConfigsList = useMemo(() => {
    if (!isNil(dataPropertyDataList)) {
      const dataConfigsList = (dataPropertyConfigsList ?? [])
        .map(item => {
          return item.list?.map(c => ({
            ...c,
            deviceId: item.deviceId,
          }));
        })
        .flat();
      const dataPropertysList = (dataPropertyDataList ?? [])
        .map(item => {
          return item.list;
        })
        .flat();
      return (dataConfigsList ?? []).map((el: any) => ({
        ...el,
        value: dataPropertysList?.find((item: any) => item.dataPropertyId == el.dataPropertyId)?.value,
        old: dataPropertysList?.find((item: any) => item.dataPropertyId == el.dataPropertyId)?.old,
      }));
    } else {
      return (dataPropertyConfigsList ?? [])
        .map(item => {
          return item.list?.map(c => ({
            ...c,
            deviceId: item.deviceId,
          }));
        })
        .flat();
    }
  }, [dataPropertyConfigsList, dataPropertyDataList]);

  // 数据过于陈旧展示
  const oldDataList = useMemo(() => {
    return allDataPropertyConfigsList
      .filter(item => item.old)
      .map(i => i.dataPropertyName)
      .join();
  }, [allDataPropertyConfigsList]);

  // 区分拖动与点击事件
  useEffect(() => {
    if (refBox.current) {
      judgeDrageOrClick(refBox.current, configId!, data?.value?.id, data?.element);
    }
  }, []);

  const onExpand = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    node.updateData({
      isExpand: !data.isExpand,
    });
    const height = !data.isExpand ? 80 + ITEM_HEIGHT * calcMaxLength(allDataPropertyConfigsList.length) : 80;
    handleCommonExpand(
      data?.value?.id,
      data?.element,
      node,
      height,
      !data.isExpand,
      allDataPropertyConfigsList.length,
      graphRef,
      allNodeDataRef
    );
  };

  return (
    <div className={styles.customAntiBackFlowReactNode}>
      <div className={styles.vline}></div>
      <div className={styles.hline}></div>
      <div className={styles.cardMeterSty_wrapper}>
        <div className={styles.cardMeterSty}>
          <div
            className={styles.content}
            ref={d => {
              if (d) {
                refBox.current = d;
              }
            }}
          >
            <img style={{ width: 24, height: 24, marginLeft: 8 }} src={antiBackflowDevice} alt="" />

            <CustomTooltip title={`防逆流-${data.value.name}`}>
              <div style={{ marginLeft: 8 }} className={styles.itemControlobjContentSty}>
                防逆流-{data.value.name}
              </div>
            </CustomTooltip>
            {oldDataList && oldDataList.length > 0 && (
              <CustomTooltip title={`数据过于陈旧：${oldDataList}`}>
                <InfoCircleOutlined className={styles.infoIcon} />
              </CustomTooltip>
            )}
          </div>
          <div className={styles.expand_content}>
            {data.isExpand && allDataPropertyConfigsList.length > 0 ? (
              <>
                <div className={styles.dividerSty} />
                <div className={styles.device_prop_wrapper}>
                  <RenderCarousel propertyLength={allDataPropertyConfigsList.length ?? 0}>
                    {(allDataPropertyConfigsList ?? []).map((item: any) => (
                      <div
                        className={styles.item_sty}
                        key={item.dataPropertyId}
                        onClick={() => {
                          window.open(
                            `${window.IOTPLATFORMORIGIN}/data/history/device?deviceId=${
                              item?.deviceId
                            }&dataPropertyId=${item?.dataPropertyId}&dateMode=${
                              !isNil(item?.timeDimensionEnum) &&
                              TimeDimensionEnmDisplayParams[item.timeDimensionEnum as TimeDimensionEnm]
                            }`,
                            '_blank'
                          );
                        }}
                      >
                        {item?.timeDimensionEnum &&
                          (item.timeDimensionEnum as TimeDimensionEnm) !== TimeDimensionEnm.REAL_TIME_VALUE && (
                            <CustomTooltip
                              title={
                                !isNil(item.timeDimensionEnum)
                                  ? TimeDimensionEnmDisplay[item.timeDimensionEnum as TimeDimensionEnm] + '数据'
                                  : '--'
                              }
                            >
                              <div className={styles.corner}></div>
                            </CustomTooltip>
                          )}
                        <div className={styles.label_sty}>
                          <CustomTooltip title={item?.dataPropertyName ?? '--'}>
                            {item?.dataPropertyName ?? '--'}
                          </CustomTooltip>
                        </div>
                        <div
                          className={
                            item?.timeDimensionEnum !== TimeDimensionEnm.REAL_TIME_VALUE
                              ? styles.value_sty
                              : styles.real_value_sty
                          }
                        >
                          <CustomTooltip title={keepTwoNull(item?.value) + (item?.generalName ?? '')}>
                            {keepTwoNull(item?.value) + (item?.generalName ?? '')}
                          </CustomTooltip>
                        </div>
                      </div>
                    ))}
                  </RenderCarousel>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {allDataPropertyConfigsList.length > 0 && (
          <div className={styles.expand_box} onClick={e => onExpand(e)}>
            {data.isExpand ? (
              <UpOutlined className={classNames(styles.nodeExpandIcon)} />
            ) : (
              <DownOutlined className={styles.nodeExpandIcon} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AntiBackflowDevice;
