export enum AlarmStatus {
  TopHighAlarm = 0,
  HighAlarm = 1,
  MiddleAlarm = 2,
  LowerAlarm = 3,
  EarlyWarningAlarm = 4,
}

export const AlarmStatusDisplay = {
  [AlarmStatus.TopHighAlarm]: '最高级',
  [AlarmStatus.HighAlarm]: '高级',
  [AlarmStatus.MiddleAlarm]: '中级',
  [AlarmStatus.LowerAlarm]: '低级',
  [AlarmStatus.EarlyWarningAlarm]: '最低级',
};
export const AlarmStatusColorDisplay = {
  [AlarmStatus.TopHighAlarm]: '#E64242',
  [AlarmStatus.HighAlarm]: '#FAAD14',
  [AlarmStatus.MiddleAlarm]: '#4A90E2',
  [AlarmStatus.LowerAlarm]: '#52C41A',
  [AlarmStatus.EarlyWarningAlarm]: '#ABD335',
};

export enum AlarmConfType {
  TYPE_1 = 1,
  TYPE_2 = 2,
  TYPE_3 = 3,
  TYPE_4 = 4,
  TYPE_5 = 5,
}

export const AlarmConfTypeDisplay = {
  [AlarmConfType.TYPE_1]: '元素关联设备数据点',
  [AlarmConfType.TYPE_2]: '元素自定义数据点',
  [AlarmConfType.TYPE_3]: '元素热区',
  [AlarmConfType.TYPE_4]: '目标设备调度',
  [AlarmConfType.TYPE_5]: '“报警列表',
};

export enum AlarmConfTypeIsShow {
  Hidden = 0, // 隐藏
  Show = 1, // 展示
}
