import { FC, useContext } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import classnames from 'classnames';
import { Tag } from '@maxtropy/components';
import checkIcon from '@/assets/images/checkIcon.png';
import styles from './index.module.scss';
import {
  ActionTypeEnum,
  BsaStrategyCalendarContext,
  checkCanSelect,
  StrategyStatusEnum,
  StrategyStatusLabel,
} from '../const';

interface Props {
  date: Dayjs;
}

const tagColorMap = {
  [StrategyStatusEnum.HAVE_RUN]: 'info',
  [StrategyStatusEnum.RUNNING]: 'success',
  [StrategyStatusEnum.HAVE_STOP]: 'error',
  [StrategyStatusEnum.TO_RUN]: 'default',
};

const cellStatusClassNameMap = {
  [StrategyStatusEnum.HAVE_RUN]: styles.haveRun,
  [StrategyStatusEnum.RUNNING]: styles.running,
  [StrategyStatusEnum.HAVE_STOP]: styles.haveStop,
  [StrategyStatusEnum.TO_RUN]: styles.toRun,
};

const DateCell: FC<Props> = props => {
  const { date } = props;
  const context = useContext(BsaStrategyCalendarContext);
  const { viewDate, setViewDate, actionType, selectedDateList, setSelectedDateList, strategyCalendarData, cellHeight } =
    context;

  const isCurrentDay = date.isSame(new Date(), 'day');
  const isViewing = viewDate?.isSame(date, 'day');
  const isSelected = selectedDateList?.some(v => v.isSame(date, 'day'));

  const foundData = strategyCalendarData?.find(item => dayjs(item.time).isSame(date, 'day'));

  const onCellClick = () => {
    if (actionType === ActionTypeEnum.VIEW) {
      setViewDate?.(date);
    } else {
      if (!checkCanSelect(date, strategyCalendarData!, actionType!)) {
        return;
      }
      if (isSelected) {
        const newSelectedDateList = selectedDateList?.filter(v => !v.isSame(date, 'day'))!;
        setSelectedDateList?.(newSelectedDateList);
      } else {
        setSelectedDateList?.([...selectedDateList!, date]);
      }
    }
  };

  return (
    <div
      className={classnames(
        styles.dateCell,
        actionType === ActionTypeEnum.VIEW && isViewing && styles.viewing,
        actionType !== ActionTypeEnum.VIEW && isSelected && styles.selected,
        cellStatusClassNameMap[foundData?.status! as StrategyStatusEnum],
        actionType !== ActionTypeEnum.VIEW &&
          !checkCanSelect(date, strategyCalendarData!, actionType!) &&
          styles.disabledSelect
      )}
      style={{ height: cellHeight }}
      onClick={onCellClick}
    >
      <div className={styles.cellContent}>
        {!!foundData && (
          <Tag border="solid" color={tagColorMap[foundData.status! as StrategyStatusEnum]} className={styles.statusTag}>
            {StrategyStatusLabel[foundData.status! as StrategyStatusEnum]}
          </Tag>
        )}
        <div className={classnames(styles.dayNumber, isCurrentDay && styles.currentDayNumber)}>{date.format('DD')}</div>
        {isSelected && <img src={checkIcon} alt="" className={styles.checkIcon}></img>}
      </div>
    </div>
  );
};

export default DateCell;
