import { PermissionsType } from '@/common/permissionsConst';
import InstructionConfig from '.';
import DispathReLog from './DispathReLog';

const routes = [
  {
    path: '/microgrid/conf/overview',
    permission: PermissionsType.P_MICRO_CONFG_OVERVIEW,
    element: <InstructionConfig />,
  },
  {
    path: '/microgrid/conf/overview/reLog/:configId',
    permission: PermissionsType.P_MICRO_CONFG_OVERVIEW,
    element: <DispathReLog />,
  },
];

export default routes;
