import { OrganizationResponse } from '@/api/device';

export interface TiledArrayProps {
  label: string;
  value: string;
}

export const tiledArray = (data: OrganizationResponse[]) => {
  const res: TiledArrayProps[] = [];
  function loop(array: OrganizationResponse[]) {
    array.forEach(item => {
      if (item.data.name) {
        res.push({
          label: item.data?.name,
          value: item.data.mcid,
        });
      }
      if (item.children) loop(item.children);
    });
  }
  loop(data);
  return res;
};
