import { Node } from '@antv/x6';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import styles from './index.module.scss';
import charger from '../../imgs/charger.gif';
import CustomTooltip from '../CustomTooltip';
import { judgeDrageOrClick } from '../../utils';

export interface ChargingStationProps {
  node: Node;
  configId?: number;
}

const ChargingStation: FC<ChargingStationProps> = props => {
  const { node, configId } = props;
  const refBox = useRef<HTMLDivElement>();

  const data = useMemo(() => {
    return node.getData();
  }, [node.getData()]);

  // 区分拖动与点击事件
  useEffect(() => {
    if (refBox.current) {
      judgeDrageOrClick(refBox.current, configId!, data?.value?.id, data?.element);
    }
  }, []);

  return (
    <div className={styles.customChargingStationReactNode}>
      <div className={styles.vline}></div>
      <div
        className={styles.cardSty}
        ref={d => {
          if (d) {
            refBox.current = d;
          }
        }}
      >
        <img style={{ width: 40, height: 40 }} src={charger} alt="" />
        <CustomTooltip title={data.value.name}>
          <div className={styles.itemControlobjContentSty}>{data.value.name}</div>
        </CustomTooltip>
      </div>
    </div>
  );
};

export default ChargingStation;
