// 状态(0-停用,1-启用,2-作废,3-草稿)
export enum Status {
  DISABLE = 0,
  ENABLE = 1,
  INVALID = 2,
  DRAFT = 3,
}

export const StatusLabel = {
  [Status.DISABLE]: '停用',
  [Status.ENABLE]: '启用',
  [Status.INVALID]: '作废',
  [Status.DRAFT]: '使用中',
};

export const StatusTagColor = {
  [Status.DISABLE]: 'invalid',
  [Status.ENABLE]: 'success',
  [Status.INVALID]: 'invalid',
  [Status.DRAFT]: 'warning',
};
