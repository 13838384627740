import { PermissionsType } from '@/common/permissionsConst';
import StrategyTemplate from '.';
import StrategyTemplateEdit from './StrategyTemplateEdit';
import StrategyTemplateDetail from './StrategyTemplateDetail';
const routes = [
  {
    path: '/energy-storage/basic/bsa/templateList',
    permission: PermissionsType.P_STRATEGYTEMPLATE,
    element: <StrategyTemplate />,
  },
  {
    path: '/energy-storage/basic/bsa/templateEdit/:id',
    permission: PermissionsType.P_STRATEGYTEMPLATE,
    element: <StrategyTemplateEdit />,
  },
  {
    path: '/energy-storage/basic/bsa/templateCreate',
    permission: PermissionsType.P_STRATEGYTEMPLATE,
    element: <StrategyTemplateEdit />,
  },
  {
    path: '/energy-storage/basic/bsa/templateDetail/:id',
    permission: PermissionsType.P_STRATEGYTEMPLATE,
    element: <StrategyTemplateDetail />,
  },
];

export default routes;
