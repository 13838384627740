import React, { Key, useState } from 'react';
import {
  EllipsisSpan,
  Table,
  Button,
  Wrapper,
  Paging,
  usePaging,
  useUpdate,
  useBreadcrumbRoutes,
  Form,
  Input,
  PopConfirm,
  CustomFilter,
} from '@maxtropy/components';
import { Col, Space } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import {
  apiV2BsaStrategyTemplatePagePost,
  apiV2BsaStrategyTemplateDeletePost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { StrategyTemplateType, StrategyTemplateTypeDisplay } from './type';
import { PlusOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import BaseInfo from '../Bsa/RulesConfig/components/BaseInfo';
// type SearchParams = Omit<EnergyConsumptionRequest, 'page' | 'size'>;
type SearchParams = Omit<{}, 'page' | 'size'>;

// interface FilterParams extends Omit<meterProjectPagingItem, 'page' | 'size'> {}
interface FilterParams extends Omit<{}, 'page' | 'size'> {}

const formatString = 'YYYY-MM-DD HH:mm:ss';

const columns: any = [
  {
    title: '策略模板编号',
    ellipsis: true,
    dataIndex: 'templateNo',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '策略模板名称',
    ellipsis: true,
    dataIndex: 'templateName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '模板类型',
    dataIndex: 'templateType',
    ellipsis: true,
    render: (v: StrategyTemplateType) => <EllipsisSpan value={StrategyTemplateTypeDisplay[v]} />,
  },
  {
    title: '最后操作时间',
    ellipsis: true,
    dataIndex: 'updateTime',
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format(formatString) : '--'} />,
  },
  {
    title: '最后操作人',
    ellipsis: true,
    dataIndex: 'updateUser',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const StrategyTemplate = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const bsaId = urlSearchParams.get('bsaId') || undefined;

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [updateState, updateFn] = useUpdate();

  const onFinish = (val: any) => {
    const params = {
      templateName: val.templateName,
    };
    setSearchParams(params);
  };

  const onReset = () => {
    setPageOffset(1);
    setSearchParams({});
  };

  // console.log('searchParams', searchParams);

  // 策略模板分页
  const { data: tableList, loading: tableLoading } = useRequest(
    () =>
      apiV2BsaStrategyTemplatePagePost({ ...searchParams, neoBsaId: bsaId, page: pageOffset, size: pageSize }).then(
        res => {
          setTotalCount(res.total!);
          return res.list;
        }
      ),
    {
      ready: !!bsaId,
      refreshDeps: [pageOffset, pageSize, searchParams, updateState, bsaId],
    }
  );

  // 删除策略模板
  const onDel = (id: Key) => {
    apiV2BsaStrategyTemplateDeletePost({ id }).then(() => {
      updateFn();
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 220,
      render: (v: any, record: any) => {
        return (
          <Space size={16}>
            <PopConfirm title="您是否删除此策略模板？" onConfirm={() => onDel(record?.id)}>
              <Button type="link">删除</Button>
            </PopConfirm>

            <Button
              type="link"
              onClick={() => {
                navigate(`/energy-storage/basic/bsa/templateEdit/${record.id}?bsaId=${bsaId}`);
              }}
            >
              编辑
            </Button>

            <Button
              type="link"
              onClick={() => {
                navigate(`/energy-storage/basic/bsa/templateDetail/${record.id}?bsaId=${bsaId}`);
              }}
            >
              查看
            </Button>
            <Button
              type="link"
              onClick={() => {
                navigate(`/energy-storage/basic/bsa/strategy/${bsaId}`);
              }}
            >
              投放
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Wrapper
      filters={
        <div>
          <BaseInfo bsaId={bsaId} />
          <CustomFilter<FilterParams> form={form} onFinish={onFinish} onReset={onReset}>
            <Form.Item name="templateName" label="模板名称">
              <Input placeholder="请输入" />
            </Form.Item>
          </CustomFilter>
        </div>
      }
      routes={[...(breadcrumbRoutes?.routes ?? [])]}
      className={styles.wrap}
    >
      <div>
        <Space size={8} style={{ marginBottom: 10 }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              navigate(`/energy-storage/basic/bsa/templateCreate?bsaId=${bsaId}`);
            }}
          >
            新建策略模板
          </Button>
        </Space>

        <Table
          rowKey={'id'}
          sticky
          scroll={{ x: 1300 }}
          loading={tableLoading}
          dataSource={tableList}
          columns={buildColumns}
        />

        <Paging pagingInfo={pagingInfo} />
      </div>
    </Wrapper>
  );
};

export default StrategyTemplate;
