import { Descriptions, Drawer, Form, Spin } from 'antd';
import React, { FC, useState } from 'react';
import styles from './index.module.scss';
import ShowInput from '@/components/ShowInput';
import { useRequest } from 'ahooks';
import { isNil } from 'lodash';
import { apiV2DispatchUetStructPublishBusbarDetailPost } from '@maxtropy/intelligent-dispath-apis-v2';
import {
  BusBarVoltageLevel,
  BusBarVoltageLevelLabel,
  NodeType as NodeTagType,
} from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import TitleTag from '../TitleTag';

export interface BusBarDrawerProps {
  isShowModal: (open: boolean) => void;
  open: boolean;
  id?: number;
  version?: number;
}

const { Item } = Form;

const BusBarDrawer: FC<BusBarDrawerProps> = props => {
  const { isShowModal, open, id, version } = props;

  const [form] = Form.useForm();
  const [title, setTitle] = useState<string>();

  const { loading } = useRequest(
    async () => {
      if (isNil(id) || !open) return Promise.resolve(undefined);
      if (!isNil(version)) {
        const detail = await apiV2DispatchUetStructPublishBusbarDetailPost({ id, version });
        setTitle(detail.name);
        form.setFieldsValue({
          ...detail,
          NodeTypeName: '母线',
          level: BusBarVoltageLevelLabel[detail.level as BusBarVoltageLevel],
        });
      }
    },
    {
      refreshDeps: [id, open, version],
    }
  );

  return (
    <div className={styles.drawerStyle}>
      <Drawer
        title={<TitleTag title={title ?? '母线'} nodeType={NodeTagType.DISTRIBUTION} />}
        placement="right"
        width={400}
        mask={false}
        onClose={() => {
          isShowModal?.(false);
        }}
        open={open}
        getContainer={false}
      >
        <Spin spinning={loading}>
          <Form form={form}>
            <Descriptions labelStyle={{ width: '40%' }} title={null} bordered column={1} size="small">
              <Descriptions.Item label="元素类型">
                <Item name="NodeTypeName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="母线名称">
                <Item name="name" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="电压等级">
                <Item name="level" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
            </Descriptions>

            <div className={styles.preview}>
              <div className={styles.previewBtn} style={{ marginTop: 16, marginBottom: 8 }}>
                查看图标
              </div>

              <div className={styles.iconWrapper}>
                <div className={styles.iconBox}>
                  <div className={styles.icon} />
                  <div className={styles.iconText}>
                    <Item noStyle dependencies={['name', 'level']}>
                      {({ getFieldValue }) => (
                        <>
                          <div className={styles.generatrix}>{getFieldValue('name')}</div>
                          <div className={styles.voltageLevel}>
                            {BusBarVoltageLevelLabel[getFieldValue('level') as BusBarVoltageLevel]}
                          </div>
                        </>
                      )}
                    </Item>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </div>
  );
};

export default BusBarDrawer;
