import { FC, useState } from 'react';
import {
  Button,
  EllipsisSpan,
  FormTitle,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  Wrapper,
} from '@maxtropy/components';
import { ColumnsType } from 'antd/es/table';
import { useNavigate, useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import {
  apiV2BsaControlConfigLogPagePost,
  apiV2BsaDetailBsaV2Post,
  V2BsaControlConfigLogPagePostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import DetailDialog from './DetailDialog';
import { Affix } from 'antd';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

type DataListItem = Exclude<V2BsaControlConfigLogPagePostResponse['list'], undefined>[number];

const ControlConfigDiff: FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const pageName = '控制配置修改日志';
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;
  const [detailId, setDetailId] = useState<number>();

  const { data, loading } = useRequest(
    async () => {
      const res = await apiV2BsaControlConfigLogPagePost({
        bsaId: +id!,
        page: pageOffset,
        size: pageSize,
      });
      setTotalCount(res.total ?? 0);
      return res.list;
    },
    {
      refreshDeps: [pageOffset, pageSize],
    }
  );

  // 获取软件版本作区分
  const { data: softwareVersion } = useRequest(async () => {
    const bsaData = await apiV2BsaDetailBsaV2Post({
      id: +id!,
    });
    return bsaData.softwareVersion;
  });

  const onView = (id: number) => {
    setDetailId(id);
  };

  const columns: ColumnsType<DataListItem> = [
    {
      title: '修改时间',
      dataIndex: 'updateTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={dayjs(v).format(DATE_FORMAT)}></EllipsisSpan>,
    },
    {
      title: '修改人',
      dataIndex: 'username',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v}></EllipsisSpan>,
    },
    {
      title: '修改对比',
      key: 'action',
      render: (_, record) => (
        <Button type="link" onClick={() => onView(record.id!)}>
          查看
        </Button>
      ),
    },
  ];

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), { name: pageName }]} className={styles.wrap}>
      <FormTitle title={pageName}></FormTitle>
      <div className={styles.pageContent}>
        <Table rowKey="id" pagination={false} columns={columns} dataSource={data}></Table>
        <Paging pagingInfo={pagingInfo}></Paging>

        <Affix offsetBottom={30}>
          <Button onClick={() => navigate(-1)}>返回</Button>
        </Affix>
      </div>

      <DetailDialog id={detailId} setId={setDetailId} softwareVersion={softwareVersion}></DetailDialog>
    </Wrapper>
  );
};

export default ControlConfigDiff;
