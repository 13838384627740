import {
  Paging,
  Table,
  Tabs,
  Wrapper,
  useBreadcrumbRoutes,
  Form,
  CustomFilter,
  usePaging,
  Select,
  Button,
  EllipsisSpan,
  PopConfirm,
  Tag,
} from '@maxtropy/components';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Cascader, ConfigProvider, Input, Space, TabsProps } from 'antd';
import { useRequest } from 'ahooks';
import { LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';

import {
  apiV2DeviceDeviceTypeTreePost,
  apiV2GreenMicrowebShowcaseDeviceListPost,
  apiV2GreenMicrowebShowcaseDistributeLogPagePost,
  V2GreenMicrowebShowcaseDistributeLogPagePostRequest,
  V2GreenMicrowebShowcaseDistributeLogPagePostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';

import {
  DeviceTypes,
  DeviceTypeTree,
  formatOptionData,
  getChildNodesByParentIds,
} from '@/pages/InstructionConfig/utils';
import { DefaultOptionType } from 'antd/es/cascader';
type SearchParams = Omit<V2GreenMicrowebShowcaseDistributeLogPagePostRequest, 'page' | 'size'>;
export type ListItem = Exclude<V2GreenMicrowebShowcaseDistributeLogPagePostResponse['list'], undefined>[number];
const routes = [{ name: '调度日志' }];
const DispathReLog = () => {
  const routesContext = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const pagingInfo = usePaging(20);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const { configId } = useParams();
  const urlSearch = new URLSearchParams(window.location.search);
  const url_deviceId = urlSearch.get('deviceId'); // 结构id
  const navigate = useNavigate();
  const { data: pageList, loading } = useRequest(
    () => {
      return apiV2GreenMicrowebShowcaseDistributeLogPagePost({
        ...searchParams,
        confId: configId,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list;
      });
    },
    {
      ready: !!configId,
      refreshDeps: [pageOffset, pageSize, searchParams, configId],
    }
  );

  const buildColumns = [
    {
      title: '目标设备',
      ellipsis: true,
      dataIndex: 'deviceName',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所属类目',
      ellipsis: true,
      dataIndex: 'deviceTypeName',
      render: (v: string, record: any) => <EllipsisSpan value={v} />,
    },

    {
      title: '目标数据属性',
      ellipsis: true,
      dataIndex: 'dataPropertyName',
      render: (v: string) => <EllipsisSpan value={v} />,
    },

    {
      title: '指令名称',
      ellipsis: true,
      dataIndex: 'instructionName',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '下发状态',
      ellipsis: true,
      dataIndex: 'status',
      render: (v: number) => {
        return v === 1 ? (
          <Tag border="solid" color="success">
            下发成功
          </Tag>
        ) : (
          <Tag border="solid" color="error">
            发送失败
          </Tag>
        );
      },
    },
    {
      title: '最后操作时间',
      ellipsis: true,
      dataIndex: 'createTime',
      render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
    },
    {
      title: '操作人',
      ellipsis: true,
      dataIndex: 'lastUpdateUser',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];
  // 所属类目
  const { data: deviceTypeData } = useRequest(() => {
    return apiV2DeviceDeviceTypeTreePost();
  });

  const { data: targetDeviceOptions } = useRequest(
    () => {
      return apiV2GreenMicrowebShowcaseDeviceListPost({ id: configId });
    },
    {
      ready: !!configId,
      refreshDeps: [configId],
    }
  );

  useEffect(() => {
    let _list = targetDeviceOptions?.list;
    if (url_deviceId && _list?.length) {
      let deviceInfo = _list.find(item => item.id?.toString() === url_deviceId);
      if (!deviceInfo) return;
      form.setFieldsValue({
        deviceId: deviceInfo.id,
      });
      setSearchParams({ deviceId: deviceInfo.id });
    }
  }, [url_deviceId, targetDeviceOptions?.list]);

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  const filters = (
    <CustomFilter
      form={form}
      initialValues={{
        status: '',
      }}
      onFinish={(values: any) => {
        const deviceTypeIds = getChildNodesByParentIds(values.deviceType, deviceTypeData?.tree as DeviceTypes[]);
        setPageOffset(1);
        setSearchParams({ ...values, deviceTypeIds });
      }}
      onReset={() => {
        setPageOffset(1);
        form.resetFields();
        setSearchParams({});
      }}
    >
      <Form.Item name="deviceType" label="所属类目">
        <Cascader
          options={formatOptionData(deviceTypeData as DeviceTypeTree)}
          allowClear={false}
          fieldNames={{ children: 'child' }}
          multiple
          maxTagCount="responsive"
          showSearch={{ filter }}
          placeholder={'请选择所属类目'}
          onChange={() => form.setFieldValue('deviceId', undefined)}
        />
      </Form.Item>

      <Form.Item label="目标设备" name="deviceId">
        <Select
          options={targetDeviceOptions?.list?.map(item => ({
            value: item.id,
            label: item.name,
          }))}
          placeholder="请选择"
          allowClear
          showSearch
          optionFilterProp="label"
        />
      </Form.Item>
      <Form.Item label="下发状态" name="status">
        <Select
          options={[
            { id: '', label: '全部' },
            { id: '1', label: '成功' },
            { id: '0', label: '失败' },
          ].map(item => ({
            value: item.id,
            label: item.label,
          }))}
          placeholder="请选择"
          allowClear
          showSearch
          optionFilterProp="label"
        />
      </Form.Item>
    </CustomFilter>
  );
  return (
    <Wrapper filters={filters} routes={[...(routesContext?.routes ?? []), ...routes]} className={styles.wrapper}>
      <div>
        <Table
          rowKey={'id'}
          sticky
          scroll={{ x: 1300 }}
          loading={loading}
          dataSource={pageList}
          columns={buildColumns}
        />

        <Paging pagingInfo={pagingInfo} />
      </div>
      <Space className="sticky-footr" style={{ paddingTop: 30 }}>
        <Button onClick={() => navigate('/microgrid/conf/overview', { replace: true })}>返回</Button>
      </Space>
    </Wrapper>
  );
};

export default DispathReLog;
