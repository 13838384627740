import { FC, useContext, useEffect, useRef, useState } from 'react';
import { Form, Select, SubContent, Tag } from '@maxtropy/components';
import { ActionTypeEnum, BsaStrategyCalendarContext } from '../const';
import { useParams } from 'react-router-dom';
import {
  apiV2BsaStrategyTemplateGetByBsaPost,
  apiV2BsaStrategyTemplateGetPost,
  V2BsaStrategyTemplateGetByBsaPostResponse,
  V2BsaStrategyTemplateGetPostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { CloseCircleOutlined } from '@ant-design/icons';
import StrategyTemplateEdit, { StrategyTemplateEditRefProps } from '../../components/StrategyTemplateEdit';
import { isNil } from 'lodash-es';

type TemplateItem = Exclude<V2BsaStrategyTemplateGetByBsaPostResponse['list'], undefined>[number];

const ActionView: FC = () => {
  const context = useContext(BsaStrategyCalendarContext);
  const { actionType, selectedDateList, setSelectedDateList, setGetActionFormData } = context;
  const { id } = useParams();
  const [templateList, setTemplateList] = useState<TemplateItem[]>([]);
  const [strategyTemplateData, setStrategyTemplateData] = useState<V2BsaStrategyTemplateGetPostResponse>();
  const [form] = Form.useForm();
  const strategyTemplateRef = useRef<StrategyTemplateEditRefProps>(null);

  const strategyId = Form.useWatch('strategyId', form);

  useEffect(() => {
    setGetActionFormData?.(() => {
      return async () => {
        const formData = await form.validateFields();
        const strategyData = await strategyTemplateRef.current?.getFormData();
        if (isNil(strategyData)) return;
        return {
          strategyId: formData.strategyId,
          strategyData,
        };
      };
    });
  }, [form]);

  useEffect(() => {
    if (!id || actionType !== ActionTypeEnum.PUT) return;
    apiV2BsaStrategyTemplateGetByBsaPost({
      id: +id,
    }).then(res => {
      setTemplateList(res.list ?? []);
    });
  }, [id, actionType]);

  useEffect(() => {
    if (!strategyId) return;
    apiV2BsaStrategyTemplateGetPost({
      id: strategyId,
    }).then(res => {
      setStrategyTemplateData(res);
    });
  }, [strategyId]);

  let formattedSelectedDate: { month: string; dayList: string[] }[] = [];
  selectedDateList?.forEach(date => {
    const month = dayjs(date).format('YYYY年MM月');
    const found = formattedSelectedDate.find(i => i.month === month);
    if (found) {
      found.dayList.push(dayjs(date).format('YYYY-MM-DD'));
    } else {
      formattedSelectedDate.push({ month, dayList: [dayjs(date).format('YYYY-MM-DD')] });
    }
  });
  formattedSelectedDate.sort((a, b) => (dayjs(a.month).isAfter(dayjs(b.month)) ? 1 : -1));
  formattedSelectedDate.forEach(item => {
    item.dayList.sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1));
  });

  const onDeleteDate = (date: string) => {
    const newSelectedDateList = selectedDateList?.filter(v => !v.isSame(date, 'day'))!;
    setSelectedDateList?.(newSelectedDateList);
  };

  return (
    <div className={styles.actionView}>
      <SubContent title="投放策略" className={styles.flexContent}>
        <div className={styles.overflowContent}>
          <Form form={form}>
            {actionType === ActionTypeEnum.PUT && (
              <Form.Item label="策略模板" name="strategyId" rules={[{ required: true }]}>
                <Select
                  style={{ flex: 1 }}
                  placeholder="请选择"
                  options={templateList.map(item => {
                    return {
                      label: item.templateName,
                      value: item.id,
                    };
                  })}
                ></Select>
              </Form.Item>
            )}
          </Form>

          {strategyId && (
            <StrategyTemplateEdit
              id={strategyId}
              bsaId={id}
              showTemplateName={false}
              size="small"
              data={strategyTemplateData}
              ref={strategyTemplateRef}
            ></StrategyTemplateEdit>
          )}

          <Form form={form}>
            <Form.Item
              label="选中日期"
              style={{ marginBottom: 7 }}
              required
              name="selectedDate"
              rules={[
                {
                  validator: (rule, value) => {
                    if (selectedDateList?.length === 0) {
                      return Promise.reject('请选择日期');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            ></Form.Item>
            <Form.Item noStyle>
              {formattedSelectedDate.map(item => (
                <div key={item.month} className={styles.monthRow}>
                  <div className={styles.monthLabel}>{item.month}：</div>
                  <div className={styles.dateListWrap}>
                    {item.dayList.map(day => (
                      <Tag key={day} border="solid" className={styles.dateTag}>
                        <span>{day}</span>
                        <CloseCircleOutlined
                          className={styles.closeIcon}
                          onClick={() => {
                            onDeleteDate(day);
                          }}
                        />
                      </Tag>
                    ))}
                  </div>
                </div>
              ))}
            </Form.Item>
          </Form>
        </div>
      </SubContent>
    </div>
  );
};

export default ActionView;
