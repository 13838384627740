import { Graph } from '@antv/x6';

// 初始化画图
export function initMockScTopoGraph(dom: HTMLElement): Graph {
  return new Graph({
    container: dom,
    panning: true,
    mousewheel: true,
    grid: {
      size: 4,
      visible: true,
      type: 'dot',
      args: {
        color: '#ffffff35', // 网点颜色
        thickness: 1, // 网点大小
      },
    },
    interacting: {
      nodeMovable: false,
    },
  });
}
