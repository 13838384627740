import { DataPropertyType } from '@/pages/InstructionConfig/type';
import { Button, Form, InputNumber, Modal } from '@maxtropy/components';
import {
  apiV2DevicePropertyEdgePointPost,
  apiV2InstructionConfigCommandPost,
  V2GreenMicrowebShowcaseInstructionConfigPagePostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { Row, Space } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { Key, useCallback, useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
export type RowItem = Exclude<V2GreenMicrowebShowcaseInstructionConfigPagePostResponse['list'], undefined>[number];
interface Iprops {
  configId: number;
  callback?: () => void;
}
export const useShowSendConfirmModal = ({ configId, callback }: Iprops) => {
  const targetValue = useRef<any>();
  const [form] = Form.useForm();
  const [sendLoading, setSendLoading] = useState(false);
  const remoteSend = useCallback((row: RowItem, value?: number, configId?: number) => {
    setSendLoading(true);
    apiV2InstructionConfigCommandPost({
      id: row.id,
      value,
      instructionDistributeChannel: 0,
      microgridConfId: configId,
    })
      .then(() => callback?.())
      .finally(() => setSendLoading(false));
  }, []);
  const _func = useCallback(
    async (row: RowItem) => {
      // 遥信
      if (row.dataPropertyType === DataPropertyType.ENUM) {
        Modal.confirm({
          title: row.buttonName,
          getContainer: document.getElementById('full_screen_box') ?? document.body,
          content: (
            <div className={styles.confirm_box}>
              <div className={styles.confirm_title}>
                <div className={styles.send_text_box}>
                  <span>你确定要下发</span>
                  <span className={styles.tip_red}>
                    {row.instructionName}-{row.deviceName}
                  </span>

                  <span>吗?</span>
                </div>
              </div>
              <div className={styles.confirm_content}>
                <Space>
                  <span>目标值:</span>
                  <span className={styles.target_value}>{row.dataPropertyEnumName ?? '--'}</span>
                </Space>
              </div>
            </div>
          ),

          onOk() {
            let value = row.targetValue;
            remoteSend(row, value, configId);
          },
        });
        return;
      }
      // 遥测
      if (row.dataPropertyType === DataPropertyType.YC) {
        // 查询目标数据属性列表

        const res = await apiV2DevicePropertyEdgePointPost({ id: row.deviceId });

        const data = res.list?.find(item => item.dataPropertyId === row.dataPropertyId);
        const { k, b } = data ?? {};
        // 是否可编辑
        if (!row.canModify) {
          Modal.confirm({
            title: row.buttonName,
            getContainer: document.getElementById('full_screen_box') ?? document.body,
            content: (
              <div className={styles.confirm_box}>
                <div className={styles.confirm_title}>
                  <div className={styles.send_text_box}>
                    <span>你确定要下发</span>
                    <span className={styles.tip_red}>
                      {row.instructionName}-{row.deviceName}
                    </span>

                    <span>吗?</span>
                  </div>
                </div>
                <div className={styles.confirm_content}>
                  <Space>
                    <span>目标数据属性:</span>
                    <span className={styles.target_value}>{row.dataPropertyName}</span>
                  </Space>
                  <div className={styles.divider}></div>
                  <Space>
                    <span>目标值:</span>
                    <span className={styles.target_value}>{row.targetValue}</span>
                  </Space>
                  <div className={styles.divider}></div>
                  <Space>
                    <span>公式:</span>
                    <span className={styles.target_value}>{`y=${k}x+${b}`}</span>
                    <span className={styles.target_value}>,x为下发值，y为目标值</span>
                  </Space>
                </div>
              </div>
            ),
            onOk() {
              let value = row.targetValue;
              remoteSend(row, value, configId);
            },
          });
          return;
        }

        Modal.confirm({
          title: row.buttonName,
          getContainer: document.getElementById('full_screen_box') ?? document.body,

          content: (
            <Form
              initialValues={{
                target: row.targetValue,
              }}
              preserve={false}
              form={form}
              labelCol={{ style: { width: 62 } }}
            >
              <div className={styles.confirm_box}>
                <div className={styles.confirm_title}>
                  <div className={styles.send_text_box}>
                    <span>你确定要下发</span>
                    <span className={styles.tip_red}>
                      {row.instructionName}-{row.deviceName}
                    </span>

                    <span>吗?</span>
                  </div>
                </div>
                <div className={styles.confirm_content}>
                  <Space>
                    <span>目标数据属性:</span>
                    <span className={styles.target_value}>{row.dataPropertyName}</span>
                  </Space>
                  <div className={styles.divider}></div>
                  <Space>
                    <FormItem
                      style={{ marginBottom: 0 }}
                      label={<span style={{ color: 'rgba(255,255,255,0.65)' }}>目标值</span>}
                      name="target"
                      rules={[{ required: true }]}
                    >
                      <InputNumber defaultValue={row.targetValue} style={{ width: 240 }} />
                    </FormItem>
                  </Space>
                  <div className={styles.divider}></div>
                  <Space>
                    <span>公式:</span>
                    <span className={styles.target_value}>{`y=${k}x+${b}`}</span>
                    <span className={styles.target_value}>,x为下发值，y为目标值</span>
                  </Space>
                </div>
              </div>
            </Form>
          ),
          onOk: async e => {
            let validRes = await form
              .validateFields()
              .then(res => res.target.toString())
              .catch(_ => null);

            if (!validRes) {
              return Promise.reject();
            }
            remoteSend(row, validRes, configId);

            return Promise.resolve();
          },
        });
      }
    },
    [configId]
  );
  useEffect(() => {
    return () => {
      Modal.destroyAll();
    };
  }, []);
  return { loading: sendLoading, func: _func };
};
