import { Graph, Node } from '@antv/x6';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { TimeDimensionEnm, TimeDimensionEnmDisplay, TimeDimensionEnmDisplayParams, TreeNode } from '../../type';
import styles from './index.module.scss';
import { Select, Tag } from '@maxtropy/components';
import CustomTooltip from '../CustomTooltip';
import energyStorage from '../../imgs/energyStorage.gif';
import { DownOutlined, InfoCircleOutlined, UpOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { calcMaxLength, handleCommonExpand, judgeDrageOrClick } from '../../utils';
import { isNil } from 'lodash-es';
import {
  dataPropertyConfigsItemProps,
  dataPropertyConfigsListProps,
  ITEM_HEIGHT,
  propertyItemProps,
  treeItemProps,
} from '../..';
import { keepTwoNull } from '@/pages/MicrogridConfOverview/utils';
import { V2GreenMicrowebShowcaseDataPropertyDataPostResponse } from '@maxtropy/intelligent-dispath-apis-v2';
import RenderCarousel from '../RenderCarousel';

export interface BsaProps {
  node: Node;
  graphRef?: Graph;
  allNodeDataRef?: TreeNode[];
  configId?: number;
}

const Bsa: FC<BsaProps> = props => {
  const { node, graphRef, allNodeDataRef, configId } = props;
  const refBox = useRef<HTMLDivElement>();
  const [propertyList, setPropertyList] = useState<
    (dataPropertyConfigsItemProps & { value?: number; old?: boolean })[]
  >([]);
  const [deviceSelectValue, setDeviceSelectValue] = useState<number>();

  const data = useMemo(() => {
    return node.getData();
  }, [node.getData()]);

  // 获取数据点数据信息
  const dataPropertyDataList: V2GreenMicrowebShowcaseDataPropertyDataPostResponse['list'] = useMemo(() => {
    return data?.value?.deviceInfos;
  }, [data]);

  // 获取数据点信息
  const dataPropertyConfigsList = useMemo(() => {
    return data?.value?.dataPropertyConfigs ?? [];
  }, [data]);

  // 默认选择第一个设备
  useEffect(() => {
    if (dataPropertyConfigsList && dataPropertyConfigsList.length > 0) {
      setDeviceSelectValue(dataPropertyConfigsList[0]?.deviceId);
    }
  }, [dataPropertyConfigsList]);

  // 合并两组数据
  useEffect(() => {
    if (!isNil(dataPropertyDataList) && !isNil(deviceSelectValue) && !isNil(dataPropertyConfigsList)) {
      const dataList = dataPropertyDataList.find(
        (item: propertyItemProps) => item.deviceId === deviceSelectValue
      )?.list;
      const dataConfigList = dataPropertyConfigsList.find(
        (item: dataPropertyConfigsListProps) => item.deviceId === deviceSelectValue
      )?.list;
      const mergeArr = (dataConfigList ?? []).map((el: any) => ({
        ...el,
        value: dataList?.find((item: any) => item.dataPropertyId == el.dataPropertyId)?.value,
        old: dataList?.find((item: any) => item.dataPropertyId == el.dataPropertyId)?.old,
      }));
      setPropertyList(mergeArr);
    }
  }, [deviceSelectValue, dataPropertyDataList, dataPropertyConfigsList]);

  // 数据过于陈旧展示
  const oldDataList = useMemo(() => {
    return (propertyList ?? [])
      .filter(item => item?.old)
      .map(i => i?.dataPropertyName)
      .join();
  }, [propertyList]);

  // 区分拖动与点击事件
  useEffect(() => {
    if (refBox.current) {
      judgeDrageOrClick(refBox.current, configId!, data?.value?.id, data?.element);
    }
  }, []);

  const onExpand = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    node.updateData({
      isExpand: !data.isExpand,
    });
    const height = data.isExpand ? 145 + ITEM_HEIGHT * calcMaxLength(propertyList.length) : 145;
    handleCommonExpand(
      data?.value?.id,
      data?.element,
      node,
      height,
      data.isExpand,
      propertyList.length,
      graphRef,
      allNodeDataRef
    );
  };

  return (
    <div className={styles.customBsaReactNode}>
      <div className={styles.vline}></div>
      <div className={styles.content}>
        <div
          className={styles.cardSty}
          ref={d => {
            if (d) {
              refBox.current = d;
            }
          }}
        >
          {data.value.isPart && (
            <div className={styles.tagSty}>
              <Tag color="green">部分</Tag>
            </div>
          )}
          <img style={{ width: 40, height: 40, marginTop: 6 }} src={energyStorage} alt="" />
          <CustomTooltip title={data.value.name}>
            <div className={styles.itemControlobjContentSty}>{data.value.name}</div>
          </CustomTooltip>
          {oldDataList && oldDataList.length > 0 && (
            <CustomTooltip title={`数据过于陈旧：${oldDataList}`}>
              <InfoCircleOutlined className={styles.infoIcon} />
            </CustomTooltip>
          )}
          <div className={styles.FcsPcsInfo}>
            <div className={styles.pcsInfoSty}>PCS{data.value.pcsRate}</div>
          </div>
        </div>
        <div className={styles.expand_content}>
          {data.isExpand && propertyList.length > 0 ? (
            <>
              <div className={styles.dividerSty} />
              <Select
                className={styles.select_sty}
                popupClassName={styles.ele_drapDownSty}
                placeholder="请选择"
                notFoundContent={<div className={styles.ele_nodata_sty}>暂无数据</div>}
                value={deviceSelectValue}
                getPopupContainer={() => document.getElementById('full_screen_box') ?? document.body}
                onChange={deviceId => {
                  setDeviceSelectValue(deviceId);
                  const dataPropertyList = (
                    (dataPropertyConfigsList as treeItemProps['dataPropertyConfigs']) ?? []
                  ).find(item => item.deviceId === deviceId)?.list;
                  setPropertyList(dataPropertyList ?? []);
                }}
                options={((dataPropertyConfigsList as treeItemProps['dataPropertyConfigs']) ?? []).map(item => ({
                  label: item.deviceName,
                  value: item.deviceId,
                }))}
                showSearch
                optionFilterProp="label"
              />
              <div className={styles.device_prop_wrapper}>
                <RenderCarousel propertyLength={propertyList.length ?? 0}>
                  {(propertyList ?? []).map(item => (
                    <div
                      key={item.dataPropertyId}
                      className={styles.item_sty}
                      onClick={() => {
                        window.open(
                          `${
                            window.IOTPLATFORMORIGIN
                          }/data/history/device?deviceId=${deviceSelectValue}&dataPropertyId=${
                            item?.dataPropertyId
                          }&dateMode=${
                            !isNil(item?.timeDimensionEnum) &&
                            TimeDimensionEnmDisplayParams[item.timeDimensionEnum as TimeDimensionEnm]
                          }`,
                          '_blank'
                        );
                      }}
                    >
                      {(item.timeDimensionEnum as TimeDimensionEnm) !== TimeDimensionEnm.REAL_TIME_VALUE && (
                        <CustomTooltip
                          title={
                            !isNil(item.timeDimensionEnum)
                              ? TimeDimensionEnmDisplay[item.timeDimensionEnum as TimeDimensionEnm] + '数据'
                              : '--'
                          }
                        >
                          <div className={styles.corner}></div>
                        </CustomTooltip>
                      )}
                      <div className={styles.label_sty}>
                        <CustomTooltip title={item?.dataPropertyName ?? '--'}>
                          {item?.dataPropertyName ?? '--'}
                        </CustomTooltip>
                      </div>
                      <div
                        className={
                          item?.timeDimensionEnum !== TimeDimensionEnm.REAL_TIME_VALUE
                            ? styles.value_sty
                            : styles.real_value_sty
                        }
                      >
                        <CustomTooltip title={keepTwoNull(item?.value) + (item?.generalName ?? '')}>
                          {keepTwoNull(item?.value) + (item?.generalName ?? '')}
                        </CustomTooltip>
                      </div>
                    </div>
                  ))}
                </RenderCarousel>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        {propertyList.length > 0 && (
          <div className={styles.expand_box} onClick={e => onExpand(e)}>
            {data.isExpand ? (
              <UpOutlined className={classNames(styles.nodeExpandIcon)} />
            ) : (
              <DownOutlined className={styles.nodeExpandIcon} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Bsa;
