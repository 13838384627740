import { Node } from '@antv/x6';
import React, { FC, useMemo } from 'react';
import styles from './index.module.scss';
import transformer from '../../imgs/icon_transformer.gif';

export interface TransformerProps {
  node: Node;
}

const Transformer: FC<TransformerProps> = props => {
  const { node } = props;

  const data = useMemo(() => {
    return node.getData();
  }, [node.getData()]);

  return (
    <div className={styles.customTransFormerReactNode}>
      <div className={styles.vlineTop}></div>
      <div className={styles.cardSty}>
        <img style={{ width: 40, height: 40 }} src={transformer} alt="" />

        <div className={styles.itemControlobjContentSty}>{data?.name}</div>

        <div className={styles.highVoltage}>{data?.highVoltageLevel}</div>
        <div className={styles.lowVoltage}>{data?.lowerVoltageLevel}</div>
      </div>
      <div className={styles.vlineBottom}></div>
    </div>
  );
};

export default Transformer;
