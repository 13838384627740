import { NodeType } from '@/pages/SchedulingTopology/type';

export interface TreeNode {
  id: number | string;
  element: NodeType;
  parentId?: number;
  children: TreeNode[];
}

export enum TimeDimensionEnm {
  REAL_TIME_VALUE = 'REAL_TIME_VALUE',
  DAY = 'DAY',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export const TimeDimensionEnmDisplay = {
  [TimeDimensionEnm.REAL_TIME_VALUE]: '实时值',
  [TimeDimensionEnm.DAY]: '日',
  [TimeDimensionEnm.MONTH]: '月',
  [TimeDimensionEnm.YEAR]: '年',
};
export const TimeDimensionEnmDisplayParams = {
  [TimeDimensionEnm.REAL_TIME_VALUE]: 'original',
  [TimeDimensionEnm.DAY]: 'date',
  [TimeDimensionEnm.MONTH]: 'month',
  [TimeDimensionEnm.YEAR]: 'year',
};
export enum SwitchStatus {
  CLOSE = 0, // 合闸
  OPEN = 1, // 分闸
}
export const RemoteControlEnum = 10403; // 远程控制
