import { ComponentType } from 'react';
import LinkPoint from './Element/LinkPoint';
import AlarmConf from './Element/AlarmConf';
import CustomPoint from './Element/CustomPoint';
import HotSpot from './Element/HotSpot';
import TargetDevice from './Element/TargetDevice';

export enum TabType {
  LINKPOINT, // 元素关联设备数据点
  CUSTOMPOINT, // 元素自定义数据点
  HOTSPOT, // 元素热区
  TARGET, // 标设备调度
  ALARM, // 报警列表
}

export const tabTypeDisplay = {
  [TabType.LINKPOINT]: '元素关联设备数据点',
  [TabType.CUSTOMPOINT]: '元素自定义数据点',
  [TabType.HOTSPOT]: '元素热区',
  [TabType.TARGET]: '目标设备调度',
  [TabType.ALARM]: '报警列表',
};

export const tabTypeMap: Record<TabType, ComponentType<any>> = {
  [TabType.LINKPOINT]: LinkPoint,
  [TabType.CUSTOMPOINT]: CustomPoint,
  [TabType.HOTSPOT]: HotSpot,
  [TabType.TARGET]: TargetDevice,
  [TabType.ALARM]: AlarmConf,
};
export interface RefProps {
  saveBtn?: (success?: () => void) => void;
  isEditStatus?: boolean;
}
export enum ConfigStatus {
  DISABLE,
  ENABLE,
}

export enum ElementType {
  BSA = 'BSA',
  PVA = 'PVA',
}
export const elementTypeDisplay = {
  [ElementType.BSA]: '储能站',
  [ElementType.PVA]: '光伏站',
};
