import { PermissionsType } from '@/common/permissionsConst';
import SchedulingTopoDemo from '.';
const routes = [
  {
    path: '/intelligent/SchedulingTopo/demoView',
    element: <SchedulingTopoDemo />,
    permission: PermissionsType.P_PVBESSDC,
  },
];

export default routes;
