import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import { Button, FormTitle, Select } from '@maxtropy/components';
import { Space } from 'antd';
import { FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShareDataContext } from '../..';

export interface IHeaderOps {
  fullBtn: () => void;
}

const HeaderOps: FC<IHeaderOps> = props => {
  const { fullBtn } = props;
  const navigate = useNavigate();
  const { configId, setConfigId, isFullScreen, setIsExpand, isExpand, confList } = useContext(ShareDataContext);
  const hasConfigBtn = useHasPermission(PermissionsType.B_MICRO_CONFIG_BTN);
  const hasFullBtn = useHasPermission(PermissionsType.B_FULL_SCREEN);
  const hasExpandBtn = useHasPermission(PermissionsType.B_EXPAND_COLLAPSE);

  useEffect(() => {
    if (confList && confList.length > 0) {
      setConfigId?.(confList[0].id);
    }
  }, [confList]);

  return (
    <FormTitle
      style={{ marginBottom: 16 }}
      extraContent={
        <Space size={8}>
          {hasExpandBtn && (
            <Button type="primary" onClick={() => setIsExpand?.(!isExpand)}>
              {isExpand ? '收起' : '展开'}
            </Button>
          )}
          {hasConfigBtn && (
            <Button type="primary" onClick={() => navigate('/microgrid/conf/page')}>
              配置
            </Button>
          )}
          {hasFullBtn && (
            <Button type="primary" onClick={fullBtn}>
              {isFullScreen ? '退出' : '全屏'}
            </Button>
          )}
        </Space>
      }
      title={
        <Space size={16}>
          <span>微电网组态</span>
          <Select
            style={{ width: 240 }}
            options={confList?.map(item => ({
              label: item.name,
              value: item.id,
            }))}
            onChange={v => setConfigId?.(v)}
            value={configId}
            getPopupContainer={() => document.getElementById('full_screen_box') ?? document.body}
            showSearch
            optionFilterProp="label"
          />
        </Space>
      }
    />
  );
};

export default HeaderOps;
