export interface TopologyList {
  id?: number;
  /**
   * 调度拓扑名称
   */
  name?: string;
  /**
   * 关联UET
   */
  uetVos?: {
    id?: number;
    /**
     * 名称
     */
    name?: string;
    /**
     * 编号
     */
    serialNumber?: string;
    /**
     * 所属组织
     */
    mcid?: string;
    /**
     * 组织简称
     */
    customerName?: string;
    /**
     * 组织全称
     */
    customerFullName?: string;
    /**
     * 组织编号
     */
    customerCode?: string;
    /**
     * 所属租户
     */
    tenantMcid?: string;
    /**
     * 状态(0-停用,1-启用,2-作废,3-草稿)
     */
    status?: number;
    /**
     * 创建者muid
     */
    createMuid?: string;
    createCustomerUserName?: string;
    /**
     * 修改者muid
     */
    updateMuid?: string;
    updateCustomerUserName?: string;
    createTime?: string;
    updateTime?: string;
    /**
     * 是否填写完基础设定
     */
    baseComplete?: boolean;
    /**
     * 包含的业务单元类型
     */
    businessUnits?: {
      id?: number;
      uetId?: number;
      /**
       * 业务单元类型id
       */
      businessUnitType?: number;
      /**
       * 是否完成填写
       */
      complete?: boolean;
      createTime?: string;
      updateTime?: string;
    }[];
    ous?: {
      id?: number;
      /**
       * 名称
       */
      name?: string;
      /**
       * 序列号
       */
      serialNumber?: string;
      /**
       * 类型id
       */
      ouTypeId?: number;
      /**
       * 0,禁用;1,启用;2,作废(原本作废用的是0,修改为2,并且OU状态值目前只有启用和作废)
       */
      status?: number;
      /**
       * 租户mcid
       */
      tenantMcid?: string;
      /**
       * 组织mcid
       */
      mcid?: string;
      /**
       * 负责人姓名
       */
      directorName?: string;
      /**
       * 负责人电话
       */
      directorPhone?: string;
      /**
       * 备注
       */
      remark?: string;
      /**
       * 创建时间
       */
      createTime?: string;
      /**
       * 更新时间
       */
      updateTime?: string;
      /**
       * 操作人
       */
      updateByUsername?: string;
      /**
       * 操作人id
       */
      updateByUserId?: string;
      /**
       * 操作端
       */
      updateSource?: number;
      /**
       * 操作人(实施)
       */
      manageUpdateByUsername?: string;
      /**
       * 操作人id(实施)
       */
      manageUpdateByUserId?: string;
      /**
       * 首页地址
       */
      homePage?: string;
      /**
       * 更新时间(实施)
       */
      manageUpdateTime?: string;
    }[];
  }[];
  /**
   * 关联上级拓扑
   */
  linkVo?: {
    id?: number;
    /**
     * 调度拓扑名称
     */
    name?: string;
    /**
     * 关联UET
     */
    uetVos?: {
      id?: number;
      /**
       * 名称
       */
      name?: string;
      /**
       * 编号
       */
      serialNumber?: string;
      /**
       * 所属组织
       */
      mcid?: string;
      /**
       * 组织简称
       */
      customerName?: string;
      /**
       * 组织全称
       */
      customerFullName?: string;
      /**
       * 组织编号
       */
      customerCode?: string;
      /**
       * 所属租户
       */
      tenantMcid?: string;
      /**
       * 状态(0-停用,1-启用,2-作废,3-草稿)
       */
      status?: number;
      /**
       * 创建者muid
       */
      createMuid?: string;
      createCustomerUserName?: string;
      /**
       * 修改者muid
       */
      updateMuid?: string;
      updateCustomerUserName?: string;
      createTime?: string;
      updateTime?: string;
      /**
       * 是否填写完基础设定
       */
      baseComplete?: boolean;
      /**
       * 包含的业务单元类型
       */
      businessUnits?: {
        id?: number;
        uetId?: number;
        /**
         * 业务单元类型id
         */
        businessUnitType?: number;
        /**
         * 是否完成填写
         */
        complete?: boolean;
        createTime?: string;
        updateTime?: string;
      }[];
      ous?: {
        id?: number;
        /**
         * 名称
         */
        name?: string;
        /**
         * 序列号
         */
        serialNumber?: string;
        /**
         * 类型id
         */
        ouTypeId?: number;
        /**
         * 0,禁用;1,启用;2,作废(原本作废用的是0,修改为2,并且OU状态值目前只有启用和作废)
         */
        status?: number;
        /**
         * 租户mcid
         */
        tenantMcid?: string;
        /**
         * 组织mcid
         */
        mcid?: string;
        /**
         * 负责人姓名
         */
        directorName?: string;
        /**
         * 负责人电话
         */
        directorPhone?: string;
        /**
         * 备注
         */
        remark?: string;
        /**
         * 创建时间
         */
        createTime?: string;
        /**
         * 更新时间
         */
        updateTime?: string;
        /**
         * 操作人
         */
        updateByUsername?: string;
        /**
         * 操作人id
         */
        updateByUserId?: string;
        /**
         * 操作端
         */
        updateSource?: number;
        /**
         * 操作人(实施)
         */
        manageUpdateByUsername?: string;
        /**
         * 操作人id(实施)
         */
        manageUpdateByUserId?: string;
        /**
         * 首页地址
         */
        homePage?: string;
        /**
         * 更新时间(实施)
         */
        manageUpdateTime?: string;
      }[];
    }[];
    /**
     * 关联上级拓扑
     */
    linkVo?: {};
  };
}

export enum TopoState {
  UNPULISH = 0, // 未发布
  DRAFT_UNPULISH = 1, // 发布后有更改
  ALLPULISH = 2, // 全部发布
}

export const TopoStateDisplay = {
  [TopoState.UNPULISH]: '未发布',
  [TopoState.DRAFT_UNPULISH]: '已发布',
  [TopoState.ALLPULISH]: '已发布',
};
