import React, { Key, useEffect, useState } from 'react';
import styles from './index.module.scss';

import { Button, Form, Modal, Select } from '@maxtropy/components';
import Title from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Title';
import Footer from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Footer';
// import ShowInput from '@/shared/components/ShowInput';
import { useRequest } from 'ahooks';
import {
  apiV2DispatchUetStructChargingStationDeletePost,
  apiV2DispatchUetStructChargingStationDetailPost,
  apiV2DispatchUetStructChargingStationEditPost,
  apiV2DispatchUetStructChargingStationSavePost,
  apiV2DispatchUetStructChargingStationUetStructRelatedListPost,
  apiV2DispatchUetStructPublishChargingStationDetailPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { isNil } from 'lodash-es';
import { NodeType as NodeTypeClass } from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import ShowInput from '@/components/ShowInput';
import { NodeType, TreeNode, allInfoProps } from '@/pages/SchedulingTopology/type';
import { findTopGridAccessPointData } from '../EnergyStorageStation/utils';

const { Item } = Form;

interface Props {
  id?: number;
  open?: boolean;
  dispatchGroupId?: number;
  isPreview?: boolean;
  isShowModal?: (open: boolean) => void;
  getFinishedValues?: (values: any) => void;
  shouldDeleteElement?: (state: boolean) => void;
  version?: number;
  allInfo?: allInfoProps;
  allNodeData?: TreeNode[];
}
const ChargingStationModal: React.FC<Props> = ({
  id,
  dispatchGroupId,
  open,
  isPreview,
  isShowModal,
  getFinishedValues,
  shouldDeleteElement,
  version,
  allInfo,
  allNodeData,
}) => {
  const [form] = Form.useForm();
  const chargingStationId = Form.useWatch('chargingStationId', form);

  const urlSearch = new URLSearchParams(window.location.search);
  const topologyId = urlSearch.get('topologyId'); // 结构id
  const [gridAccessPointId, setGridAccessPointId] = useState<Key>(); // 电网接入点id
  const [iconVisible, setIconVisible] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();

  const onShowIcon = () => {
    form.validateFields().then(() => {
      setIconVisible(true);
    });
  };

  const onCancel = () => {
    form.resetFields();
    setTitle(undefined);
    isShowModal?.(false);
  };

  const { data: stationOptions } = useRequest(
    async () => {
      if (isNil(topologyId)) return;
      const res = await apiV2DispatchUetStructChargingStationUetStructRelatedListPost({
        uetStructId: +topologyId!,
        gridAccessPointId,
      });
      if (res.list) {
        return res.list;
      }
    },
    { ready: !!gridAccessPointId && !!topologyId, refreshDeps: [gridAccessPointId, topologyId] }
  );

  const { data: detail } = useRequest(
    async () => {
      if (isNil(id) || !open) return;
      setIsDetail(true);
      let detail;
      if (!isNil(version)) {
        detail = await apiV2DispatchUetStructPublishChargingStationDetailPost({ id, version });
      } else {
        detail = await apiV2DispatchUetStructChargingStationDetailPost({ id });
      }
      if (detail) {
        setIconVisible(true);
        form.setFieldsValue({
          ...detail,
        });
      }
      return detail;
    },
    { refreshDeps: [id, open, version] }
  );

  // 通过当前id与type查找电网接入点id
  useEffect(() => {
    if (detail) {
      if (allNodeData) {
        const gridAccessPointData = findTopGridAccessPointData(allNodeData, detail.id, NodeType.CHARGING_STATION);
        setGridAccessPointId(gridAccessPointData?.id);
      }
    } else {
      if (allInfo) {
        const gridAccessPointData = findTopGridAccessPointData(
          allInfo.allNodeData,
          allInfo.node.getData().id,
          allInfo.node.getData().element
        );
        setGridAccessPointId(gridAccessPointData?.id);
      }
    }
  }, [allInfo, detail, allNodeData]);

  useEffect(() => {
    if (chargingStationId) {
      const station = stationOptions?.find(item => item.id === chargingStationId);
      form.setFieldsValue({
        circuitName: station?.circuitName,
      });
    } else {
      form.setFieldsValue({
        circuitName: undefined,
      });
      setIconVisible(false);
    }
  }, [chargingStationId, form, stationOptions]);

  return (
    <>
      <Modal
        open={open}
        contentClassName="modal-form-content"
        title={
          <Title
            id={id}
            styles={styles}
            isDetail={isDetail}
            isPreview={isPreview}
            title={title ?? '充电站'}
            delMethod={apiV2DispatchUetStructChargingStationDeletePost}
            onEdit={() => setIsDetail(false)}
            onCancel={onCancel}
            shouldDeleteElement={state => shouldDeleteElement?.(state)}
            nodeType={NodeTypeClass.LOAD}
          />
        }
        footer={
          <Footer
            isDetail={isDetail}
            styles={styles}
            form={form}
            dispatchGroupId={dispatchGroupId}
            createMethod={apiV2DispatchUetStructChargingStationSavePost}
            updateMethod={apiV2DispatchUetStructChargingStationEditPost}
            getFinishedValues={val => getFinishedValues?.(val)}
            onCancel={onCancel}
          />
        }
        onCancel={onCancel}
        destroyOnClose
      >
        <div className={styles.modalWrapper}>
          <Form form={form} labelCol={{ span: 6 }} labelAlign="left">
            <Item name="chargingStationId" label="充电站" rules={[{ required: true }]}>
              <Select
                options={stationOptions?.map(item => ({
                  value: item.id,
                  label: item.name,
                }))}
                placeholder="请选择"
                allowClear
                disabled={isDetail}
              />
            </Item>

            <Item name="circuitName" label="回路">
              <ShowInput />
            </Item>

            <Item name="id" hidden />

            <div className={styles.preview}>
              <Button type="link" className={styles.previewBtn} onClick={onShowIcon}>
                查看图标
              </Button>

              <div className={styles.iconWrapper}>
                {iconVisible && (
                  <div className={styles.iconBox}>
                    <div className={styles.icon} />

                    <Item noStyle dependencies={['chargingStationId']}>
                      {({ getFieldValue }) => {
                        const id = getFieldValue('chargingStationId');
                        const name = stationOptions?.find(item => item.id === id)?.name;

                        return <div className={styles.iconText}>{name}</div>;
                      }}
                    </Item>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default ChargingStationModal;
