import { V2BsaStrategyTemplateGetPostResponse } from '@maxtropy/intelligent-dispath-apis-v2';
import {
  ActionType,
  ActionTypeTime,
  ActionTypeTypeDisplay,
  ChargingAndDischargingPower,
  PVPeriods,
  PeaksAndValleysType,
  PricedisplayType,
} from './type';

export const isFullDay = (times: ActionTypeTime[], displayType: PricedisplayType) =>
  !getItemTypes(times, displayType).some(t => t === undefined);

export const getItemTypes = (times: ActionTypeTime[], displayType: PricedisplayType) =>
  times.reduce(
    (acc, cur) => {
      acc.splice(
        cur.intervalStart,
        cur.intervalEnd - cur.intervalStart + 1,
        ...new Array(cur.intervalEnd - cur.intervalStart + 1).fill(cur.type)
      );
      return acc;
    },
    [...new Array(displayType === PricedisplayType.HALF ? 48 : 24)]
  );

export const getItemTime = (index: number, displayType: PricedisplayType) => {
  index = displayType === PricedisplayType.HALF ? index : index * 2;
  return zeroPad(Math.floor(index / 2), 2) + ':' + (index % 2 === 0 ? '00' : '30');
};

export const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');

export const getPeriodTypes = (times: ActionTypeTime[], displayType: PricedisplayType): ActionTypeTime[] =>
  getItemTypes(times, displayType).reduce((acc, cur, index) => {
    const last = acc[acc.length - 1];
    if (last && last.type === cur) {
      last.intervalEnd = index;
    } else {
      acc.push({
        type: cur,
        intervalStart: index,
        intervalEnd: index,
      });
    }
    return acc;
  }, [] as ActionTypeTime[]);

export const getPeriodTime = (time: ActionTypeTime, displayType: PricedisplayType) =>
  `${ActionTypeTypeDisplay[time.type]}: ${getItemTime(time.intervalStart, displayType)}~${getItemTime(
    time.intervalEnd + 1,
    displayType
  )}`;

export const ChargeList = [PeaksAndValleysType.Flat, PeaksAndValleysType.Valley];
export const DisChargeList = [PeaksAndValleysType.Peak, PeaksAndValleysType.Sharp];

export interface CreatePriceFormProps {
  timePeriodGranularity: PricedisplayType;
  price: ActionTypeTime[];
}

export function getTimeString(record: CreatePriceFormProps) {
  const price = record.price.map(i => {
    const end =
      record.timePeriodGranularity === PricedisplayType.HALF
        ? i.intervalEnd + 1 === 48
          ? 0
          : i.intervalEnd + 1
        : i.intervalEnd + 1 === 24
        ? 0
        : i.intervalEnd + 1;
    const res = {
      type: i.type,
      startTime: getItemTime(i.intervalStart, record.timePeriodGranularity),
      endTime: getItemTime(end, record.timePeriodGranularity),
    };
    return res;
  });
  return price;
}

export function getPriceTimeIndex(record: V2BsaStrategyTemplateGetPostResponse) {
  if (record.additionalFields?.periodTypes === PVPeriods.CustomSettings) {
    const data: ActionTypeTime[] = (record.additionalFields?.chargingAndDischargingPeriodDetail ?? []).map(i => {
      const coefficient = record.additionalFields?.timePeriodGranularity === PricedisplayType.HALF ? 2 : 1;
      const [startHoursStr, startMinuteStr] = (i.startTime as string).split(':');
      const startIndex =
        Number(startHoursStr) * coefficient +
        (record.additionalFields?.timePeriodGranularity === PricedisplayType.HALF
          ? startMinuteStr === '00'
            ? 0
            : 1
          : 0);
      const [endHoursStr, endMinuteStr] = (i.endTime as string).split(':');
      const endIndex =
        Number(endHoursStr) * coefficient +
        (record.additionalFields?.timePeriodGranularity === PricedisplayType.HALF
          ? endMinuteStr === '00'
            ? 0
            : 1
          : 0);
      return {
        type: i.type as ActionType,
        intervalStart: startIndex,
        intervalEnd: endIndex === 0 ? 24 * coefficient - 1 : endIndex - 1,
      };
    });
    return data;
  }
}

export const ChargeListFormat = [
  {
    peakType: PeaksAndValleysType.Flat,
    chargeType: ActionType.Charge,
  },
  {
    peakType: PeaksAndValleysType.Valley,
    chargeType: ActionType.Charge,
  },
  {
    peakType: PeaksAndValleysType.Peak,
    chargeType: ActionType.DisCharge,
  },
  { peakType: PeaksAndValleysType.Sharp, chargeType: ActionType.DisCharge },
];

export function formatEndtime(endTime?: string) {
  let finalEndTime = endTime;
  const formatEndTime = Number(endTime?.split(':')[0]);
  if (formatEndTime === 0) {
    finalEndTime = '24:00';
  }
  return finalEndTime;
}

export function formatSubmitTime(endTime?: string) {
  let finalEndTime = endTime;
  const formatEndTime = Number(endTime?.split(':')[0]);
  if (formatEndTime === 24) {
    finalEndTime = '00:00';
  }
  return finalEndTime;
}
