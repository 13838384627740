import { Node } from '@antv/x6';
import React, { FC, useMemo } from 'react';
import styles from './index.module.scss';
import loadOffice from '../../imgs/load-office.gif';
import loadIndustry from '../../imgs/Load-industry.gif';
import Compressor from '../../imgs/Compressor.gif';
import { BillingType } from '../../utils/demoData';

export interface LoadProps {
  node: Node;
}

const Load: FC<LoadProps> = props => {
  const { node } = props;

  const data = useMemo(() => {
    return node.getData();
  }, [node.getData()]);

  const img = useMemo(() => {
    if (data?.type === BillingType.OfficeLoad) {
      return loadOffice;
    } else if (data?.type === BillingType.ProductionUnit1 || data?.type === BillingType.ProductionUnit2) {
      return loadIndustry;
    } else {
      return Compressor;
    }
  }, [data?.type]);

  return (
    <div className={styles.customLoadReactNode}>
      <div className={styles.vline}></div>
      <div className={styles.cardSty} style={{ height: data?.type === BillingType.OfficeLoad ? 94 : 198 }}>
        <div className={styles.topLine}></div>
        <img style={{ width: 40, height: 40, marginTop: 6 }} src={img} alt="" />
        <div className={styles.itemControlobjContentSty}>{data?.name}</div>

        {data?.type !== BillingType.OfficeLoad && (
          <>
            <div className={styles.dividerSty} />
            <div className={styles.bottomContentSty}>
              <div className={styles.itemProp}>
                <div className={styles.itemPropName}>电压</div>
                <div className={styles.itemPropValue}>{data?.voltage}V</div>
              </div>
              {data?.type !== BillingType.OfficeLoad && (
                <div className={styles.itemProp}>
                  <div className={styles.itemPropName}>电流</div>
                  <div className={styles.itemPropValue}>
                    {data?.current
                      ? Math.abs(data?.current)
                      : (Math.abs(data?.power / data?.voltage) * 1000).toFixed(2)}
                    A
                  </div>
                </div>
              )}
              <div className={styles.itemProp}>
                <div className={styles.itemPropName}>功率</div>
                <div className={styles.itemPropValue}>
                  {data?.power ?? ((data?.voltage * data?.current) / 1000).toFixed(2)}kW
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Load;
