import { fetch, Key } from '@maxtropy/components';
import { Organization } from './customer-user';

export interface OrganizationResponse {
  data: Organization;
  parent?: number;
  children: OrganizationResponse[];
}

export interface DeviceTypeTree {
  aliasName: string[];
  tree: DeviceType[];
}

export interface DeviceType {
  id: number;
  key: string;
  name: string;
  children?: DeviceType[];
  deviceTypes?: DeviceType[];
}

export function getOrganizationWithCodeWithCurrent(): Promise<OrganizationResponse> {
  return fetch(`/api/device/organization-with-code-with-current`);
}

export const getDeviceTypeTree = () =>
  fetch<DeviceTypeTree>(
    `/api/v2/device/deviceType/tree`,
    {
      method: 'POST',
    },
    true
  );
