import React, { FC, useMemo } from 'react';
import { TreeNode } from '../../type';
import { FcsList, GateWayList } from '@/pages/SchedulingTopology/type';
import { Graph, Node } from '@antv/x6';
import styles from './index.module.scss';
import { VoltageLevel, VoltageLevelLabel } from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';

export interface GridSideProps {
  node: Node;
}

const GridSide: FC<GridSideProps> = props => {
  const { node } = props;

  const data = useMemo(() => {
    return node.getData();
  }, [node.getData()]);

  return (
    <div className={styles.customReactNode}>
      <div>
        <span className={styles.labelSty}>{data?.value?.summaryPointName ?? '电网侧'}</span>
        <span className={styles.valueSty}>
          {data?.value?.summaryPointName === '电网侧'
            ? VoltageLevelLabel[data.value?.voltageLevel as VoltageLevel] ?? ''
            : ''}
        </span>
      </div>
      <div className={styles.hline}></div>
      <div className={styles.vline}></div>
    </div>
  );
};

export default GridSide;
