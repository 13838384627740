import { Col, Divider, Row, Space } from 'antd';
import React, { useRef, useState } from 'react';

import styles from '../../index.module.scss';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Tooltip, useTenantPermissions, useUpdate } from '@maxtropy/components';
import { useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import {
  apiV2EnergyDispatchInfoPost,
  apiV2EnergyDispatchUpdatePost,
  apiV2UetListAllStatusPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { isEmpty, pick } from 'lodash-es';
import { PermissionsType } from '@/common/permissionsConst';
import { isNil } from '@antv/util';
import ShowInput from '@/components/ShowInput';
import { getOrganization } from '@/api/customer-user';
import { tiledArray } from '@/pages/EnergyDispatchManagement/List/utils';

const { Item } = Form;

interface Props {
  detail?: boolean;
  customerMcids: string[];
  setCustomerMcids: (val: string[]) => void;
}
const Top: React.FC<Props> = ({ customerMcids, detail, setCustomerMcids }) => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const permission = useTenantPermissions();

  const [open, setOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [state, forceUpdate] = useUpdate();

  const onSave = () => {
    if (isNil(id)) return;
    form
      .validateFields()
      .then(values => {
        apiV2EnergyDispatchUpdatePost({
          id: +id!,
          ...pick(values, ['uetId', 'name', 'customerMcidList']),
        }).then(() => {
          setEdit(false);
          forceUpdate();
        });
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const { data: info } = useRequest(
    async () => {
      if (!id) return;
      const res = await apiV2EnergyDispatchInfoPost({ id: +id });
      if (res) {
        const { uetList } = res;
        const uetId = uetList?.map(cur => cur.id);
        setCustomerMcids(uetList?.map(cur => cur.mcid) as string[]);

        form.setFieldsValue({
          ...res,
          uetId,
          customerMcidList: res.customerList?.map(item => item.mcid),
        });
        return res;
      }
    },
    {
      refreshDeps: [id, state],
    }
  );

  const { data: uetOptions } = useRequest(
    async () => {
      if (!info) return [];
      const res = await apiV2UetListAllStatusPost({ customerMcids });

      if (res) {
        return res.list?.map?.(item => ({
          label: item.name,
          value: item.id,
        }));
      }
    },
    {
      refreshDeps: [info, customerMcids],
    }
  );

  // 组织列表
  const { data: organization } = useRequest(async () => {
    const res = await getOrganization();
    return res ? tiledArray([res]) : [];
  });

  return (
    <>
      <Form form={form} layout="vertical" className={styles.top} wrapperCol={{ span: 22 }}>
        <div style={{ position: 'relative', padding: '15px 20px' }}>
          <p className={styles.title}>调度组基础信息</p>

          {open && (
            <>
              <Row style={{ marginTop: 15 }}>
                <Col span={6}>
                  <Item label="调度组编号" name="code" rules={[{ required: true, message: '请输入调度组编号' }]}>
                    <ShowInput />
                  </Item>
                </Col>

                <Col span={6}>
                  <Item label="调度组名称" name="name" rules={[{ required: true, message: '请输入调度组名称' }]}>
                    <Input disabled={!edit}></Input>
                  </Item>
                </Col>

                <Col span={6}>
                  <Item
                    label="关联组织"
                    name="customerMcidList"
                    rules={[{ required: true, message: '请选择关联组织' }]}
                  >
                    <Select placeholder="请选择" options={organization} disabled={!edit} mode="multiple" allowClear />
                  </Item>
                </Col>

                <Col span={6}>
                  <Item label="关联UET" name="uetId" rules={[{ required: true, message: '请选择关联UET' }]}>
                    <Select placeholder="请选择" options={uetOptions} disabled={!edit} mode="multiple" allowClear />
                  </Item>
                </Col>
              </Row>
            </>
          )}
          {!open && (
            <>
              <Space split={<Divider type="vertical" />} className={styles.topTags}>
                <Tooltip title={`调度组编号: ${info?.code ?? '--'}`}>
                  <span className={styles.hint}>调度组编号: {info?.code ?? '--'}</span>
                </Tooltip>

                <Tooltip title={`调度组名称: ${info?.name ?? '--'}`}>
                  <span className={styles.hint}>调度组名称: {info?.name ?? '--'}</span>
                </Tooltip>

                <Tooltip
                  title={`关联组织: ${
                    isEmpty(info?.customerList) ? '--' : info?.customerList?.map(list => list.name).join('、')
                  }`}
                >
                  <span className={styles.hint}>
                    关联组织：
                    {isEmpty(info?.customerList) ? '--' : info?.customerList?.map(list => list.name).join('、')}
                  </span>
                </Tooltip>

                <Tooltip
                  title={`关联UET: ${isEmpty(info?.uetList) ? '--' : info?.uetList?.map(list => list.name).join('、')}`}
                >
                  <span className={styles.hint}>
                    关联UET: {isEmpty(info?.uetList) ? '--' : info?.uetList?.map(list => list.name).join('、')}
                  </span>
                </Tooltip>
              </Space>
            </>
          )}
        </div>

        <div className={styles.btns}>
          <Space size={8}>
            {open && (
              <>
                {edit ? (
                  <>
                    <Button type="primary" onClick={() => onSave()} htmlType="submit">
                      保存
                    </Button>
                    <Button
                      onClick={() => {
                        if (info) {
                          const { uetList } = info;
                          const uetId = uetList?.map(cur => cur.id);
                          setCustomerMcids(uetList?.map(cur => cur.mcid) as string[]);
                          form.setFieldsValue({
                            ...info,
                            uetId,
                            customerMcidList: info.customerList?.map(item => item.mcid),
                          });
                          form.setFieldsValue({});
                        }
                        setEdit(false);
                      }}
                    >
                      取消
                    </Button>
                  </>
                ) : (
                  <>
                    {(permission ?? []).includes(PermissionsType.B_EDITUEDBASICSET) && !detail && (
                      <Button type="primary" onClick={() => setEdit(true)}>
                        编辑
                      </Button>
                    )}
                  </>
                )}
                <Button type="link" onClick={() => setOpen(false)}>
                  收起
                  <DownOutlined />
                </Button>
              </>
            )}
            {!open && (
              <Button type="link" onClick={() => setOpen(true)}>
                展开
                <RightOutlined />
              </Button>
            )}
          </Space>
        </div>
      </Form>
    </>
  );
};

export default Top;
