import { Graph, Node } from '@antv/x6';
import { register } from '@antv/x6-react-shape';
import AntiBackflowDevice from '../components/AntiBackflowDevice';
import BackupPower from '../components/BackupPower';
import Bsa from '../components/Bsa';
import BusBar from '../components/BusBar';
import ChargingStation from '../components/ChargingStation';
import FcsController from '../components/FcsController';
import GateWay from '../components/GateWay';
import GridAccessPoint from '../components/GridAccessPoint';
import GridSide from '../components/GridSide';
import Load from '../components/Load';
import Meters from '../components/Meters';
import PVa from '../components/PVa';
import Transformer from '../components/Transformer';
import UniversalSwitch from '../components/UniversalSwitch';
import { BusBarCircleNumber, FcsList, GateWayList, nodeElementLinkTree, NodeType, TreeNode } from '../type';
import OtherVertical from '../components/OtherVertical';
import OtherTransverse from '../components/OtherTransverse';

export default function (
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    circleIndex?: number,
    circleArr?: BusBarCircleNumber[]
  ) => void,
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void,
  nodeElementLink?: nodeElementLinkTree[]
) {
  registerGridSideNode(
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    nodeElementLink
  );
  registerGridAccessPointNode(
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink
  );
  registerBsaGridBillingPointNode(
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink
  );
  registerTransformerNode(
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink
  );

  registerBusBarNode(
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink
  );
  registerPVaNode(
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink
  );
  registerBsaNode(
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink
  );
  registerLoadNode(
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink
  );
  registerGateWayNode(allNodeDataRef, confirm, showModalDetail);
  registerChargingStationNode(
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink
  );
  registerBackupPowerNode(
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink
  );
  // 防逆流
  registerAntiBackflowDeviceNode(
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink
  );
  // 通用开关
  registerUniversalSwitchNode(
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink
  );
  // 电网计费点
  registerGridAccessBillingPointNode(
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink
  );
  // 光伏计费点
  registerPVBillingPointNode(
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink
  );
  // 防超容点
  registerAntiOverCapacityPointNode(
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink
  );
  // 储能计费点
  registerBsaBillingPointNode(
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink
  );
  // 充电站计费点
  registerChargingStationBillingPointNode(
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink
  );
  // 负载计费点
  registerLoadBillingPointNode(
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink
  );
  // 就地控制器
  registerFcsControllerNode(allNodeDataRef, confirm, showModalDetail);
  // 其他-垂直
  registerOtherVerticalNode(
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink
  );
  // 其他-水平
  registerOtherTransverseNode(
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink
  );
}

// 电网侧
function registerGridSideNode(
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void,
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  nodeElementLink?: nodeElementLinkTree[]
) {
  register({
    shape: NodeType.GRID_SIDE,
    width: 160,
    height: 60,
    effect: ['data'],
    component: props => (
      <GridSide
        {...props}
        graphRef={graphRef}
        allNodeDataRef={allNodeDataRef}
        gatewayListRef={gatewayListRef}
        fcsListRef={fcsListRef}
        handleCreateNode={handleCreateNode}
        confirm={confirm}
        nodeElementLink={nodeElementLink}
      />
    ),
  });
}

// 电网接入点
function registerGridAccessPointNode(
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void,
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void,
  nodeElementLink?: nodeElementLinkTree[]
) {
  register({
    shape: NodeType.GRID_ACCESS_POINT,
    width: 160,
    height: 140,
    effect: ['data'],
    component: props => (
      <GridAccessPoint
        {...props}
        graphRef={graphRef}
        allNodeDataRef={allNodeDataRef}
        gatewayListRef={gatewayListRef}
        fcsListRef={fcsListRef}
        handleCreateNode={handleCreateNode}
        confirm={confirm}
        showModalDetail={showModalDetail}
        nodeElementLink={nodeElementLink}
      />
    ),
  });
}

// 变压器
function registerTransformerNode(
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void,
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void,
  nodeElementLink?: nodeElementLinkTree[]
) {
  register({
    shape: NodeType.TRANSFORMER,
    width: 160,
    height: 172,
    effect: ['data'],
    component: props => (
      <Transformer
        {...props}
        graphRef={graphRef}
        allNodeDataRef={allNodeDataRef}
        gatewayListRef={gatewayListRef}
        fcsListRef={fcsListRef}
        handleCreateNode={handleCreateNode}
        confirm={confirm}
        showModalDetail={showModalDetail}
        nodeElementLink={nodeElementLink}
      />
    ),
  });
}

// 电网计费点
function registerGridAccessBillingPointNode(
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsListRef: FcsList[],
    index?: number
  ) => void,
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void,
  nodeElementLink?: nodeElementLinkTree[]
) {
  register({
    shape: NodeType.GRID_ACCESS_BILLING_POINT,
    width: 200,
    height: 80,
    effect: ['data'],
    component: props => (
      <Meters
        {...props}
        graphRef={graphRef}
        allNodeDataRef={allNodeDataRef}
        gatewayListRef={gatewayListRef}
        fcsListRef={fcsListRef}
        handleCreateNode={handleCreateNode}
        confirm={confirm}
        showModalDetail={showModalDetail}
        nodeElementLink={nodeElementLink}
      />
    ),
  });
}

// 光伏计费点
function registerPVBillingPointNode(
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void,
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void,
  nodeElementLink?: nodeElementLinkTree[]
) {
  register({
    shape: NodeType.PVA_BILLING_POINT,
    width: 200,
    height: 80,
    effect: ['data'],
    component: props => (
      <Meters
        {...props}
        graphRef={graphRef}
        allNodeDataRef={allNodeDataRef}
        gatewayListRef={gatewayListRef}
        fcsListRef={fcsListRef}
        handleCreateNode={handleCreateNode}
        confirm={confirm}
        showModalDetail={showModalDetail}
        nodeElementLink={nodeElementLink}
      />
    ),
  });
}
// 防超容点
function registerAntiOverCapacityPointNode(
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void,
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void,
  nodeElementLink?: nodeElementLinkTree[]
) {
  register({
    shape: NodeType.ANTI_OVERCAPACITY_POINT,
    width: 200,
    height: 80,
    effect: ['data'],
    component: props => (
      <Meters
        {...props}
        graphRef={graphRef}
        allNodeDataRef={allNodeDataRef}
        gatewayListRef={gatewayListRef}
        fcsListRef={fcsListRef}
        handleCreateNode={handleCreateNode}
        confirm={confirm}
        showModalDetail={showModalDetail}
        nodeElementLink={nodeElementLink}
      />
    ),
  });
}
// 储能计费点
function registerBsaBillingPointNode(
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void,
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void,
  nodeElementLink?: nodeElementLinkTree[]
) {
  register({
    shape: NodeType.BSA_BILLING_POINT,
    width: 200,
    height: 80,
    effect: ['data'],
    component: props => (
      <Meters
        {...props}
        graphRef={graphRef}
        allNodeDataRef={allNodeDataRef}
        gatewayListRef={gatewayListRef}
        fcsListRef={fcsListRef}
        handleCreateNode={handleCreateNode}
        confirm={confirm}
        showModalDetail={showModalDetail}
        nodeElementLink={nodeElementLink}
      />
    ),
  });
}
// 充电站计费点
function registerChargingStationBillingPointNode(
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void,
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void,
  nodeElementLink?: nodeElementLinkTree[]
) {
  register({
    shape: NodeType.CHARGING_STATION_BILLING_POINT,
    width: 200,
    height: 80,
    effect: ['data'],
    component: props => (
      <Meters
        {...props}
        graphRef={graphRef}
        allNodeDataRef={allNodeDataRef}
        gatewayListRef={gatewayListRef}
        fcsListRef={fcsListRef}
        handleCreateNode={handleCreateNode}
        confirm={confirm}
        showModalDetail={showModalDetail}
        nodeElementLink={nodeElementLink}
      />
    ),
  });
}
// 负载计费点
function registerLoadBillingPointNode(
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void,
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void,
  nodeElementLink?: nodeElementLinkTree[]
) {
  register({
    shape: NodeType.LOAD_BILLING_POINT,
    width: 200,
    height: 80,
    effect: ['data'],
    component: props => (
      <Meters
        {...props}
        graphRef={graphRef}
        allNodeDataRef={allNodeDataRef}
        gatewayListRef={gatewayListRef}
        fcsListRef={fcsListRef}
        handleCreateNode={handleCreateNode}
        confirm={confirm}
        showModalDetail={showModalDetail}
        nodeElementLink={nodeElementLink}
      />
    ),
  });
}
// 储能站并网点BSA_GRID_CONNECTION_BILLING_POINT
function registerBsaGridBillingPointNode(
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void,
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void,
  nodeElementLink?: nodeElementLinkTree[]
) {
  register({
    shape: NodeType.BSA_GRID_CONNECTION_BILLING_POINT,
    width: 200,
    height: 80,
    effect: ['data'],
    component: props => (
      <Meters
        {...props}
        graphRef={graphRef}
        allNodeDataRef={allNodeDataRef}
        gatewayListRef={gatewayListRef}
        fcsListRef={fcsListRef}
        handleCreateNode={handleCreateNode}
        confirm={confirm}
        showModalDetail={showModalDetail}
        nodeElementLink={nodeElementLink}
      />
    ),
  });
}

// 母线
function registerBusBarNode(
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    circleIndex?: number,
    circleArr?: BusBarCircleNumber[]
  ) => void,
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void,
  nodeElementLink?: nodeElementLinkTree[]
) {
  register({
    shape: NodeType.BUS_BAR,
    width: 280,
    height: 30,
    effect: ['data'],
    component: props => (
      <BusBar
        {...props}
        graphRef={graphRef}
        allNodeDataRef={allNodeDataRef}
        gatewayListRef={gatewayListRef}
        fcsListRef={fcsListRef}
        handleCreateNode={handleCreateNode}
        confirm={confirm}
        showModalDetail={showModalDetail}
        nodeElementLink={nodeElementLink}
      />
    ),
  });
}

// 光伏站
function registerPVaNode(
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void,
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void,
  nodeElementLink?: nodeElementLinkTree[]
) {
  register({
    shape: NodeType.PVA,
    width: 160,
    height: 130,
    effect: ['data'],
    component: props => (
      <PVa
        {...props}
        graphRef={graphRef}
        allNodeDataRef={allNodeDataRef}
        gatewayListRef={gatewayListRef}
        fcsListRef={fcsListRef}
        handleCreateNode={handleCreateNode}
        confirm={confirm}
        showModalDetail={showModalDetail}
        nodeElementLink={nodeElementLink}
      />
    ),
  });
}

// 储能站
function registerBsaNode(
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void,
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void,
  nodeElementLink?: nodeElementLinkTree[]
) {
  register({
    shape: NodeType.BSA,
    width: 160,
    height: 130,
    effect: ['data'],
    component: props => (
      <Bsa
        {...props}
        graphRef={graphRef}
        allNodeDataRef={allNodeDataRef}
        gatewayListRef={gatewayListRef}
        fcsListRef={fcsListRef}
        handleCreateNode={handleCreateNode}
        confirm={confirm}
        showModalDetail={showModalDetail}
        nodeElementLink={nodeElementLink}
      />
    ),
  });
}

// 负载
function registerLoadNode(
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void,
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void,
  nodeElementLink?: nodeElementLinkTree[]
) {
  register({
    shape: NodeType.LOAD,
    width: 160,
    height: 116,
    effect: ['data'],
    component: props => (
      <Load
        {...props}
        graphRef={graphRef}
        allNodeDataRef={allNodeDataRef}
        gatewayListRef={gatewayListRef}
        fcsListRef={fcsListRef}
        handleCreateNode={handleCreateNode}
        confirm={confirm}
        showModalDetail={showModalDetail}
        nodeElementLink={nodeElementLink}
      />
    ),
  });
}

// 网关
function registerGateWayNode(
  allNodeDataRef?: TreeNode[],
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void
) {
  register({
    shape: NodeType.GATEWAY,
    width: 160,
    height: 40,
    effect: ['data'],
    component: props => (
      <GateWay {...props} allNodeDataRef={allNodeDataRef} confirm={confirm} showModalDetail={showModalDetail} />
    ),
  });
}

// 充电站Charging station
function registerChargingStationNode(
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void,
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void,
  nodeElementLink?: nodeElementLinkTree[]
) {
  register({
    shape: NodeType.CHARGING_STATION,
    width: 160,
    height: 116,
    effect: ['data'],
    component: props => (
      <ChargingStation
        {...props}
        graphRef={graphRef}
        allNodeDataRef={allNodeDataRef}
        gatewayListRef={gatewayListRef}
        fcsListRef={fcsListRef}
        handleCreateNode={handleCreateNode}
        confirm={confirm}
        showModalDetail={showModalDetail}
        nodeElementLink={nodeElementLink}
      />
    ),
  });
}

// 备用电源
function registerBackupPowerNode(
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void,
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void,
  nodeElementLink?: nodeElementLinkTree[]
) {
  register({
    shape: NodeType.BACKUP_POWER,
    width: 160,
    height: 116,
    effect: ['data'],
    component: props => (
      <BackupPower
        {...props}
        graphRef={graphRef}
        allNodeDataRef={allNodeDataRef}
        gatewayListRef={gatewayListRef}
        fcsListRef={fcsListRef}
        handleCreateNode={handleCreateNode}
        confirm={confirm}
        showModalDetail={showModalDetail}
        nodeElementLink={nodeElementLink}
      />
    ),
  });
}

// 防逆流装置
function registerAntiBackflowDeviceNode(
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void,
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void,
  nodeElementLink?: nodeElementLinkTree[]
) {
  register({
    shape: NodeType.ANTI_BACKFLOW_DEVICE,
    width: 200,
    height: 80,
    effect: ['data'],
    component: props => (
      <AntiBackflowDevice
        {...props}
        graphRef={graphRef}
        allNodeDataRef={allNodeDataRef}
        gatewayListRef={gatewayListRef}
        fcsListRef={fcsListRef}
        handleCreateNode={handleCreateNode}
        confirm={confirm}
        showModalDetail={showModalDetail}
        nodeElementLink={nodeElementLink}
      />
    ),
  });
}
// 通用开关
function registerUniversalSwitchNode(
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void,
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void,
  nodeElementLink?: nodeElementLinkTree[]
) {
  register({
    shape: NodeType.UNIVERSAL_JOINT_OPENING,
    width: 200,
    height: 144,
    effect: ['data'],
    component: props => (
      <UniversalSwitch
        {...props}
        graphRef={graphRef}
        allNodeDataRef={allNodeDataRef}
        gatewayListRef={gatewayListRef}
        fcsListRef={fcsListRef}
        handleCreateNode={handleCreateNode}
        confirm={confirm}
        showModalDetail={showModalDetail}
        nodeElementLink={nodeElementLink}
      />
    ),
  });
}

// 就地控制器
function registerFcsControllerNode(
  allNodeDataRef?: TreeNode[],
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void
) {
  register({
    shape: NodeType.FCS_CONTROLLER,
    width: 160,
    height: 40,
    effect: ['data'],
    component: props => (
      <FcsController {...props} allNodeDataRef={allNodeDataRef} confirm={confirm} showModalDetail={showModalDetail} />
    ),
  });
}

// 其他-垂直
function registerOtherVerticalNode(
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void,
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void,
  nodeElementLink?: nodeElementLinkTree[]
) {
  register({
    shape: NodeType.OTHER_VERTICAL,
    width: 160,
    height: 172,
    effect: ['data'],
    component: props => (
      <OtherVertical
        {...props}
        graphRef={graphRef}
        allNodeDataRef={allNodeDataRef}
        gatewayListRef={gatewayListRef}
        fcsListRef={fcsListRef}
        handleCreateNode={handleCreateNode}
        confirm={confirm}
        showModalDetail={showModalDetail}
        nodeElementLink={nodeElementLink}
      />
    ),
  });
}
// 其他-水平
function registerOtherTransverseNode(
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void,
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void,
  showModalDetail?: (id: number, type: NodeType) => void,
  nodeElementLink?: nodeElementLinkTree[]
) {
  register({
    shape: NodeType.OTHER_TRANSVERSE,
    width: 200,
    height: 80,
    effect: ['data'],
    component: props => (
      <OtherTransverse
        {...props}
        graphRef={graphRef}
        allNodeDataRef={allNodeDataRef}
        gatewayListRef={gatewayListRef}
        fcsListRef={fcsListRef}
        handleCreateNode={handleCreateNode}
        confirm={confirm}
        showModalDetail={showModalDetail}
        nodeElementLink={nodeElementLink}
      />
    ),
  });
}
