import { Paging, Table, Tabs, Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import React, { useState } from 'react';
import styles from './index.module.scss';
import { TabsProps } from 'antd';
import ControlInsTable from './components/ControlInsTable';
import OperationRecordsTable from './components/OperationRecordsTable';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';

const InstructionConfig = () => {
  const routesContext = useBreadcrumbRoutes();
  const [currentTab, setCurrentTab] = useState<string>('1');

  // 操作日志
  const hasOperationLogsPermission = useHasPermission(PermissionsType.B_OPERATIONLOGS);

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: '指令配置',
    },
    {
      key: '2',
      label: '操作记录',
      disabled: !hasOperationLogsPermission,
    },
  ];

  return (
    <Wrapper routes={routesContext?.routes} className={styles.wrapper}>
      <Tabs items={tabItems} activeKey={currentTab} selectedLinePlacement="bottom" bordered onChange={setCurrentTab} />
      <>{currentTab === '1' && <ControlInsTable />}</>
      <>{currentTab === '2' && <OperationRecordsTable />}</>
    </Wrapper>
  );
};

export default InstructionConfig;
