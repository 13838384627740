import { Graph, Node } from '@antv/x6';
import { Cascader, Dropdown, Popconfirm, Tooltip } from 'antd';
import React, { FC, useMemo, useState } from 'react';
import {
  FcsList,
  GateWayList,
  nodeElementLinkTree,
  NodeType,
  NodeTypeClass,
  NodeTypeDisplay,
  TreeNode,
} from '../../type';
import styles from './index.module.scss';
// import electricityMeter from '../../imgs/electricityMeter.gif';
import bsaMeter from '../../imgs/bsaMeter.png';
import bsaMeterDash from '../../imgs/bsaMeterDash.png';
import loadMeter from '../../imgs/loadMeter.png';
import loadMeterDash from '../../imgs/loadMeterDash.png';
import soureMeter from '../../imgs/soureMeter.png';
import soureMeterDash from '../../imgs/soureMeterDash.png';
import transformerMeter from '../../imgs/transformerMeter.png';
import transformerMeterDash from '../../imgs/transformerMeterDash.png';

import { findDrawIndexByCurrentNode } from '../../utils/utils';
import { SingleValueType } from 'rc-cascader/lib/Cascader';
import { DefaultOptionType } from 'antd/es/cascader';
import { CloseOutlined } from '@ant-design/icons';
import { isMeters } from '../../utils/graph';
import { ElectricityType } from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import { limitByNodes } from '../../utils/nodelimit';

export interface MetersProps {
  node: Node;
  graphRef?: Graph;
  allNodeDataRef?: TreeNode[];
  gatewayListRef?: GateWayList[];
  fcsListRef?: FcsList[];
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void;
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void;
  showModalDetail?: (id: number, type: NodeType) => void;
  nodeElementLink?: nodeElementLinkTree[];
}

const Meters: FC<MetersProps> = props => {
  const {
    node,
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink,
  } = props;

  const [cascaderValue, setCascaderValue] = useState<SingleValueType>();
  const [topDropDownOpen, setTopDropDownOpen] = useState<boolean>(false);
  const [bottomDropDownOpen, setBottomDropDownOpen] = useState<boolean>(false);

  const data = useMemo(() => {
    return node.getData();
  }, [node.getData()]);

  const isShowTopBtn = useMemo(() => {
    return data?.isShowAllAddDelBtn && data?.isShowTopAddBtn;
  }, [data]);

  const isShowBottomBtn = useMemo(() => {
    return data?.isShowAllAddDelBtn && data?.isShowBottomAddBtn;
  }, [data]);

  const belongNodeTypeClassImg = useMemo(() => {
    if (data?.element === NodeType.ANTI_OVERCAPACITY_POINT) {
      return NodeTypeClass.TRANSFORM;
    } else if (data?.element === NodeType.GRID_ACCESS_BILLING_POINT || data?.element === NodeType.PVA_BILLING_POINT) {
      return NodeTypeClass.SOURCE;
    } else if (
      data?.element === NodeType.CHARGING_STATION_BILLING_POINT ||
      data?.element === NodeType.LOAD_BILLING_POINT
    ) {
      return NodeTypeClass.LOAD;
    } else if (
      data?.element === NodeType.BSA_BILLING_POINT ||
      data?.element === NodeType.BSA_GRID_CONNECTION_BILLING_POINT
    ) {
      return NodeTypeClass.STORAGE;
    }
  }, [data]);

  const borderLeftColor = useMemo(() => {
    if (belongNodeTypeClassImg === NodeTypeClass.TRANSFORM) {
      return '#00ADFF';
    } else if (belongNodeTypeClassImg === NodeTypeClass.SOURCE) {
      return '#16DD8E';
    } else if (belongNodeTypeClassImg === NodeTypeClass.LOAD) {
      return '#ABD335';
    } else if (belongNodeTypeClassImg === NodeTypeClass.STORAGE) {
      return '#CE90D1';
    }
  }, [belongNodeTypeClassImg]);

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  return (
    <div
      className={styles.customMetersReactNode}
      style={{
        opacity: data.isShowNetWork ? 0.5 : 1,
      }}
    >
      {data?.isShowAllAddDelBtn && (
        <Popconfirm
          overlayClassName={styles.popconfirmSty}
          title={
            <p style={{ color: '#fff' }}>
              确定要删除
              <span style={{ color: '#E64242' }}> {data.value.name}</span>设备吗？
            </p>
          }
          onConfirm={e => {
            e?.stopPropagation();
            confirm?.(
              data.element,
              data.id,
              findDrawIndexByCurrentNode({ id: data?.id, element: data?.element }, allNodeDataRef)
            );
          }}
          okText="确定"
          cancelText="取消"
        >
          <div
            onClick={e => {
              e.stopPropagation();
            }}
            className={styles.deleteIcon}
          >
            <CloseOutlined />
          </div>
        </Popconfirm>
      )}
      {isShowTopBtn && (
        <Dropdown
          destroyPopupOnHide
          overlayClassName={styles.drapDownStyle}
          open={topDropDownOpen}
          onOpenChange={open => {
            setTopDropDownOpen(open);
          }}
          dropdownRender={() => (
            <Cascader
              popupClassName={styles.drapDownCascaderSty}
              options={nodeElementLink}
              allowClear={false}
              fieldNames={{ value: 'key' }}
              showSearch={{ filter }}
              notFoundContent={'暂无数据'}
              placeholder={'请输入元素名称'}
              expandTrigger={'hover'}
              open={topDropDownOpen}
              value={cascaderValue}
              onChange={value => {
                setCascaderValue(value);
                limitByNodes(
                  value[value.length - 1] as NodeType,
                  node,
                  'upper',
                  undefined,
                  graphRef,
                  allNodeDataRef,
                  gatewayListRef,
                  fcsListRef,
                  handleCreateNode
                );
                setTopDropDownOpen(false);
              }}
            />
          )}
          trigger={['click']}
        >
          <div
            onClick={e => {
              e.preventDefault();
              setCascaderValue(undefined);
            }}
            className={styles.addCircleTop}
          >
            <div className={styles.addIcon}>+</div>
          </div>
        </Dropdown>
      )}

      <div className={styles.vline}></div>

      {isShowBottomBtn && (
        <Dropdown
          destroyPopupOnHide
          overlayClassName={styles.drapDownStyle}
          open={bottomDropDownOpen}
          onOpenChange={open => {
            setBottomDropDownOpen(open);
          }}
          dropdownRender={() => (
            <Cascader
              popupClassName={styles.drapDownCascaderSty}
              options={nodeElementLink}
              allowClear={false}
              fieldNames={{ value: 'key' }}
              showSearch={{ filter }}
              notFoundContent={'暂无数据'}
              placeholder={'请输入元素名称'}
              open={bottomDropDownOpen}
              expandTrigger={'hover'}
              value={cascaderValue}
              onChange={value => {
                setCascaderValue(value);
                limitByNodes(
                  value[value.length - 1] as NodeType,
                  node,
                  'down',
                  undefined,
                  graphRef,
                  allNodeDataRef,
                  gatewayListRef,
                  fcsListRef,
                  handleCreateNode
                );
                setBottomDropDownOpen(false);
              }}
            />
          )}
          trigger={['click']}
        >
          <div
            onClick={e => {
              e.preventDefault();
              setCascaderValue(undefined);
            }}
            className={styles.addCircleBottom}
          >
            <div className={styles.addIcon}>+</div>
          </div>
        </Dropdown>
      )}

      <div className={styles.hline}></div>
      <div
        className={styles.cardMeterSty}
        style={{
          borderLeft: `3px solid ${borderLeftColor}`,
        }}
        onClick={() => {
          showModalDetail?.(data.id, data.element);
        }}
      >
        {belongNodeTypeClassImg === NodeTypeClass.SOURCE && (
          <img
            style={{ width: 24, height: 24, marginLeft: 12 }}
            src={data?.value?.ammeterType === ElectricityType.REAL ? soureMeter : soureMeterDash}
            alt=""
          />
        )}
        {belongNodeTypeClassImg === NodeTypeClass.STORAGE && (
          <img
            style={{ width: 24, height: 24, marginLeft: 12 }}
            src={data?.value?.ammeterType === ElectricityType.REAL ? bsaMeter : bsaMeterDash}
            alt=""
          />
        )}
        {belongNodeTypeClassImg === NodeTypeClass.LOAD && (
          <img
            style={{ width: 24, height: 24, marginLeft: 12 }}
            src={data?.value?.ammeterType === ElectricityType.REAL ? loadMeter : loadMeterDash}
            alt=""
          />
        )}
        {belongNodeTypeClassImg === NodeTypeClass.TRANSFORM && (
          <img
            style={{ width: 24, height: 24, marginLeft: 12 }}
            src={data?.value?.ammeterType === ElectricityType.REAL ? transformerMeter : transformerMeterDash}
            alt=""
          />
        )}

        <Tooltip title={`${data.value.name}(${data?.element ? NodeTypeDisplay[data?.element as NodeType] : '--'})`}>
          <div style={{ marginLeft: 12 }} className={styles.itemControlobjContentSty}>
            {data.value.name}({data?.element ? NodeTypeDisplay[data?.element as NodeType] : '--'})
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default Meters;
