import styles from './index.module.scss';
import totalMeter from '../../imgs/electricityMeter4.gif';

const MainMeter = () => {
  return (
    <div className={styles.customMainMeterNode}>
      <div className={styles.vline}></div>

      <div className={styles.hline}></div>
      <div
        className={styles.cardMeterSty}
        style={{
          borderLeft: `3px solid #00ADFF`,
        }}
      >
        <img style={{ width: 24, height: 24, marginLeft: 12 }} src={totalMeter} alt="" />

        <div style={{ marginLeft: 12 }} className={styles.itemControlobjContentSty}>
          进线总电表
        </div>
      </div>
    </div>
  );
};

export default MainMeter;
