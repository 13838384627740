import { FC, useEffect } from 'react';
import { activateTheme, TenantLayout as LayoutComponents, Themes } from '@maxtropy/components';
import TenantLayout from '@/components/Layout';
import { Outlet } from 'react-router-dom';

// 全屏路由出口
export const FullScreenOutlet: FC = () => {
  useEffect(() => {
    activateTheme(Themes.DARK);
  }, []);

  return <Outlet />;
};

// 主业务页面路由出口
export const MainOutlet: FC = () => {
  useEffect(() => {
    activateTheme(Themes.DARK);
  }, []);

  return (
    <TenantLayout>
      <LayoutComponents>
        <Outlet />
      </LayoutComponents>
    </TenantLayout>
  );
};
