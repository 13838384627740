import Title from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Title';

import React, { Key, useEffect, useState } from 'react';
import styles from './index.module.scss';
import Footer from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Footer';
import {
  NodeType as NodeTypeClass,
  VoltageLevel,
  VoltageLevelLabel,
} from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import { useRequest } from 'ahooks';
import { isNil, omit } from 'lodash-es';
import {
  apiV2DeviceAttributePost,
  apiV2DispatchUetStructDevicePagePost,
  apiV2DispatchUetStructPublishTransformerGetPost,
  apiV2DispatchUetStructTransformerCreatePost,
  apiV2DispatchUetStructTransformerDeletePost,
  apiV2DispatchUetStructTransformerGetPost,
  apiV2DispatchUetStructTransformerUpdatePost,
  apiV2UetListUetTopologyStructPost,
  V2DispatchUetStructPublishTransformerGetPostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import ShowInput from '@/components/ShowInput';
import { NodeType, TreeNode, allInfoProps } from '@/pages/SchedulingTopology/type';
import { findTopGridAccessPointData } from '../EnergyStorageStation/utils';
import { Button, Form, InputNumber, message, Modal, Select } from '@maxtropy/components';

const { Item } = Form;

interface Props {
  id?: number;
  open?: boolean;
  dispatchGroupId?: number;
  isPreview?: boolean;
  isShowModal?: (open: boolean) => void;
  getFinishedValues?: (values: any) => void;
  shouldDeleteElement?: (state: boolean) => void;
  version?: number;
  allInfo?: allInfoProps;
  allNodeData?: TreeNode[];
}
const TransformerModal: React.FC<Props> = ({
  id,
  dispatchGroupId,
  open,
  isPreview,
  isShowModal,
  getFinishedValues,
  shouldDeleteElement,
  version,
  allInfo,
  allNodeData,
}) => {
  const [form] = Form.useForm();

  const [gridAccessPointId, setGridAccessPointId] = useState<Key>(); // 电网接入点id
  const [iconVisible, setIconVisible] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();
  const [showCapacity, setShowCapacity] = useState<boolean>(false);

  const urlParams = new URLSearchParams(window.location.search);
  const topologyId = urlParams.get('topologyId');

  const deviceId = Form.useWatch('deviceId', form);
  const capacity = Form.useWatch('capacity', form);
  const targetLoad = Form.useWatch('targetLoad', form);

  const handleShowIcon = () => {
    form.validateFields().then(() => {
      setIconVisible(true);
    });
  };

  const onCancel = () => {
    form.resetFields();
    setTitle(undefined);
    isShowModal?.(false);
  };

  // 变压器list
  const { data: deviceOptions } = useRequest(
    async () => {
      if (!open) return Promise.resolve([]);
      const uetList = await apiV2UetListUetTopologyStructPost({ id: +topologyId! });
      const uetIds = uetList?.list?.map(item => item.id) ?? [];
      const res = await apiV2DispatchUetStructDevicePagePost({
        page: 1,
        size: 500,
        deviceTypeIdList: [5012, 5011],
        uetIds: uetIds as number[],
        uetStructId: +topologyId!,
        gridAccessPointId,
      });

      if (res && res.list) {
        return res.list.map(list => ({
          label: list.name,
          value: list.id,
        }));
      }
    },
    {
      refreshDeps: [id, open, gridAccessPointId, topologyId],
      ready: !!gridAccessPointId && !!topologyId,
    }
  );

  // 设置容量
  useRequest(
    async () => {
      form.resetFields(['capacity']);
      if (isNil(deviceId)) {
        return;
      }
      const deviceProperties = await apiV2DeviceAttributePost({
        id: deviceId,
      });
      if (deviceProperties) {
        const { list } = deviceProperties;
        const capacityValue = list?.find(list => list.fieldId === 31)?.value;
        if (capacityValue) {
          setShowCapacity(false);
          form.setFieldsValue({
            capacity: +capacityValue!,
          });
        } else {
          setShowCapacity(true);
        }
      }
    },
    {
      refreshDeps: [deviceId, id],
    }
  );

  // 目标负载率
  useEffect(() => {
    if (!capacity || isNil(targetLoad)) {
      form.setFieldsValue({
        targetLoadRate: undefined,
      });
      return;
    }
    // 目标负载/容量*100，单位为%
    const targetLoadRate = ((targetLoad / capacity) * 100).toFixed(2);

    form.setFieldsValue({
      targetLoadRate: +targetLoadRate,
    });
  }, [capacity, form, targetLoad]);

  // 详情
  const { data: detail } = useRequest(
    async () => {
      if (isNil(id) || !open) return Promise.resolve(undefined);
      setIsDetail(true);
      let detail: V2DispatchUetStructPublishTransformerGetPostResponse | null | undefined;
      if (!isNil(version)) {
        detail = await apiV2DispatchUetStructPublishTransformerGetPost({ id, version });
      } else {
        detail = await apiV2DispatchUetStructTransformerGetPost({ id });
      }

      if (detail) {
        const title = deviceOptions?.find(option => option.value === detail?.deviceId);
        setTitle(title?.label);
        form.setFieldsValue(omit(detail, 'targetLoadRate'));
        setIconVisible(true);
      }
      return detail;
    },
    {
      refreshDeps: [id, open, deviceOptions, version],
    }
  );

  // 通过当前id与type查找电网接入点id
  useEffect(() => {
    if (detail) {
      if (allNodeData) {
        const gridAccessPointData = findTopGridAccessPointData(allNodeData, detail.id, NodeType.TRANSFORMER);
        setGridAccessPointId(gridAccessPointData?.id);
      }
    } else {
      if (allInfo) {
        const gridAccessPointData = findTopGridAccessPointData(
          allInfo.allNodeData,
          allInfo.node.getData().id,
          allInfo.node.getData().element
        );
        setGridAccessPointId(gridAccessPointData?.id);
      }
    }
  }, [allInfo, detail, allNodeData]);

  return (
    <>
      <Modal
        contentClassName="modal-form-content"
        open={open}
        title={
          <Title
            title={title ?? '变压器'}
            styles={styles}
            id={id}
            isDetail={isDetail}
            isPreview={isPreview}
            delMethod={apiV2DispatchUetStructTransformerDeletePost}
            onEdit={() => setIsDetail(false)}
            onCancel={onCancel}
            shouldDeleteElement={state => shouldDeleteElement?.(state)}
            nodeType={NodeTypeClass.TRANSFORMER}
          />
        }
        footer={
          <Footer
            form={form}
            isDetail={isDetail}
            styles={styles}
            dispatchGroupId={dispatchGroupId}
            createMethod={apiV2DispatchUetStructTransformerCreatePost}
            updateMethod={apiV2DispatchUetStructTransformerUpdatePost}
            getFinishedValues={val => getFinishedValues?.(val)}
            onCancel={onCancel}
          />
        }
        onCancel={onCancel}
        destroyOnClose
      >
        <div className={styles.modalWrapper}>
          <Form form={form} labelCol={{ span: 6 }} labelAlign="left">
            <Item label="变压器" name="deviceId" rules={[{ required: true, message: '请选择变压器' }]}>
              <Select
                placeholder="请输入"
                options={deviceOptions}
                onBlur={() => setIconVisible(false)}
                allowClear
                disabled={isDetail}
              />
            </Item>

            <Item
              label="容量"
              name="capacity"
              rules={[{ required: true, message: '请设置容量' }]}
              extra={
                showCapacity && (
                  <a
                    href=" "
                    onClick={e => {
                      e.preventDefault();
                      if (isNil(deviceId)) {
                        message.warning('请先选择变压器');
                        return;
                      }
                      window.open(
                        `${window.IOTPLATFORMORIGIN}/device/manage/device/${deviceId}/attribute/edit`,
                        '_blank'
                      );
                    }}
                  >
                    设置容量
                  </a>
                )
              }
            >
              <ShowInput addonAfter="kVA" />
            </Item>

            <Item label="目标负载" name="targetLoad">
              <InputNumber
                style={{ width: '100%' }}
                addonAfter="kW"
                max={1000000}
                precision={0}
                min={0}
                disabled={isDetail}
              />
            </Item>

            <Item label="目标负载率" name="targetLoadRate">
              <ShowInput addonAfter={'%'} />
            </Item>

            <Item
              label="高压侧电压"
              name="highVoltageLevel"
              dependencies={['lowerVoltageLevel']}
              rules={[
                {
                  required: true,
                  message: '请选择高压侧电压',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value && getFieldValue('lowerVoltageLevel')) {
                      if (
                        Number(VoltageLevelLabel[value as VoltageLevel].slice(0, -2)) <=
                        Number(VoltageLevelLabel[getFieldValue('lowerVoltageLevel') as VoltageLevel].slice(0, -2))
                      ) {
                        return Promise.reject('高压侧电压必须大于低压侧');
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Select
                options={Object.entries(VoltageLevelLabel)
                  .map(([key, value]) => ({
                    label: value,
                    value: +key,
                  }))
                  .sort((a, b) => {
                    return (a.label.slice(0, -2) as unknown as number) - (b.label.slice(0, -2) as unknown as number);
                  })}
                placeholder="请选择"
                allowClear
                disabled={isDetail}
              />
            </Item>

            <Item
              label="低压侧电压"
              name="lowerVoltageLevel"
              dependencies={['highVoltageLevel']}
              rules={[
                {
                  required: true,
                  message: '请选择低压侧电压',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value && getFieldValue('highVoltageLevel')) {
                      if (
                        Number(VoltageLevelLabel[value as VoltageLevel].slice(0, -2)) >=
                        Number(VoltageLevelLabel[getFieldValue('highVoltageLevel') as VoltageLevel].slice(0, -2))
                      ) {
                        return Promise.reject('低压侧电压必须小于高压侧');
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Select
                options={Object.entries(VoltageLevelLabel)
                  .map(([key, value]) => ({
                    label: value,
                    value: +key,
                  }))
                  .sort((a, b) => {
                    return (a.label.slice(0, -2) as unknown as number) - (b.label.slice(0, -2) as unknown as number);
                  })}
                placeholder="请选择"
                allowClear
                disabled={isDetail}
              />
            </Item>

            <Item name="thresholdValue" label="防超容阈值" rules={[{ required: true, message: '请输入防超容阈值' }]}>
              <InputNumber style={{ width: '100%' }} addonAfter="kW" precision={0} min={0} disabled={isDetail} />
            </Item>

            <Item name="id" hidden />

            <div className={styles.preview}>
              <Button type="link" className={styles.previewBtn} onClick={handleShowIcon}>
                查看图标
              </Button>

              <div className={styles.iconWrapper}>
                {iconVisible && (
                  <div className={styles.iconBox}>
                    <div className={styles.icon} />

                    <Item noStyle dependencies={['deviceId', 'highVoltageLevel', 'lowerVoltageLevel']}>
                      {({ getFieldValue }) => {
                        const deviceName = deviceOptions?.find(item => item.value === getFieldValue('deviceId'))?.label;
                        const highVoltageLevel = getFieldValue('highVoltageLevel');
                        const lowerVoltageLevel = getFieldValue('lowerVoltageLevel');
                        return (
                          <>
                            <div className={styles.iconText} style={{ marginTop: 6 }}>
                              {deviceName}
                            </div>
                            <div className={styles.highVoltage}>
                              {VoltageLevelLabel[highVoltageLevel as VoltageLevel]?.split('kV')?.[0]}/
                              {VoltageLevelLabel[lowerVoltageLevel as VoltageLevel]?.split('kV')?.[0]} kV
                            </div>
                          </>
                        );
                      }}
                    </Item>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default TransformerModal;
