import styles from './index.module.scss';
import poweGrid from '../../imgs/poweGrid.gif';
import { FC, useEffect, useMemo, useRef } from 'react';
import { Node } from '@antv/x6';
import { Tooltip } from '@maxtropy/components';
import { judgeDrageOrClick } from '../../utils';
import CustomTooltip from '../CustomTooltip';

export interface GridAccessPointProps {
  node: Node;
  configId?: number;
}

const GridAccessPoint: FC<GridAccessPointProps> = props => {
  const { node, configId } = props;
  const refBox = useRef<HTMLDivElement>();

  const data = useMemo(() => {
    return node.getData();
  }, [node.getData()]);

  // 区分拖动与点击事件
  useEffect(() => {
    if (refBox.current) {
      judgeDrageOrClick(refBox.current, configId!, data?.value?.id, data?.element);
    }
  }, []);

  return (
    <div className={styles.customAccesspointReactNode} style={{ opacity: 1 }}>
      <div className={styles.vlineTop}></div>
      <div
        className={styles.cardSty}
        ref={d => {
          if (d) {
            refBox.current = d;
          }
        }}
      >
        <img style={{ width: 40, height: 40 }} src={poweGrid} alt="" />
        <CustomTooltip title={data.value.name}>
          <div className={styles.itemControlobjContentSty}>{data.value.name}</div>
        </CustomTooltip>
      </div>
      <div className={styles.vlineBottom}></div>
    </div>
  );
};

export default GridAccessPoint;
