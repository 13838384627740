import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Edge, Graph } from '@antv/x6';
import { Button, FormContent, Radio, Tooltip, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import {
  apiV2DispatchUetStructGetPublishTreePost,
  apiV2DispatchUetStructStructInfoPost,
  V2DispatchUetStructStructInfoPostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { Divider, Layout, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FcsList, GateWayList, NodeType, TreeNode } from '../type';
import { cancelFullscreen, goFullscreen, initScTopoGraph, addPortwithGateWay, isMeters } from '../utils/graph';
import registerCustomerNode from '../utils/registerCustomerNode';
import VersionRecords from './components/VersionRecords';
import styles from './index.module.scss';
import {
  changePreviewNodeData,
  firstRenderSide,
  formatResSingleData,
  formatResTreeData,
  formatResTreeDataSideNode,
  handleFetchData,
  removeAllEdgeAndPort,
} from '../utils/dataUtil';
import BusBarDrawer from './components/BusBarDrawer';
import PVaDrawer from './components/PVaDrawer';
import BsaDrawer from './components/BsaDrawer';
import GridAccessPointDrawer from './components/GridAccessPointDrawer';
import TransformerDrawer from './components/TransformerDrawer';
import MeteringMeterDrawer from './components/MeteringMeterDrawer';
import LoadDrawer from './components/LoadDrawer';
import GatewayDrawer from './components/GatewayDrawer';
import ChargingStationDrawer from './components/ChargingStationDrawer';
import BackupPowerDrawer from './components/BackupPowerDrawer';
import AntiBackflowDeviceDrawer from './components/AntiBackflowDeviceDrawer';
import FcsControllerDrawer from './components/FcsControllerDrawer';
import CreatingModal from '../PublishLoading/CreatingModal';
import { graphAddEdgeByNodeLink, graphAllNodesAddChild } from '../utils/draw';
import UniversalSwitchDrawer from './components/UniversalSwitchDrawer';
import OtherTransverseDrawer from './components/OtherTransverseDrawer';
import OtherVerticalDrawer from './components/OtherVerticalDrawer';

const { Content, Sider } = Layout;

// 视图类型
export enum viewTypeEnum {
  ENERGYFLOWTOPO = '1',
  NETWORK = '2',
}

export const viewTypeDisplay = {
  [viewTypeEnum.ENERGYFLOWTOPO]: '能量拓扑视图',
  [viewTypeEnum.NETWORK]: '组网视图',
};

const PreviewTopo = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  let url_dispatchingGroupId = urlSearchParams.get('dispatchingGroup') || undefined;
  let url_topologyIdId = urlSearchParams.get('topologyId') || undefined;

  const breadcrumbRoutes = useBreadcrumbRoutes();
  const refContainer = useRef<HTMLDivElement>();
  const allNodeData = useRef<TreeNode[]>([]);
  const edgesRef = useRef<Edge<Edge.Properties>[]>([]);
  const graphRef = useRef<Graph>();
  const gatewayList = useRef<GateWayList[]>([]);
  const fcsList = useRef<FcsList[]>([]); // Fcs节点
  const outerRef = useRef<HTMLDivElement>(); // 全屏

  const [drawOpen, setDrawOpen] = useState<boolean>(false);
  const [viewType, setViewType] = useState<viewTypeEnum>();

  const [type, setType] = useState<NodeType>(); // 节点类型
  const [currentNodeId, setCurrentNodeId] = useState<number>(); // 当前点击的节点id

  const [dispatchInfo, setDispatchInfo] = useState<V2DispatchUetStructStructInfoPostResponse>();
  const [isFullScreen, setIsFullScreen] = useState(false); // 全屏
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle
  const [currentTag, setCurrentTag] = useState<number>(); // 最新版本

  const [canvasLoading, setCanvasLoading] = useState<boolean>(false);

  // 查询拓扑结构基本信息
  useEffect(() => {
    if (url_topologyIdId) {
      apiV2DispatchUetStructStructInfoPost({ id: Number(url_topologyIdId) }).then(res => {
        setDispatchInfo(res);
      });
    }
  }, [url_topologyIdId]);

  // 查询数据
  useEffect(() => {
    if (url_topologyIdId) {
      const graph = initScTopoGraph(refContainer.current!);
      graphRef.current = graph;
      setCanvasLoading(true);
      apiV2DispatchUetStructGetPublishTreePost({ id: Number(url_topologyIdId) }).then(res => {
        setCanvasLoading(false);
        if (!res) {
          const addNodeData = firstRenderSide(allNodeData.current);
          // 画布添加
          graphRef.current!.addNode(addNodeData);
          setViewType(viewTypeEnum.ENERGYFLOWTOPO);
        } else {
          // 渲染数据
          const allgraphNodes = handleFetchData(res);
          graphRef.current!.addNodes(allgraphNodes);
          // 维护网关与fcs（单独的）
          gatewayList.current = formatResSingleData(NodeType.GATEWAY, res?.gatewayList);
          fcsList.current = formatResSingleData(NodeType.FCS_CONTROLLER, res?.fcsList);
          // 维护树结构（电网侧不是实体）
          allNodeData.current = formatResTreeData(res?.trees ?? []);
          // 维护电网侧元素Node（电网侧不是实体，后端数据不返回，因此前端新加）
          const sideNodes = formatResTreeDataSideNode(res?.trees ?? [], res.diagrams);
          graphRef.current!.addNodes(sideNodes);
          graphAllNodesAddChild(graphRef.current!, allNodeData.current);
          // 添加自定义节点与母线或节点关联的边
          graphAddEdgeByNodeLink(graphRef.current!);
          setViewType(viewTypeEnum.ENERGYFLOWTOPO);
        }
      });
    }
  }, [url_topologyIdId]);

  // 切换视图
  useEffect(() => {
    if (graphRef.current && viewType) {
      const addedAllNodes = graphRef.current!.getNodes();
      const gridSideNodesExceptFirst = allNodeData.current.filter((_, index) => index !== 0);
      if (viewType === viewTypeEnum.ENERGYFLOWTOPO) {
        // 删除所有的边和链接桩子
        const egdes = graphRef.current.getEdges();
        egdes.forEach(item => {
          item.attr('line/stroke', 'rgba(74, 144, 226, 1)'); // 修改边颜色，等价于 edge.prop('attrs/line/stroke', '#ccc')
        });
        removeAllEdgeAndPort(addedAllNodes, edgesRef.current, graphRef.current);
        // 后面的电网侧删除按钮去除，隐藏删除和添加按钮，以及isShowNetWork=false（透明度）
        changePreviewNodeData(addedAllNodes, gridSideNodesExceptFirst, false, false, false);
      } else {
        const egdes = graphRef.current.getEdges();
        egdes.forEach(item => {
          item.attr('line/stroke', 'rgba(74, 144, 226, 0.5)'); // 修改边颜色，等价于 edge.prop('attrs/line/stroke', '#ccc')
        });
        // 后面的电网侧删除按钮去除
        changePreviewNodeData(addedAllNodes, gridSideNodesExceptFirst, false, false, true);
        // 添加链接装,如果有关联（fcs的gatewayId与gateway的edgeId对应上的话）TODO
        addPortwithGateWay(addedAllNodes, graphRef.current, edgesRef.current);
      }
    }
  }, [viewType]);

  // 抽屉展示
  const showModalDetail = (id: number, type: NodeType) => {
    setDrawOpen(true);
    setType(type);
    setCurrentNodeId(id);
  };

  const handleFullScreen = () => {
    setIsFullScreen(document.fullscreenElement !== null);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreen);
    return () => document.removeEventListener('fullscreenchange', handleFullScreen);
  }, []);

  const fullBtn = () => {
    if (outerRef.current) {
      isFullScreen ? cancelFullscreen() : goFullscreen(outerRef.current);
    }
  };

  // 渲染每个modal框
  const renderEachModal = () => {
    const propParams = {
      isShowModal: (open: boolean) => {
        setDrawOpen(open);
      },
      open: drawOpen,
      id: currentNodeId,
      dispatchGroupId: url_dispatchingGroupId ? Number(url_dispatchingGroupId) : undefined,
      isPreview: true,
      version: currentTag,
      type,
      allNodeData: allNodeData.current,
      dispatchUetStructId: url_topologyIdId ? Number(url_topologyIdId) : undefined,
      graph: graphRef.current,
    };
    if (type === NodeType.GRID_ACCESS_POINT) {
      return <GridAccessPointDrawer {...propParams} />;
    } else if (type === NodeType.TRANSFORMER) {
      return <TransformerDrawer {...propParams} />;
    } else if (type === NodeType.PVA) {
      return <PVaDrawer {...propParams} />;
    } else if (type && isMeters(type)) {
      return <MeteringMeterDrawer {...propParams} />;
    } else if (type === NodeType.LOAD) {
      return <LoadDrawer {...propParams} />;
    } else if (type === NodeType.BUS_BAR) {
      return <BusBarDrawer {...propParams} />;
    } else if (type === NodeType.GATEWAY) {
      return <GatewayDrawer {...propParams} />;
    } else if (type === NodeType.BSA) {
      return <BsaDrawer {...propParams} />;
    } else if (type === NodeType.CHARGING_STATION) {
      return <ChargingStationDrawer {...propParams} />;
    } else if (type === NodeType.BACKUP_POWER) {
      return <BackupPowerDrawer {...propParams} />;
    } else if (type === NodeType.ANTI_BACKFLOW_DEVICE) {
      return <AntiBackflowDeviceDrawer {...propParams} />;
    } else if (type === NodeType.FCS_CONTROLLER) {
      return <FcsControllerDrawer {...propParams} />;
    } else if (type === NodeType.UNIVERSAL_JOINT_OPENING) {
      return <UniversalSwitchDrawer {...propParams} />;
    } else if (type === NodeType.OTHER_TRANSVERSE) {
      return <OtherTransverseDrawer {...propParams} />;
    } else if (type === NodeType.OTHER_VERTICAL) {
      return <OtherVerticalDrawer {...propParams} />;
    } else {
      return <></>;
    }
  };

  registerCustomerNode(
    graphRef.current,
    allNodeData.current,
    gatewayList.current,
    fcsList.current,
    undefined,
    undefined,
    showModalDetail
  );

  const routes = [{ name: '查看最新发布' }];

  return (
    <>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
        <FormContent
          style={{ height: 'calc(100vh - 158px)', overflowY: 'hidden', padding: '5px 10px' }}
          title={
            <div>
              <span className={styles.topTitle}>{dispatchInfo?.name ?? '--'}</span>

              <Space split={<Divider type="vertical" />} className={styles.topTags}>
                <Tooltip title={`所属调度组:${dispatchInfo?.dispatchGroupName ?? '--'}`}>
                  <p className={styles.hint}>所属调度组:{dispatchInfo?.dispatchGroupName ?? '--'}</p>
                </Tooltip>
                <Tooltip title={`关联UET:${dispatchInfo?.uetVos?.map(i => i.name).join() ?? '--'}`}>
                  <p className={styles.hint}>关联UET:{dispatchInfo?.uetVos?.map(i => i.name).join() ?? '--'}</p>
                </Tooltip>
                <Tooltip title={`关联拓扑:${dispatchInfo?.linkVo?.name ?? '--'}`}>
                  <p className={styles.hint}>关联拓扑:{dispatchInfo?.linkVo?.name ?? '--'}</p>
                </Tooltip>
              </Space>
            </div>
          }
          extraContent={
            <Space size={8}>
              <Button type="primary" onClick={() => fullBtn()}>
                全屏
              </Button>
              <Button
                onClick={() => {
                  navigate(-1);
                }}
              >
                返回
              </Button>
            </Space>
          }
        >
          <Layout className={styles.layout}>
            <Sider width={openSider ? 260 : 0} className={styles.siderSty}>
              <VersionRecords topologyId={url_topologyIdId} getCurrentTag={(tag?: number) => setCurrentTag(tag)} />
            </Sider>

            <Content className={styles.content}>
              <div
                className={styles.toggleBtn}
                style={{ left: openSider ? -30 : 0 }}
                onClick={() => setOpenSider(!openSider)}
              >
                {openSider ? <LeftOutlined /> : <RightOutlined />}
              </div>
              <div
                className={styles.btnAndCanvasBgSty}
                ref={d => {
                  if (d) {
                    outerRef.current = d;
                  }
                }}
              >
                <div className={styles.topBtnSty}>
                  <Space size={10}>
                    <Radio.Group
                      options={Object.entries(viewTypeDisplay).map(([k, v]) => ({
                        label: v,
                        value: k,
                      }))}
                      onChange={e => {
                        setViewType(e.target.value);
                      }}
                      value={viewType}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Space>
                  {isFullScreen && (
                    <Button type="primary" onClick={() => fullBtn()}>
                      退出全屏
                    </Button>
                  )}
                </div>
                <div className={styles.currentTagSty} style={{ right: isFullScreen ? 106 : 6 }}>
                  当前版本：{currentTag}
                </div>
                <div className={styles.canvasWapper}>
                  <div className={styles.helloworldApp}>
                    <div
                      className={styles.appContent}
                      style={{ minHeight: isFullScreen ? 'calc(100vh - 50px)' : undefined }}
                      ref={d => {
                        if (d) {
                          refContainer.current = d;
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </Content>
          </Layout>
        </FormContent>
      </Wrapper>
      {canvasLoading && <CreatingModal loading={canvasLoading} content={'画布加载中'} />}
      {renderEachModal()}
    </>
  );
};

export default PreviewTopo;
