import { CheckCircleOutlined, PlusOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Tag, useTenantPermissions, useUpdate, PopConfirm, Modal, message } from '@maxtropy/components';
import React, { useRef, useState } from 'react';

import AddTopologyModal from '@/pages/EnergyDispatchManagement/Edit/Topology/AddTopologyModal';
import { Col, Descriptions, Row } from 'antd';
import { useRequest } from 'ahooks';
import styles from './index.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty, isNil } from 'lodash-es';
import {
  apiV2DispatchUetStructCheckWritingPost,
  apiV2DispatchUetStructDeletePost,
  apiV2DispatchUetStructListPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { TopologyList, TopoState, TopoStateDisplay } from '@/pages/EnergyDispatchManagement/Edit/Topology/types';
import { PermissionsType } from '@/common/permissionsConst';
import dayjs from 'dayjs';

interface Props {
  detail?: boolean;
  customerMcids: string[];
}
const Topology: React.FC<Props> = ({ detail, customerMcids }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const permission = useTenantPermissions();

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [currentListInfo, setCurrentListInfo] = useState<TopologyList>();
  const [state, forceUpdate] = useUpdate();
  const randomTime = useRef(dayjs().valueOf());

  const onEdit = (item: TopologyList) => {
    setCurrentListInfo(item);
    setModalVisible(true);
  };

  const { data: topologyList = [] } = useRequest(
    async () => {
      if (isNil(id)) return;
      const res = await apiV2DispatchUetStructListPost({ id: +id });
      if (res) return res.list;
    },
    {
      refreshDeps: [id, state],
    }
  );

  // 删除
  const onDelete = (item: TopologyList) => {
    apiV2DispatchUetStructDeletePost({ id: item.id! }).then(_ => {
      message.success('删除成功');
      forceUpdate();
    });
  };

  return (
    <>
      {(permission ?? []).includes(PermissionsType.B_ADDEDT) && !detail && (
        <div className={styles.addTopologyBtn}>
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setModalVisible(true)}>
            新建调度拓扑结构
          </Button>
        </div>
      )}

      <div className={styles.wrapper}>
        <Row gutter={[8, 8]}>
          {topologyList.map(list => (
            <Col span={12} key={list.id}>
              <div className={styles.content}>
                <Descriptions
                  layout="vertical"
                  labelStyle={{ color: 'rgba(255, 255, 255, 0.65)' }}
                  colon={false}
                  column={2}
                >
                  <Descriptions.Item label="调度拓扑结构名称">
                    {list?.name ?? '--'}
                    <Tag
                      style={{ marginLeft: 8 }}
                      border="solid"
                      color={
                        list.state === TopoState.ALLPULISH || list.state === TopoState.DRAFT_UNPULISH
                          ? 'success'
                          : 'warning'
                      }
                    >
                      {TopoStateDisplay[list.state as TopoState]}
                    </Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="关联上级拓扑">{list?.linkVo?.name ?? '--'}</Descriptions.Item>
                  <Descriptions.Item label="关联UET">
                    {isEmpty(list?.uetVos) ? '--' : list?.uetVos?.map(item => item.name).join('、 ')}
                  </Descriptions.Item>
                </Descriptions>

                {list.state === TopoState.ALLPULISH ? (
                  <div className={styles.statusTipsSty}>
                    <CheckCircleOutlined style={{ color: '#52C41A', marginRight: 2 }} />
                    最新草稿版本已发布
                  </div>
                ) : list.state === TopoState.DRAFT_UNPULISH ? (
                  <div className={styles.statusTipsSty}>
                    <WarningOutlined style={{ color: '#FAAD14', marginRight: 2 }} />
                    最新草稿版本尚未发布
                  </div>
                ) : (
                  <></>
                )}
                <div className={styles.action}>
                  {(permission ?? []).includes(PermissionsType.B_DELETEEDT) &&
                    !detail &&
                    list.state === TopoState.UNPULISH && (
                      <PopConfirm placement="top" title={'确定删除该拓扑？'} onConfirm={() => onDelete(list)}>
                        <Button type="link">删除</Button>
                      </PopConfirm>
                    )}
                  {(permission ?? []).includes(PermissionsType.B_EDITEDTBASICSET) && !detail && (
                    <Button type="link" onClick={() => onEdit(list)}>
                      编辑基础信息
                    </Button>
                  )}

                  {(permission ?? []).includes(PermissionsType.B_EDITEDT) && !detail && (
                    <Button
                      type="link"
                      onClick={() =>
                        apiV2DispatchUetStructCheckWritingPost({
                          id: list.id!,
                          random: randomTime.current,
                        }).then(res => {
                          if (res.isContinuousEditing) {
                            Modal.error({
                              title: `${res.name}操作信息处理中，请稍后`,
                            });
                          } else {
                            navigate(`/energy/scheduling/topology?dispatchingGroup=${id}&topologyId=${list.id}`);
                          }
                        })
                      }
                    >
                      编辑拓扑草稿
                    </Button>
                  )}
                  {(list.state === TopoState.ALLPULISH || list.state === TopoState.DRAFT_UNPULISH) && (
                    <Button
                      type="link"
                      onClick={() =>
                        navigate(`/energy/scheduling/topology/preview?dispatchingGroup=${id}&topologyId=${list.id}`)
                      }
                    >
                      查看最新发布
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>

      {modalVisible && (
        <AddTopologyModal
          customerMcids={customerMcids}
          onCancel={() => setModalVisible(false)}
          visible={modalVisible}
          currentListInfo={currentListInfo}
          setCurrentTopoInfo={val => setCurrentListInfo(val)}
          topologyList={topologyList?.map(item => ({
            value: item.id,
            label: item.name,
          }))}
          forceUpdate={() => forceUpdate()}
        />
      )}
    </>
  );
};

export default Topology;
