import {
  Paging,
  Table,
  Tabs,
  Wrapper,
  useBreadcrumbRoutes,
  Form,
  CustomFilter,
  usePaging,
  Select,
  Button,
  EllipsisSpan,
  PopConfirm,
} from '@maxtropy/components';
import React, { useState } from 'react';
import styles from './index.module.scss';
import { Input, Space, TabsProps } from 'antd';
import { useRequest } from 'ahooks';
import { LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import MicroConfigModal from './Element/MicroConfigModal';
import {
  apiV2MicrogridConfigurationConfStatusChangePost,
  apiV2MicrogridConfigurationConfStatusDeletePost,
  apiV2MicrogridConfigurationDispatchUetStructListPost,
  apiV2MicrogridConfigurationPagePost,
  V2MicrogridConfigurationPagePostRequest,
  V2MicrogridConfigurationPagePostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { ConfigStatus } from './const';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
type SearchParams = Omit<V2MicrogridConfigurationPagePostRequest, 'page' | 'size'>;
export type ListItem = Exclude<V2MicrogridConfigurationPagePostResponse['list'], undefined>[number];
const MicrogridConfigure = () => {
  const routesContext = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const pagingInfo = usePaging(20);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [opType, setOpType] = useState('add');
  const [visible, setVisible] = useState(false);
  const [currentRow, setCurrentRow] = useState<ListItem>();
  const navigate = useNavigate();
  const hasConfigAdd = useHasPermission(PermissionsType.B_CONFIG_ADD);
  const hasConfigSet = useHasPermission(PermissionsType.B_CONFIG_SET);
  const hasConfigEdit = useHasPermission(PermissionsType.B_CONFIG_EDIT);
  const hasConfigDelete = useHasPermission(PermissionsType.B_CONFIG_DELETE);
  const hsaConfigOps = useHasPermission(PermissionsType.B_CONFIG_OPS);
  const {
    data: pageList,
    loading,
    refresh,
  } = useRequest(
    () => {
      return apiV2MicrogridConfigurationPagePost({
        ...searchParams,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list;
      });
    },
    {
      refreshDeps: [pageOffset, pageSize, searchParams],
    }
  );
  const { data: dispatchUetStructIdList } = useRequest(
    () => {
      return apiV2MicrogridConfigurationDispatchUetStructListPost({}).then(res =>
        res.list?.map(item => ({
          label: `${item.dispatchGroupName}-${item.dispatchUetStructName}`,
          value: item.dispatchUetStructId,
        }))
      );
    },
    {
      refreshDeps: [],
    }
  );
  const onFinish = (val: SearchParams) => {
    setSearchParams(val);
  };

  const onReset = () => {
    setPageOffset(1);
    setSearchParams({});
  };

  const changeStatus = (row: ListItem) => {
    apiV2MicrogridConfigurationConfStatusChangePost({
      id: row.id,
      status: row.status === ConfigStatus.DISABLE ? ConfigStatus.ENABLE : ConfigStatus.DISABLE,
    }).then(refresh);
  };
  const deleteBtn = (row: ListItem) => {
    apiV2MicrogridConfigurationConfStatusDeletePost({ id: row.id }).then(refresh);
  };
  const buildColumns = [
    {
      title: '组态名称',
      ellipsis: true,
      dataIndex: 'configurationName',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '运营单元',
      ellipsis: true,
      dataIndex: 'ous',
      render: (v: string, record: any) => (
        <EllipsisSpan value={record?.ous?.map((item: any) => item.name)?.toString()} />
      ),
    },

    {
      title: '关联调度组/拓扑名称',
      ellipsis: true,
      dataIndex: 'dispatchGroupName',
      render: (v: string, record: any) => <EllipsisSpan value={`${v}/${record.dispatchUetStructName ?? ''}`} />,
    },

    {
      title: '最后操作人',
      ellipsis: true,
      dataIndex: 'lastUpdateUser',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '最后操作时间',
      ellipsis: true,
      dataIndex: 'updateTime',
      render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
    },
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 250,
      render: (v: any, record: any) => {
        /**
         * 禁用 -- > 可以删除
         * 失效  --> 启用和禁用都会置灰
         */

        return (
          <Space size={16}>
            {hasConfigSet && (
              <Button
                type="link"
                disabled={record.effective === 1 && record.status === ConfigStatus.ENABLE}
                onClick={() => {
                  navigate(`/microgrid/conf/page/config/${record.id}/${record.dispatchUetStructId}`);
                }}
              >
                配置
              </Button>
            )}

            {hasConfigEdit && (
              <Button
                type="link"
                onClick={() => {
                  setVisible(true);
                  setOpType('edit');
                  setCurrentRow(record);
                }}
              >
                编辑
              </Button>
            )}

            {hsaConfigOps && (
              // disabled={record.effective === 0}之前的逻辑现在不需要禁用
              <Button type="link" onClick={() => changeStatus(record)}>
                {record.status === ConfigStatus.DISABLE ? '启用' : '禁用'}
              </Button>
            )}
            {hasConfigDelete && (
              <PopConfirm title="是否删除该组态？删除后相关配置无法恢复。" onConfirm={() => deleteBtn(record)}>
                <Button
                  type="link"
                  disabled={record.status !== ConfigStatus.DISABLE}
                  danger={record.status === ConfigStatus.DISABLE}
                >
                  删除
                </Button>
              </PopConfirm>
            )}
          </Space>
        );
      },
    },
  ];
  const filters = (
    <CustomFilter<SearchParams> form={form} onFinish={onFinish} onReset={onReset}>
      <Form.Item name="name" label="组态名称">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item name="dispatchUetStructId" label="调度组/拓扑">
        <Select placeholder="请选择" options={dispatchUetStructIdList} />
      </Form.Item>
    </CustomFilter>
  );
  return (
    <Wrapper filters={filters} routes={routesContext?.routes} className={styles.wrapper}>
      <div>
        <Space size={8} style={{ marginBottom: 10 }}>
          {hasConfigAdd && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setVisible(true);
                setOpType('add');
              }}
            >
              新增组态
            </Button>
          )}
        </Space>

        <Table
          rowKey={'id'}
          sticky
          scroll={{ x: 1300 }}
          loading={loading}
          dataSource={pageList}
          columns={buildColumns}
        />

        <Paging pagingInfo={pagingInfo} />
      </div>
      {visible && (
        <MicroConfigModal
          opType={opType}
          row={currentRow}
          onConfirm={() => {
            setVisible(false);
            refresh();
          }}
          onCancel={() => setVisible(false)}
        />
      )}
    </Wrapper>
  );
};

export default MicrogridConfigure;
