import React, { FC, useEffect, useMemo, useRef } from 'react';
import { TreeNode } from '../../type';
import { Graph, Node } from '@antv/x6';
import CustomTooltip from '../CustomTooltip';
import styles from './index.module.scss';
import FCS from '../../imgs/FCS.png';
import { judgeDrageOrClick } from '../../utils';

export interface FcsControllerProps {
  node: Node;
  allNodeDataRef?: TreeNode[];
  configId?: number;
}

const FcsController: FC<FcsControllerProps> = props => {
  const { node, allNodeDataRef, configId } = props;
  const refBox = useRef<HTMLDivElement>();

  const data = useMemo(() => {
    return node.getData();
  }, [node.getData()]);

  // 区分拖动与点击事件
  useEffect(() => {
    if (refBox.current) {
      judgeDrageOrClick(refBox.current, configId!, data?.value?.id, data?.element);
    }
  }, []);

  // useEffect(() => {
  //   if (!isNil(data.isExpand)) {
  //     const height = data.isExpand ? 80 + 9 + ITEM_HEIGHT * datas.length : 80;
  //     const opt: Animation.BaseOptions = {
  //       delay: 0,
  //       duration: 300,
  //       timing: 'linear',
  //     };
  //     node.transition('size/height', height, opt);
  //   }
  // }, [data.isExpand]);

  // const onExpand = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  //   e.stopPropagation();
  //   node.updateData({
  //     isExpand: !data.isExpand,
  //   });
  // };

  return (
    <div className={styles.customGatewayReactNode}>
      <div className={styles.cardMeterSty_wrapper}>
        <div
          className={styles.cardMeterSty}
          ref={d => {
            if (d) {
              refBox.current = d;
            }
          }}
        >
          <div className={styles.card_content}>
            <img style={{ width: 24, height: 24, marginLeft: 12 }} src={FCS} alt="" />
            <div className={styles.gatewayNameSty}>
              <CustomTooltip
                title={
                  <>
                    <div>FCS名称：{data.value.name}</div>
                    <div>FCS编号：{data.value.serialNumber}</div>
                  </>
                }
              >
                <div className={styles.itemControlobjContentSty}>{data.value.name}</div>
              </CustomTooltip>
            </div>
          </div>
          {/* <div className={styles.expand_content}>
            {data.isExpand ? (
              <>
                <div className={styles.dividerSty} />
                <div className={styles.device_prop_wrapper}>
                  {datas.map(item => (
                    <div className={classNames(styles.item_sty, styles.triangle)}>
                      <CustomTooltip title={'月数据'}>
                        <div className={styles.corner}></div>
                      </CustomTooltip>
                      <div className={styles.label_sty}>
                        <CustomTooltip title={item.name}>{item.name}</CustomTooltip>
                      </div>
                      <div className={styles.value_sty}>{item.value}</div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <></>
            )}
          </div> */}
        </div>
        {/* <div className={styles.expand_box} onClick={e => onExpand(e)}>
          {data.isExpand ? (
            <UpOutlined className={classNames(styles.nodeExpandIcon)} />
          ) : (
            <DownOutlined className={styles.nodeExpandIcon} />
          )}
        </div> */}
      </div>
    </div>
  );
};

export default FcsController;
