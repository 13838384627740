import React, { Key, useEffect, useState } from 'react';

import styles from './index.module.scss';

import Title from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Title';
import Footer from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Footer';
import { Button, Form, Input, InputNumber, Modal, Radio, Select } from '@maxtropy/components';
import {
  apiV2DispatchUetStructAntiBackflowDeletePost,
  apiV2DispatchUetStructAntiBackflowDetailPost,
  apiV2DispatchUetStructAntiBackflowEditPost,
  apiV2DispatchUetStructAntiBackflowSavePost,
  apiV2DispatchUetStructAntiBackflowUetStructRelatedDevicePost,
  apiV2DispatchUetStructPublishAntiBackflowDetailPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { useRequest } from 'ahooks';
import { isNil } from 'lodash-es';
import {
  DeviceType,
  DeviceTypeLabels,
  NodeType as NodeTypeClass,
} from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import { NodeType, TreeNode, allInfoProps } from '@/pages/SchedulingTopology/type';
import { findTopGridAccessPointData } from '../EnergyStorageStation/utils';

const { Item } = Form;

interface Props {
  id?: number;
  open?: boolean;
  dispatchGroupId?: number;
  isPreview?: boolean;
  isShowModal?: (open: boolean) => void;
  getFinishedValues?: (values: any) => void;
  shouldDeleteElement?: (state: boolean) => void;
  version?: number;
  allInfo?: allInfoProps;
  allNodeData?: TreeNode[];
}
const AntiBackflowDeviceModal: React.FC<Props> = ({
  id,
  dispatchGroupId,
  open,
  isPreview,
  isShowModal,
  getFinishedValues,
  shouldDeleteElement,
  version,
  allInfo,
  allNodeData,
}) => {
  const [form] = Form.useForm();

  const urlSearch = new URLSearchParams(window.location.search);
  const topologyId = urlSearch.get('topologyId'); // 结构id

  const [iconVisible, setIconVisible] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();
  const [gridAccessPointId, setGridAccessPointId] = useState<Key>(); // 电网接入点id

  const sw = Form.useWatch('sw', form);
  const deviceType = Form.useWatch('deviceType', form);

  const onShowIcon = () => {
    form.validateFields().then(() => {
      setIconVisible(true);
    });
  };

  const onCancel = () => {
    form.resetFields();
    setTitle(undefined);
    isShowModal?.(false);
  };

  const { data: deviceOptions } = useRequest(
    async () => {
      if (isNil(topologyId)) return;
      const res = await apiV2DispatchUetStructAntiBackflowUetStructRelatedDevicePost({
        deviceType: deviceType,
        uetStructId: +topologyId!,
        gridAccessPointId,
      });

      if (res.list) {
        return res.list.map(item => ({
          label: item.name,
          value: item.id,
        }));
      }
    },
    {
      ready: !!gridAccessPointId && !!topologyId && !!deviceType,
      refreshDeps: [gridAccessPointId, topologyId, deviceType],
    }
  );

  const { data: detail } = useRequest(
    async () => {
      if (isNil(id) || !open) return;
      setIsDetail(true);
      let detail;
      if (!isNil(version)) {
        detail = await apiV2DispatchUetStructPublishAntiBackflowDetailPost({ id, version });
      } else {
        detail = await apiV2DispatchUetStructAntiBackflowDetailPost({ id });
      }
      if (detail) {
        form.setFieldsValue({
          ...detail,
          deviceId: detail.deviceVo?.id,
        });
        setIconVisible(true);
        setTitle(detail.deviceVo?.name);
      }
      return detail;
    },
    {
      refreshDeps: [id, open, version],
    }
  );

  // 通过当前id与type查找电网接入点id
  useEffect(() => {
    if (detail) {
      if (allNodeData) {
        const gridAccessPointData = findTopGridAccessPointData(allNodeData, detail.id, NodeType.ANTI_BACKFLOW_DEVICE);
        setGridAccessPointId(gridAccessPointData?.id);
      }
    } else {
      if (allInfo) {
        const gridAccessPointData = findTopGridAccessPointData(
          allInfo.allNodeData,
          allInfo.node.getData().id,
          allInfo.node.getData().element
        );
        setGridAccessPointId(gridAccessPointData?.id);
      }
    }
  }, [allInfo, detail, allNodeData]);

  return (
    <>
      <Modal
        open={open}
        contentClassName="modal-form-content"
        title={
          <Title
            id={id}
            styles={styles}
            isDetail={isDetail}
            isPreview={isPreview}
            title={title ?? '防逆流装置'}
            delMethod={apiV2DispatchUetStructAntiBackflowDeletePost}
            onEdit={() => setIsDetail(false)}
            onCancel={onCancel}
            shouldDeleteElement={state => shouldDeleteElement?.(state)}
            nodeType={NodeTypeClass.SWITCH}
          />
        }
        footer={
          <Footer
            isDetail={isDetail}
            styles={styles}
            form={form}
            dispatchGroupId={dispatchGroupId}
            getFinishedValues={val => getFinishedValues?.(val)}
            createMethod={apiV2DispatchUetStructAntiBackflowSavePost}
            updateMethod={apiV2DispatchUetStructAntiBackflowEditPost}
            onCancel={onCancel}
          />
        }
        destroyOnClose
        onCancel={onCancel}
      >
        <div className={styles.modalWrapper}>
          <Form form={form} labelCol={{ span: 6 }} labelAlign="left">
            {/* <Item label="装置名称" name="deviceName" rules={[{ required: true, min: 2, max: 30 }]}>
              <Input placeholder="请输入" disabled={isDetail} allowClear />
            </Item> */}

            {/* <Item name="sw" initialValue={true} noStyle />

            <Checkbox
              style={{
                marginBottom: '10px',
              }}
              checked={sw}
              onChange={e => form.setFieldsValue({ sw: e.target.checked })}
            >
              开关
            </Checkbox> */}
            <Item
              name="deviceType"
              label="设备类型"
              initialValue={DeviceType.ELEC}
              rules={[{ required: true, message: '请选择设备类型' }]}
            >
              <Radio.Group disabled={isDetail} onChange={() => form.setFieldValue('deviceId', undefined)}>
                <Radio value={DeviceType.ELEC}>{DeviceTypeLabels[DeviceType.ELEC]}</Radio>
                <Radio value={DeviceType.ANTI}>{DeviceTypeLabels[DeviceType.ANTI]}</Radio>
              </Radio.Group>
            </Item>

            <Item name="deviceId" label="关联设备" rules={[{ required: true, message: '请选择关联设备' }]}>
              <Select options={deviceOptions} placeholder="请选择" disabled={isDetail} allowClear />
            </Item>

            <Item name="thresholdValue" label="防逆流阈值" rules={[{ required: true, message: '请输入防逆流阈值' }]}>
              <InputNumber style={{ width: '100%' }} addonAfter="kW" precision={0} min={0} disabled={isDetail} />
            </Item>

            {/* <Item
              name="durationOfBackflow"
              label="逆流持续时间"
              rules={[{ required: true, message: '请输入逆流持续时间' }]}
            >
              <InputNumber style={{ width: '100%' }} addonAfter="s" precision={4} min={0} disabled={isDetail} />
            </Item> */}

            <Item name="id" hidden />

            <div className={styles.preview}>
              <Button type="link" className={styles.previewBtn} onClick={onShowIcon}>
                查看图标
              </Button>

              <div className={styles.iconWrapper}>
                {iconVisible && (
                  <div className={styles.iconBox}>
                    <div className={styles.icon} />

                    <Item noStyle dependencies={['deviceId']}>
                      {({ getFieldValue }) => {
                        const deiviceIdTemp = getFieldValue('deviceId');
                        const deviceName = deviceOptions?.find(i => i.value === deiviceIdTemp)?.label;
                        return <div className={styles.iconText}>防逆流-{deviceName}</div>;
                      }}
                    </Item>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AntiBackflowDeviceModal;
