import Footer from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Footer';
import Title from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Title';

import React, { Key, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Button, Checkbox, Form, Modal, Select } from '@maxtropy/components';
// import ShowInput from '@/shared/components/ShowInput';
import {
  apiV2DispatchUetStructBackupPowerDeletePost,
  apiV2DispatchUetStructBackupPowerDetailPost,
  apiV2DispatchUetStructBackupPowerEditPost,
  apiV2DispatchUetStructBackupPowerSavePost,
  apiV2DispatchUetStructBackupPowerUetStructRelatedListPost,
  apiV2DispatchUetStructPublishBackupPowerDetailPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { useRequest } from 'ahooks';
import { isNil } from 'lodash-es';
import { BackUpPowerType, BackUpPowerTypeLabel } from './types';
import { NodeType as NodeTypeClass } from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import ShowInput from '@/components/ShowInput';
import { NodeType, TreeNode, allInfoProps } from '@/pages/SchedulingTopology/type';
import { findTopGridAccessPointData } from '../EnergyStorageStation/utils';

const { Item } = Form;

type Options = {
  value: number;
  label: string;
};

interface Props {
  id?: number;
  open?: boolean;
  dispatchGroupId?: number;
  isPreview?: boolean;
  isShowModal?: (open: boolean) => void;
  getFinishedValues?: (values: any) => void;
  shouldDeleteElement?: (state: boolean) => void;
  version?: number;
  allInfo?: allInfoProps;
  allNodeData?: TreeNode[];
}
const BackupPowerModal: React.FC<Props> = ({
  id,
  dispatchGroupId,
  open,
  isPreview,
  isShowModal,
  getFinishedValues,
  shouldDeleteElement,
  version,
  allInfo,
  allNodeData,
}) => {
  const [form] = Form.useForm();
  const backupPowerId = Form.useWatch('backupPowerId', form);

  const urlSearch = new URLSearchParams(window.location.search);
  const topologyId = urlSearch.get('topologyId'); // 结构id
  const [gridAccessPointId, setGridAccessPointId] = useState<Key>(); // 电网接入点id

  const [iconVisible, setIconVisible] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();
  const [controlLabel, setControlLabel] = useState<String>();
  const [deviceOption, setDeviceOption] = useState<Options[]>([]);
  const hasControlItem = Form.useWatch('hasControlItem', form);

  const onShowIcon = () => {
    form.validateFields().then(() => {
      setIconVisible(true);
    });
  };

  const onCancel = () => {
    form.resetFields();
    setTitle(undefined);
    isShowModal?.(false);
  };

  const { data: backupPowerList = [] } = useRequest(
    async () => {
      if (isNil(topologyId)) return;
      const res = await apiV2DispatchUetStructBackupPowerUetStructRelatedListPost({
        uetStructId: +topologyId!,
        gridAccessPointId,
      });
      return res.list;
    },
    { ready: !!gridAccessPointId && !!topologyId, refreshDeps: [gridAccessPointId, topologyId] }
  );

  const { data: detail } = useRequest(
    async () => {
      if (isNil(id) || !open) return;
      setIsDetail(true);
      let detail;
      if (!isNil(version)) {
        detail = await apiV2DispatchUetStructPublishBackupPowerDetailPost({ id, version });
      } else {
        detail = await apiV2DispatchUetStructBackupPowerDetailPost({ id });
      }
      if (detail) {
        setIconVisible(true);
        form.setFieldsValue({
          ...detail,
          backupPowerId: detail.backupPower?.id,
          deviceId: detail.backupPower?.relatedDeviceId,
        });
      }
      return detail;
    },
    {
      refreshDeps: [id, open, version],
    }
  );

  // 通过当前id与type查找电网接入点id
  useEffect(() => {
    if (detail) {
      if (allNodeData) {
        const gridAccessPointData = findTopGridAccessPointData(allNodeData, detail.id, NodeType.BACKUP_POWER);
        setGridAccessPointId(gridAccessPointData?.id);
      }
    } else {
      if (allInfo) {
        const gridAccessPointData = findTopGridAccessPointData(
          allInfo.allNodeData,
          allInfo.node.getData().id,
          allInfo.node.getData().element
        );
        setGridAccessPointId(gridAccessPointData?.id);
      }
    }
  }, [allInfo, detail, allNodeData]);

  useEffect(() => {
    if (backupPowerId) {
      const backupPower = backupPowerList.find(item => item.id === backupPowerId);
      setControlLabel(BackUpPowerTypeLabel[backupPower?.type as BackUpPowerType]);
      setDeviceOption([
        {
          value: backupPower?.relatedDeviceId!,
          label: backupPower?.relatedDeviceName!,
        },
      ]);
      form.setFieldsValue({
        circuitName: backupPower?.circuitName,
        deviceId: backupPower?.relatedDeviceId,
      });
    } else {
      form.setFieldsValue({
        circuitName: undefined,
        deviceId: undefined,
      });
    }
  }, [backupPowerId, backupPowerList, form]);

  return (
    <>
      <Modal
        open={open}
        contentClassName="modal-form-content"
        title={
          <Title
            id={id}
            styles={styles}
            isDetail={isDetail}
            isPreview={isPreview}
            title={title ?? '备用电源'}
            delMethod={apiV2DispatchUetStructBackupPowerDeletePost}
            onEdit={() => setIsDetail(false)}
            onCancel={onCancel}
            shouldDeleteElement={state => shouldDeleteElement?.(state)}
            nodeType={NodeTypeClass.SOURCE}
          />
        }
        footer={
          <Footer
            isDetail={isDetail}
            styles={styles}
            form={form}
            dispatchGroupId={dispatchGroupId}
            createMethod={apiV2DispatchUetStructBackupPowerSavePost}
            updateMethod={apiV2DispatchUetStructBackupPowerEditPost}
            getFinishedValues={val => getFinishedValues?.(val)}
            onCancel={onCancel}
          />
        }
        onCancel={onCancel}
        destroyOnClose
      >
        <div className={styles.modalWrapper}>
          <Form form={form} labelCol={{ span: 6 }} labelAlign="left">
            <Item name="backupPowerId" label="备用电源" rules={[{ required: true, message: '请选择备用电源' }]}>
              <Select
                placeholder="请选择"
                disabled={isDetail}
                allowClear
                options={backupPowerList?.map(item => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </Item>

            <Item name="circuitName" label="关联回路">
              <ShowInput />
            </Item>

            <Item name="hasControlItem" initialValue={false} noStyle />

            <Checkbox
              style={{
                marginBottom: 10,
              }}
              onChange={e => {
                form.setFieldsValue({
                  hasControlItem: e.target.checked,
                });
              }}
              checked={hasControlItem}
              disabled={isDetail}
            >
              添加控制对象
            </Checkbox>

            <Item name="id" hidden />

            {hasControlItem && (
              <Item name="deviceId" label={controlLabel}>
                <Select options={deviceOption} placeholder="请选择" disabled={isDetail} allowClear />
              </Item>
            )}

            <div className={styles.preview}>
              <Button type="link" className={styles.previewBtn} onClick={onShowIcon}>
                查看图标
              </Button>

              <div className={styles.iconWrapper}>
                {iconVisible && (
                  <>
                    <div className={styles.iconBox}>
                      <div className={styles.icon} />

                      <Item noStyle dependencies={['backupPowerId']}>
                        {({ getFieldValue }) => {
                          const id = getFieldValue('backupPowerId');
                          const name = backupPowerList.find(item => item.id === id)?.name;

                          return <div className={styles.iconText}>{name}</div>;
                        }}
                      </Item>

                      {/* {hasControlItem && (
                        <>
                          <div className={styles.line} />
                          <div className={styles.controlArea}>
                            <Item noStyle dependencies={['deviceId']}>
                              {({ getFieldValue }) => {
                                const id = getFieldValue('deviceId');
                                const name = deviceOption.find(item => item.value === id)?.label;
                                if (name) {
                                  return <div className={styles.controlItem}>{name}</div>;
                                }
                              }}
                            </Item>
                          </div>
                        </>
                      )} */}
                    </div>
                  </>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default BackupPowerModal;
