import { FC } from 'react';
import { Modal } from '@maxtropy/components';
import dayjs from 'dayjs';
import styles from './index.module.scss';

interface Props {
  dateList: string[];
  open: boolean;
  onClose: () => void;
}

const RelateDateModal: FC<Props> = props => {
  const { dateList, open, onClose } = props;

  let data: { month: string; dayList: string[] }[] = [];
  dateList.forEach(date => {
    const dateNumber = isNaN(+date) ? date : +date;
    const month = dayjs(dateNumber).format('YYYY年MM月');
    const found = data.find(i => i.month === month);
    if (found) {
      found.dayList.push(dayjs(dateNumber).format('YYYY-MM-DD'));
    } else {
      data.push({ month, dayList: [dayjs(dateNumber).format('YYYY-MM-DD')] });
    }
  });
  data.sort((a, b) => (dayjs(a.month).isAfter(dayjs(b.month)) ? 1 : -1));
  data.forEach(item => {
    item.dayList.sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1));
  });

  return (
    <Modal title="涉及日期" size="normal" bodyScroll={true} open={open} onCancel={onClose} onOk={onClose}>
      <div className={styles.relateDateModalContent}>
        {data.map(item => (
          <div key={item.month} className={styles.monthRow}>
            <div className={styles.monthLabel}>{item.month}：</div>
            <div className={styles.dateListWrap}>
              {item.dayList.map(day => (
                <div key={day} className={styles.dateItem}>
                  {day}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default RelateDateModal;
