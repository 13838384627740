import { isNil } from 'lodash-es';

export function goFullscreen(element: HTMLDivElement) {
  if (document.fullscreenElement) {
    return Promise.reject(new Error('全屏模式被禁用'));
  }
  let result = null;
  if (element.requestFullscreen) {
    result = element.requestFullscreen();
  }
  return result || Promise.reject(new Error('不支持全屏'));
}
export function cancelFullscreen() {
  document.exitFullscreen?.();
}

export const keepTwoNull = (data?: number) => {
  if (!isNil(data)) {
    return Number(data.toFixed(2));
  } else {
    return '-';
  }
};
