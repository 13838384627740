import React, { useMemo } from 'react';
import styles from './index.module.scss';
import { Checkbox } from 'antd';
import { LinkType } from '@/pages/EnergyDispatchManagement/ElementCreationModal/EnergyStorageStation';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { uniq } from 'lodash';

interface Options {
  label: string;
  value: number;
  type?: any;
}

interface Props {
  value?: any;
  disabled?: boolean;
  options?: Options[];
  onChange?: (val: any) => void;
}

const FcsCheckGroup: React.FC<Props> = ({ value, options, disabled, onChange }) => {
  // console.log('🦄 ~ value:', value);
  // const [masterControlList, slaveControlList, nonMasterSlaveControlList] = useMemo(() => {
  //   const master = options?.filter(opt => opt.type === LinkType.MASTER) || [];
  //   const fcs = options?.filter(opt => opt.type === LinkType.SLAVE) || [];
  //   const nonMasterSlaveControl = options?.filter(opt => opt.type === LinkType.NON_MASTER_SLAVE_CONTROL) || [];
  //   return [master, fcs, nonMasterSlaveControl];
  // }, [options]);

  const onGroupCheck = (val: CheckboxValueType[]) => {
    // const masterControlId = masterControlList?.map(item => item.value) ?? [];
    // const nonMasterSlaveControlId = nonMasterSlaveControlList?.map(item => item.value) ?? [];
    // onChange?.(uniq([...val, ...masterControlId, ...nonMasterSlaveControlId]));
    onChange?.(uniq([...val]));
  };

  return (
    <>
      {/* <div>{masterControlList && masterControlList.map(item => item.label).join(',')}</div>
      <div>{nonMasterSlaveControlList && nonMasterSlaveControlList.map(item => item.label).join(',')}</div> */}
      <Checkbox.Group
        options={options}
        disabled={disabled}
        className={styles.checkBox}
        value={value}
        onChange={onGroupCheck}
      />
    </>
  );
};

export default FcsCheckGroup;
