import { Node } from '@antv/x6';
import React, { FC, useMemo } from 'react';
import styles from './index.module.scss';
import energyStorage from '../../imgs/icon_energyStorage.gif';
import { Progress } from 'antd';
import { isNil } from 'lodash';

export interface BsaProps {
  node: Node;
}

const Bsa: FC<BsaProps> = props => {
  const { node } = props;

  const data = useMemo(() => {
    return node.getData();
  }, [node.getData()]);

  const strokeColor = {
    '0%': 'rgba(90,208,66,0.95)',
    '100%': 'rgba(71,255,102,0.95)',
  };

  return (
    <div className={styles.customBsaReactNode}>
      <div className={styles.cardSty}>
        <div className={styles.topContentSty}>
          <img style={{ width: 40, height: 40 }} src={energyStorage} alt="" />
          <div className={styles.itemControlobjContentSty}>储能柜</div>
        </div>

        <div className={styles.dividerSty} />

        <div className={styles.bottomContentSty}>
          <div className={styles.itemProp}>
            <div className={styles.itemPropName}>电压</div>
            <div className={styles.itemPropValue}>{data?.voltage.toFixed(2)}V</div>
          </div>
          <div className={styles.itemProp}>
            <div className={styles.itemPropName}>电流</div>
            <div className={styles.itemPropValue}>
              {!isNil(data?.current)
                ? Math.abs(data?.current).toFixed(2)
                : (Math.abs(data?.power / data?.voltage) * 1000).toFixed(2)}
              A
            </div>
          </div>
          <div className={styles.itemProp}>
            <div className={styles.itemPropName}>功率</div>
            <div
              className={styles.itemPropValue}
              style={{
                color: data?.power === 0 ? 'rgba(255, 255, 255, 0.85);' : data?.power > 0 ? '#57FB8B' : '#00ADFF',
              }}
            >
              {data?.power.toFixed(2)}kW
            </div>
          </div>
          <div className={styles.itemSocProp}>
            <div className={styles.itemSocNameValueProp}>
              <div className={styles.itemPropName}>SOC</div>
              <div className={styles.itemPropValue}>{Number(data?.socPercent.toFixed(2))}%</div>
            </div>
            <div className={styles.itemSocPercentSty}>
              <Progress
                percent={data?.socPercent}
                strokeColor={strokeColor}
                trailColor="#646464"
                size={[128, 4]}
                showInfo={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bsa;
