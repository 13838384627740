import React, { useState } from 'react';

import styles from './index.module.scss';
import Footer from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Footer';
import Title from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Title';
import {
  BusBarVoltageLevel,
  BusBarVoltageLevelLabel,
  NodeType,
  VoltageLevelLabel,
} from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import { useRequest } from 'ahooks';
import { isNil } from 'lodash-es';
import {
  apiV2DispatchUetStructBusbarCreatePost,
  apiV2DispatchUetStructBusbarDeletePost,
  apiV2DispatchUetStructBusbarListPost,
  apiV2DispatchUetStructBusbarUpdatePost,
  apiV2DispatchUetStructPublishBusbarDetailPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { Button, Form, Input, Modal, Select } from '@maxtropy/components';

const { Item } = Form;

interface Props {
  id?: number;
  open?: boolean;
  dispatchGroupId?: number;
  isPreview?: boolean;
  isShowModal?: (open: boolean) => void;
  getFinishedValues?: (values: any) => void;
  shouldDeleteElement?: (state: boolean) => void;
  version?: number;
}
const GeneratrixModal: React.FC<Props> = ({
  id,
  dispatchGroupId,
  open,
  isPreview,
  isShowModal,
  getFinishedValues,
  shouldDeleteElement,
  version,
}) => {
  const [form] = Form.useForm();

  const [iconVisible, setIconVisible] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();

  const onShowIcon = () => {
    form.validateFields().then(() => {
      setIconVisible(true);
    });
  };

  const onCancel = () => {
    form.resetFields();
    setTitle(undefined);
    isShowModal?.(false);
  };

  useRequest(
    async () => {
      if (isNil(id) || !open) return Promise.resolve(undefined);
      setIsDetail(true);
      let detail;
      if (!isNil(version)) {
        detail = await apiV2DispatchUetStructPublishBusbarDetailPost({ id, version });
      } else {
        detail = await apiV2DispatchUetStructBusbarListPost({ id });
      }
      if (detail) {
        setTitle(detail.name);
        setIconVisible(true);
        form.setFieldsValue(detail);
        setIconVisible(true);
      }
    },
    {
      refreshDeps: [id, open, version],
    }
  );

  return (
    <>
      <Modal
        open={open}
        contentClassName="modal-form-content"
        title={
          <Title
            id={id}
            styles={styles}
            isDetail={isDetail}
            isPreview={isPreview}
            title={title ?? '母线'}
            delMethod={apiV2DispatchUetStructBusbarDeletePost}
            onEdit={() => setIsDetail(false)}
            onCancel={onCancel}
            shouldDeleteElement={state => shouldDeleteElement?.(state)}
            nodeType={NodeType.DISTRIBUTION}
          />
        }
        footer={
          <Footer
            styles={styles}
            isDetail={isDetail}
            form={form}
            createMethod={apiV2DispatchUetStructBusbarCreatePost}
            updateMethod={apiV2DispatchUetStructBusbarUpdatePost}
            dispatchGroupId={dispatchGroupId}
            getFinishedValues={val => getFinishedValues?.(val)}
            onCancel={onCancel}
          />
        }
        onCancel={onCancel}
        destroyOnClose
      >
        <div className={styles.modalWrapper}>
          <Form form={form} labelCol={{ span: 6 }} labelAlign="left">
            <Item name="name" label="母线名称" rules={[{ required: true, min: 2, max: 30 }]}>
              <Input placeholder="请输入" disabled={isDetail} allowClear />
            </Item>

            <Item name="level" label="电压等级" rules={[{ required: true, message: '请选择电压等级' }]}>
              <Select
                placeholder="请输入"
                options={Object.entries(BusBarVoltageLevelLabel)
                  .map(([key, value]) => ({
                    label: value,
                    value: +key,
                  }))
                  .sort((a, b) => {
                    return (a.label.slice(0, -2) as unknown as number) - (b.label.slice(0, -2) as unknown as number);
                  })}
                disabled={isDetail}
                onBlur={() => setIconVisible(false)}
                allowClear
              />
            </Item>

            <Item name="id" hidden />

            <div className={styles.preview}>
              <Button type="link" className={styles.previewBtn} onClick={onShowIcon}>
                查看图标
              </Button>

              <div className={styles.iconWrapper}>
                {iconVisible && (
                  <div className={styles.iconBox}>
                    <div className={styles.icon} />
                    <div className={styles.iconText}>
                      <Item noStyle dependencies={['name', 'level']}>
                        {({ getFieldValue }) => (
                          <>
                            <div className={styles.generatrix}>{getFieldValue('name')}</div>
                            <div className={styles.voltageLevel}>
                              {BusBarVoltageLevelLabel[getFieldValue('level') as BusBarVoltageLevel]}
                            </div>
                          </>
                        )}
                      </Item>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default GeneratrixModal;
