import Home from '@/pages/Home';
import { RoutePathItem } from '@maxtropy/components';
import { Navigate } from 'react-router-dom';

const routes: RoutePathItem[] = [
  {
    path: '/',
    element: <Navigate to="/home" />,
  },
  {
    path: '/home',
    element: <Home />,
  },
];

export default routes;
