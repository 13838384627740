import { FC, useContext } from 'react';
import { Space } from 'antd';
import { Button, Modal, PopConfirm, Tooltip } from '@maxtropy/components';
import styles from './index.module.scss';
import {
  ActionTypeEnum,
  ActionTypeLabel,
  BsaStrategyCalendarContext,
  ConnectStatusEnum,
  OpChangeStatus,
} from './const';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import {
  apiV2BsaStrategyCalendarInfoPost,
  apiV2BsaStrategyDeliverPost,
  apiV2BsaStrategyStatusChangePost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import dayjs from 'dayjs';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { isNil } from 'lodash-es';

const TopActionBar: FC = () => {
  const { id } = useParams();
  const context = useContext(BsaStrategyCalendarContext);
  const {
    actionType,
    setActionType,
    selectedDateList,
    setSelectedDateList,
    getActionFormData,
    strategyCalendarData,
    setStrategyCalendarData,
    connectStatus,
    calendarShowDate,
  } = context;

  const hasStartStop = useHasPermission(PermissionsType.B_START_STOP_CHARGING_AND_DISCHARGING_STRATEGY);
  const hasPut = useHasPermission(PermissionsType.B_PUT_CHARGING_AND_DISCHARGING_STRATEGY);
  const hasDelete = useHasPermission(PermissionsType.B_DELETE_CHARGING_AND_DISCHARGING_STRATEGY);

  // 提交完成后，清空选中日期，重新请求选中月份的数据
  const afterConfirm = () => {
    setSelectedDateList?.([]);
    setActionType?.(ActionTypeEnum.VIEW);
    const day = dayjs(calendarShowDate);
    let newStrategyCalendarData = strategyCalendarData?.filter(item => !dayjs(item.time).isSame(day, 'month')) ?? [];
    apiV2BsaStrategyCalendarInfoPost({
      neoBsaId: id,
      year: day.year(),
      month: day.month() + 1,
    }).then(res => {
      setStrategyCalendarData?.([...(res.list ?? []), ...newStrategyCalendarData]);
    });
  };

  const onPut = () => {
    getActionFormData?.().then((values: any) => {
      if (isNil(values)) return;
      const modal = Modal.info({
        title: '正在投放策略...',
        footer: null,
        keyboard: false,
      });
      apiV2BsaStrategyDeliverPost({
        neoBsaId: id,
        times: selectedDateList?.map(item => dayjs(item).valueOf()),
        templateId: values.strategyId,
        actionType: OpChangeStatus.PUT,
        ...values.strategyData,
      })
        .then(() => {
          Modal.success({
            title: '投放策略成功！',
          });
          afterConfirm();
        })
        .finally(() => {
          modal.destroy();
        });
    });
  };

  const onCommonAction = () => {
    const actionMap = {
      [ActionTypeEnum.START]: OpChangeStatus.START,
      [ActionTypeEnum.STOP]: OpChangeStatus.STOP,
      [ActionTypeEnum.DELETE]: OpChangeStatus.DELETE,
    };
    getActionFormData?.().then(() => {
      const modal = Modal.info({
        title: `正在${ActionTypeLabel[actionType!]}策略...`,
        footer: null,
        keyboard: false,
      });
      apiV2BsaStrategyStatusChangePost({
        neoBsaId: id,
        action: actionMap[actionType as ActionTypeEnum.STOP | ActionTypeEnum.START | ActionTypeEnum.DELETE],
        strategyIdWithTimes: selectedDateList?.map(item => {
          return {
            time: dayjs(item).valueOf()?.toString(),
            strategyId: strategyCalendarData?.find(dataItem => {
              return dayjs(dataItem.time).isSame(item, 'day');
            })?.id,
          };
        }),
      })
        .then(() => {
          Modal.success({
            title: `${ActionTypeLabel[actionType!]}策略成功！`,
          });
          afterConfirm();
        })
        .finally(() => {
          modal.destroy();
        });
    });
  };

  return (
    <div className={styles.topActionBar}>
      <Space size={8}>
        {actionType === ActionTypeEnum.VIEW && (
          <>
            {hasPut && (
              <Button
                type="primary"
                disabled={connectStatus === ConnectStatusEnum.OFFLINE}
                onClick={() => {
                  setActionType?.(ActionTypeEnum.PUT);
                }}
              >
                投放
              </Button>
            )}

            {hasStartStop && (
              <Button
                type="primary"
                disabled={connectStatus === ConnectStatusEnum.OFFLINE}
                onClick={() => {
                  setActionType?.(ActionTypeEnum.START);
                }}
              >
                启动
              </Button>
            )}

            {hasStartStop && (
              <Button
                type="primary"
                disabled={connectStatus === ConnectStatusEnum.OFFLINE}
                onClick={() => {
                  setActionType?.(ActionTypeEnum.STOP);
                }}
              >
                停止
              </Button>
            )}

            {hasDelete && (
              <Button
                type="primary"
                disabled={connectStatus === ConnectStatusEnum.OFFLINE}
                onClick={() => {
                  setActionType?.(ActionTypeEnum.DELETE);
                }}
              >
                删除
              </Button>
            )}

            <Tooltip
              title={
                <>
                  <div>
                    1、投放：选择<span className={styles.primaryColor}>无策略的当日/未来日期</span>进行投放。
                  </div>
                  <div>
                    2、启动：选择<span className={styles.primaryColor}>已停止策略的当日/未来日期</span>进行启动。
                  </div>
                  <div>
                    3、停止：选择<span className={styles.primaryColor}>已启动策略的当日/未来日期</span>进行停止。
                  </div>
                  <div>
                    4、删除：选择<span className={styles.primaryColor}>已停止策略的当日/未来日期</span>进行删除。
                  </div>
                </>
              }
            >
              <InfoCircleOutlined className={styles.tip} />
            </Tooltip>
          </>
        )}

        {actionType === ActionTypeEnum.PUT && (
          <PopConfirm title="你确定要投放吗？" onConfirm={onPut}>
            <Button type="primary">确认投放</Button>
          </PopConfirm>
        )}

        {actionType === ActionTypeEnum.START && (
          <PopConfirm title="确定启动策略？" onConfirm={onCommonAction}>
            <Button type="primary">确认启动</Button>
          </PopConfirm>
        )}

        {actionType === ActionTypeEnum.STOP && (
          <PopConfirm title="确定停止策略？" onConfirm={onCommonAction}>
            <Button type="primary">确认停止</Button>
          </PopConfirm>
        )}

        {actionType === ActionTypeEnum.DELETE && (
          <PopConfirm title="确定删除策略？" onConfirm={onCommonAction}>
            <Button type="primary">确认删除</Button>
          </PopConfirm>
        )}

        {actionType !== ActionTypeEnum.VIEW && (
          <Button
            onClick={() => {
              setActionType?.(ActionTypeEnum.VIEW);
              setSelectedDateList?.([]);
            }}
          >
            取消
          </Button>
        )}
      </Space>
    </div>
  );
};

export default TopActionBar;
