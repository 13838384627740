import Title from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Title';
import React, { Key, useState, useEffect, useMemo, useCallback } from 'react';
import styles from './index.module.scss';
import Footer from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Footer';
import { useRequest } from 'ahooks';
import { isNil } from 'lodash-es';
import {
  apiV2DispatchUetStructMeterBsaOptionalItemPost,
  apiV2DispatchUetStructMeterCreatePost,
  apiV2DispatchUetStructMeterDeletePost,
  apiV2DispatchUetStructMeterListPost,
  apiV2DispatchUetStructMeterMonitorObjectByMeterTypePost,
  apiV2DispatchUetStructMeterUpdatePost,
  apiV2DispatchUetStructPublishMeterDetailPost,
  V2DispatchUetStructDevicePagePostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { Button, Form, Modal, Radio, Select } from '@maxtropy/components';
import { ElectricityType, ElectricityTypeLabel } from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import ShowInput from '@/components/ShowInput';
import AddMetersModal from './AddMetersModal';
import { isEmpty } from 'lodash-es';
import {
  allInfoProps,
  NodeType,
  NodeTypeClassDisplay,
  NodeTypeDisplay,
  TreeNode,
} from '@/pages/SchedulingTopology/type';
import { NodeType as NodeTypeClass } from '../types';

import bsaMeter from './imgs/bsaMeter.png';
import bsaMeterDash from './imgs/bsaMeterDash.png';
import loadMeter from './imgs/loadMeter.png';
import loadMeterDash from './imgs/loadMeterDash.png';
import soureMeter from './imgs/soureMeter.png';
import soureMeterDash from './imgs/soureMeterDash.png';
import transformerMeter from './imgs/transformerMeter.png';
import transformerMeterDash from './imgs/transformerMeterDash.png';
import { findMonitorPId, formatBsaUnitData } from '@/pages/SchedulingTopology/utils/utils';
import classNames from 'classnames/bind';
import { findTopGridAccessPointData } from '../EnergyStorageStation/utils';
import { Cascader } from 'antd';

enum MeterType {
  incomingMainElectricityMeter = 1,
  productionLoadMeter,
  photovoltaicPowerMeter,
  energyStorageMeter,
  transformerMonitoringMeter,
}

const MeterTypeLabel = {
  [MeterType.incomingMainElectricityMeter]: '进线总电表',
  [MeterType.productionLoadMeter]: '生产负载电表',
  [MeterType.photovoltaicPowerMeter]: '光伏计量电表',
  [MeterType.energyStorageMeter]: '储能计量电表',
  [MeterType.transformerMonitoringMeter]: '变压器监测电表',
};

const { Item } = Form;

const cx = classNames.bind(styles);

const filterOption = (
  input: string,
  option?: {
    label: string | undefined;
    value: number | undefined;
  }
) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

interface Props {
  id?: number;
  open?: boolean;
  dispatchGroupId?: number;
  isPreview?: boolean;
  isShowModal?: (open: boolean) => void;
  getFinishedValues?: (values: any) => void;
  shouldDeleteElement?: (state: boolean) => void;
  type?: NodeType;
  version?: number;
  allInfo?: allInfoProps;
  allNodeData?: TreeNode[];
}
const MeteringMeterModal: React.FC<Props> = ({
  id,
  dispatchGroupId,
  open,
  isPreview,
  isShowModal,
  getFinishedValues,
  shouldDeleteElement,
  type,
  version,
  allInfo,
  allNodeData,
}) => {
  const [form] = Form.useForm();

  const urlParams = new URLSearchParams(window.location.search);
  const topologyId = urlParams.get('topologyId');

  const [iconVisible, setIconVisible] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();

  const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
  const [gridAccessPointId, setGridAccessPointId] = useState<Key>(); // 电网接入点id
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectRows, setSelectRows] = useState<V2DispatchUetStructDevicePagePostResponse['list']>([]);

  const handleShowIcon = () => {
    form.validateFields().then(() => {
      setIconVisible(true);
    });
  };

  const onCancel = () => {
    form.resetFields();
    setTitle(undefined);
    isShowModal?.(false);
  };

  useEffect(() => {
    if (isEmpty(selectRows)) return;
    const { code, name, id } = selectRows![0];
    form.setFieldsValue({
      code,
      deviceName: name,
      deviceId: id,
      // edgeGatewayId: id,
      // serialNumber,
      // systemType: SystemTypeLabel[systemType as SystemType],
    });
  }, [form, selectRows]);

  const { data: detail } = useRequest(
    async () => {
      if (isNil(id) || !open) return Promise.resolve(undefined);
      setIsDetail(true);
      let detail;
      if (!isNil(version)) {
        detail = await apiV2DispatchUetStructPublishMeterDetailPost({ id, version });
      } else {
        detail = await apiV2DispatchUetStructMeterListPost({ id });
      }

      if (detail) {
        setTitle(detail.deviceName);
        setSelectedRowKeys([detail.deviceId!]);
        form.setFieldsValue({
          ...detail,
          monitorId: detail.monitorObject?.id,
        });
        setIconVisible(true);
      }
      return detail;
    },
    {
      refreshDeps: [id, open, version],
    }
  );

  // 通过当前id与type查找电网接入点id
  useEffect(() => {
    if (detail) {
      if (allNodeData) {
        const gridAccessPointData = findTopGridAccessPointData(allNodeData, detail.id, type);
        setGridAccessPointId(gridAccessPointData?.id);
      }
    } else {
      if (allInfo) {
        const gridAccessPointData = findTopGridAccessPointData(
          allInfo.allNodeData,
          allInfo.node.getData().id,
          allInfo.node.getData().element
        );
        setGridAccessPointId(gridAccessPointData?.id);
      }
    }
  }, [allInfo, detail, allNodeData]);

  // 监测对象（非储能并网点）
  const { data: monitorList } = useRequest(
    async () => {
      return apiV2DispatchUetStructMeterMonitorObjectByMeterTypePost({
        meterType: type!,
        dispatchStructId: Number(topologyId),
      }).then(res => {
        if (detail) {
          form.setFieldsValue({
            monitorId: detail.monitorObject?.id,
          });
        }
        return res;
      });
    },
    {
      ready: !!type && !!topologyId && open && type !== NodeType.BSA_GRID_CONNECTION_BILLING_POINT,
      refreshDeps: [topologyId, open, type],
    }
  );

  // 监测对象（储能并网点）
  const { data: bsaMonitorList } = useRequest(
    async () => {
      return apiV2DispatchUetStructMeterBsaOptionalItemPost({ id: Number(topologyId) }).then(res => {
        if (detail) {
          form.setFieldsValue({
            monitorId: [findMonitorPId(res.list, detail.monitorObject?.id!), detail.monitorObject?.id],
          });
        }
        return res;
      });
    },
    {
      ready: !!type && !!topologyId && open && type === NodeType.BSA_GRID_CONNECTION_BILLING_POINT,
      refreshDeps: [topologyId, open, type, detail],
    }
  );

  const meterType = useMemo(() => {
    if (type === NodeType.GRID_ACCESS_BILLING_POINT || type === NodeType.PVA_BILLING_POINT) {
      return NodeTypeClass.SOURCE;
    } else if (type === NodeType.ANTI_OVERCAPACITY_POINT) {
      return NodeTypeClass.TRANSFORMER;
    } else if (type === NodeType.BSA_BILLING_POINT || type === NodeType.BSA_GRID_CONNECTION_BILLING_POINT) {
      return NodeTypeClass.STORAGE;
    } else if (type === NodeType.CHARGING_STATION_BILLING_POINT || type === NodeType.LOAD_BILLING_POINT) {
      return NodeTypeClass.LOAD;
    }
  }, [type]);

  const renderImg = useCallback(
    (ammeterType: ElectricityType) => {
      if (meterType === NodeTypeClass.SOURCE) {
        if (ammeterType === ElectricityType.REAL) {
          return soureMeter;
        } else {
          return soureMeterDash;
        }
      } else if (meterType === NodeTypeClass.TRANSFORMER) {
        if (ammeterType === ElectricityType.REAL) {
          return transformerMeter;
        } else {
          return transformerMeterDash;
        }
      } else if (meterType === NodeTypeClass.STORAGE) {
        if (ammeterType === ElectricityType.REAL) {
          return bsaMeter;
        } else {
          return bsaMeterDash;
        }
      } else if (meterType === NodeTypeClass.LOAD) {
        if (ammeterType === ElectricityType.REAL) {
          return loadMeter;
        } else {
          return loadMeterDash;
        }
      }
    },
    [meterType]
  );

  const borderLeftColor = useMemo(() => {
    if (meterType === NodeTypeClass.TRANSFORMER) {
      return '#00ADFF';
    } else if (meterType === NodeTypeClass.SOURCE) {
      return '#16DD8E';
    } else if (meterType === NodeTypeClass.LOAD) {
      return '#ABD335';
    } else if (meterType === NodeTypeClass.STORAGE) {
      return '#CE90D1';
    }
  }, [meterType]);

  // const belongNodeTypeClasss = useMemo(() => {
  //   if (type === NodeType.ANTI_OVERCAPACITY_POINT) {
  //     return '防超容点';
  //   } else if (type === NodeType.BSA_GRID_CONNECTION_BILLING_POINT) {
  //     return '储能并网点';
  //   } else {
  //     return '计费点';
  //   }
  // }, [type]);

  return (
    <>
      <Modal
        open={open}
        contentClassName="modal-form-content"
        title={
          <Title
            styles={styles}
            delMethod={apiV2DispatchUetStructMeterDeletePost}
            isDetail={isDetail}
            isPreview={isPreview}
            // title={title ?? '计费点/超容点'}
            title={NodeTypeDisplay[type as NodeType]}
            onEdit={() => setIsDetail(false)}
            onCancel={onCancel}
            shouldDeleteElement={state => shouldDeleteElement?.(state)}
            nodeType={meterType}
          />
        }
        footer={
          <Footer
            isDetail={isDetail}
            styles={styles}
            form={form}
            dispatchGroupId={dispatchGroupId}
            createMethod={apiV2DispatchUetStructMeterCreatePost}
            updateMethod={apiV2DispatchUetStructMeterUpdatePost}
            getFinishedValues={val => getFinishedValues?.(val)}
            onCancel={onCancel}
            meterType={type}
          />
        }
        onCancel={onCancel}
        destroyOnClose
      >
        <div className={styles.modalWrapper}>
          <Form form={form} labelCol={{ span: 6 }} labelAlign="left">
            <Item
              label="电表编号"
              name="code"
              rules={[{ required: true, message: '请选择电表' }]}
              // extra={
              //   <Button
              //     type="link"
              //     onClick={e => {
              //       e.preventDefault();
              //       setAddModalVisible(true);
              //     }}
              //     className={styles.customBtn}
              //     disabled={isDetail}
              //   >
              //     电表选择
              //   </Button>
              // }
            >
              <span
                className={cx('select_btn', isDetail ? 'disable_btn' : '')}
                onClick={() => {
                  if (isDetail) return;
                  setAddModalVisible(true);
                }}
              >
                {selectRows?.[0]?.code ?? detail?.code ?? '电表选择'}
              </span>
            </Item>

            <Item label="电表名称" name="deviceName">
              <ShowInput />
            </Item>

            {type === NodeType.BSA_GRID_CONNECTION_BILLING_POINT ? (
              <Item name="monitorId" label="监测对象" rules={[{ required: true, message: '请选择监测对象' }]}>
                <Cascader
                  placeholder="请选择"
                  options={formatBsaUnitData(bsaMonitorList?.list)}
                  allowClear={false}
                  fieldNames={{ value: 'key' }}
                  disabled={isDetail}
                />
              </Item>
            ) : (
              // rules={[{ required: true, message: '请选择监测对象' }]}
              <Item name="monitorId" label="监测对象" rules={[{ required: true, message: '请选择监测对象' }]}>
                <Select
                  placeholder="请选择"
                  options={(monitorList?.list ?? []).map(item => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  disabled={isDetail}
                  allowClear
                />
              </Item>
            )}

            <Item
              label="电表类型"
              name="ammeterType"
              rules={[{ required: true, message: '请选择电表类型' }]}
              extra="真实电表为实际配置的电表，虚拟电表为根据所选关联设备"
            >
              <Radio.Group disabled={isDetail}>
                <Radio value={ElectricityType.REAL}>{ElectricityTypeLabel[ElectricityType.REAL]}</Radio>
                <Radio value={ElectricityType.VIRTUAL}>{ElectricityTypeLabel[ElectricityType.VIRTUAL]}</Radio>
              </Radio.Group>
            </Item>

            <Item name="deviceId" hidden />
            <Item name="id" hidden />

            <div className={styles.preview}>
              <Button type="link" className={styles.previewBtn} onClick={handleShowIcon}>
                查看图标
              </Button>

              <div className={styles.iconWrapper}>
                {iconVisible && (
                  <div className={styles.iconBoxMeters} style={{ borderLeft: `3px solid ${borderLeftColor}` }}>
                    <Item noStyle dependencies={['ammeterType']}>
                      {({ getFieldValue }) => {
                        const ammeterType = getFieldValue('ammeterType');
                        return (
                          <div
                            className={styles.icon}
                            style={{
                              backgroundImage: `url(${renderImg(ammeterType)})`,
                            }}
                          />
                        );
                      }}
                    </Item>

                    <Item noStyle dependencies={['deviceName']}>
                      {({ getFieldValue }) => (
                        <div className={styles.iconText}>
                          {/* {belongNodeTypeClasss}--{getFieldValue('deviceName')} */}
                          {getFieldValue('deviceName')}({NodeTypeDisplay[type as NodeType]})
                        </div>
                      )}
                    </Item>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Modal>

      <AddMetersModal
        topologyId={topologyId}
        gridAccessPointId={gridAccessPointId}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={(rowKeys: Key[]) => setSelectedRowKeys(rowKeys)}
        setSelectRows={setSelectRows}
        onCancel={() => setAddModalVisible(false)}
        open={addModalVisible}
      />
    </>
  );
};

export default MeteringMeterModal;
