import styles from './index.module.scss';
import Meter from '../../imgs/electricityMeter1.gif';

const NoDataBillingPoint = () => {
  return (
    <div className={styles.customNoDataBillingPointNode}>
      <div className={styles.vline}></div>
      <div className={styles.hline}></div>
      <div
        className={styles.cardMeterSty}
        style={{
          borderLeft: `3px solid #16DD8E`,
        }}
      >
        <img style={{ width: 24, height: 24, marginLeft: 12 }} src={Meter} alt="" />

        <div style={{ marginLeft: 12 }} className={styles.itemControlobjContentSty}>
          计费点-1M
        </div>
      </div>
    </div>
  );
};

export default NoDataBillingPoint;
