import React, { FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Space } from 'antd';
import { Button, Modal, Tag, Tooltip } from '@maxtropy/components';
import {
  apiV2BsaDetailBsaV2Post,
  apiV2BsaUpdateControlConfigPost,
  V2BsaDetailBsaV2PostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import bsaIcon from '@/assets/images/bsaIcon.png';
import styles from './index.module.scss';
import { FileSearchOutlined, InfoCircleOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import { BsaStrategyCalendarContext, ConnectStatusEnum } from './const';

const TopCard: FC = () => {
  const { id } = useParams();
  const [bsaData, setBsaData] = useState<V2BsaDetailBsaV2PostResponse>();

  const context = useContext(BsaStrategyCalendarContext);
  const { connectStatus, update, setUpdate } = context;

  useEffect(() => {
    apiV2BsaDetailBsaV2Post({
      id: Number(id),
    }).then(res => {
      setBsaData(res);
    });
  }, [id, update]);

  const updateControlBtn = () => {
    const modal = Modal.info({
      title: '正在下发配置...',
      footer: null,
      keyboard: false,
    });
    apiV2BsaUpdateControlConfigPost({
      id: Number(id),
    })
      .then(() => {
        setUpdate?.();
        Modal.success({
          title: '配置下发成功！',
        });
      })
      .finally(() => {
        modal.destroy();
      });
  };

  return (
    <div className={styles.topCard}>
      <Space split={<div className={styles.divider} />}>
        <div className={styles.itemWrap}>
          <img src={bsaIcon} alt="" className={styles.basIcon} />
          <div>{bsaData?.name || '--'}</div>
        </div>
        <div>
          <span className={styles.label}>软件版本：</span>
          <span className={styles.value}>{bsaData?.softwareVersion || '--'}</span>
        </div>
        <div>
          <span className={styles.label}>策略版本：</span>
          <span className={styles.value}>{bsaData?.strategyVersion || '--'}</span>
        </div>
        <div>
          <span className={styles.label}>EMS连接状态：</span>
          {connectStatus === ConnectStatusEnum.ONLINE ? (
            <Tag color="success" border="solid">
              在线
            </Tag>
          ) : (
            <>
              <Tag color="invalid" border="solid">
                离线
              </Tag>
              <Tooltip title="EMS连接状态异常，无法进行充放电计划">
                <InfoCircleOutlined className={styles.warningColor} />
              </Tooltip>
            </>
          )}
        </div>
        <div>
          <span className={styles.label}>控制配置：</span>
          <Button
            type="link"
            onClick={() => {
              window.open('/energy-storage/basic/bsa/viewControlConfig/' + id);
            }}
          >
            <FileSearchOutlined />
            查看
          </Button>
          {bsaData?.needUpdate ? (
            <>
              <Button type="link" onClick={updateControlBtn} disabled={connectStatus !== ConnectStatusEnum.ONLINE}>
                <VerticalAlignBottomOutlined />
                点击下发
              </Button>
              <Tooltip title="当前控制配置未同步过，请点击下发按钮，下发控制配置">
                <InfoCircleOutlined className={styles.warningColor} />
              </Tooltip>
            </>
          ) : (
            <Button type="link" className={styles.successColor}>
              已下发
            </Button>
          )}
        </div>
      </Space>
    </div>
  );
};

export default TopCard;
