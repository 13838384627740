import React, { FC } from 'react';
import { V2BsaControlConfigPostResponse } from '@maxtropy/intelligent-dispath-apis-v2';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';

type Props = Exclude<V2BsaControlConfigPostResponse['antiOverCapacityMeters'], undefined>[number];

const TransformerCard: FC<Props> = props => {
  const { name, overcapacityThreshold, id } = props;

  return (
    <div className={styles.transformerCard}>
      <div className={styles.name}>
        {id ? (
          name
        ) : (
          <>
            <InfoCircleOutlined className={styles.infoIcon}></InfoCircleOutlined>
            <span className={styles.errorColor}>uedt未关联变压器电表</span>
          </>
        )}
      </div>
      <div className={styles.valueLabel}>防超容阈值</div>
      <div className={styles.value}>
        <span>{overcapacityThreshold}</span>
        <span className={styles.unit}>kW</span>
      </div>
    </div>
  );
};

export default TransformerCard;
