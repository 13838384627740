import { FC, useContext } from 'react';
import { Calendar } from 'antd';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { BsaStrategyCalendarContext, MONTH_FORMAT } from '../const';
import DateCell from './DateCell';
import CalendarHeader from './CalendarHeader';

const LeftCalendarComponent: FC = () => {
  const context = useContext(BsaStrategyCalendarContext);
  const { calendarShowDate, setCalendarShowDate } = context;

  return (
    <div className={styles.contentWrap}>
      <Calendar
        className={styles.calendar}
        onPanelChange={v => {
          setCalendarShowDate?.(v.format(MONTH_FORMAT));
        }}
        value={dayjs(calendarShowDate)}
        headerRender={({ value, onChange }) => {
          return <CalendarHeader value={value} onChange={onChange}></CalendarHeader>;
        }}
        fullCellRender={v => <DateCell date={v}></DateCell>}
      ></Calendar>
    </div>
  );
};

export default LeftCalendarComponent;
