import { Radio, Tooltip } from '@maxtropy/components';
import { Space } from 'antd';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
import React, { useMemo, useState } from 'react';
import {
  ActionType,
  ActionTypeColor,
  ActionTypeTime,
  ActionTypeTypeDisplay,
  PricedisplayType,
} from '@/pages/StrategyTemplate/type';
import { getItemTime, getPeriodTime, getPeriodTypes } from '@/pages/StrategyTemplate/utils';
import { InfoCircleFilled } from '@ant-design/icons';

const cx = classnames.bind(styles);

interface ActionTypeTimePeakItemProps {
  value?: ActionTypeTime[];
  onChange?: (times: ActionTypeTime[]) => void;
  displayType?: PricedisplayType;
  disabled?: boolean;
}

const PeakItem: React.FC<ActionTypeTimePeakItemProps> = ({ value = [], onChange, displayType, disabled = false }) => {
  const [actionType, setActionType] = useState<ActionType>();
  const [range, setRange] = useState<[number | undefined, number | undefined]>([undefined, undefined]);
  const [start, end] = range;

  const onItemMouseLeave = () => {
    setRange([undefined, undefined]);
  };

  const count = useMemo(() => {
    if (displayType === undefined) return 0;
    return displayType === PricedisplayType.HALF ? 48 : 24;
  }, [displayType]);

  const getItemColor = (index: number) => {
    const [tempStart, tempEnd] = [start, end].sort((a, b) => (a || 0) - (b || 0));
    if (tempStart !== undefined && index >= tempStart && tempEnd !== undefined && index <= tempEnd) {
      return `${ActionTypeColor[actionType!]}`;
    }
    const time = value.find(time => time.intervalStart <= index && time.intervalEnd >= index);
    if (time) {
      return ActionTypeColor[time.type];
    }
    return 'rgba(var(--base-text-color),0.2)';
  };

  const onItemClick = (index: number) => {
    if (actionType === undefined) {
      return;
    }
    if (displayType === undefined) {
      return;
    }
    if (start === undefined) {
      setRange([index, index]);
      return;
    }

    const [tempStart, tempEnd] = [start, index].sort((a, b) => a - b);
    onChange?.(
      getPeriodTypes(
        [
          ...value,
          {
            type: actionType!,
            intervalStart: tempStart,
            intervalEnd: tempEnd,
          },
        ],
        displayType
      ).filter((time: ActionTypeTime) => time.type !== undefined)
    );

    setRange([undefined, undefined]);
  };

  const onItemMouseEnter = (index: number) => {
    if (actionType === undefined) {
      return;
    }
    if (start === undefined) {
      return;
    }
    setRange([start, index]);
  };

  return (
    <>
      {!disabled ? (
        <Space direction="vertical">
          <Radio.Group
            value={actionType}
            onChange={e => {
              setActionType(e.target.value);
            }}
          >
            <Radio value={ActionType.Charge}>
              <div className={cx('radio-wrap')}>
                {ActionTypeTypeDisplay[ActionType.Charge]}
                <span style={{ backgroundColor: ActionTypeColor[ActionType.Charge] }} className={cx('tip-bar')} />
              </div>
            </Radio>
            <Radio value={ActionType.DisCharge}>
              <div className={cx('radio-wrap')}>
                {ActionTypeTypeDisplay[ActionType.DisCharge]}
                <span style={{ backgroundColor: ActionTypeColor[ActionType.DisCharge] }} className={cx('tip-bar')} />
              </div>
            </Radio>
            <Radio value={ActionType.StandBy}>
              <div className={cx('radio-wrap')}>
                {ActionTypeTypeDisplay[ActionType.StandBy]}
                <span style={{ backgroundColor: ActionTypeColor[ActionType.StandBy] }} className={cx('tip-bar')} />
              </div>
            </Radio>
          </Radio.Group>
          <Space>
            <span className={styles.infoSty}>
              先选中充放电动作（充电、放电、待机），再选择时间轴上的起止时间块，直到无灰色时间块即可完成配置
            </span>
          </Space>
          <div className={cx('peak-item')} onMouseLeave={() => onItemMouseLeave()}>
            {[...new Array(count)].map((_, index) => (
              <Tooltip
                title={`${getItemTime(index, displayType!)}~${getItemTime(index + 1, displayType!)}`}
                key={index}
              >
                <span
                  className={cx(
                    'bar',
                    displayType === PricedisplayType.HALF ? 'half' : '',
                    displayType === PricedisplayType.HOUR ? 'hour' : ''
                  )}
                  style={{ background: getItemColor(index) }}
                  onClick={() => onItemClick(index)}
                  onMouseEnter={() => onItemMouseEnter(index)}
                />
              </Tooltip>
            ))}
          </div>
        </Space>
      ) : (
        <>
          <Radio.Group disabled style={{ marginBottom: 8 }}>
            <Radio value={ActionType.Charge}>
              <div className={cx('radio-wrap')}>
                {ActionTypeTypeDisplay[ActionType.Charge]}
                <span style={{ backgroundColor: ActionTypeColor[ActionType.Charge] }} className={cx('tip-bar')} />
              </div>
            </Radio>
            <Radio value={ActionType.DisCharge}>
              <div className={cx('radio-wrap')}>
                {ActionTypeTypeDisplay[ActionType.DisCharge]}
                <span style={{ backgroundColor: ActionTypeColor[ActionType.DisCharge] }} className={cx('tip-bar')} />
              </div>
            </Radio>
            <Radio value={ActionType.StandBy}>
              <div className={cx('radio-wrap')}>
                {ActionTypeTypeDisplay[ActionType.StandBy]}
                <span style={{ backgroundColor: ActionTypeColor[ActionType.StandBy] }} className={cx('tip-bar')} />
              </div>
            </Radio>
          </Radio.Group>
          <br />
          {getPeriodTypes(value, displayType!).map((item, index) => {
            if (item.type) {
              return (
                <Tooltip title={getPeriodTime(item, displayType!)} key={index}>
                  {[...new Array(item.intervalEnd - item.intervalStart + 1)].map((_, i) => (
                    <span
                      key={i}
                      className={cx(
                        'bar',
                        displayType === PricedisplayType.HALF ? 'half' : '',
                        displayType === PricedisplayType.HOUR ? 'hour' : ''
                      )}
                      style={{
                        background: ActionTypeColor[item.type],
                      }}
                    />
                  ))}
                </Tooltip>
              );
            }
            return [...new Array(item.intervalEnd - item.intervalStart + 1)].map((_, i) => (
              <span key={i} className={cx('bar')} style={{ background: 'rgba(255,255,255,0.2)' }} />
            ));
          })}
        </>
      )}
    </>
  );
};

export default PeakItem;
