import React, { FC, useEffect } from 'react';
import { isNil } from 'lodash-es';
import styles from './index.module.scss';
import { Button, Form, getRealUrl, ShowInput } from '@maxtropy/components';
import {
  LinkLeftOrRightStatus,
  LinkLeftOrRightStatusDisplay,
  LinkStatus,
  LinkStatusDisplay,
  NodeType as NodeTypeType,
} from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import TitleTag from '../TitleTag';
import { Descriptions, Drawer, Spin } from 'antd';
import { useRequest } from 'ahooks';
import { apiV2DispatchUetStructOtherVerticalPublishGetPost } from '@maxtropy/intelligent-dispath-apis-v2';
import { iconArr } from '@/pages/SchedulingTopology/utils/utils';
import { NodeType, NodeTypeDisplay } from '@/pages/SchedulingTopology/type';
import { Graph } from '@antv/x6';

export interface OtherVerticalProps {
  isShowModal: (open: boolean) => void;
  open: boolean;
  id?: number;
  version?: number;
  graph?: Graph;
}

const { Item } = Form;

const OtherVerticalDrawer: FC<OtherVerticalProps> = props => {
  const { isShowModal, open, id, version, graph } = props;
  const [form] = Form.useForm();
  const associationType = Form.useWatch('associationType', form);

  const { data, loading } = useRequest(
    async () => {
      const detail = await apiV2DispatchUetStructOtherVerticalPublishGetPost({ id: id!, version });
      form.setFieldsValue({
        ...detail,
        NodeTypeName: '其他样式-竖向',
        icon: detail.icon
          ? iconArr
              .concat({
                label: '自定义',
                value: 'CUSTOM',
                img: '',
              })
              .find(i => i.value === detail.icon)?.label
          : '--',
        file: detail.file ?? undefined,
        associationTypeName: !isNil(detail.associationType)
          ? LinkStatusDisplay[detail.associationType as LinkStatus]
          : '--',
        associationId: detail.associationId + '-' + NodeTypeDisplay[detail.associationElement as NodeType],
        associationNode: !isNil(detail.associationNode)
          ? LinkLeftOrRightStatusDisplay[detail.associationNode as LinkLeftOrRightStatus]
          : '--',
      });
      return detail;
    },
    {
      ready: !isNil(id) && !isNil(version) && open,
      refreshDeps: [id, open, version],
    }
  );

  useEffect(() => {
    if (graph && data) {
      const findLinkNodeInfo = graph
        .getNodes()
        .find(i => i.getData()?.id + '-' + i.getData()?.element === data.associationId + '-' + data.associationElement);
      form.setFieldValue(
        'associationId',
        data.associationId +
          '-' +
          findLinkNodeInfo?.getData()?.value?.name +
          `(${NodeTypeDisplay[data.associationElement as NodeType]})`
      );
    }
  }, [graph, data]);

  return (
    <div className={styles.drawerStyle}>
      <Drawer
        title={<TitleTag title={data?.name ?? '其他样式-竖向'} nodeType={NodeTypeType.OTHER} />}
        placement="right"
        width={400}
        mask={false}
        onClose={() => {
          isShowModal?.(false);
        }}
        open={open}
        getContainer={false}
      >
        <Spin spinning={loading}>
          <Form form={form} labelCol={{ span: 8 }} labelAlign="left">
            <Descriptions labelStyle={{ width: '40%' }} title={null} bordered column={1} size="small">
              <Descriptions.Item label="元素类型">
                <Item name="NodeTypeName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="节点名称">
                <Item name="name" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="设备编号">
                <Item name="code" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="设备名称">
                <Item name="deviceName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="图标选择">
                <Item name="icon" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>

              <Descriptions.Item label="图标">
                <img
                  className={styles.img_sty}
                  src={data?.file ? getRealUrl(data?.file) : iconArr.find(i => i.value === data?.icon)?.img}
                  alt=""
                />
              </Descriptions.Item>
              <Descriptions.Item label="关联情况">
                <Item name="associationTypeName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              {associationType === LinkStatus.BusBar && (
                <Descriptions.Item label="关联母线">
                  <Item name="associationId" style={{ marginBottom: 0 }}>
                    <ShowInput />
                  </Item>
                </Descriptions.Item>
              )}
              {associationType === LinkStatus.Node && (
                <Descriptions.Item label="关联元素">
                  <Item name="associationId" style={{ marginBottom: 0 }}>
                    <ShowInput />
                  </Item>
                </Descriptions.Item>
              )}
              {associationType === LinkStatus.BusBar && (
                <Descriptions.Item label="关联节点">
                  <Item name="associationNode" style={{ marginBottom: 0 }}>
                    <ShowInput />
                  </Item>
                </Descriptions.Item>
              )}
            </Descriptions>

            <Item name="id" hidden />
            <Item name="icon" hidden />
            <Item name="associationType" hidden />
            <Item name="deviceId" hidden />
            <Item name="type" initialValue={1} hidden />

            <div className={styles.preview}>
              <Button type="link" className={styles.previewBtn} style={{ marginTop: 16, marginBottom: 8 }}>
                查看图标
              </Button>

              <div className={styles.iconWrapper}>
                <div className={styles.iconBox}>
                  <div
                    className={styles.icon}
                    style={{
                      backgroundImage: `url(${
                        data?.file ? getRealUrl(data?.file) : iconArr.find(i => i.value === data?.icon)?.img
                      })`,
                    }}
                  />
                  <Item noStyle dependencies={['name']}>
                    {({ getFieldValue }) => <div className={styles.iconText}>{getFieldValue('name')}</div>}
                  </Item>
                </div>
              </div>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </div>
  );
};

export default OtherVerticalDrawer;
