import { useBreadcrumbRoutes, Wrapper, Button, Table, EllipsisSpan } from '@maxtropy/components';
import { Col, Row, Space, Spin } from 'antd';
import BaseInfo from './components/BaseInfo';
import TableList, { ButtonType, buttonTypeDisplay } from './components/TableList';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import { apiV2StrategyRuleListPost, apiV2StrategyRuleRefreshPost } from '@maxtropy/intelligent-dispath-apis-v2';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
const routes = [{ name: '规则配置' }];
const RulesConfig = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const { id } = useParams();
  const [refreshFlag, setRefreshFlag] = useState<boolean>(false);
  const {
    data: tableListInfo,
    loading,
    refresh: refreshList,
  } = useRequest(
    () => {
      let promises = Object.keys(buttonTypeDisplay).map(item =>
        apiV2StrategyRuleListPost({
          neoBsaId: id,
          type: item as any,
        })
      );
      return Promise.all(promises).then(res => {
        return res.map(item => item.list ?? []);
      });
    },
    {
      ready: !!id && refreshFlag,
      refreshDeps: [id],
    }
  );

  useRequest(async () => {
    const res = await apiV2StrategyRuleRefreshPost({});
    setRefreshFlag(res.result ?? false);
  });

  const navigate = useNavigate();
  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrap}>
      <div className={styles.top_area}>
        <BaseInfo />
      </div>
      <Spin spinning={loading}>
        <div className={styles.bottom_area}>
          <Row gutter={17}>
            <Col span={8}>
              <TableList
                refresh={() => refreshList()}
                list={tableListInfo?.[0]}
                buttonTypeList={[ButtonType.CLOSE, ButtonType.POWER, ButtonType.REMIND]}
              />
            </Col>
            <Col span={8}>
              <TableList
                refresh={() => refreshList()}
                list={tableListInfo?.[1]}
                buttonTypeList={[ButtonType.POWER, ButtonType.CLOSE, ButtonType.REMIND]}
              />
            </Col>
            <Col span={8}>
              <TableList
                refresh={() => refreshList()}
                list={tableListInfo?.[2]}
                buttonTypeList={[ButtonType.REMIND, ButtonType.CLOSE, ButtonType.POWER]}
              />
            </Col>
          </Row>
        </div>
      </Spin>
      <div className="sticky-footer" style={{ paddingLeft: 0, paddingTop: 28 }}>
        <Space size={8}>
          <Button
            onClick={() => {
              navigate('/energy-storage/basic/bsa', { replace: true });
            }}
          >
            返回
          </Button>
        </Space>
      </div>
    </Wrapper>
  );
};
export default RulesConfig;
