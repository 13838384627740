import { FC, useContext } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Button, DatePicker, Dropdown } from '@maxtropy/components';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { MenuItemType } from 'rc-menu/lib/interface';
import { ActionTypeEnum, BsaStrategyCalendarContext, checkCanSelect, MONTH_FORMAT } from '../const';
import styles from './index.module.scss';

interface Props {
  value: Dayjs;
  onChange: (date: Dayjs) => void;
}

enum QuickSelectEnum {
  ALL = 'all',
  WORK_DAY = 'workDay',
  WEEKEND = 'weekend',
}

const CalendarHeader: FC<Props> = props => {
  const { value, onChange } = props;
  const context = useContext(BsaStrategyCalendarContext);
  const { actionType, setCalendarShowDate, selectedDateList, setSelectedDateList, strategyCalendarData } = context;

  let headerQuickSelectMenu: MenuItemType[] = [
    {
      key: QuickSelectEnum.ALL,
      label: '全选',
    },
    {
      key: QuickSelectEnum.WORK_DAY,
      label: '工作日',
    },
    {
      key: QuickSelectEnum.WEEKEND,
      label: '双休日',
    },
  ];
  let dayOfWeek = ['一', '二', '三', '四', '五', '六', '日'];
  for (let i = 0; i < 7; i++) {
    headerQuickSelectMenu.push({
      key: String((i + 1) % 7),
      label: `星期${dayOfWeek[i]}`,
    });
  }
  headerQuickSelectMenu.forEach(item => {
    item.onClick = v => {
      let thisSelectedDateList: Dayjs[] = [];
      const key = v.key as QuickSelectEnum | string;
      const start = dayjs(value).startOf('month');
      const end = dayjs(value).endOf('month');
      let flagDate = start.clone();
      switch (key) {
        case QuickSelectEnum.ALL:
          while (flagDate.isSameOrBefore(end, 'day')) {
            checkCanSelect(flagDate, strategyCalendarData!, actionType!) && thisSelectedDateList.push(flagDate);
            flagDate = flagDate.add(1, 'day');
          }
          break;
        case QuickSelectEnum.WORK_DAY:
          while (flagDate.isSameOrBefore(end, 'day')) {
            if (flagDate.day() !== 0 && flagDate.day() !== 6) {
              checkCanSelect(flagDate, strategyCalendarData!, actionType!) && thisSelectedDateList.push(flagDate);
            }
            flagDate = flagDate.add(1, 'day');
          }
          break;
        case QuickSelectEnum.WEEKEND:
          while (flagDate.isSameOrBefore(end, 'day')) {
            if (flagDate.day() === 0 || flagDate.day() === 6) {
              checkCanSelect(flagDate, strategyCalendarData!, actionType!) && thisSelectedDateList.push(flagDate);
            }
            flagDate = flagDate.add(1, 'day');
          }
          break;
        default:
          while (flagDate.isSameOrBefore(end, 'day')) {
            if (flagDate.day() === Number(key)) {
              checkCanSelect(flagDate, strategyCalendarData!, actionType!) && thisSelectedDateList.push(flagDate);
            }
            flagDate = flagDate.add(1, 'day');
          }
          break;
      }
      const addSelectedDateList = thisSelectedDateList.filter(item => {
        return selectedDateList?.findIndex(i => i.isSame(item)) === -1;
      });
      const newSelectedDateList = selectedDateList?.concat(addSelectedDateList) ?? [];
      setSelectedDateList?.(newSelectedDateList);
    };
  });

  return (
    <div className={styles.dateHeader}>
      {actionType !== ActionTypeEnum.VIEW && (
        <Dropdown type="link" menu={{ items: headerQuickSelectMenu }}>
          选择快捷方式
        </Dropdown>
      )}

      <DatePicker
        className={styles.datePicker}
        picker="month"
        placeholder="请选择日期"
        value={value}
        onChange={v => onChange(v!)}
        allowClear={false}
      ></DatePicker>
      <Button
        icon={<LeftOutlined />}
        onClick={() => {
          setCalendarShowDate?.(dayjs(value).subtract(1, 'month').format(MONTH_FORMAT));
        }}
      ></Button>
      <Button
        icon={<RightOutlined />}
        onClick={() => {
          setCalendarShowDate?.(dayjs(value).add(1, 'month').format(MONTH_FORMAT));
        }}
      ></Button>
    </div>
  );
};

export default CalendarHeader;
