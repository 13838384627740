import {
  BusBarVoltageLevel,
  BusBarVoltageLevelLabel,
} from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import { Graph, Node } from '@antv/x6';
import { Cascader, Dropdown, Popconfirm } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { BusBarCircleNumber, FcsList, GateWayList, nodeElementLinkTree, NodeType, TreeNode } from '../../type';
import { findDrawIndexByCurrentNode } from '../../utils/utils';
import styles from './index.module.scss';
import { SingleValueType } from 'rc-cascader/lib/Cascader';
import { DefaultOptionType } from 'antd/es/cascader';
import { CloseOutlined } from '@ant-design/icons';
import { limitByNodes } from '../../utils/nodelimit';
import { ADDCIRCLE_RADIUS, EACH_BUSBAR_LENGTH } from '../../utils/graph';
import { isNil } from 'lodash-es';

export interface BusBarProps {
  node: Node;
  graphRef?: Graph;
  allNodeDataRef?: TreeNode[];
  gatewayListRef?: GateWayList[];
  fcsListRef?: FcsList[];
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    circleIndex?: number,
    circleArr?: BusBarCircleNumber[]
  ) => void;
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void;
  showModalDetail?: (id: number, type: NodeType) => void;
  nodeElementLink?: nodeElementLinkTree[];
}

const BusBar: FC<BusBarProps> = props => {
  const {
    node,
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink,
  } = props;
  const [cascaderValue, setCascaderValue] = useState<SingleValueType>();
  const [dropDownOpen, setDropDownOpen] = useState<Record<string, boolean>>();

  const data = useMemo(() => {
    return node.getData();
  }, [node.getData()]);

  const circleArr: BusBarCircleNumber[] = useMemo(() => {
    return data?.busbarNeedExtendsMap?.circleArr;
  }, [data]);

  const busbarLength = useMemo(() => {
    return data?.busbarNeedExtendsMap?.busbarLength;
  }, [data]);
  const busbarTlineHeight = useMemo(() => {
    return data?.busbarNeedExtendsMap?.height ?? 0;
  }, [data]);

  // 母线上面那条线
  const tlinePosition = useMemo(() => {
    const findIndex = (circleArr ?? []).findIndex(i => i.circleNumber === 0);
    const left = circleArr[findIndex].left;
    return left + EACH_BUSBAR_LENGTH / 2 - 1;
  }, [circleArr]);

  // 母线下面的线
  const blinePosition = useCallback(
    (circleNumber: number, bLinePosition: number) => {
      const findCircle = (circleArr ?? []).find(i => i.circleNumber === circleNumber);

      return (findCircle?.left ?? 0) + EACH_BUSBAR_LENGTH / 2 + (bLinePosition ?? 0) - 1;
    },
    [circleArr]
  );

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  return (
    <div
      className={styles.generatrixWrapper}
      style={{
        width: busbarLength,
        opacity: data.isShowNetWork ? 0.5 : 1,
      }}
      onClick={() => {
        showModalDetail?.(data.id, NodeType.BUS_BAR);
      }}
    >
      <div className={styles.generatrixNode} style={{ width: busbarLength }}>
        {circleArr.map((item, index) => (
          <div key={item.circleNumber}>
            {item.circleNumber === 0 && (
              <div
                className={styles.tline}
                style={{ left: tlinePosition, height: 15 + busbarTlineHeight, top: -14 - busbarTlineHeight }}
              ></div>
            )}
            {item.circleNumber !== 0 && item.circleNumber !== Math.max(...circleArr.map(i => i.circleNumber)) && (
              <div
                className={styles.bline}
                style={{
                  left: blinePosition(item.circleNumber, item.bLinePosition),
                }}
              ></div>
            )}
            {data?.isShowAllAddDelBtn && (
              <div style={{ zIndex: 99 }}>
                <Dropdown
                  destroyPopupOnHide
                  overlayClassName={styles.drapDownStyle}
                  open={!!dropDownOpen?.[item.circleNumber]}
                  onOpenChange={open => {
                    setDropDownOpen({
                      [`${item.circleNumber}`]: open,
                    });
                  }}
                  dropdownRender={() => (
                    <Cascader
                      popupClassName={styles.drapDownCascaderSty}
                      options={nodeElementLink}
                      allowClear={false}
                      fieldNames={{ value: 'key' }}
                      showSearch={{ filter }}
                      notFoundContent={'暂无数据'}
                      placeholder={'请输入元素名称'}
                      expandTrigger={'hover'}
                      open={!!dropDownOpen?.[item.circleNumber]}
                      value={cascaderValue}
                      onClick={e => {
                        e?.stopPropagation();
                      }}
                      onChange={value => {
                        setCascaderValue(value);
                        limitByNodes(
                          value[value.length - 1] as NodeType,
                          node,
                          'right',
                          item.circleNumber,
                          graphRef,
                          allNodeDataRef,
                          gatewayListRef,
                          fcsListRef,
                          handleCreateNode,
                          circleArr
                        );
                        setDropDownOpen({
                          [`${index}`]: false,
                        });
                      }}
                    />
                  )}
                  trigger={['click']}
                >
                  <div
                    onClick={e => {
                      e.preventDefault();
                      e?.stopPropagation();
                      setCascaderValue(undefined);
                    }}
                    className={styles.addCircle}
                    style={{ left: item.circleNumber <= 0 ? item.left : item.left - ADDCIRCLE_RADIUS * 2 }}
                  >
                    <div className={styles.addIcon}>+</div>
                  </div>
                </Dropdown>
              </div>
            )}
          </div>
        ))}
      </div>

      <div
        className={styles.generatrixValueSty}
        style={{
          left: busbarLength + 20,
        }}
      >
        <div>{data.value.name}</div>
        {isNil(data?.value?.level) || data?.value?.level === BusBarVoltageLevel.NULL ? (
          <></>
        ) : (
          <div style={{ color: '#faad14' }}>{BusBarVoltageLevelLabel[data.value.level as BusBarVoltageLevel]}</div>
        )}
      </div>
      {data?.isShowAllAddDelBtn && (
        <Popconfirm
          overlayClassName={styles.popconfirmSty}
          title={
            <p style={{ color: '#fff' }}>
              确定要删除
              <span style={{ color: '#E64242' }}> {data.value.name}</span>
              及该设备下级所有关联对象吗？
            </p>
          }
          onConfirm={e => {
            e?.stopPropagation();
            confirm?.(
              NodeType.BUS_BAR,
              data.id,
              findDrawIndexByCurrentNode({ id: data?.id, element: data?.element }, allNodeDataRef)
            );
          }}
          okText="确定"
          cancelText="取消"
        >
          <div
            onClick={e => {
              e.stopPropagation();
            }}
            className={styles.deleteIcon}
          >
            <CloseOutlined />
          </div>
        </Popconfirm>
      )}
    </div>
  );
};

export default BusBar;
