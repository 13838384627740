import { PermissionsType } from '@/common/permissionsConst';
import SchedulingTopology from '.';
import PreviewTopo from './PreviewTopo';
const routes = [
  {
    path: '/energy/scheduling/topology',
    permission: PermissionsType.P_EDTSET,
    element: <SchedulingTopology />,
  },
  {
    path: '/energy/scheduling/topology/preview',
    permission: PermissionsType.P_EDTSET,
    element: <PreviewTopo />,
  },
];

export default routes;
