import { Graph, Node } from '@antv/x6';
import dayjs from 'dayjs';
import { TreeNode } from './type';
import { NodeType } from '@/pages/SchedulingTopology/type';
import {
  V2DispatchUetStructGetPublishTreePostResponse,
  apiV2GreenMicrowebShowcaseHotspotElementConfPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import {
  addedNodeValue,
  getChildrenIds,
  loopResTreeTofindItem,
  treeToArray,
} from '@/pages/SchedulingTopology/utils/graph';
import type { Animation } from '@antv/x6/es/model/animation';
import { loopTreeTofindId } from '@/pages/SchedulingTopology/utils/utils';
import { message } from '@maxtropy/components';
import { Key } from 'react';
import { ITEM_HEIGHT } from '.';
import { formatResTreeData } from '@/pages/SchedulingTopology/utils/dataUtil';

// 初始化画图
export function initTopoGraph(dom: HTMLElement): Graph {
  return new Graph({
    container: dom,
    autoResize: true,
    panning: true,
    mousewheel: true,
    grid: {
      size: 4,
      visible: true,
      type: 'dot',
      args: {
        color: '#ffffff35', // 网点颜色
        thickness: 1, // 网点大小
      },
    },
    // interacting: false, // 节点是否可移动
    interacting: function (cellView) {
      if (!cellView.cell.getData()?.disableMove) {
        return { nodeMovable: false };
      }
      return true;
    },
  });
}

// 初次渲染电网侧
export function firstRenderSide(allNodeData: TreeNode[]) {
  let allNodeDataTemp = [...allNodeData]; // 深拷贝
  const dataId = dayjs().valueOf();
  // 维护数组
  allNodeDataTemp.push({
    id: dataId,
    parentId: undefined,
    element: NodeType.GRID_SIDE,
    children: [],
  });
  // 初次渲染电网侧
  const addNodeData = {
    shape: NodeType.GRID_SIDE,
    x: 100,
    y: 50,
    data: {
      parentId: null,
      id: dataId,
      value: {
        name: '电网侧',
      },
      element: NodeType.GRID_SIDE,
    },
  };
  return { allNodeDataTemp, addNodeData };
}

// 处理接口拿到的数据(把树中的data拼到元素节点中)
// export function handleFetchData(res: V2GreenMicrowebShowcaseTreePostResponse): any[] {
export function handleFetchData(res: V2DispatchUetStructGetPublishTreePostResponse): any[] {
  const allgraphNodes: any[] = [];
  res.diagrams?.forEach(i => {
    let findItem: any = null;
    let value: any = null;
    // 如果是网关单独处理值
    if (i.shape === NodeType.GATEWAY) {
      value = addedNodeValue(
        i.shape,
        res.gatewayList?.find(c => c.id === i.elementId)
      );
    } else if (i.shape === NodeType.FCS_CONTROLLER) {
      value = addedNodeValue(
        i.shape,
        res.fcsList?.find(c => c.id === i.elementId)
      );
    } else {
      findItem = loopResTreeTofindItem(res?.trees ?? [], i.elementId!, i.shape as NodeType);
      const allNodeData = formatResTreeData(res?.trees ?? []);
      const arrayTree = treeToArray(allNodeData);
      const findLink = arrayTree.find(
        (c: any) => c?.data?.associationId + c?.data?.associationElement === (findItem?.id ?? '') + findItem?.element
      );
      value = {
        ...addedNodeValue(findItem?.element as NodeType, findItem?.data),
        dataPropertyConfigs: findItem?.dataPropertyConfigs ?? undefined,
        hasChildren: findLink || findItem?.children?.length ? true : false,
      };
    }

    allgraphNodes.push({
      shape: i.shape,
      x: i.x,
      y: i.y,
      data: {
        busbarNeedExtendsMap:
          typeof i.busbarNeedExtendsMap == 'string' ? JSON.parse(i.busbarNeedExtendsMap) : i.busbarNeedExtendsMap,
        disableMove:
          i.shape === NodeType.GATEWAY || i.shape === NodeType.FCS_CONTROLLER || i.shape === NodeType.BUS_BAR,
        id: i.elementId,
        element: i.shape,
        value,
      },
    });
  });
  return allgraphNodes;
}

// 改变Y坐标
export function changeYPosition(graph: Graph, ids: { id: number; element: NodeType }[], needAddHeight: number) {
  const addedAllNodes = graph.getNodes();
  let nodes: Node<Node.Properties>[] = [];
  for (let i = 0; i < ids.length; i++) {
    const findNode = addedAllNodes.find(
      item => item.getData().id === ids[i].id && item.getData().element === ids[i].element
    );
    findNode && nodes.push(findNode);
  }

  nodes.forEach(item => {
    let { x, y } = item.getPosition();
    item.setPosition({ x: x, y: y + needAddHeight });
  });
}

// 处理剩余节点的偏移与当前节点的高度
export const handleCommonExpand = (
  id: number,
  element: NodeType,
  node: Node<Node.Properties>,
  height: number,
  changedIsExpand: boolean,
  datasLength: number,
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  extraHeight?: number // 主要针对一些中间元素有下拉框等样式,
) => {
  const opt: Animation.BaseOptions = {
    delay: 0,
    duration: 300,
    timing: 'linear',
  };
  const findItem = loopTreeTofindId(allNodeDataRef ?? [], id, element);
  const ids = getChildrenIds(findItem?.children ?? []).filter(item => item.id + item.element !== id + element);
  const changeYPositionHeight = changedIsExpand
    ? ITEM_HEIGHT * calcMaxLength(datasLength) + (extraHeight ?? 0)
    : -(ITEM_HEIGHT * calcMaxLength(datasLength) + (extraHeight ?? 0));
  changeYPosition(graphRef!, ids, changeYPositionHeight);
  node.transition('size/height', height + (extraHeight ?? 0), opt);
};

// 处理切换设备数据点数量变化后的节点高度与剩余子节点位置变化
export const handleDeviceChanged = (
  id: number,
  element: NodeType,
  node: Node<Node.Properties>,
  height: number,
  phaseValue: number,
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  extraHeight?: number // 主要针对一些中间元素有下拉框等样式,
  // changeExtraHeightPosition = true
) => {
  const opt: Animation.BaseOptions = {
    delay: 0,
    duration: 300,
    timing: 'linear',
  };
  const findItem = loopTreeTofindId(allNodeDataRef ?? [], id, element);
  const ids = getChildrenIds(findItem?.children ?? []).filter(item => item.id + item.element !== id + element);
  const changeYPositionHeight = ITEM_HEIGHT * calcMaxLength(phaseValue);
  changeYPosition(graphRef!, ids, changeYPositionHeight);
  node.transition('size/height', height + (extraHeight ?? 0), opt);
};

// 查询热区url并跳转
export const navigateNodeHotArea = (configId: number, elementId: number, nodeType: NodeType) => {
  apiV2GreenMicrowebShowcaseHotspotElementConfPost({
    id: configId,
    element: nodeType,
    elementId,
  }).then(res => {
    if (res.url) {
      window.open(`${res.url}`, '_blank');
    } else {
      // message.error('未配置url');
    }
  });
};

// 判断是否为网址链接（不用）
export function isUrl(s: string) {
  var regexp = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
  return regexp.test(s);
}

// 区分拖动与点击
export function judgeDrageOrClick(boxRef: HTMLElement, configId: number, elementId: number, nodeType: NodeType) {
  let startPosition = { x: 0, y: 0 };

  boxRef.addEventListener('mousedown', function (event) {
    startPosition = { x: event.clientX, y: event.clientY };
  });

  boxRef.addEventListener('mouseup', function (event) {
    if (event.clientX === startPosition.x && event.clientY === startPosition.y) {
      navigateNodeHotArea(configId!, elementId, nodeType);
    }
  });
}

// 数组去重
export function unique(arr: any[]) {
  if (arr instanceof Array) {
    return Array.from(new Set(arr));
  }
  return [];
}

// 判断节点类型，给定基础高度，用于展开收起高度
export const classificateNodeType = (nodeType: NodeType) => {
  switch (nodeType) {
    case NodeType.TRANSFORMER:
    case NodeType.OTHER_VERTICAL:
      return 174;
    case NodeType.PVA:
    case NodeType.BSA:
    case NodeType.UNIVERSAL_JOINT_OPENING:
      return 145;
    case NodeType.LOAD:
    case NodeType.BACKUP_POWER:
      return 94;
    case NodeType.ANTI_BACKFLOW_DEVICE:
    case NodeType.ANTI_OVERCAPACITY_POINT:
    case NodeType.GRID_ACCESS_BILLING_POINT:
    case NodeType.PVA_BILLING_POINT:
    case NodeType.CHARGING_STATION_BILLING_POINT:
    case NodeType.LOAD_BILLING_POINT:
    case NodeType.BSA_BILLING_POINT:
    case NodeType.BSA_GRID_CONNECTION_BILLING_POINT:
    case NodeType.OTHER_TRANSVERSE:
      return 80;
    case NodeType.GRID_SIDE:
      return 60;
    case NodeType.GRID_ACCESS_POINT:
      return 120;
    case NodeType.BUS_BAR:
      return 30;
    default:
      return 0;
  }
};

// 最多是5个
export const calcMaxLength = (length: number) => {
  return length > 5 ? 5 : length;
};
