import React, { Key, useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import Title from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Title';
import {
  apiV2DispatchUetStructBsaCircuitListPost,
  apiV2DispatchUetStructBsaControlObjectAllListPost,
  apiV2DispatchUetStructBsaControlObjectListPost,
  apiV2DispatchUetStructBsaDeletePost,
  apiV2DispatchUetStructBsaDetailPost,
  apiV2DispatchUetStructBsaEditPost,
  apiV2DispatchUetStructBsaListPost,
  apiV2DispatchUetStructBsaPcsListPost,
  apiV2DispatchUetStructBsaSavePost,
  apiV2DispatchUetStructPublishBsaDetailPost,
  V2DispatchUetStructBsaControlObjectAllListPostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import Footer from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Footer';
import {
  NodeType as NodeTypeClass,
  VoltageLevelLabel,
} from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import { useRequest } from 'ahooks';
import { isNil } from 'lodash-es';
import FcsCheckGroup from '@/pages/EnergyDispatchManagement/ElementCreationModal/EnergyStorageStation/FcsCheckGroup';
import { NodeType, TreeNode, allInfoProps } from '@/pages/SchedulingTopology/type';
import { findTopGridAccessPointData } from './utils';
import { Button, Form, Modal, Select } from '@maxtropy/components';

const { Item } = Form;

export enum LinkType {
  NON_MASTER_SLAVE_CONTROL = -1,
  MASTER = 0,
  SLAVE = 1,
}

interface Props {
  id?: number;
  open?: boolean;
  dispatchGroupId?: number;
  dispatchUetStructId?: number;
  isPreview?: boolean;
  isShowModal?: (open: boolean) => void;
  getFinishedValues?: (values: any) => void;
  shouldDeleteElement?: (state: boolean) => void;
  version?: number;
  allInfo?: allInfoProps;
  allNodeData?: TreeNode[];
}
const EnergyStorageStationModal: React.FC<Props> = ({
  id,
  dispatchGroupId,
  dispatchUetStructId,
  open,
  isPreview,
  isShowModal,
  getFinishedValues,
  shouldDeleteElement,
  version,
  allInfo,
  allNodeData,
}) => {
  const [form] = Form.useForm();

  const urlSearch = new URLSearchParams(window.location.search);
  const topologyId = urlSearch.get('topologyId'); // 结构id
  const dispatchingGroup = urlSearch.get('dispatchingGroup'); // 调度组id

  const bsaId = Form.useWatch('bsaId', form);
  const hasControlItem = Form.useWatch('hasControlItem', form);

  const [iconVisible, setIconVisible] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();
  const [gridAccessPointId, setGridAccessPointId] = useState<Key>(); // 电网接入点id
  const [selectBsaId, setSelectBsaId] = useState<number>();

  const onShowIcon = () => {
    form.validateFields().then(() => {
      setIconVisible(true);
    });
  };

  const onCancel = () => {
    form.resetFields();
    setTitle(undefined);
    isShowModal?.(false);
  };

  // 储能站list
  const { data: bsaListData } = useRequest(
    async () => {
      if (isNil(+topologyId!) || !open) return;
      const res = await apiV2DispatchUetStructBsaListPost({
        uetStructId: +topologyId!,
        gridAccessPointId,
      });
      if (res && res.list) {
        return res.list;
      }
    },
    { ready: !!gridAccessPointId && !!topologyId, refreshDeps: [gridAccessPointId, topologyId] }
  );

  const bsaList = useMemo(() => {
    if (bsaListData && bsaListData.length) {
      return bsaListData.map(item => ({
        label: item.name,
        value: item.id,
      }));
    }
  }, [bsaListData]);

  // 总进线回路list
  const { data: circuitList } = useRequest(
    async () => {
      if (isNil(+topologyId!) || !open) return;
      const res = await apiV2DispatchUetStructBsaCircuitListPost({
        dispatchUetStructId: dispatchUetStructId,
        gradAccessPointId: gridAccessPointId,
      });
      if (res && res.list) {
        return res.list.map(item => ({
          label: item.name,
          value: item.id,
        }));
      }
    },
    {
      ready: !!dispatchUetStructId && !!gridAccessPointId,
      refreshDeps: [dispatchUetStructId, gridAccessPointId],
    }
  );

  // 选择bsa清空下面的所有信息
  useEffect(() => {
    if (selectBsaId) {
      form.resetFields();
      // 保留id
      !isNil(id) && form.setFieldValue('id', id);
      form.setFieldValue('bsaId', selectBsaId);
      if (bsaListData && bsaListData.length > 0) {
        form.setFieldValue('circuitId', bsaListData.find(i => i.id === selectBsaId)?.circuitId);
      }
    }
  }, [selectBsaId, bsaListData, id]);

  // 详情
  const { data: detail } = useRequest(
    async () => {
      if (!open || isNil(id)) return;
      setIsDetail(true);
      let res;
      if (!isNil(version)) {
        res = await apiV2DispatchUetStructPublishBsaDetailPost({ id, version });
      } else {
        res = await apiV2DispatchUetStructBsaDetailPost({ id: +id });
      }

      if (res) {
        setIconVisible(true);
        setTitle(res.bsaSimpleVo?.name);
        form.setFieldsValue({
          ...res,
          hasControlItem: res.hasControlItem ?? false,
          bsaId: res.bsaSimpleVo?.id,
          bindPcsIdList: res.bindPcsList?.filter(item => item.hasChoose).map(i => i.id),
        });

        return res;
      }
    },
    { refreshDeps: [open, id, version] }
  );

  // 通过当前id与type查找电网接入点id
  useEffect(() => {
    if (detail) {
      if (allNodeData) {
        const gridAccessPointData = findTopGridAccessPointData(allNodeData, detail.id, NodeType.BSA);
        setGridAccessPointId(gridAccessPointData?.id);
      }
    } else {
      if (allInfo) {
        const gridAccessPointData = findTopGridAccessPointData(
          allInfo.allNodeData,
          allInfo.node.getData().id,
          allInfo.node.getData().element
        );
        setGridAccessPointId(gridAccessPointData?.id);
      }
    }
  }, [allInfo, detail, allNodeData]);

  // pcs list
  const { data: pcsList } = useRequest(
    async () => {
      if (isNil(bsaId) || !open) {
        form.setFieldsValue({
          bindPcsIdList: [],
        });
        return [];
      }
      if (bsaId) {
        // form.resetFields();
        const res = await apiV2DispatchUetStructBsaPcsListPost({
          dispatchGroupId: +dispatchingGroup!,
          dispatchUetStructId: +topologyId!,
          id: +bsaId,
        });
        if (res) {
          if (res.list?.length === 1) {
            // setOnlyOnePcsId(res.list?.[0].id);
            if (!res?.list[0].disable) {
              form.setFieldsValue({
                bindPcsIdList: [res.list?.[0].id],
              });
            }
          }
          return res.list;
        }
      }
    },
    {
      refreshDeps: [bsaId, open, detail],
    }
  );

  // 下拉可以回填的bsa
  const { data: controObjectList } = useRequest(
    async () => {
      if (isNil(bsaId) || !open) {
        form.setFieldsValue({
          pcsIdList: [],
          airConditionerIdList: [],
          fireControlIdList: [],
          hygrographIdList: [],
          smokeSensorIdList: [],
        });
        return [];
      }
      apiV2DispatchUetStructBsaControlObjectListPost({
        dispatchGroupId: +dispatchingGroup!,
        dispatchUetStructId: +topologyId!,
        bsaId: +bsaId,
      }).then(res => {
        form.setFieldsValue({
          pcsIdList: res.pcsList?.map(i => i.id) ?? [],
          airConditionerIdList: res.airConditionerDevice?.map(i => i.id) ?? [],
          fireControlIdList: res.fireControlDevice?.map(i => i.id) ?? [],
          hygrographIdList: res.hygrographDevice?.map(i => i.id) ?? [],
          smokeSensorIdList: res.smokeSensorDevice?.map(i => i.id) ?? [],
        });
        return res;
      });
    },
    {
      refreshDeps: [bsaId, open, detail],
    }
  );

  // 关联的配套设备类型
  const { data: controlDeviceList } = useRequest(
    async () => {
      if (isNil(bsaId) || !open) {
        form.setFieldsValue({
          pcsIdList: [],
          airConditionerIdList: [],
          fireControlIdList: [],
          hygrographIdList: [],
          smokeSensorIdList: [],
        });
        return [];
      }
      return await apiV2DispatchUetStructBsaControlObjectAllListPost({
        dispatchGroupId: +dispatchingGroup!,
        dispatchUetStructId: +topologyId!,
        bsaId: +bsaId,
      });
    },
    {
      refreshDeps: [bsaId, open, detail],
    }
  );

  return (
    <>
      <Modal
        open={open}
        contentClassName="modal-form-content"
        title={
          <Title
            id={id}
            styles={styles}
            isDetail={isDetail}
            isPreview={isPreview}
            title={title ?? '储能站'}
            delMethod={apiV2DispatchUetStructBsaDeletePost}
            onEdit={() => setIsDetail(false)}
            onCancel={onCancel}
            shouldDeleteElement={state => shouldDeleteElement?.(state)}
            nodeType={NodeTypeClass.STORAGE}
          />
        }
        footer={
          <Footer
            isDetail={isDetail}
            styles={styles}
            form={form}
            dispatchGroupId={dispatchGroupId}
            createMethod={apiV2DispatchUetStructBsaSavePost}
            updateMethod={apiV2DispatchUetStructBsaEditPost}
            onCancel={onCancel}
            getFinishedValues={val => getFinishedValues?.(val)}
          />
        }
        onCancel={onCancel}
        destroyOnClose
      >
        <div className={styles.modalWrapper}>
          <Form form={form} labelCol={{ span: 6 }} labelAlign="left">
            <Item name="bsaId" label="储能站" rules={[{ required: true, message: '请选择储能站' }]}>
              <Select
                placeholder="请选择"
                onChange={val => setSelectBsaId(val)}
                options={bsaList}
                disabled={isDetail}
              />
            </Item>
            <Item name="circuitId" label="所在总进线回路" rules={[{ required: true, message: '请选择所在总进线回路' }]}>
              <Select
                placeholder="请选择"
                showSearch
                optionFilterProp="label"
                options={circuitList}
                disabled={isDetail}
              />
            </Item>

            <Item name="voltageLevel" label="并网电压等级" rules={[{ required: true, message: '请选择并网电压等级' }]}>
              <Select
                placeholder="请选择"
                options={Object.entries(VoltageLevelLabel)
                  .map(([key, value]) => {
                    return { label: value, value: Number(key) };
                  })
                  .sort((a, b) => {
                    return (a.label.slice(0, -2) as unknown as number) - (b.label.slice(0, -2) as unknown as number);
                  })}
                disabled={isDetail}
              />
            </Item>

            <Item name="bindPcsIdList" label="关联PCS" rules={[{ required: true, message: '请选择关联PCS' }]}>
              <FcsCheckGroup
                options={pcsList?.map(item => ({
                  label: item.device?.name ? item.sequence! + 1 + ' | ' + item.device?.name : '',
                  // label: item.serialNumber ?? '',
                  value: item.id!,
                  // type: item.linkType,
                  // disabled: item.disable ?? false,
                  disabled: detail?.bindPcsList?.find(ele => ele.id === item.id)?.hasChoose
                    ? false
                    : item.disable ?? false,
                }))}
                disabled={isDetail || pcsList?.length === 1}
              />
            </Item>

            {/* <Item name="hasControlItem" noStyle />

            <Checkbox
              style={{
                marginBottom: 10,
              }}
              onChange={e => {
                form.setFieldsValue({
                  hasControlItem: e.target.checked,
                });
              }}
              checked={hasControlItem}
              disabled={isDetail}
            >
              添加控制对象
            </Checkbox>

            {hasControlItem && (
              <Item noStyle dependencies={['bsaId']}>
                {({ getFieldValue }) => {
                  const bsaId = getFieldValue('bsaId');
                  const allpcsList = (controlDeviceList as V2DispatchUetStructBsaControlObjectAllListPostResponse)
                    ?.pcsList;
                  const allairConditionerList = (
                    controlDeviceList as V2DispatchUetStructBsaControlObjectAllListPostResponse
                  )?.airConditionerDevice;
                  const allfireControlList = (
                    controlDeviceList as V2DispatchUetStructBsaControlObjectAllListPostResponse
                  )?.fireControlDevice;
                  const allhygrographList = (
                    controlDeviceList as V2DispatchUetStructBsaControlObjectAllListPostResponse
                  )?.hygrographDevice;
                  const allsmokeSensorList = (
                    controlDeviceList as V2DispatchUetStructBsaControlObjectAllListPostResponse
                  )?.smokeSensorDevice;
                  return bsaId ? (
                    <>
                      {allpcsList && allpcsList.length > 0 && (
                        <Item name="pcsIdList" label="PCS">
                          <Select
                            optionFilterProp="label"
                            placeholder="请选择"
                            mode="multiple"
                            options={allpcsList.map(item => ({
                              label: item.device?.name ?? '',
                              value: item.id,
                            }))}
                            allowClear
                            disabled={isDetail}
                          />
                        </Item>
                      )}
                      {allairConditionerList && allairConditionerList.length > 0 && (
                        <Item name="airConditionerIdList" label="空调">
                          <Select
                            placeholder="请选择"
                            optionFilterProp="label"
                            mode="multiple"
                            options={(
                              controlDeviceList as V2DispatchUetStructBsaControlObjectAllListPostResponse
                            )?.airConditionerDevice?.map(item => ({
                              label: item?.name ?? '',
                              value: item.id,
                            }))}
                            allowClear
                            disabled={isDetail}
                          />
                        </Item>
                      )}
                      {allfireControlList && allfireControlList.length > 0 && (
                        <Item name="fireControlIdList" label="消防">
                          <Select
                            placeholder="请选择"
                            optionFilterProp="label"
                            mode="multiple"
                            options={(
                              controlDeviceList as V2DispatchUetStructBsaControlObjectAllListPostResponse
                            )?.fireControlDevice?.map(item => ({
                              label: item?.name ?? '',
                              value: item.id,
                            }))}
                            allowClear
                            disabled={isDetail}
                          />
                        </Item>
                      )}
                      {allhygrographList && allhygrographList.length > 0 && (
                        <Item name="hygrographIdList" label="温湿度计">
                          <Select
                            placeholder="请选择"
                            optionFilterProp="label"
                            mode="multiple"
                            options={(
                              controlDeviceList as V2DispatchUetStructBsaControlObjectAllListPostResponse
                            )?.hygrographDevice?.map(item => ({
                              label: item?.name ?? '',
                              value: item.id,
                            }))}
                            allowClear
                            disabled={isDetail}
                          />
                        </Item>
                      )}
                      {allsmokeSensorList && allsmokeSensorList.length > 0 && (
                        <Item name="smokeSensorIdList" label="烟感">
                          <Select
                            placeholder="请选择"
                            optionFilterProp="label"
                            mode="multiple"
                            options={(
                              controlDeviceList as V2DispatchUetStructBsaControlObjectAllListPostResponse
                            )?.smokeSensorDevice?.map(item => ({
                              label: item?.name ?? '',
                              value: item.id,
                            }))}
                            allowClear
                            disabled={isDetail}
                          />
                        </Item>
                      )}
                    </>
                  ) : (
                    <></>
                  );
                }}
              </Item>
            )} */}

            <Item name="id" hidden />

            <div className={styles.preview}>
              <Button type="link" className={styles.previewBtn} onClick={onShowIcon}>
                查看图标
              </Button>

              <div className={styles.iconWrapper}>
                {iconVisible && (
                  <div className={styles.iconBox}>
                    <div className={styles.icon} />

                    <Item noStyle dependencies={['bsaId']}>
                      {({ getFieldValue }) => {
                        const name = bsaList?.find(i => i.value === getFieldValue('bsaId'))?.label;
                        return <div className={styles.iconText}>{name}</div>;
                      }}
                    </Item>

                    <Item noStyle dependencies={['bindPcsIdList']}>
                      {({ getFieldValue }) => {
                        const pcsIds = getFieldValue('bindPcsIdList') as number[];
                        if (pcsIds?.length < pcsList?.length! && pcsIds?.length > 0) {
                          return <div className={styles.part}>部分</div>;
                        }
                      }}
                    </Item>

                    <div className={styles.fcsAndpcs}>
                      <div className={styles.text}>
                        <Item noStyle dependencies={['bindPcsIdList']}>
                          {({ getFieldValue }) => {
                            const bindPcsIdList = getFieldValue('bindPcsIdList') as number[];
                            return (
                              <div className={styles.pcs}>{`PCS ${(bindPcsIdList ?? []).length}/${
                                (pcsList ?? []).length
                              }`}</div>
                            );
                          }}
                        </Item>
                      </div>
                    </div>

                    {/* {hasControlItem && (
                      <>
                        <div className={styles.dashed} />

                        <div className={styles.controlObject}>
                          <Item
                            noStyle
                            dependencies={[
                              'pcsIdList',
                              'airConditionerIdList',
                              'fireControlIdList',
                              'hygrographIdList',
                              'smokeSensorIdList',
                            ]}
                          >
                            {({ getFieldValue }) => {
                              const pcsIds = getFieldValue('pcsIdList') as number[];
                              const airConditionerIds = getFieldValue('airConditionerIdList') as number[];
                              const fireControlIds = getFieldValue('fireControlIdList') as number[];
                              const hygrographIds = getFieldValue('hygrographIdList') as number[];
                              const smokeSensorIds = getFieldValue('smokeSensorIdList') as number[];

                              // pcsList
                              const allpcsList = (
                                controlDeviceList as V2DispatchUetStructBsaControlObjectAllListPostResponse
                              )?.pcsList;
                              let pcsArrTemp: V2DispatchUetStructBsaControlObjectAllListPostResponse['pcsList'] = [];
                              (pcsIds ?? []).forEach(id => {
                                const findItem = allpcsList?.find(i => i.id === id);
                                findItem && pcsArrTemp?.push(findItem);
                              });
                              const pcsArr = pcsArrTemp.map(item => {
                                return {
                                  ...item,
                                  name: item?.device?.name,
                                };
                              });

                              // 空调
                              const allairConditionerList = (
                                controlDeviceList as V2DispatchUetStructBsaControlObjectAllListPostResponse
                              )?.airConditionerDevice;
                              let airConditionerArr: V2DispatchUetStructBsaControlObjectAllListPostResponse['airConditionerDevice'] =
                                [];
                              (airConditionerIds ?? []).forEach(id => {
                                const findItem = allairConditionerList?.find(i => i.id === id);
                                findItem && airConditionerArr?.push(findItem);
                              });

                              // 消防
                              const allfireControlList = (
                                controlDeviceList as V2DispatchUetStructBsaControlObjectAllListPostResponse
                              )?.fireControlDevice;
                              let allfireControlArr: V2DispatchUetStructBsaControlObjectAllListPostResponse['fireControlDevice'] =
                                [];
                              (fireControlIds ?? []).forEach(id => {
                                const findItem = allfireControlList?.find(i => i.id === id);
                                findItem && allfireControlArr?.push(findItem);
                              });

                              // 温湿度计
                              const allhygrographList = (
                                controlDeviceList as V2DispatchUetStructBsaControlObjectAllListPostResponse
                              )?.hygrographDevice;
                              let allhygrographArr: V2DispatchUetStructBsaControlObjectAllListPostResponse['hygrographDevice'] =
                                [];
                              (hygrographIds ?? []).forEach(id => {
                                const findItem = allhygrographList?.find(i => i.id === id);
                                findItem && allhygrographArr?.push(findItem);
                              });

                              // 烟感
                              const allsmokeSensorList = (
                                controlDeviceList as V2DispatchUetStructBsaControlObjectAllListPostResponse
                              )?.smokeSensorDevice;
                              let allsmokeSensorArr: V2DispatchUetStructBsaControlObjectAllListPostResponse['smokeSensorDevice'] =
                                [];
                              (smokeSensorIds ?? []).forEach(id => {
                                const findItem = allsmokeSensorList?.find(i => i.id === id);
                                findItem && allsmokeSensorArr?.push(findItem);
                              });

                              const totalArr = [
                                ...pcsArr,
                                ...airConditionerArr,
                                ...allfireControlArr,
                                ...allhygrographArr,
                                ...allsmokeSensorArr,
                              ];

                              return totalArr.map((item, index) => (
                                <div key={index} className={styles.controlObjectIcon}>
                                  {item.name}
                                </div>
                              ));
                            }}
                          </Item>
                        </div>
                      </>
                    )} */}
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default EnergyStorageStationModal;
