import React, { Key } from 'react';
import styles from './index.module.scss';
import { DataPropertyType } from '../../type';

interface Iprops {
  sendValue?: Key;
  k?: number;
  b?: number;
  dataPropertyType?: DataPropertyType;
}

const SendValueDom = ({ sendValue, dataPropertyType, k, b }: Iprops) => {
  return (
    <div className={styles.compute_box}>
      <div className={styles.compute_value}>
        <span className={styles.text}>下发值:</span>
        <span className={styles.value}>{`${sendValue ?? '--'}`}</span>
      </div>
      {dataPropertyType !== DataPropertyType.ENUM && (
        <>
          <div className={styles.throug_line}></div>
          <div className={styles.compute_formula}>
            y={k ?? 'k'}x+{b ?? 'b'}，x为下发值，y为目标值
          </div>
        </>
      )}
    </div>
  );
};

export default SendValueDom;
