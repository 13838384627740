import React, { useRef } from 'react';
import styles from './index.module.scss';
import { WarningFilled } from '@ant-design/icons';
import { Tag } from '@maxtropy/components';
import { Carousel } from 'antd';
import { mockAlarmData } from '../../utils/demoData';

const AlarmRecord = () => {
  const refContainer = useRef<HTMLDivElement>();
  // const [autoPlay, setAutoPlay] = useState(true);

  // useEffect(() => {
  //   refContainer.current?.addEventListener('mouseover', () => {
  //     setAutoPlay(false);
  //   });
  //   refContainer.current?.addEventListener('mouseout', () => {
  //     setAutoPlay(true);
  //   });
  // }, []);

  const levelRender = (level: string) => {
    if (level === 'high') {
      return (
        <Tag border="solid" color="#FAAD14">
          高级
        </Tag>
      );
    } else if (level === 'highest') {
      return (
        <Tag border="solid" color="#E64242">
          最高级
        </Tag>
      );
    } else if (level === 'middle') {
      return (
        <Tag border="solid" color="#4A90E2">
          中级
        </Tag>
      );
    } else {
      return (
        <Tag border="solid" color="#87d068">
          低级
        </Tag>
      );
    }
  };

  return (
    <>
      <div className={styles.topContentSty}>
        <div>
          <WarningFilled style={{ color: '#FAAD14' }} />
          <span className={styles.alarmInfoTitle}>报警信息</span>
        </div>
        <div>
          <span className={styles.alarmNumberTitle}>数量</span>
          <span className={styles.alarmNumber}>4</span>
        </div>
      </div>
      <div
        className={styles.infoContent}
        ref={d => {
          if (d) {
            refContainer.current = d;
          }
        }}
      >
        <Carousel
          autoplay={true}
          vertical={true}
          dots={false}
          cssEase="linear"
          autoplaySpeed={2000}
          speed={1500}
          slidesToShow={3}
        >
          {mockAlarmData.map(item => (
            <div className={styles.itemSty} key={item.id}>
              <div className={styles.itemTopSty}>
                <div className={styles.timeSty}>{item.time}</div>
                <div>{levelRender(item.level)}</div>
              </div>
              <div className={styles.alarmDetailInfoSty}>{item.desc}</div>
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default AlarmRecord;
