import { Graph, Node } from '@antv/x6';
import { Modal } from '@maxtropy/components';
import { BusBarCircleNumber, FcsList, GateWayList, NodeData, NodeType, NodeTypeDisplay, TreeNode } from '../type';
import { loopTreeTofindParent } from './graph';
import {
  exceptAnti,
  findBottomDataSplitByBusBar,
  findDrawIndexByCurrentNode,
  findTopDataSplitByBusBar,
  loopTreeTofindId,
} from './utils';

// 节点与节点之间限制条件
export function limitByNodes(
  nodeKey: NodeType,
  node: Node<Node.Properties>,
  upperOrDown: string = 'down',
  circleIndex?: number,
  graphRef?: Graph,
  allNodeDataRef?: TreeNode[],
  gatewayListRef?: GateWayList[],
  fcsListRef?: FcsList[],
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    circleIndex?: number,
    circleArr?: BusBarCircleNumber[]
  ) => void,
  circleArr?: BusBarCircleNumber[]
) {
  // 节点之间校验
  const currentNodedata: NodeData = node.getData();
  // 查找该元素是第几棵树下的
  const drawIndex = findDrawIndexByCurrentNode(currentNodedata, allNodeDataRef);
  const findItem = loopTreeTofindId([allNodeDataRef![drawIndex]], currentNodedata.id, currentNodedata.element);
  const findParentItem = loopTreeTofindParent(
    [allNodeDataRef![drawIndex]],
    currentNodedata.id,
    currentNodedata.element
  );

  // 只要点前节点不是电网侧都禁用AccessPoint
  if (nodeKey === NodeType.GRID_ACCESS_POINT) {
    if (currentNodedata.element !== NodeType.GRID_SIDE) {
      Modal.error({
        title: `当前节点下禁止添加${NodeTypeDisplay[NodeType.GRID_ACCESS_POINT]}`,
      });
      return;
    }
  }
  if (currentNodedata.element === NodeType.GRID_SIDE) {
    if (nodeKey !== NodeType.GRID_ACCESS_POINT) {
      Modal.error({
        title: `当前节点下仅能添加${NodeTypeDisplay[NodeType.GRID_ACCESS_POINT]}`,
      });
      return;
    }
  }
  // 电网接入点下方加号：
  // 不能添加储能站、备用电源
  if (currentNodedata.element === NodeType.GRID_ACCESS_POINT) {
    if (nodeKey === NodeType.GRID_ACCESS_POINT || nodeKey === NodeType.BSA || nodeKey === NodeType.BACKUP_POWER) {
      Modal.error({
        title: `当前节点下禁止添加${NodeTypeDisplay[nodeKey]}`,
      });
      return;
    }
  }
  // 母线下方不可添加母线
  // && !findItem?.children?.[index!]
  if (currentNodedata.element === NodeType.BUS_BAR) {
    if (nodeKey === NodeType.BUS_BAR || nodeKey === NodeType.GRID_ACCESS_POINT) {
      Modal.error({
        title: `当前节点下禁止添加${NodeTypeDisplay[nodeKey]}`,
      });
      return;
    }
  }

  // s73禁止在元素中间添加母线
  if (
    (findItem?.children && findItem?.children.length > 0 && nodeKey === NodeType.BUS_BAR) ||
    (upperOrDown === 'upper' && nodeKey === NodeType.BUS_BAR)
  ) {
    Modal.error({
      title: '禁止在两节点之间补母线',
    });
    return;
  }

  // 元素上下加号合并后: (可添加元素有变压器、母线、计量电表、防逆流装置)
  if ((findItem?.children && findItem?.children.length > 0 && upperOrDown === 'down') || upperOrDown === 'upper') {
    if (
      nodeKey === NodeType.BACKUP_POWER ||
      nodeKey === NodeType.BSA ||
      nodeKey === NodeType.CHARGING_STATION ||
      nodeKey === NodeType.GRID_ACCESS_POINT ||
      nodeKey === NodeType.LOAD ||
      nodeKey === NodeType.PVA
    ) {
      Modal.error({
        title: `当前节点下禁止添加${NodeTypeDisplay[nodeKey]}`,
      });
      return;
    }
  }

  // if上下元素包含母线：剔除母线
  if (
    (findItem?.children &&
      findItem?.children.length > 0 &&
      upperOrDown === 'down' &&
      findItem!.children[0].element === NodeType.BUS_BAR) ||
    // currentNodedata.element === NodeType.BUS_BAR ||
    (upperOrDown === 'upper' && findParentItem?.element === NodeType.BUS_BAR)
  ) {
    if (
      nodeKey === NodeType.BSA ||
      nodeKey === NodeType.PVA ||
      nodeKey === NodeType.LOAD ||
      nodeKey === NodeType.CHARGING_STATION ||
      nodeKey === NodeType.BACKUP_POWER ||
      nodeKey === NodeType.BUS_BAR
    ) {
      Modal.error({
        title: `当前节点下禁止添加${NodeTypeDisplay[nodeKey]}`,
      });
      return;
    }
  }

  // if上下元素包含变压器：剔除变压器
  if (
    (findItem?.children &&
      findItem?.children.length > 0 &&
      upperOrDown === 'down' &&
      findItem!.children[0].element === NodeType.TRANSFORMER) ||
    // currentNodedata.element === NodeType.TRANSFORMER ||
    (upperOrDown === 'upper' && findParentItem?.element === NodeType.TRANSFORMER)
  ) {
    if (nodeKey === NodeType.TRANSFORMER) {
      Modal.error({
        title: `当前节点下禁止添加${NodeTypeDisplay[NodeType.TRANSFORMER]}`,
      });
      return;
    }
    if (
      nodeKey === NodeType.BSA ||
      nodeKey === NodeType.PVA ||
      nodeKey === NodeType.LOAD ||
      nodeKey === NodeType.CHARGING_STATION ||
      nodeKey === NodeType.BACKUP_POWER
    ) {
      Modal.error({
        title: `当前节点下禁止添加${NodeTypeDisplay[nodeKey]}`,
      });
      return;
    }
  }

  // if上下元素包含储能、光伏站、负载、充电站、备用电源、防逆流：剔除防逆流
  if (
    (findItem?.children &&
      findItem?.children.length > 0 &&
      upperOrDown === 'down' &&
      exceptAnti(findItem!.children[0].element)) ||
    (upperOrDown === 'upper' && exceptAnti(findParentItem?.element!))
  ) {
    if (nodeKey === NodeType.ANTI_BACKFLOW_DEVICE) {
      Modal.error({
        title: `当前节点下禁止添加${NodeTypeDisplay[NodeType.ANTI_BACKFLOW_DEVICE]}`,
      });
      return;
    }
    if (
      nodeKey === NodeType.BSA ||
      nodeKey === NodeType.PVA ||
      nodeKey === NodeType.LOAD ||
      nodeKey === NodeType.CHARGING_STATION ||
      nodeKey === NodeType.BACKUP_POWER
    ) {
      Modal.error({
        title: `当前节点下禁止添加${NodeTypeDisplay[nodeKey]}`,
      });
      return;
    }
  }

  // 纵线加号（电网侧到母线为单独一个纵线）：
  // if纵线包含防逆流：不能添加储能、光伏站、负载、充电站、备用电源、防逆流

  let topArr: TreeNode[] = [];
  let bottomArr: TreeNode[] = [];
  topArr = findTopDataSplitByBusBar([allNodeDataRef![drawIndex]], currentNodedata.id, currentNodedata.element);
  if (findItem?.element === NodeType.BUS_BAR) {
    topArr = [];
    bottomArr = [];
  } else {
    bottomArr = findBottomDataSplitByBusBar(findItem?.children);
  }
  const vlineArr = [...topArr, ...bottomArr].filter(i => i.element !== NodeType.BUS_BAR);

  if (vlineArr.find(i => i.element === NodeType.ANTI_BACKFLOW_DEVICE)) {
    if (
      nodeKey === NodeType.BSA ||
      nodeKey === NodeType.PVA ||
      nodeKey === NodeType.LOAD ||
      nodeKey === NodeType.CHARGING_STATION ||
      nodeKey === NodeType.BACKUP_POWER ||
      nodeKey === NodeType.ANTI_BACKFLOW_DEVICE
    ) {
      Modal.error({
        title: `当前节点下禁止添加储能、光伏站、负载、充电站、备用电源、防逆流`,
      });
      return;
    }
  }
  if (
    vlineArr.find(i => i.element === NodeType.BSA) ||
    vlineArr.find(i => i.element === NodeType.PVA) ||
    vlineArr.find(i => i.element === NodeType.LOAD) ||
    vlineArr.find(i => i.element === NodeType.CHARGING_STATION) ||
    vlineArr.find(i => i.element === NodeType.BACKUP_POWER) ||
    vlineArr.find(i => i.element === NodeType.ANTI_BACKFLOW_DEVICE)
  ) {
    if (nodeKey === NodeType.ANTI_BACKFLOW_DEVICE) {
      Modal.error({
        title: `当前节点下禁止添加${NodeTypeDisplay[nodeKey]}`,
      });
      return;
    }
  }
  if (vlineArr.find(i => i.element === NodeType.TRANSFORMER)) {
    // if纵线包含变压器：不能添加变压器
    if (nodeKey === NodeType.TRANSFORMER) {
      Modal.error({
        title: `当前节点下禁止添加${NodeTypeDisplay[NodeType.TRANSFORMER]}`,
      });
      return;
    }
  }
  handleCreateNode!(
    upperOrDown,
    [allNodeDataRef![drawIndex]],
    node,
    graphRef!,
    nodeKey,
    gatewayListRef!,
    fcsListRef!,
    circleIndex,
    circleArr
  );
}
