import qs from 'qs';
import { fetch } from '@maxtropy/components';
import { Key } from 'react';

export enum DataPropertyType {
  YC = 1,
  ENUM,
  STRING,
  YK,
  YT,
}

export interface ModalRes {
  id: number;
  modelNo: string;
  manufacturerId: number;
  deviceTypeId: number;
  createTime: string;
  updateTime: string;
  manufacturerName: string;
}

export interface ModalReq {
  deviceTypeIds?: Key; //设备类目id
  manufacturerId?: Key; //厂商id
}

// 获取物模型型号
export function getModel(params: ModalReq): Promise<ModalRes[]> {
  return fetch(`/api/physical-model/list?${qs.stringify(params, { indices: false })}`);
}

export enum ControlType {
  Platform = 1,
}

export const ControlTypeDisplay = {
  [ControlType.Platform]: '平台',
};

export enum OperationType {
  ADD = 1,
  EDIT,
  DELETE,
}
export const formateOperationTypeDisplay = {
  [OperationType.ADD]: '新增',
  [OperationType.EDIT]: '编辑',
  [OperationType.DELETE]: '删除',
};

export const formateOperationTypeColor = {
  [OperationType.ADD]: 'success',
  [OperationType.EDIT]: 'info',
  [OperationType.DELETE]: 'warning',
};

export function isJSON(str?: string) {
  if (!str) return false;
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}
