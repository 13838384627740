import React, { Key, useEffect, useState } from 'react';
import styles from './index.module.scss';
import Title from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Title';
import Footer from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Footer';
import { NodeType as NodeTypeClass } from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';

import { isNil } from 'lodash-es';
import {
  apiV2DispatchUetStructUniversalJoinOpenSavePost,
  apiV2DispatchUetStructUniversalJoinOpenUpdatePost,
  apiV2DispatchUetStructUniversalJoinOpenDeletePost,
  apiV2DispatchUetStructUniversalJoinOpenPublishDetailPost,
  apiV2DispatchUetStructUniversalJoinOpenGetPost,
  apiV2DispatchUetStructUniversalJoinOpenDeviceListPost,
  V2DispatchUetStructUniversalJoinOpenDeviceListPostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { useRequest } from 'ahooks';
import { Button, Form, Input, Modal, Select } from '@maxtropy/components';
import { DoubleRightOutlined } from '@ant-design/icons';
import { NodeType, TreeNode, allInfoProps } from '@/pages/SchedulingTopology/type';
import { findTopGridAccessPointData } from '../EnergyStorageStation/utils';

const { Item } = Form;

interface Props {
  id?: number;
  open?: boolean;
  dispatchGroupId?: number;
  isPreview?: boolean;
  isShowModal?: (open: boolean) => void;
  getFinishedValues?: (values: any) => void;
  shouldDeleteElement?: (state: boolean) => void;
  version?: number;
  allInfo?: allInfoProps;
  allNodeData?: TreeNode[];
}
const UniversalSwitchModal: React.FC<Props> = ({
  id,
  dispatchGroupId,
  open,
  isPreview,
  isShowModal,
  getFinishedValues,
  shouldDeleteElement,
  version,
  allInfo,
  allNodeData,
}) => {
  const [form] = Form.useForm();
  const urlParams = new URLSearchParams(window.location.search);
  const topologyId = urlParams.get('topologyId');
  const [iconVisible, setIconVisible] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();
  const [deviceList, setDeviceList] = useState<V2DispatchUetStructUniversalJoinOpenDeviceListPostResponse['list']>();

  const [gridAccessPointId, setGridAccessPointId] = useState<Key>(); // 电网接入点id
  const onShowIcon = () => {
    form.validateFields().then(() => {
      setIconVisible(true);
    });
  };

  const onCancel = () => {
    form.resetFields();
    setTitle(undefined);
    isShowModal?.(false);
  };

  const { data: detail } = useRequest(
    async () => {
      if (isNil(id) || !open) return Promise.resolve(undefined);
      setIsDetail(true);
      let detail;
      if (!isNil(version)) {
        detail = await apiV2DispatchUetStructUniversalJoinOpenPublishDetailPost({ id, version });
      } else {
        detail = await apiV2DispatchUetStructUniversalJoinOpenGetPost({ id });
      }
      if (detail) {
        form.setFieldsValue({
          ...detail,
          deviceIds: detail?.deviceList?.map(item => item.id),
        });
        setIconVisible(true);
        setTitle(detail.name);
      }
      return detail;
    },
    {
      refreshDeps: [id, open, version],
    }
  );

  // 通过当前id与type查找电网接入点id
  useEffect(() => {
    if (detail) {
      if (allNodeData) {
        const gridAccessPointData = findTopGridAccessPointData(
          allNodeData,
          detail.id,
          NodeType.UNIVERSAL_JOINT_OPENING
        );
        setGridAccessPointId(gridAccessPointData?.id);
      }
    } else {
      if (allInfo) {
        const gridAccessPointData = findTopGridAccessPointData(
          allInfo.allNodeData,
          allInfo.node.getData().id,
          allInfo.node.getData().element
        );
        setGridAccessPointId(gridAccessPointData?.id);
      }
    }
  }, [allInfo, detail, allNodeData]);
  // 断路器下拉列表
  useEffect(() => {
    if (!open || !topologyId || !gridAccessPointId) return;
    apiV2DispatchUetStructUniversalJoinOpenDeviceListPost({ gridAccessPointId, uetStructId: +topologyId }).then(res => {
      setDeviceList(res.list);
    });
  }, [open, topologyId, gridAccessPointId]);

  return (
    <>
      <Modal
        open={open}
        contentClassName="modal-form-content"
        title={
          <Title
            id={id}
            styles={styles}
            isDetail={isDetail}
            isPreview={isPreview}
            title={title ?? '通用开关'}
            delMethod={apiV2DispatchUetStructUniversalJoinOpenDeletePost}
            onEdit={() => setIsDetail(false)}
            onCancel={onCancel}
            shouldDeleteElement={state => shouldDeleteElement?.(state)}
            nodeType={NodeTypeClass.SWITCH}
          />
        }
        footer={
          <Footer
            isDetail={isDetail}
            styles={styles}
            form={form}
            dispatchGroupId={dispatchGroupId}
            createMethod={apiV2DispatchUetStructUniversalJoinOpenSavePost}
            updateMethod={apiV2DispatchUetStructUniversalJoinOpenUpdatePost}
            getFinishedValues={val => getFinishedValues?.(val)}
            onCancel={onCancel}
          />
        }
        onCancel={onCancel}
        destroyOnClose
      >
        <div className={styles.modalWrapper}>
          <Form form={form} labelCol={{ span: 6 }} labelAlign="left">
            <Item name="name" label="通用开关名称" rules={[{ required: true, min: 2, max: 30 }]}>
              <Input maxLength={30} placeholder="请输入" disabled={isDetail} allowClear />
            </Item>

            <Item name="deviceIds" label="断路器">
              <Select
                mode="multiple"
                placeholder="请选择"
                options={deviceList?.map(item => ({
                  label: item.name,
                  value: item.id,
                }))}
                showSearch
                optionFilterProp="label"
                disabled={isDetail}
                allowClear
              />
            </Item>

            <Item name="id" hidden />

            <div className={styles.preview}>
              <Button type="link" className={styles.previewBtn} onClick={onShowIcon}>
                查看图标
              </Button>

              <div className={styles.iconWrapper}>
                {iconVisible && (
                  <div className={styles.iconBox}>
                    <div className={styles.icon} />

                    <Item noStyle dependencies={['name']}>
                      {({ getFieldValue }) => <div className={styles.iconText}>{getFieldValue('name')}</div>}
                    </Item>
                    <Item noStyle dependencies={['deviceIds']}>
                      {({ getFieldValue }) => {
                        let datas = getFieldValue('deviceIds');
                        return datas && datas?.length ? (
                          <div style={{ fontSize: 12 }} className={styles.iconText}>
                            断路器数量:{datas?.length}
                          </div>
                        ) : (
                          ''
                        );
                      }}
                    </Item>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default UniversalSwitchModal;
