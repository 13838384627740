export enum AlarmLevel {
  HIGHEST,
  HIGH,
  MIDDLE,
  LOW,
  LOWEST,
}

export const AlarmLevelDisplay = {
  [AlarmLevel.HIGHEST]: '最高级',
  [AlarmLevel.HIGH]: '高级',
  [AlarmLevel.MIDDLE]: '中级',
  [AlarmLevel.LOW]: '低级',
  [AlarmLevel.LOWEST]: '最低级',
};

export const AlarmLevelColorDisplay = {
  [AlarmLevel.HIGHEST]: 'error',
  [AlarmLevel.HIGH]: 'warning',
  [AlarmLevel.MIDDLE]: 'info',
  [AlarmLevel.LOW]: 'success',
  [AlarmLevel.LOWEST]: '#ABD335',
};
