import ShowInput from '@/components/ShowInput';
import { EllipsisSpan, Form, InputNumber, Select, TimePicker } from '@maxtropy/components';
import { V2BsaControlConfigPostResponse } from '@maxtropy/intelligent-dispath-apis-v2';
import { ColumnsType } from 'antd/es/table';
import { ActionType, ActionTypeTypeDisplay, actionTypeOptions } from './type';
import { isNil } from 'lodash-es';
import dayjs from 'dayjs';

export interface PowerListItem {
  // id: Key;
  type: number;
  startTime: number;
  endTime: number;
  power?: number;
}

type TimeChunk = { startTime: number; endTime: number };

const PowerItem = (index: number, bsaConfigData?: V2BsaControlConfigPostResponse) => {
  return (
    <Form.Item noStyle dependencies={['chargingAndDischargingPeriodDetail', index, 'type']}>
      {({ getFieldValue }) => {
        const configType = getFieldValue(['chargingAndDischargingPeriodDetail', index, 'type']);
        if (Number(configType) === ActionType.Charge) {
          return (
            <Form.Item
              style={{ marginBottom: 0 }}
              name={['chargingAndDischargingPeriodDetail', index, 'power']}
              rules={[{ required: true, message: '请输入功率' }]}
            >
              <InputNumber
                placeholder="请输入"
                style={{ width: '80%' }}
                min={1}
                max={bsaConfigData?.maxChargePower ?? 99999999}
              />
            </Form.Item>
          );
        } else if (Number(configType) === ActionType.DisCharge) {
          return (
            <Form.Item
              style={{ marginBottom: 0 }}
              name={['chargingAndDischargingPeriodDetail', index, 'power']}
              rules={[{ required: true, message: '请输入功率' }]}
            >
              <InputNumber
                placeholder="请输入"
                style={{ width: '80%' }}
                min={1}
                max={bsaConfigData?.maxDischargePower ?? 99999999}
              />
            </Form.Item>
          );
        } else {
          return (
            <Form.Item
              style={{ marginBottom: 0 }}
              name={['chargingAndDischargingPeriodDetail', index, 'power']}
              rules={[{ required: true, message: '请输入功率' }]}
              initialValue={0}
            >
              <InputNumber placeholder="请输入" style={{ width: '80%' }} disabled />
            </Form.Item>
          );
        }
      }}
    </Form.Item>
  );
};

export const columnsWithFollowPrice = (
  bsaConfigData?: V2BsaControlConfigPostResponse,
  isDetail?: boolean
): ColumnsType<PowerListItem> => {
  return [
    {
      title: '动作',
      dataIndex: 'type',
      render(value, _) {
        return <EllipsisSpan value={ActionTypeTypeDisplay[value as ActionType]}></EllipsisSpan>;
      },
    },
    {
      title: '时间段',
      render(_, _record, index) {
        return (
          <Form.Item name={['chargingAndDischargingPeriodDetail', index, 'time']} style={{ marginBottom: 0 }}>
            <ShowInput />
          </Form.Item>
        );
      },
    },
    {
      title: '功率(kW)',
      dataIndex: 'power',
      // editable: true,
      render: (value, _record, index) => {
        if (isDetail) {
          return <EllipsisSpan value={value}></EllipsisSpan>;
        } else {
          return PowerItem(index, bsaConfigData);
        }
      },
    },
  ];
};

export const columnsWithCustomFollowMax: ColumnsType<PowerListItem> = [
  {
    title: '动作',
    dataIndex: 'type',
    render(value, _) {
      return <EllipsisSpan value={ActionTypeTypeDisplay[value as ActionType]}></EllipsisSpan>;
    },
  },
  {
    title: '开始时间',
    dataIndex: 'startTime',
    render(value, _) {
      return <EllipsisSpan value={value}></EllipsisSpan>;
    },
  },
  {
    title: '结束时间',
    dataIndex: 'endTime',
    render(value, _) {
      return <EllipsisSpan value={value}></EllipsisSpan>;
    },
  },
];

export const columnsWithCustomCustom = (
  bsaConfigData?: V2BsaControlConfigPostResponse,
  isDetail?: boolean
): ColumnsType<PowerListItem> => [
  {
    title: '动作',
    dataIndex: 'type',
    render(value, _) {
      return <EllipsisSpan value={ActionTypeTypeDisplay[value as ActionType]}></EllipsisSpan>;
    },
  },
  {
    title: '开始时间',
    dataIndex: 'startTime',
    render(value, _) {
      return <EllipsisSpan value={value}></EllipsisSpan>;
    },
  },
  {
    title: '结束时间',
    dataIndex: 'endTime',
    render(value, _) {
      return <EllipsisSpan value={value} />;
    },
  },
  {
    title: '功率(kW)',
    dataIndex: 'power',
    render: (value, _record, index) => {
      if (isDetail) {
        return <EllipsisSpan value={value} />;
      } else {
        return PowerItem(index, bsaConfigData);
      }
    },
  },
];

// 验证时间是否有重合
const checkTimeOverlap = (timeList: TimeChunk[]) => {
  if (!timeList.some(k => dayjs(k.startTime).format('HH:mm') === '00:00')) {
    return false;
  }
  if (!timeList.some(k => dayjs(k.endTime).format('HH:mm') === '00:00')) {
    return false;
  }
  for (let i = 0; i < timeList.length - 1; i++) {
    if (timeList[i + 1].startTime < timeList[i].endTime) {
      return true;
    }
  }
  return false;
};

// 验证时间是否满足24小时
export const checekTime24Hour = (timeList: any) => {
  const newList = timeList
    .map((k: any) => {
      if (k.endTime.format('HH:mm') == '00:00') {
        return {
          startTime: k.startTime.valueOf(),
          endTime: k.endTime.add(1, 'days').valueOf(),
        };
      }
      return {
        startTime: k.startTime.valueOf(),
        endTime: k.endTime.valueOf(),
      };
    })
    .sort((a: any, b: any) => a.startTime - b.startTime);

  const seconds = newList.reduce((acc: number, current: TimeChunk) => {
    return acc + current.endTime - current.startTime;
  }, 0);

  const daySeconds = 24 * 60 * 60 * 1000;
  return seconds !== daySeconds;
};

const disabledTime = (now: any, getFieldValue: any, nowIndex: number, isStart: boolean) => {
  const timeList = getFieldValue('chargingAndDischargingPeriodDetail');

  const nowTime = {
    startTime: timeList[nowIndex]?.startTime?.valueOf(),
    endTime:
      timeList[nowIndex].endTime?.format('HH:mm') === '00:00'
        ? timeList[nowIndex]?.endTime?.add(1, 'days').valueOf()
        : timeList[nowIndex]?.endTime?.valueOf(),
  };

  const deadline = isStart ? nowTime.endTime : nowTime.startTime;

  // step1:把结束时间为00:00加1天,并转化为时间戳
  // step2:对时间戳的开始时间进行排序
  // step3:将时间戳转换为hour和minute
  const newList = timeList
    .filter((m: any, index: number) => index !== nowIndex && m.startTime && m.endTime)
    .map((k: any) => {
      if (k.endTime.format('HH:mm') == '00:00') {
        return {
          startTime: k.startTime.valueOf(),
          endTime: k.endTime.add(1, 'days').valueOf(),
        };
      }
      return {
        startTime: k.startTime.valueOf(),
        endTime: k.endTime.valueOf(),
      };
    })
    .sort((a: TimeChunk, b: TimeChunk) => a.startTime - b.startTime);

  // 针对00:00做特殊判断处理
  const startZeroTime = newList.some((m: TimeChunk) => dayjs(m.startTime).format('HH:mm') === '00:00');
  const endZeroTime = newList.some((m: TimeChunk) => dayjs(m.endTime).format('HH:mm') === '00:00');

  const minutes = new Array(24).fill(0).map((_, hourIndex) => {
    return {
      hour: hourIndex,
      mins: new Array(60).fill(0).map((_, minIndex) => {
        const currentTime = dayjs().hour(hourIndex).minute(minIndex).second(0).millisecond(0).valueOf();

        // const now = dayjs(currentTime).format('HH:mm');

        const nowDisable = isStart ? currentTime > deadline : currentTime < deadline;

        if (hourIndex === 0 && minIndex === 0) {
          return {
            minute: minIndex,
            disabled: isStart ? startZeroTime : endZeroTime,
          };
        }
        return {
          minute: minIndex,
          disabled:
            nowDisable ||
            newList.some((n: any) => {
              if (n.startTime === n.endTime) {
                return currentTime === n.startTime;
              }
              if (isStart && n.startTime === currentTime) {
                return true;
              }
              if (!isStart && n.endTime === currentTime) {
                return true;
              }
              return currentTime > n.startTime && currentTime < n.endTime;
            }),
        };
      }),
    };
  });

  const disabledHours = minutes
    .filter(item => {
      return !item.mins.filter(k => !k.disabled).length;
    })
    .map(m => m.hour);

  return {
    disabledHours: () => {
      return disabledHours;
    },
    disabledMinutes: (selectedHour?: number) => {
      return minutes
        .find(m => m.hour == (selectedHour ?? 0))!
        .mins.filter(k => k.disabled)
        .map(n => n.minute);
    },
  };
};

const timeValidator = (value: any, getFieldValue: any, index: number) => {
  const timeList = getFieldValue('chargingAndDischargingPeriodDetail');
  const startTime = timeList[index].startTime;
  const endTime = timeList[index].endTime;

  if (isNil(startTime) || isNil(endTime)) {
    return Promise.resolve();
  }

  if (!isNil(startTime) && !isNil(endTime) && endTime.format('HH:mm') != '00:00' && dayjs(startTime).isAfter(endTime)) {
    return Promise.reject(new Error('开始时间需要早于结束时间'));
  }

  const newList = timeList
    .filter((n: any) => !isNil(n.startTime) && !isNil(n.endTime))
    .map((k: any) => {
      if (k.endTime.format('HH:mm') == '00:00') {
        return {
          startTime: k.startTime.valueOf(),
          endTime: k.endTime.add(1, 'days').valueOf(),
        };
      }
      return {
        startTime: k.startTime.valueOf(),
        endTime: k.endTime.valueOf(),
      };
    })
    .sort((a: any, b: any) => a.startTime - b.startTime);

  if (checkTimeOverlap(newList)) {
    return Promise.reject(new Error('与已存在时间段重叠'));
  }

  // if (checekTime24Hour(newList)) {
  //   return Promise.reject(new Error('时间没有满24小时'));
  // }

  return Promise.resolve();
};

export const columnsWithCustomTimeFollowMax: ColumnsType<PowerListItem> = [
  {
    title: '动作',
    dataIndex: 'type',
    width: 140,
    render(value, _, index) {
      return (
        <Form.Item dependencies={['chargingAndDischargingPeriodDetail', index]} noStyle>
          {({ setFieldValue }) => {
            return (
              <Form.Item
                style={{ marginBottom: 0 }}
                name={['chargingAndDischargingPeriodDetail', index, 'type']}
                rules={[{ required: true, message: '请选择' }]}
              >
                <Select
                  options={actionTypeOptions}
                  placeholder="请选择"
                  onChange={e => {
                    if (Number(e) == ActionType.StandBy) {
                      setFieldValue(['chargingAndDischargingPeriodDetail', index, 'power'], 0);
                    } else {
                      setFieldValue(['chargingAndDischargingPeriodDetail', index, 'power'], undefined);
                    }
                  }}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      );
    },
  },
  {
    title: '开始时间',
    dataIndex: 'startTime',
    width: 200,
    render(value, _, index) {
      return (
        <Form.Item dependencies={['chargingAndDischargingPeriodDetail']} noStyle>
          {({ getFieldValue }) => {
            return (
              <Form.Item
                style={{ marginBottom: 0 }}
                name={['chargingAndDischargingPeriodDetail', index, 'startTime']}
                rules={[
                  { required: true, message: '请选择' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      return timeValidator(value, getFieldValue, index);
                    },
                  }),
                ]}
              >
                <TimePicker
                  format="HH:mm"
                  showNow={false}
                  disabledTime={now => disabledTime(now, getFieldValue, index, true)}
                  placeholder="开始时间"
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      );
    },
  },
  {
    title: '结束时间',
    dataIndex: 'endTime',
    width: 200,
    render(value, _, index) {
      return (
        <Form.Item dependencies={['chargingAndDischargingPeriodDetail']} noStyle>
          {({ getFieldValue }) => {
            return (
              <Form.Item
                style={{ marginBottom: 0 }}
                name={['chargingAndDischargingPeriodDetail', index, 'endTime']}
                rules={[
                  { required: true, message: '请选择' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      return timeValidator(value, getFieldValue, index);
                    },
                  }),
                ]}
              >
                <TimePicker
                  format="HH:mm"
                  disabledTime={now => disabledTime(now, getFieldValue, index, false)}
                  showNow={false}
                  placeholder="结束时间"
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      );
    },
  },
];

export const columnsWithCustomTimeFllowPrice = (
  bsaConfigData?: V2BsaControlConfigPostResponse
): ColumnsType<PowerListItem> => [
  ...columnsWithCustomTimeFollowMax,
  {
    title: '功率(kW)',
    width: 180,
    dataIndex: 'power',
    render: (value, _record, index) => {
      return PowerItem(index, bsaConfigData);
    },
  },
];
