import { Graph, Node } from '@antv/x6';
import { Cascader, Dropdown, Popconfirm, Tooltip } from 'antd';
import React, { FC, useMemo, useState } from 'react';
import { FcsList, GateWayList, nodeElementLinkTree, NodeType, TreeNode } from '../../type';
import styles from './index.module.scss';
import backupBattery from '../../imgs/backupBattery.gif';
import { findDrawIndexByCurrentNode } from '../../utils/utils';
import { SingleValueType } from 'rc-cascader/lib/Cascader';
import { DefaultOptionType } from 'antd/es/cascader';
import { CloseOutlined } from '@ant-design/icons';
import { limitByNodes } from '../../utils/nodelimit';

export interface BackupPowerProps {
  node: Node;
  graphRef?: Graph;
  allNodeDataRef?: TreeNode[];
  gatewayListRef?: GateWayList[];
  fcsListRef?: FcsList[];
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void;
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void;
  showModalDetail?: (id: number, type: NodeType) => void;
  nodeElementLink?: nodeElementLinkTree[];
}

const BackupPower: FC<BackupPowerProps> = props => {
  const {
    node,
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink,
  } = props;
  const [cascaderValue, setCascaderValue] = useState<SingleValueType>();
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);

  const data = useMemo(() => {
    return node.getData();
  }, [node.getData()]);

  const isShowTopBtn = useMemo(() => {
    return data?.isShowAllAddDelBtn && data?.isShowTopAddBtn;
  }, [data]);

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  return (
    <div className={styles.customBackUppowerReactNode} style={{ opacity: data.isShowNetWork ? 0.5 : 1 }}>
      {data?.isShowAllAddDelBtn && (
        <Popconfirm
          overlayClassName={styles.popconfirmSty}
          title={
            <p style={{ color: '#fff' }}>
              确定要删除
              <span style={{ color: '#E64242' }}> {data.value.name}</span>
              及该设备下级所有关联对象吗？
            </p>
          }
          onConfirm={e => {
            e?.stopPropagation();
            confirm?.(
              NodeType.BACKUP_POWER,
              data.id,
              findDrawIndexByCurrentNode({ id: data?.id, element: data?.element }, allNodeDataRef)
            );
          }}
          okText="确定"
          cancelText="取消"
        >
          <div
            onClick={e => {
              e.stopPropagation();
            }}
            className={styles.deleteIcon}
          >
            <CloseOutlined />
          </div>
        </Popconfirm>
      )}
      {isShowTopBtn && (
        <Dropdown
          destroyPopupOnHide
          overlayClassName={styles.drapDownStyle}
          open={dropDownOpen}
          onOpenChange={open => {
            setDropDownOpen(open);
          }}
          dropdownRender={() => (
            <Cascader
              popupClassName={styles.drapDownCascaderSty}
              options={nodeElementLink}
              allowClear={false}
              fieldNames={{ value: 'key' }}
              showSearch={{ filter }}
              notFoundContent={'暂无数据'}
              placeholder={'请输入元素名称'}
              expandTrigger={'hover'}
              open={dropDownOpen}
              value={cascaderValue}
              onChange={value => {
                setCascaderValue(value);
                limitByNodes(
                  value[value.length - 1] as NodeType,
                  node,
                  'upper',
                  undefined,
                  graphRef,
                  allNodeDataRef,
                  gatewayListRef,
                  fcsListRef,
                  handleCreateNode
                );
                setDropDownOpen(false);
              }}
            />
          )}
          trigger={['click']}
        >
          <div
            onClick={e => {
              e.preventDefault();
              setCascaderValue(undefined);
            }}
            className={styles.addCircle}
          >
            <div className={styles.addIcon}>+</div>
          </div>
        </Dropdown>
      )}
      <div className={styles.vline}></div>
      <div
        className={styles.cardSty}
        onClick={() => {
          showModalDetail?.(data.id, NodeType.BACKUP_POWER);
        }}
      >
        <img style={{ width: 40, height: 40 }} src={backupBattery} alt="" />
        <Tooltip title={data.value.name}>
          <div className={styles.titleSty}>{data.value.name}</div>
        </Tooltip>
        {/* {data?.value?.controlObjName && (
          <>
            <div className={styles.dividerSty} />
            <div className={styles.itemControlobjSty}>
              <Tooltip title={data?.value?.controlObjName}>
                <div className={styles.itemControlobjContentSty}>{data?.value?.controlObjName}</div>
              </Tooltip>
            </div>
          </>
        )} */}
      </div>
    </div>
  );
};

export default BackupPower;
