import { FC, useState } from 'react';
import {
  CustomFilter,
  EllipsisSpan,
  Form,
  Input,
  Modal,
  Paging,
  Select,
  Table,
  Tag,
  usePaging,
} from '@maxtropy/components';
import { useRequest } from 'ahooks';
import {
  apiV2BsaAlarmRulePagePost,
  apiV2DeviceDeviceTypeTreeWithoutScenePost,
  V2BsaAlarmRulePagePostRequest,
  V2BsaAlarmRulePagePostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { Cascader } from 'antd';
import { ColumnProps } from 'antd/es/table';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  alarmRuleIdList: number[];
  setAlarmRuleIdList: (alarmRuleIdList: number[]) => void;
  bsaId: number;
}

export enum AlarmLevel {
  HIGHEST,
  HIGH,
  MIDDLE,
  LOW,
  LOWEST,
}

export const AlarmLevelDisplay = {
  [AlarmLevel.HIGHEST]: '最高级',
  [AlarmLevel.HIGH]: '高级',
  [AlarmLevel.MIDDLE]: '中级',
  [AlarmLevel.LOW]: '低级',
  [AlarmLevel.LOWEST]: '最低级',
};

export const AlarmLevelColorDisplay = {
  [AlarmLevel.HIGHEST]: 'error',
  [AlarmLevel.HIGH]: 'warning',
  [AlarmLevel.MIDDLE]: 'info',
  [AlarmLevel.LOW]: 'success',
  [AlarmLevel.LOWEST]: '#ABD335',
};

type DataItem = Exclude<V2BsaAlarmRulePagePostResponse['list'], undefined>[number];

const AlarmRuleModal: FC<Props> = props => {
  const { open, setOpen, alarmRuleIdList, setAlarmRuleIdList, bsaId } = props;
  const [searchParams, setSearchParams] = useState<Partial<V2BsaAlarmRulePagePostRequest>>({});
  const [searchForm] = Form.useForm();
  const pagingInfo = usePaging(20);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [tempAlarmRuleIdList, setTempAlarmRuleIdList] = useState<number[]>(alarmRuleIdList);

  const alarmLevelOptions = () => {
    return Object.entries(AlarmLevelDisplay).map(i => ({
      label: i[1],
      value: i[0],
    }));
  };

  const { data: deviceTypeData } = useRequest(() => apiV2DeviceDeviceTypeTreeWithoutScenePost({}));

  const { data, loading } = useRequest(
    async () => {
      const res = await apiV2BsaAlarmRulePagePost({
        ...searchParams,
        page: pageOffset,
        size: pageSize,
        neoBsaId: bsaId,
      });
      setTotalCount(res.total ?? 0);
      return res.list;
    },
    {
      refreshDeps: [pageOffset, pageSize, searchParams, bsaId],
    }
  );

  const columns: ColumnProps<DataItem>[] = [
    {
      title: '规则名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '报警等级',
      dataIndex: 'alarmType',
      ellipsis: { showTitle: true },
      width: 130,
      render: (v: AlarmLevel) => (
        <EllipsisSpan
          value={
            <Tag border="solid" color={AlarmLevelColorDisplay[v]}>
              {AlarmLevelDisplay[v]}
            </Tag>
          }
        />
      ),
    },
    {
      title: '报警信息',
      dataIndex: 'alarmName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '规则编号',
      dataIndex: 'code',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所属设备类型',
      dataIndex: 'deviceTypeName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];

  const onFilterFinish = (val: any) => {
    // deviceType传第二级
    const deviceType: [number, number][] | [number][] | undefined = val.deviceType;
    const finalDeviceType = deviceType
      ?.map(item => {
        // 如果有第二级
        if (item[1]) {
          return item[1];
        }
        // 如果没有第二级，只有第一级，说明第一级全选
        else {
          return deviceTypeData?.deviceTypes?.find(i => i.id === item[0])?.children?.map(i => i.id) ?? [];
        }
      })
      .flat();
    setSearchParams({ ...val, deviceType: finalDeviceType });
    setPageOffset(1);
  };

  return (
    <Modal
      size="big"
      open={open}
      onCancel={() => setOpen(false)}
      onOk={() => {
        setAlarmRuleIdList(tempAlarmRuleIdList);
        setOpen(false);
      }}
      title="PCS功率分配保护报警规则选择"
    >
      <CustomFilter
        onFinish={onFilterFinish}
        onReset={() => {
          searchForm.resetFields();
          setSearchParams({});
          setPageOffset(1);
        }}
        form={searchForm}
        colSpan={8}
      >
        <Form.Item label="规则名称" name="name">
          <Input placeholder="请输入"></Input>
        </Form.Item>
        <Form.Item label="规则编号" name="code">
          <Input placeholder="请输入"></Input>
        </Form.Item>
        <Form.Item label="报警信息" name="alarmName">
          <Input placeholder="请输入"></Input>
        </Form.Item>
        <Form.Item label="设备类目" name="deviceType">
          <Cascader
            placeholder="请选择"
            multiple
            options={deviceTypeData?.deviceTypes}
            fieldNames={{
              label: 'name',
              value: 'id',
            }}
            maxTagCount="responsive"
          ></Cascader>
        </Form.Item>
        <Form.Item label="报警等级" name="alarmType">
          <Select placeholder="请选择" options={alarmLevelOptions()} mode="multiple"></Select>
        </Form.Item>
      </CustomFilter>

      <Table
        rowKey="id"
        loading={loading}
        pagination={false}
        scroll={{ y: 400 }}
        showSelectedCount
        selectedCount={alarmRuleIdList.length}
        dataSource={data}
        columns={columns}
        rowSelection={{
          selectedRowKeys: tempAlarmRuleIdList,
          onChange: (selectedRowKeys: any) => {
            setTempAlarmRuleIdList(selectedRowKeys);
          },
        }}
      ></Table>
      <Paging pagingInfo={pagingInfo}></Paging>
    </Modal>
  );
};

export default AlarmRuleModal;
