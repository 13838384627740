export enum NodeMockType {
  GRID_SIDE = 'GRID_SIDE', // 电网侧
  GRID_ACCESS_POINT = 'GRID_ACCESS_POINT', // 电网接入点
  MAIN_METER = 'MAIN_METER', // 进线总电表
  ANTI_BACKFLOW_DEVICE = 'ANTI_BACKFLOW_DEVICE', // 防逆流装置
  TRANSFORMER = 'TRANSFORMER', // 变压器
  AC_BUSBAR = 'AC_BUSBAR', // 交流母线
  ACDC_CONVERTER = 'ACDC_CONVERTER', // 交直流变换器
  DC_BUSBAR = 'DC_BUSBAR', // 直流母线
  DC_CONVERTER = 'DC_CONVERTER', // 直流装换器
  BILLING_POINT = 'BILLING_POINT', // 计费点
  NODATA_BILLING_POINT = 'NODATA_BILLING_POINT', // 无数据的计费点

  PVA = 'PVA', // 光伏站
  BSA = 'BSA', // 储能站
  LOAD = 'LOAD', // 负载
}
