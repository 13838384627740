import { Graph, Node } from '@antv/x6';
import { Button, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import { useEffect, useRef, useState } from 'react';
import { cancelFullscreen, goFullscreen } from '../SchedulingTopology/utils/graph';
import { BillingType, formatDemoData } from './utils/demoData';
import styles from './index.module.scss';
import registerCustomerNode from './registerCustomerNode';
import { NodeMockType } from './type';
import { initMockScTopoGraph } from './utils/graph';
import { useSize } from 'ahooks';
import AlarmRecord from './components/AlarmRecord';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { calcBsaData, calcPvData, calcSocPercent, randomNumber } from './utils/util';

const SchedulingTopoDemo = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [isFullScreen, setIsFullScreen] = useState(false); // 全屏
  const [currentTime, setCurrentTime] = useState(dayjs()); // 初始化为当前时间
  const [graph, setGraph] = useState<Graph>();

  const refContainer = useRef<HTMLDivElement>();
  const outerRef = useRef<HTMLDivElement>(); // 全屏
  const graphRef = useRef<Graph>(); // 画布实例
  const graphSize = useSize(refContainer.current!);
  const allNodesRef = useRef<Node<Node.Properties>[]>([]);

  // 查询数据
  useEffect(() => {
    if (graphSize?.width) {
      if (graphRef.current) {
        graphRef.current.resize(graphSize?.width);
      } else {
        const graph = initMockScTopoGraph(refContainer.current!);
        graphRef.current = graph;
        setGraph(graph);
        const demoData = formatDemoData(graphSize?.width);
        // 渲染数据
        graphRef.current.addNodes(demoData);
        // 保存所有计费点
        const allNodes = graphRef.current.getNodes();
        allNodesRef.current = allNodes;
      }
    }
  }, [graphSize]);

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreen);
    return () => document.removeEventListener('fullscreenchange', handleFullScreen);
  }, []);

  const handleFullScreen = () => {
    setIsFullScreen(document.fullscreenElement !== null);
  };
  const fullBtn = () => {
    if (outerRef.current) {
      isFullScreen ? cancelFullscreen() : goFullscreen(outerRef.current);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs()); // 每秒更新一次当前时间
    }, 60000);
    return () => clearInterval(intervalId); // 清除定时器
  }, []);

  useEffect(() => {
    if (currentTime && graph) {
      const bsaNode = allNodesRef.current.find(i => i.shape === NodeMockType.BSA); // 储能
      const acDcConverter = allNodesRef.current.find(i => i.shape === NodeMockType.ACDC_CONVERTER); // 交直流转换器
      const officeNode = allNodesRef.current.find(i => i.getData().type === BillingType.OfficeLoad);
      const pvNode = allNodesRef.current.find(i => i.getData().type === BillingType.Pv); // 光伏计费点
      const compressorNode = allNodesRef.current.find(i => i.getData().type === BillingType.Compressor); // 空压机负载
      const productionUnit1Node = allNodesRef.current.find(i => i.getData().type === BillingType.ProductionUnit1); // 1车间生产机组
      const productionUnit2Node = allNodesRef.current.find(i => i.getData().type === BillingType.ProductionUnit2); // 2车间生产机组

      // 交直流转换器数据变化
      if (acDcConverter) {
        const randomBetween5 = randomNumber(5);
        acDcConverter.updateData({
          voltage: 750 * (1 + randomBetween5),
          current: 70 * (1 + randomBetween5),
          power: (750 * (1 + randomBetween5) * 70 * (1 + randomBetween5)) / 1000,
        });
      }

      // 光伏
      if (pvNode) {
        const { voltage, power, current } = calcPvData(currentTime);
        pvNode.updateData({
          voltage: voltage,
          power: power,
          current: current,
        });
      }

      // 储能柜
      if (bsaNode) {
        const { voltage, power, current } = calcBsaData(currentTime);
        bsaNode.updateData({
          voltage: voltage,
          power: power,
          current: current,
        });
        // 储能柜SOC
        const socData = calcSocPercent(currentTime);
        bsaNode.updateData({
          socPercent: socData,
        });
      }

      // 办公负载
      if (officeNode) {
        const randomBetween5 = randomNumber(5);
        const power = acDcConverter?.getData().power - bsaNode?.getData().power - pvNode?.getData().power;
        officeNode.updateData({
          voltage: 750 * (1 + randomBetween5),
          power,
        });
      }

      // 空压机计费点
      if (compressorNode) {
        const randomBetween5 = randomNumber(5);
        compressorNode.updateData({
          voltage: (380 * (1 + randomBetween5)).toFixed(2),
          current: (500 * (1 + randomBetween5)).toFixed(2),
          power: Number(((380 * (1 + randomBetween5) * 500 * (1 + randomBetween5)) / 1000).toFixed(2)),
        });
      }

      if (productionUnit1Node) {
        const randomBetween5 = randomNumber(5);
        productionUnit1Node.updateData({
          voltage: (380 * (1 + randomBetween5)).toFixed(2),
          current: (1900 * (1 + randomBetween5)).toFixed(2),
          power: Number(((380 * (1 + randomBetween5) * 1900 * (1 + randomBetween5)) / 1000).toFixed(2)),
        });
      }

      if (productionUnit2Node) {
        const randomBetween5 = randomNumber(5);
        productionUnit2Node.updateData({
          voltage: (380 * (1 + randomBetween5)).toFixed(2),
          current: (1700 * (1 + randomBetween5)).toFixed(2),
          power: Number(((380 * (1 + randomBetween5) * 1700 * (1 + randomBetween5)) / 1000).toFixed(2)),
        });
      }
    }
  }, [currentTime, graph]);

  registerCustomerNode();

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? [])]}>
      <div
        className={styles.canvasWapper}
        ref={d => {
          if (d) {
            outerRef.current = d;
          }
        }}
      >
        <div className={styles.fullBtnSty}>
          {isFullScreen ? (
            <Button type="default" onClick={() => fullBtn()}>
              <FullscreenExitOutlined />
              退出全屏
            </Button>
          ) : (
            <Button type="default" onClick={() => fullBtn()}>
              <FullscreenOutlined />
              全屏
            </Button>
          )}
        </div>
        <div className={styles.alarmWrapper}>
          <AlarmRecord />
        </div>

        <div className={styles.helloworldApp}>
          <div
            className={styles.appContent}
            style={{ minHeight: isFullScreen ? '100vh' : undefined }}
            ref={d => {
              if (d) {
                refContainer.current = d;
              }
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default SchedulingTopoDemo;
