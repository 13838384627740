import { Empty, Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import React, { createContext, useState, useEffect, useRef } from 'react';
import styles from './index.module.scss';

import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import SideDraw from './components/SideDraw';
import TopoBox from './components/TopoBox';
import HeaderOps from './components/HeaderOps';
import { cancelFullscreen, goFullscreen } from './utils';
import {
  V2GreenMicrowebShowcaseConfListPostResponse,
  apiV2GreenMicrowebShowcaseConfCardPost,
  apiV2GreenMicrowebShowcaseConfListPost,
  V2GreenMicrowebShowcaseConfCardPostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { AlarmConfType, AlarmConfTypeIsShow } from './components/SideDraw/type';
import { useRequest } from 'ahooks';
export type CardConfigListItem = Exclude<V2GreenMicrowebShowcaseConfCardPostResponse['list'], undefined>[number];
export interface ShareDataProps {
  setConfigId?: React.Dispatch<React.SetStateAction<number | undefined>>;
  configId?: number;
  confList?: V2GreenMicrowebShowcaseConfListPostResponse['list'];
  isFullScreen?: boolean;
  setIsExpand?: React.Dispatch<React.SetStateAction<boolean>>;
  isExpand?: boolean;
  cardListInfo?: CardConfigListItem[];
}

export const ShareDataContext = createContext<ShareDataProps>({});

const ConfOverview = () => {
  const routesContext = useBreadcrumbRoutes();
  const [openSider, setOpenSider] = useState(true);
  const baseDivRef = useRef<HTMLDivElement>();
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false); // 是否全屏
  const [configId, setConfigId] = useState<number>(); // 选择组态下拉框
  const [isExpand, setIsExpand] = useState<boolean>(true); // 拓扑图数据点数据全部展开
  const [asideShow, setAsideShow] = useState<boolean>();
  const [cardListInfo, setCardListInfo] = useState<CardConfigListItem[]>([]);

  // 调用接口查询组态下拉
  const { data: confList } = useRequest(async () => {
    const res = await apiV2GreenMicrowebShowcaseConfListPost();
    return res.list;
  });

  // 调用接口查询是否配置了报警列表的展示，没有则隐藏左侧区域
  useEffect(() => {
    if (configId) {
      apiV2GreenMicrowebShowcaseConfCardPost({ id: configId }).then(res => {
        let isShow = res.list?.some(item => item.display === AlarmConfTypeIsShow.Show);
        setCardListInfo(res.list ?? []);
        setAsideShow(isShow);
      });
    }
  }, [configId]);

  const handleFullScreen = () => {
    setIsFullScreen(document.fullscreenElement !== null);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreen);
    return () => document.removeEventListener('fullscreenchange', handleFullScreen);
  }, []);

  const fullBtn = () => {
    if (baseDivRef.current) {
      isFullScreen ? cancelFullscreen() : goFullscreen(baseDivRef.current);
    }
  };

  return (
    <Wrapper routes={routesContext?.routes} className={styles.wrapper}>
      <div
        className={styles.screen_wrapper}
        id="full_screen_box"
        style={{ padding: isFullScreen ? 20 : 0 }}
        ref={ref => {
          if (ref) {
            baseDivRef.current = ref;
          }
        }}
      >
        <ShareDataContext.Provider
          value={{
            setConfigId,
            configId,
            confList,
            isFullScreen,
            setIsExpand,
            isExpand,
            cardListInfo,
          }}
        >
          <div className={styles.page_box}>
            <div className={styles.header_area}>
              <HeaderOps fullBtn={fullBtn} />
            </div>
            {!configId ? (
              <Empty style={{ margin: 'auto' }} />
            ) : (
              <div className={styles.bottom_content}>
                <div className={styles.inner_box}>
                  <div className={styles.left_area} style={{ width: asideShow ? (openSider ? 480 : 0) : 0 }}>
                    <div className={styles.left_box}>
                      <div className={styles.left_box_inner}>
                        <SideDraw />
                      </div>
                    </div>
                    {asideShow && (
                      <div
                        className={styles.toggleBtn}
                        style={{ right: openSider ? 0 : -20 }}
                        onClick={() => setOpenSider(!openSider)}
                      >
                        {openSider ? <LeftOutlined /> : <RightOutlined />}
                      </div>
                    )}
                  </div>
                  <div className={styles.right_area}>
                    <TopoBox />
                  </div>
                </div>
              </div>
            )}
          </div>
        </ShareDataContext.Provider>
      </div>
    </Wrapper>
  );
};

export default ConfOverview;
