import React, { Key, useEffect, useState } from 'react';

import styles from './index.module.scss';
// import ShowInput from '@/shared/components/ShowInput';
import Title from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Title';
import Footer from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Footer';
import AddGatewayModal from '@/pages/EnergyDispatchManagement/ElementCreationModal/Gateway/AddGatewayModal';
import { isEmpty, isNil } from 'lodash-es';
import { useRequest } from 'ahooks';
import {
  apiV2DispatchUetStructGatewayDeletePost,
  apiV2DispatchUetStructGatewayDetailPost,
  apiV2DispatchUetStructGatewayEditPost,
  apiV2DispatchUetStructGatewaySavePost,
  apiV2DispatchUetStructPublishGatewayDetailPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { List } from '@/pages/EnergyDispatchManagement/ElementCreationModal/Gateway/types';
import { Button, Form, Modal } from '@maxtropy/components';
import { NodeType } from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import ShowInput from '@/components/ShowInput';
import classNames from 'classnames/bind';

export enum SystemType {
  ARM = 0,
  X86 = 1,
}

export const SystemTypeLabel = {
  [SystemType.ARM]: 'ARM(智能网关)',
  [SystemType.X86]: 'X86(FCS)',
};

const cx = classNames.bind(styles);

const { Item } = Form;

interface Props {
  id?: number;
  open?: boolean;
  dispatchGroupId?: number;
  isPreview?: boolean;
  isShowModal?: (open: boolean) => void;
  getFinishedValues?: (values: any) => void;
  shouldDeleteElement?: (state: boolean) => void;
  version?: number;
  dispatchUetStructId?: number;
}
const GatewayModal: React.FC<Props> = ({
  id,
  dispatchGroupId,
  open,
  isPreview,
  isShowModal,
  getFinishedValues,
  shouldDeleteElement,
  version,
  dispatchUetStructId,
}) => {
  const [form] = Form.useForm();
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false);

  const [iconVisible, setIconVisible] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectRows, setSelectRows] = useState<List[]>([]);

  const onShowIcon = () => {
    form.validateFields().then(() => {
      setIconVisible(true);
    });
  };

  const onCancel = () => {
    form.resetFields();
    setTitle(undefined);
    isShowModal?.(false);
  };

  // 详情
  const { data: detail } = useRequest(
    async () => {
      if (isNil(id) || !open) return;
      setIsDetail(true);
      let res;
      if (!isNil(version)) {
        res = await apiV2DispatchUetStructPublishGatewayDetailPost({ id, version });
      } else {
        res = await apiV2DispatchUetStructGatewayDetailPost({ id: +id });
      }
      if (res) {
        setTitle(res.name);
        setIconVisible(true);
        setSelectedRowKeys([res.edgeGatewayId!]);
        form.setFieldsValue({
          ...res,
          systemType: SystemTypeLabel[res.systemType as SystemType],
        });
      }
      return res;
    },
    { refreshDeps: [id, open, version] }
  );

  useEffect(() => {
    if (isEmpty(selectRows)) return;
    const { serialNumber, name, id, systemType } = selectRows[0];
    form.setFieldsValue({
      name,
      edgeGatewayId: id,
      serialNumber,
      systemType: SystemTypeLabel[systemType as SystemType],
    });
  }, [form, selectRows]);

  return (
    <>
      <Modal
        open={open}
        contentClassName="modal-form-content"
        title={
          <Title
            id={id}
            styles={styles}
            isDetail={isDetail}
            isPreview={isPreview}
            title={title ?? '网关'}
            delMethod={apiV2DispatchUetStructGatewayDeletePost}
            shouldDeleteElement={state => shouldDeleteElement?.(state)}
            onEdit={() => setIsDetail(false)}
            onCancel={onCancel}
            nodeType={NodeType.NETWORK}
          />
        }
        footer={
          <Footer
            isDetail={isDetail}
            styles={styles}
            form={form}
            createMethod={apiV2DispatchUetStructGatewaySavePost}
            updateMethod={apiV2DispatchUetStructGatewayEditPost}
            getFinishedValues={val => getFinishedValues?.(val)}
            dispatchGroupId={dispatchGroupId}
            onCancel={onCancel}
          />
        }
        onCancel={onCancel}
        destroyOnClose
      >
        <div className={styles.modalWrapper}>
          <Form form={form} labelCol={{ span: 6 }} labelAlign="left">
            <Item
              label="网关编号"
              name="serialNumber"
              rules={[{ required: true, message: '请选择网关编号' }]}
              // extra={
              //   <Button
              //     type="link"
              //     onClick={e => {
              //       e.preventDefault();
              //       setAddModalVisible(true);
              //     }}
              //     className={styles.customBtn}
              //     disabled={isDetail}
              //   >
              //     {form.getFieldValue('serialNumber') ?? '网关选择'}
              //   </Button>
              // }
            >
              <span
                className={cx('select_btn', isDetail ? 'disable_btn' : '')}
                onClick={() => {
                  if (isDetail) return;
                  setAddModalVisible(true);
                }}
              >
                {selectRows[0]?.serialNumber ?? detail?.serialNumber ?? '网关选择'}
              </span>
            </Item>

            <Item name="edgeGatewayId" noStyle />

            <Item name="id" hidden />

            <Item label="网关名称" name="name">
              <ShowInput />
            </Item>

            <Item label="网关类型" name="systemType">
              <ShowInput />
            </Item>

            <div className={styles.preview}>
              <Button type="link" className={styles.previewBtn} onClick={onShowIcon}>
                查看图标
              </Button>

              <div className={styles.iconWrapper}>
                {iconVisible && (
                  <div className={styles.iconBox}>
                    <div className={styles.icon} />
                    <Item noStyle dependencies={['name']}>
                      {({ getFieldValue }) => <div className={styles.iconText}>{getFieldValue('name')}</div>}
                    </Item>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Modal>

      <AddGatewayModal
        dispatchUetStructId={dispatchUetStructId}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={(rowKeys: Key[]) => setSelectedRowKeys(rowKeys)}
        setSelectRows={setSelectRows}
        onCancel={() => setAddModalVisible(false)}
        open={addModalVisible}
      />
    </>
  );
};

export default GatewayModal;
