import { Button } from '@maxtropy/components';

export enum TextColor {
  RED = 1,
  BLUE,
  GRAY,
}

/**
 *
 * @param colorType 按钮颜色 (1，红；2，蓝；3，灰)
 * @return Button
 */
export const DeliveryButton = ({
  colorType,
  onClick,
  children,
}: {
  colorType?: number;
  onClick?: () => void;
  children?: React.ReactNode;
}) => {
  if (colorType === TextColor.RED) {
    return (
      <Button type="link" danger={true} onClick={() => onClick?.()}>
        {children}
      </Button>
    );
  }
  if (colorType === TextColor.BLUE) {
    return (
      <Button type="link" onClick={() => onClick?.()}>
        {children}
      </Button>
    );
  }
  if (colorType === TextColor.GRAY) {
    return (
      <Button type="link" disabled onClick={() => onClick?.()}>
        {children}
      </Button>
    );
  }
  return (
    <Button type="link" onClick={() => onClick?.()}>
      {children}
    </Button>
  );
};
