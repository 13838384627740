import { Button } from '@maxtropy/components';
import { TextColor } from './DeliveryButton';

/**
 *
 * @param colorType 按钮颜色 (1，红；2，蓝；3，灰)
 * @return Button
 */
export const DropdownButtonColor = ({
  colorType,
  onClick,
  children,
  disabled,
}: {
  colorType?: number;
  onClick?: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
}) => {
  if (disabled) {
    return (
      <Button style={{ padding: 0, fontSize: 12 }} type="link" disabled onClick={() => onClick?.()}>
        {children}
      </Button>
    );
  }
  if (colorType === TextColor.RED) {
    return (
      <Button style={{ padding: 0, fontSize: 12 }} type="link" danger={true} onClick={() => onClick?.()}>
        {children}
      </Button>
    );
  }
  if (colorType === TextColor.BLUE) {
    // ui规范上是白色
    return (
      <span style={{ fontSize: 12, cursor: 'pointer' }} onClick={() => onClick?.()}>
        {children}
      </span>
    );
  }
  if (colorType === TextColor.GRAY) {
    return (
      <Button style={{ padding: 0, fontSize: 12 }} type="link" disabled onClick={() => onClick?.()}>
        {children}
      </Button>
    );
  }
  return (
    <span style={{ fontSize: 12, cursor: 'pointer' }} onClick={() => onClick?.()}>
      {children}
    </span>
  );
};

/**
 *
 * @param disabled 按钮颜色 (true 禁用)
 * @return Button
 */
export const DropdownButtonCommon = ({
  onClick,
  children,
  disabled,
}: {
  onClick?: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
}) => {
  if (disabled) {
    return (
      <Button style={{ padding: 0, fontSize: 12 }} type="link" disabled onClick={() => onClick?.()}>
        {children}
      </Button>
    );
  }

  return (
    <span style={{ fontSize: 12, cursor: 'pointer' }} onClick={() => onClick?.()}>
      {children}
    </span>
  );
};
