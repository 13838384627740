import { Descriptions, Drawer, Form, Spin } from 'antd';
import React, { FC, Key, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { isEmpty, isNil } from 'lodash-es';
import { LoadType, LoadTypeLabel } from '@/pages/EnergyDispatchManagement/ElementCreationModal/Load';
import {
  apiV2DispatchUetStructDevicePagePost,
  apiV2DispatchUetStructPublishLoadGetPost,
  apiV2UetListLoadPost,
  apiV2UetListUetTopologyStructPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { useRequest } from 'ahooks';
import ShowInput from '@/components/ShowInput';
import TitleTag from '../TitleTag';
import { NodeType as NodeTypeClass } from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import { NodeType, TreeNode } from '@/pages/SchedulingTopology/type';
import { findTopGridAccessPointData } from '@/pages/EnergyDispatchManagement/ElementCreationModal/EnergyStorageStation/utils';

export interface LoadDrawerProps {
  isShowModal: (open: boolean) => void;
  open: boolean;
  id?: number;
  version?: number;
  allNodeData?: TreeNode[];
}

const { Item } = Form;

const LoadDrawer: FC<LoadDrawerProps> = props => {
  const { isShowModal, open, id, version, allNodeData } = props;

  const [form] = Form.useForm();
  const [title, setTitle] = useState<string>();

  const loadId = Form.useWatch('loadId', form);
  const [gridAccessPointId, setGridAccessPointId] = useState<Key>(); // 电网接入点id
  const urlParams = new URLSearchParams(window.location.search);
  const topologyId = urlParams.get('topologyId');

  // 负载list
  const { data: loadOptions } = useRequest(
    async () => {
      const loadList = await apiV2UetListLoadPost({ uetStructId: topologyId!, gridAccessPointId: gridAccessPointId });
      if (loadList) {
        return loadList?.list;
      }
    },
    {
      ready: !!gridAccessPointId && !!topologyId,
      refreshDeps: [gridAccessPointId, topologyId],
    }
  );

  // 控制对象list
  const { data: circuitBreakerOptions } = useRequest(
    async () => {
      if (isNil(topologyId)) return;
      const uetList = await apiV2UetListUetTopologyStructPost({ id: +topologyId });
      const uetIds = uetList?.list?.map(item => item.id) ?? [];
      const res = await apiV2DispatchUetStructDevicePagePost({
        page: 1,
        size: 500,
        deviceTypeIdList: [5028, 5029],
        uetIds: uetIds as number[],
        uetStructId: +topologyId!,
        gridAccessPointId,
      });

      if (res) {
        return res.list?.map(item => ({
          label: item.name,
          value: item.id,
        }));
      }
    },
    {
      refreshDeps: [gridAccessPointId, topologyId],
      ready: !!gridAccessPointId && !!topologyId,
    }
  );

  useEffect(() => {
    if (circuitBreakerOptions) {
      const circuitBreakerDeviceIdList = form.getFieldValue('circuitBreakerDeviceIdList');
      const filteredCircuitBreakerOptions = circuitBreakerOptions?.filter(option =>
        circuitBreakerDeviceIdList?.includes(option.value)
      );
      const circuitBreakerDeviceNameList = filteredCircuitBreakerOptions.map(i => i.label);
      form.setFieldsValue({
        circuitBreakerDeviceNameList:
          circuitBreakerDeviceNameList.length !== 0 ? circuitBreakerDeviceNameList.join() : '--',
      });
    }
  }, [circuitBreakerOptions, form]);

  const { data: detail, loading } = useRequest(
    async () => {
      const detail = await apiV2DispatchUetStructPublishLoadGetPost({ id, version });
      const title = loadOptions?.find(item => item.id === detail.loadId)?.name;
      setTitle(title);
      form.setFieldsValue({
        ...detail,
        NodeTypeName: '负载',
        name: title,
      });
      return detail;
    },
    {
      ready: !!id && !!open && !!version,
      refreshDeps: [id, loadOptions, open, version],
    }
  );

  // 通过当前id与type查找电网接入点id
  useEffect(() => {
    if (allNodeData && detail) {
      const gridAccessPointData = findTopGridAccessPointData(allNodeData, detail.id, NodeType.LOAD);
      setGridAccessPointId(gridAccessPointData?.id);
    }
  }, [allNodeData, detail]);

  useEffect(() => {
    if (isNil(loadId)) return;
    const load = loadOptions?.find(item => item.id === loadId);

    form.setFieldsValue({
      loadType: LoadTypeLabel[load?.type as LoadType],
      loop: load?.circuitName,
    });
  }, [form, loadId, loadOptions]);

  return (
    <div className={styles.drawerStyle}>
      <Drawer
        title={<TitleTag title={title ?? '负载'} nodeType={NodeTypeClass.LOAD} />}
        placement="right"
        width={400}
        mask={false}
        onClose={() => {
          isShowModal?.(false);
        }}
        open={open}
        getContainer={false}
      >
        <Spin spinning={loading}>
          <Form form={form}>
            <Descriptions labelStyle={{ width: '40%' }} title={null} bordered column={1} size="small">
              <Descriptions.Item label="元素类型">
                <Item name="NodeTypeName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="负载名称">
                <Item name="name" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>

              <Descriptions.Item label="负载类型">
                <Item name="loadType" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="关联回路">
                <Item name="loop" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
            </Descriptions>

            <Item noStyle name="id" />
            <Item noStyle name="loadId" />
            <Item noStyle name="circuitBreakerDeviceIdList" />

            <div style={{ marginTop: 16, marginBottom: 8 }}>控制对象信息</div>
            <Descriptions labelStyle={{ width: '40%' }} title={''} bordered column={1} size="small">
              <Descriptions.Item label="断路器">
                <Item name="circuitBreakerDeviceNameList" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
            </Descriptions>

            <div className={styles.preview}>
              <div className={styles.previewBtn} style={{ marginTop: 16, marginBottom: 8 }}>
                查看图标
              </div>
              <div className={styles.iconWrapper}>
                <div className={styles.iconBox}>
                  <div className={styles.icon} />

                  <Item noStyle dependencies={['loadId']}>
                    {({ getFieldValue }) => {
                      const loadId = getFieldValue('loadId');
                      const loadName = loadOptions?.find(item => item.id === loadId)?.name;
                      return (
                        <>
                          <div className={styles.iconText}>{loadName}</div>
                        </>
                      );
                    }}
                  </Item>

                  {/* <Item noStyle dependencies={['circuitBreakerDeviceIdList']}>
                    {({ getFieldValue }) => {
                      const circuitBreakerDeviceIdList = getFieldValue('circuitBreakerDeviceIdList');
                      const filteredCircuitBreakerOptions = circuitBreakerOptions?.filter(option =>
                        circuitBreakerDeviceIdList?.includes(option.value)
                      );

                      return (
                        <>
                          {isEmpty(filteredCircuitBreakerOptions) ? null : (
                            <>
                              <div className={styles.dashed} />
                              <div className={styles.controlObject}>
                                {filteredCircuitBreakerOptions?.map(item => (
                                  <div className={styles.controlObjectIcon} key={item.value}>
                                    {item.label}
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </>
                      );
                    }}
                  </Item> */}
                </div>
              </div>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </div>
  );
};

export default LoadDrawer;
