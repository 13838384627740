import { Button, EllipsisSpan, Table, useUpdate } from '@maxtropy/components';
import { useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import {
  apiV2MicrogridConfigurationDataPropertyConfigEditPost,
  apiV2MicrogridConfigurationElementDeviceLinkListPost,
  V2MicrogridConfigurationElementDeviceLinkListPostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { ColumnType } from 'antd/es/table';
import DataPropertyModal, { SaveDataItem } from './DataPropertyModal';
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from 'react';
import { Space } from 'antd';
import { v4 } from 'uuid';
import { RefProps } from '../../const';
import FooterCancelBtn from '../FooterCancelBtn';
import { NodeType, NodeTypeDisplay } from '@/pages/SchedulingTopology/type';

type DataItem = Exclude<V2MicrogridConfigurationElementDeviceLinkListPostResponse['list'], undefined>[number];
type DataItemWithUUID = DataItem & { uuid: string };

const LinkPoint: ForwardRefRenderFunction<RefProps> = (props, ref) => {
  const { uetStructId, id } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<DataItemWithUUID>();
  const [update, updateFn] = useUpdate();

  useImperativeHandle(ref, () => ({
    saveBtn: () => {},
    isEditStatus: false,
  }));

  const { data, loading } = useRequest(
    async () => {
      const res = await apiV2MicrogridConfigurationElementDeviceLinkListPost({
        id: id,
      });
      return res?.list?.map(item => ({
        ...item,
        uuid: v4(),
      }));
    },
    {
      refreshDeps: [update],
    }
  );

  const columns: ColumnType<DataItemWithUUID>[] = [
    {
      title: '元素名称',
      dataIndex: 'elementName',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v}></EllipsisSpan>,
    },
    {
      title: '类',
      dataIndex: 'element',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={NodeTypeDisplay[v as NodeType]}></EllipsisSpan>,
    },
    {
      title: '关联设备',
      dataIndex: 'linkDeviceName',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v}></EllipsisSpan>,
    },
    {
      title: '设备类目',
      dataIndex: 'deviceTypeName',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v}></EllipsisSpan>,
    },
    {
      title: '操作',
      key: 'action',
      width: 150,
      ellipsis: { showTitle: true },
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => {
            setModalOpen(true);
            setSelectedData(record);
          }}
        >
          选择/编辑数据属性
        </Button>
      ),
    },
  ];

  const [saveItemLoading, setSaveItemLoading] = useState(false);
  const onSaveItem = (val: SaveDataItem[]) => {
    setSaveItemLoading(true);
    apiV2MicrogridConfigurationDataPropertyConfigEditPost({
      uetStructId: uetStructId,
      microgridConfId: id,
      list: [
        {
          deviceId: selectedData!.linkDeviceId!,
          elementId: selectedData!.elementId!,
          element: selectedData!.element!,
          deviceTypeId: selectedData!.deviceType!,
          deviceTypeName: selectedData!.deviceTypeName!,
          details: val,
        },
      ],
    })
      .then(() => {
        setModalOpen(false);
        updateFn();
      })
      .finally(() => {
        setSaveItemLoading(false);
      });
  };

  return (
    <>
      <Table
        rowKey="uuid"
        scroll={{ y: 'calc(100vh - 400px)' }}
        dataSource={data}
        columns={columns}
        loading={loading}
      ></Table>

      <Space size={8} className="sticky-footer" style={{ paddingLeft: 0, paddingTop: 32 }}>
        <FooterCancelBtn />
      </Space>

      <DataPropertyModal
        confirmLoading={saveItemLoading}
        open={modalOpen}
        propData={
          selectedData && {
            elementId: selectedData.elementId,
            element: selectedData.element,
            uetStructId: uetStructId,
            deviceId: selectedData.linkDeviceId,
            microgridConfId: id,
          }
        }
        onCancel={() => {
          setModalOpen(false);
        }}
        onOk={val => {
          onSaveItem(val);
        }}
      />
    </>
  );
};

export default forwardRef(LinkPoint);
