import {
  Button,
  Filter,
  Wrapper,
  useBreadcrumbRoutes,
  useUpdate,
  EllipsisSpan,
  Table,
  Paging,
  usePaging,
  Tag,
  Tooltip,
  Form,
  Select,
} from '@maxtropy/components';
import React, { useState } from 'react';

import styles from './index.module.scss';
import { Col, List, Space } from 'antd';
import { useRequest } from 'ahooks';
import { getOrganization } from '@/api/customer-user';
import { tiledArray } from '@/pages/EnergyDispatchManagement/List/utils';
import { Link, useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Status, StatusLabel, StatusTagColor } from '@/pages/EnergyDispatchManagement/List/listTypes';
import dayjs from 'dayjs';
// import classnames from 'classnames';
import CreateDispatchModal from '@/pages/EnergyDispatchManagement/List/components/CreateDispatchModal';
import {
  apiV2EnergyDispatchPagePost,
  apiV2EnergyDispatchUpdateCheckPost,
  apiV2EnergyDispatchUpdateStatusPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { isEmpty, isNil } from 'lodash-es';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { ColumnsType } from 'antd/es/table';

interface Props {}
const TableTagBox = (v: any[], key: string) => {
  if (isEmpty(v)) return '--';
  if (v.length > 2) {
    let beforeNumList = v.filter((item, index) => index < 2);
    beforeNumList.push({ name: '+' + (v.length - beforeNumList.length) });
    return (
      <Tooltip
        placement="top"
        title={
          <div className={styles.tag_box}>
            {v?.map((item, index) => (
              <Tag>{`${item.name} (${item[key]})`}</Tag>
            ))}
          </div>
        }
      >
        <div className={styles.tag_box}>
          {beforeNumList?.map((item, index) => (
            <Tag style={{ display: index === beforeNumList.length - 1 ? 'inline-block' : 'block' }}>{`${item.name} ${
              item[key] ? '(' + item[key] + ')' : ''
            }`}</Tag>
          ))}
        </div>
      </Tooltip>
    );
  }
  return (
    <Tooltip
      placement="top"
      title={
        <div className={styles.tag_box}>
          {v?.map((item, index) => (
            <Tag>{`${item.name} (${item[key]})`}</Tag>
          ))}
        </div>
      }
    >
      <div className={styles.tag_box}>
        {v?.map((item, index) => (
          <Tag>{`${item.name}(${item[key]})`}</Tag>
        ))}
      </div>
    </Tooltip>
  );
};
const EnergyDispatchManagementList: React.FC<Props> = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  // 添加调度组权限
  const hasAddDispatchGroupPermission = useHasPermission(PermissionsType.B_ADDUED);
  //编辑调度组按钮
  const hasEditDispatchGroupPermission = useHasPermission(PermissionsType.B_EDITUED);
  // 启用调度组按钮
  const hasEnableDispatchGroupPermission = useHasPermission(PermissionsType.B_UEDENABLE);
  // 停用调度组按钮
  const hasDisableDispatchGroupPermission = useHasPermission(PermissionsType.B_UEDSTOP);

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [state, forceUpdate] = useUpdate();
  const [searchParams, setSearchParams] = useState<{ mcid?: string }>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const pagingInfo = usePaging(20);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const onChangeState = (id: number, state: Status) => {
    apiV2EnergyDispatchUpdateStatusPost({
      id,
      status: state,
    }).then(() => {
      forceUpdate();
    });
  };

  // 组织列表
  const { data: organization } = useRequest(async () => {
    const res = await getOrganization();
    return res ? tiledArray([res]) : [];
  });

  // 列表
  const { data: list = [], loading } = useRequest(
    async () => {
      const res = await apiV2EnergyDispatchPagePost({
        ...searchParams,
        page: pageOffset,
        size: pageSize,
      });
      if (res) {
        setTotalCount(res.total ?? 0);
        return res.list;
      }
    },
    {
      refreshDeps: [pageOffset, searchParams, state, pageSize],
    }
  );

  const filters = (
    <Filter
      form={form}
      onFinish={(v: { mcid?: string }) => {
        setSearchParams(v);
        setPageOffset(1);
      }}
      onReset={() => {
        form.resetFields();
        setSearchParams(undefined);
        setPageOffset(1);
      }}
    >
      <p className={styles.title}>能源调度管理</p>
      <Col span={8}>
        <Form.Item name="mcid" label="选择组织">
          <Select placeholder="请选择" options={organization} />
        </Form.Item>
      </Col>
    </Filter>
  );

  const naviToEdit = (id?: number) => {
    apiV2EnergyDispatchUpdateCheckPost({ id: id! }).then(_ => {
      navigate(`/energyAssetConstruction/energyTopologyManagement/energyDispatchManagement/edit/${id}/`);
    });
  };
  const columns: ColumnsType<any> = [
    {
      title: '调度组编号',
      dataIndex: 'code',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '调度组名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '当前状态',
      dataIndex: 'status',
      width: 100,
      ellipsis: { showTitle: true },
      render: (status: number) => {
        return isNil(status) ? (
          '--'
        ) : (
          <Tag border="solid" color={StatusTagColor[status as Status]}>
            {StatusLabel[status as Status]}
          </Tag>
        );
      },
    },
    {
      title: '关联组织',
      dataIndex: 'customerList',
      ellipsis: { showTitle: true },
      render: (v: any[]) => {
        return TableTagBox(v, 'code');
      },
    },
    {
      title: '关联UET',
      dataIndex: 'uetList',
      ellipsis: { showTitle: true },
      render: (v: any[]) => {
        return TableTagBox(v, 'serialNumber');
      },
    },

    {
      title: '最后编辑时间',
      dataIndex: 'updateTime',
      ellipsis: { showTitle: true },
      render: (v: any) => {
        return v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--';
      },
    },
    {
      title: '最后编辑人',
      dataIndex: 'updateCustomerUserName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '操作',
      width: 200,
      render: (v: any, item: any) => {
        return (
          <Space size={16}>
            {hasEditDispatchGroupPermission && (
              <Button
                className={styles.btn}
                type="link"
                onClick={() => {
                  naviToEdit(item.id);
                }}
                disabled={item.status === Status.ENABLE}
              >
                编辑
              </Button>
            )}

            {/*{hasEnableDispatchGroupPermission && [Status.DRAFT].includes(item.status!) && (*/}
            {/*  <Button className={styles.btn} type="link" onClick={() => onChangeState(item.id!, Status.ENABLE)}>*/}
            {/*    启用*/}
            {/*  </Button>*/}
            {/*)}*/}

            {hasDisableDispatchGroupPermission && [Status.ENABLE].includes(item.status!) && (
              <Button className={styles.btn} type="link" onClick={() => onChangeState(item.id!, Status.DRAFT)}>
                停用
              </Button>
            )}

            <Button className={styles.btn} type="link">
              <Link
                to={`/energyAssetConstruction/energyTopologyManagement/energyDispatchManagement/detail/${item.id}/`}
              >
                查看
              </Link>
            </Button>
          </Space>
        );
      },
    },
  ];
  return (
    <>
      <Wrapper routes={breadcrumbRoutes?.routes} filters={filters} className="page_wrapper">
        {hasAddDispatchGroupPermission && (
          <div className={styles.operationArea}>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => setModalVisible(true)}>
              添加调度组
            </Button>
          </div>
        )}
        <Table loading={loading} rowKey="id" columns={columns} dataSource={list} />
        <Paging pagingInfo={pagingInfo} />
      </Wrapper>

      {modalVisible && (
        <CreateDispatchModal
          visible={modalVisible}
          forceUpdate={() => forceUpdate()}
          onCancel={() => setModalVisible(false)}
        />
      )}
    </>
  );
};

export default EnergyDispatchManagementList;
