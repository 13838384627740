import { Node } from '@antv/x6';
import styles from './index.module.scss';

export interface AcBusBarProps {
  node: Node;
}

const DcBusBar = () => {
  return (
    <div className={styles.dcBusBarWrapper}>
      <div className={styles.generatrixEachNode}></div>
      <div className={styles.bline1}></div>
      <div className={styles.bline2}></div>
      <div className={styles.bline3}></div>

      <div className={styles.generatrixValueSty}>
        <div>直流母线</div>
        <div style={{ color: '#faad14' }}>{'750V'}</div>
      </div>
    </div>
  );
};

export default DcBusBar;
