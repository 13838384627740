import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { Button, Form, getRealUrl, Input, Key, Modal, Radio, Select, ShowInput, Upload } from '@maxtropy/components';
import Title from '../components/Title';
import Footer from '../components/Footer';
import { useRequest } from 'ahooks';
import { isEmpty, isNil } from 'lodash-es';
import { iconArr } from '@/pages/SchedulingTopology/utils/utils';
import {
  apiV2DispatchUetStructOtherVerticalCreatePost,
  apiV2DispatchUetStructOtherVerticalDeletePost,
  apiV2DispatchUetStructOtherVerticalListPost,
  apiV2DispatchUetStructOtherVerticalPublishGetPost,
  apiV2DispatchUetStructOtherVerticalUpdatePost,
  V2DispatchUetStructDevicePagePostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import classNames from 'classnames/bind';
import AddMetersModal from './AddMetersModal';
import { findTopGridAccessPointData } from '../EnergyStorageStation/utils';
import { allInfoProps, NodeType, NodeTypeDisplay, TreeNode } from '@/pages/SchedulingTopology/type';
import {
  LinkLeftOrRightStatus,
  LinkLeftOrRightStatusDisplay,
  LinkStatus,
  LinkStatusDisplay,
  NodeType as NodeTypeClass,
} from '../types';
import { Graph } from '@antv/x6';

const cx = classNames.bind(styles);

interface Props {
  id?: number;
  open?: boolean;
  dispatchGroupId?: number;
  isPreview?: boolean;
  isShowModal?: (open: boolean) => void;
  getFinishedValues?: (values: any) => void;
  shouldDeleteElement?: (state: boolean) => void;
  type?: NodeType;
  version?: number;
  allInfo?: allInfoProps;
  allNodeData?: TreeNode[];
  graphRef?: Graph;
}

const { Item } = Form;

const OtherVertical: FC<Props> = ({
  id,
  dispatchGroupId,
  open,
  isPreview,
  isShowModal,
  getFinishedValues,
  shouldDeleteElement,
  type,
  version,
  allInfo,
  allNodeData,
  graphRef,
}) => {
  const [form] = Form.useForm();

  const urlParams = new URLSearchParams(window.location.search);
  const topologyId = urlParams.get('topologyId');

  const [iconVisible, setIconVisible] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();
  const icon = Form.useWatch('icon', form);
  const associationType = Form.useWatch('associationType', form);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectRows, setSelectRows] = useState<V2DispatchUetStructDevicePagePostResponse['list']>([]);
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
  const [gridAccessPointId, setGridAccessPointId] = useState<Key>(); // 电网接入点id

  const onShowIcon = () => {
    form.validateFields().then(() => {
      setIconVisible(true);
    });
  };

  const onCancel = () => {
    form.resetFields();
    setTitle(undefined);
    isShowModal?.(false);
  };

  useEffect(() => {
    if (isEmpty(selectRows)) return;
    const { code, name, id } = selectRows![0];
    form.setFieldsValue({
      code,
      deviceName: name,
      deviceId: id,
    });
  }, [form, selectRows]);

  const { data: detail } = useRequest(
    async () => {
      if (isNil(id) || !open) return Promise.resolve(undefined);
      setIsDetail(true);
      let detail;
      if (!isNil(version)) {
        detail = await apiV2DispatchUetStructOtherVerticalPublishGetPost({ id, version });
      } else {
        detail = await apiV2DispatchUetStructOtherVerticalListPost({ id });
      }
      if (detail) {
        setSelectedRowKeys([detail.deviceId!]);
        form.setFieldsValue({
          ...detail,
          associationId: detail.associationId ? detail.associationId + '-' + detail.associationElement : undefined,
        });
        setIconVisible(true);
        setTitle(detail.name);
      }
      return detail;
    },
    {
      refreshDeps: [id, open, version],
    }
  );

  // 通过当前id与type查找电网接入点id
  useEffect(() => {
    if (detail) {
      if (allNodeData) {
        const gridAccessPointData = findTopGridAccessPointData(allNodeData, detail.id, type);
        setGridAccessPointId(gridAccessPointData?.id);
      }
    } else {
      if (allInfo) {
        const gridAccessPointData = findTopGridAccessPointData(
          allInfo.allNodeData,
          allInfo.node.getData().id,
          allInfo.node.getData().element
        );
        setGridAccessPointId(gridAccessPointData?.id);
      }
    }
  }, [allInfo, detail, allNodeData]);

  // 母线optios
  const busbarOptions = useMemo(() => {
    const busbarNodes = graphRef?.getNodes().filter(i => i.getData()?.element === NodeType.BUS_BAR);
    if (busbarNodes && busbarNodes.length !== 0) {
      return busbarNodes.map(i => ({
        label: i.getData()?.id + '-' + i.getData()?.value?.name,
        value: i.getData()?.id + '-' + i.getData()?.element,
      }));
    }
  }, [graphRef]);

  // 元素options
  const nodeOptions = useMemo(() => {
    let nodeNodes;
    if (id && type) {
      nodeNodes = graphRef
        ?.getNodes()
        .filter(
          i =>
            i.getData()?.element !== NodeType.BUS_BAR &&
            i.getData()?.element !== NodeType.GRID_SIDE &&
            id + type !== i.getData()?.id + i.getData()?.element
        );
    } else {
      nodeNodes = graphRef
        ?.getNodes()
        .filter(
          i =>
            i.getData()?.element !== NodeType.BUS_BAR &&
            i.getData()?.element !== NodeType.GRID_SIDE &&
            allInfo?.node.getData().id + allInfo?.node.getData().element !== i.getData()?.id + i.getData()?.element
        );
    }
    if (nodeNodes && nodeNodes.length !== 0) {
      return nodeNodes.map(i => ({
        label:
          i.getData()?.id + '-' + i.getData()?.value?.name + `(${NodeTypeDisplay[i.getData()?.element as NodeType]})`,
        value: i.getData()?.id + '-' + i.getData()?.element,
      }));
    }
  }, [graphRef, allInfo, id]);

  return (
    <>
      <Modal
        open={open}
        contentClassName="modal-form-content"
        title={
          <Title
            id={id}
            styles={styles}
            isDetail={isDetail}
            isPreview={isPreview}
            title={title ?? '其他样式-竖向'}
            delMethod={apiV2DispatchUetStructOtherVerticalDeletePost}
            onEdit={() => setIsDetail(false)}
            onCancel={onCancel}
            shouldDeleteElement={state => shouldDeleteElement?.(state)}
            nodeType={NodeTypeClass.OTHER}
          />
        }
        footer={
          <Footer
            isDetail={isDetail}
            styles={styles}
            form={form}
            dispatchGroupId={dispatchGroupId}
            createMethod={apiV2DispatchUetStructOtherVerticalCreatePost}
            updateMethod={apiV2DispatchUetStructOtherVerticalUpdatePost}
            getFinishedValues={val => getFinishedValues?.(val)}
            onCancel={onCancel}
          />
        }
        onCancel={onCancel}
        destroyOnClose
      >
        <div className={styles.modalWrapper}>
          <Form form={form} labelCol={{ span: 6 }} labelAlign="left">
            <Item name="name" label="节点名称" rules={[{ required: true, min: 1, max: 50 }]}>
              <Input placeholder="请输入" disabled={isDetail} allowClear />
            </Item>

            <Item label="设备编号" name="code">
              <span
                className={cx('select_btn', isDetail ? 'disable_btn' : '')}
                onClick={() => {
                  if (isDetail) return;
                  setAddModalVisible(true);
                }}
              >
                {selectRows?.[0]?.code ?? detail?.code ?? '设备选择'}
              </span>
            </Item>

            <Item label="设备名称" name="deviceName">
              <ShowInput />
            </Item>

            <Item name="icon" label="图标选择" rules={[{ required: true, message: '请选择图标选择' }]}>
              <Select
                placeholder="请选择"
                options={iconArr.concat({
                  label: '自定义',
                  value: 'CUSTOM',
                  img: '',
                })}
                disabled={isDetail}
                allowClear
              />
            </Item>

            {icon === 'CUSTOM' && (
              <Form.Item label="图标上传" name="file" rules={[{ required: true, message: '请上传图片' }]}>
                <Upload
                  disabled={isDetail}
                  accept={['.jpg', '.jpeg', '.png']}
                  uploadText="上传图标"
                  fileSize={1}
                  // onUploadStatus={status => {
                  //   setHasUploadStatus(status);
                  // }}
                  tip="仅支持jpg、jpeg、png，大小不超过1M，推荐160*160px大小的图标"
                />
              </Form.Item>
            )}

            <Form.Item
              label="关联情况"
              name="associationType"
              rules={[{ required: true, message: '请选择关联情况' }]}
              initialValue={LinkStatus.None}
            >
              <Radio.Group
                disabled={isDetail}
                onChange={() => {
                  form.setFieldsValue({
                    associationId: undefined,
                    associationNode: undefined,
                  });
                }}
              >
                <Radio value={LinkStatus.None}>{LinkStatusDisplay[LinkStatus.None]}</Radio>
                <Radio value={LinkStatus.BusBar}>{LinkStatusDisplay[LinkStatus.BusBar]}</Radio>
                <Radio value={LinkStatus.Node}>{LinkStatusDisplay[LinkStatus.Node]}</Radio>
              </Radio.Group>
            </Form.Item>

            {associationType === LinkStatus.BusBar && (
              <Form.Item label="关联母线" name="associationId" rules={[{ required: true, message: '请选择关联母线' }]}>
                <Select
                  disabled={isDetail}
                  style={{ width: '100%' }}
                  placeholder="请选择"
                  options={busbarOptions}
                  showSearch
                  optionFilterProp="label"
                />
              </Form.Item>
            )}

            {associationType === LinkStatus.Node && (
              <Form.Item label="关联元素" name="associationId" rules={[{ required: true, message: '请选择关联元素' }]}>
                <Select
                  disabled={isDetail}
                  style={{ width: '100%' }}
                  placeholder="请选择"
                  options={nodeOptions}
                  showSearch
                  optionFilterProp="label"
                />
              </Form.Item>
            )}

            {associationType === LinkStatus.BusBar && (
              <Form.Item
                label="关联节点"
                name="associationNode"
                rules={[{ required: true, message: '请选择关联节点' }]}
              >
                <Radio.Group disabled={isDetail}>
                  <Radio value={LinkLeftOrRightStatus.Left}>
                    {LinkLeftOrRightStatusDisplay[LinkLeftOrRightStatus.Left]}
                  </Radio>
                  <Radio value={LinkLeftOrRightStatus.Right}>
                    {LinkLeftOrRightStatusDisplay[LinkLeftOrRightStatus.Right]}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            )}

            <Item name="id" hidden />
            <Item name="deviceId" hidden />
            <Item name="type" initialValue={0} hidden />

            <div className={styles.preview}>
              <Button type="link" className={styles.previewBtn} onClick={onShowIcon}>
                查看图标
              </Button>

              <div className={styles.iconWrapper}>
                {iconVisible && (
                  <div className={styles.iconBox}>
                    <div
                      className={styles.icon}
                      style={{
                        backgroundImage: `url(${
                          form.getFieldValue('file')
                            ? getRealUrl(form.getFieldValue('file'))
                            : iconArr.find(i => i.value === icon)?.img
                        })`,
                      }}
                    />
                    <Item noStyle dependencies={['name']}>
                      {({ getFieldValue }) => <div className={styles.iconText}>{getFieldValue('name')}</div>}
                    </Item>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Modal>
      <AddMetersModal
        topologyId={topologyId}
        gridAccessPointId={gridAccessPointId}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={(rowKeys: Key[]) => setSelectedRowKeys(rowKeys)}
        setSelectRows={setSelectRows}
        onCancel={() => setAddModalVisible(false)}
        open={addModalVisible}
      />
    </>
  );
};

export default OtherVertical;
