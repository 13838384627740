import {
  apiV2DeviceAttributePost,
  apiV2DispatchUetStructDevicePagePost,
  apiV2DispatchUetStructPublishTransformerGetPost,
  apiV2UetListUetTopologyStructPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { useRequest } from 'ahooks';
import { Descriptions, Drawer, Form, Spin } from 'antd';
import { isNil, omit } from 'lodash-es';
import React, { FC, Key, useEffect, useState } from 'react';
import styles from './index.module.scss';
import ShowInput from '@/components/ShowInput';
import {
  NodeType as NodeTypeClass,
  VoltageLevel,
  VoltageLevelLabel,
} from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import TitleTag from '../TitleTag';
import { NodeType, TreeNode } from '@/pages/SchedulingTopology/type';
import { findTopGridAccessPointData } from '@/pages/EnergyDispatchManagement/ElementCreationModal/EnergyStorageStation/utils';

export interface TransformerDrawerProps {
  isShowModal: (open: boolean) => void;
  open: boolean;
  id?: number;
  version?: number;
  allNodeData?: TreeNode[];
  dispatchUetStructId?: number;
}

const { Item } = Form;

const TransformerDrawer: FC<TransformerDrawerProps> = props => {
  const { isShowModal, open, id, version, allNodeData } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const topologyId = urlParams.get('topologyId');
  const [form] = Form.useForm();
  const [title, setTitle] = useState<string>();
  const deviceId = Form.useWatch('deviceId', form);
  const [gridAccessPointId, setGridAccessPointId] = useState<Key>(); // 电网接入点id

  // 变压器list
  const { data: deviceOptions } = useRequest(
    async () => {
      if (!open) return Promise.resolve([]);
      const uetList = await apiV2UetListUetTopologyStructPost({ id: +topologyId! });
      const uetIds = uetList?.list?.map(item => item.id) ?? [];
      const res = await apiV2DispatchUetStructDevicePagePost({
        page: 1,
        size: 500,
        deviceTypeIdList: [5012, 5011],
        uetIds: uetIds as number[],
        uetStructId: +topologyId!,
        gridAccessPointId,
      });

      if (res && res.list) {
        return res.list.map(list => ({
          label: list.name,
          value: list.id,
        }));
      }
    },
    {
      refreshDeps: [id, open, gridAccessPointId, topologyId],
      ready: !!gridAccessPointId && !!topologyId,
    }
  );

  // 详情
  const { data: detail } = useRequest(
    async () => {
      if (isNil(id) || !open) return Promise.resolve(undefined);
      if (!isNil(version)) {
        const detail = await apiV2DispatchUetStructPublishTransformerGetPost({ id, version });
        form.setFieldsValue({
          ...detail,
          NodeTypeName: '变压器',
          targetLoad: (detail.targetLoad ?? '--') + 'kW',
          targetLoadRate: (detail.targetLoadRate ?? '--') + '%',
          highVoltageLevel: VoltageLevelLabel[detail.highVoltageLevel as VoltageLevel],
          lowerVoltageLevel: VoltageLevelLabel[detail.lowerVoltageLevel as VoltageLevel],
          thresholdValue: (detail.thresholdValue ?? '--') + 'kW',
        });
        return detail;
      }
    },
    {
      refreshDeps: [id, open, version],
      ready: !!version && open,
    }
  );

  // 通过当前id与type查找电网接入点id
  useEffect(() => {
    if (allNodeData && detail) {
      const gridAccessPointData = findTopGridAccessPointData(allNodeData, detail.id, NodeType.TRANSFORMER);
      setGridAccessPointId(gridAccessPointData?.id);
    }
  }, [allNodeData, detail]);

  useEffect(() => {
    if (deviceOptions && detail) {
      const title = deviceOptions?.find(option => option.value === detail?.deviceId);
      setTitle(title?.label);
      form.setFieldsValue({
        name: title?.label,
      });
    }
  }, [deviceOptions, detail]);

  // 设置容量
  const { loading } = useRequest(
    async () => {
      form.resetFields(['capacity']);
      if (isNil(deviceId)) {
        return;
      }
      const deviceProperties = await apiV2DeviceAttributePost({
        id: deviceId,
      });
      if (deviceProperties) {
        const { list } = deviceProperties;
        const capacityValue = list?.find(list => list.fieldId === 31)?.value;

        form.setFieldsValue({
          capacity: (capacityValue ?? '--') + 'kVA',
        });
      }
    },
    {
      refreshDeps: [deviceId, id],
    }
  );

  return (
    <div className={styles.drawerStyle}>
      <Drawer
        title={<TitleTag title={title ?? '变压器'} nodeType={NodeTypeClass.TRANSFORMER} />}
        placement="right"
        width={400}
        mask={false}
        onClose={() => {
          isShowModal?.(false);
        }}
        open={open}
        getContainer={false}
      >
        <Spin spinning={loading}>
          <Form form={form}>
            <Descriptions labelStyle={{ width: '40%' }} title={null} bordered column={1} size="small">
              <Descriptions.Item label="元素类型">
                <Item name="NodeTypeName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="变压器名称">
                <Item name="name" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="容量">
                <Item name="capacity" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="目标负载">
                <Item name="targetLoad" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="目标负载率">
                <Item name="targetLoadRate" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="高压侧电压">
                <Item name="highVoltageLevel" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="低压侧电压">
                <Item name="lowerVoltageLevel" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="防超容阈值">
                <Item name="thresholdValue" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
            </Descriptions>

            <Item name="deviceId" hidden />

            <div className={styles.preview}>
              <div className={styles.previewBtn} style={{ marginTop: 16, marginBottom: 8 }}>
                查看图标
              </div>

              <div className={styles.iconWrapper}>
                <div className={styles.iconBox}>
                  <div className={styles.icon} />

                  <Item noStyle dependencies={['deviceId', 'highVoltageLevel', 'lowerVoltageLevel']}>
                    {({ getFieldValue }) => {
                      const deviceName = deviceOptions?.find(item => item.value === getFieldValue('deviceId'))?.label;
                      const highVoltageLevel = getFieldValue('highVoltageLevel');
                      const lowerVoltageLevel = getFieldValue('lowerVoltageLevel');

                      return (
                        <>
                          <div className={styles.iconText}>{deviceName}</div>
                          <div className={styles.highVoltage}>
                            {highVoltageLevel?.split('kV')?.[0]}/{lowerVoltageLevel?.split('kV')?.[0]} kV
                          </div>
                        </>
                      );
                    }}
                  </Item>
                </div>
              </div>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </div>
  );
};

export default TransformerDrawer;
